import React from "react";
import { useHover } from "react-aria";
import { useLocalStorage } from "react-use";

import {
  Analysis,
  BarChart,
  Burn2,
  Calculator2,
  CheckList2,
  Dashboard2,
  Download2,
  Inbox2,
  Payroll,
  Settings2,
  Transactions,
  PlugOutline2,
  Sparkle2,
} from "@puzzle/icons";

import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { ACCOUNTING_ROUTES, Route } from "lib/routes";

import { Text } from "ve";

import { useActiveCompany, PricingFeatures } from "components/companies/ActiveCompanyProvider";
import { useInboxStore } from "components/dashboard/Inbox/InboxContext";

import Minimizer from "./Minimizer";
import ProfileMenu from "./ProfileMenu";
import CompanyMenu from "./CompanyMenu";

import { NavSection, RootNavItem, SubNavItem, CmdKItem } from "./NavItems";

import {
  rootRecipe,
  listStyle,
  headerContainerStyle,
  headerStyle,
  contentContainerStyle,
  footerContainerStyle,
  footerListStyle,
  inboxBadgeStyle,
} from "./sidebarV2.css";
import { TrialInfoPanel } from "./TrialInfoPanel/TrialInfoPanel";
import { IS_LOCAL_DEVELOPMENT } from "lib/config";

import { AI_LABS_SECTION_TITLE } from "components/aiLabs/constants";
import { AlphaTag } from "components/aiLabs/components/AlphaTag";
import { MembershipRole } from "graphql/types";
import { MatchedSource } from "components/dashboard/Accounting/Payments/PaymentsQsContext";

type SidebarV2Props = {
  minimized: boolean;
  onToggleMinimized: (minimize: boolean) => void;
  setExplorePuzzleDialogOpen: (open: boolean) => void;
};

const OPEN_SECTION_KEY = "pz:nav:open-section";

export const SidebarV2 = ({ minimized, onToggleMinimized }: SidebarV2Props) => {
  const { membershipRole } = useActiveCompany<true>();
  const [openSection, setOpenSection] = useLocalStorage(OPEN_SECTION_KEY, "none");
  const { hoverProps, isHovered: isHeaderHovered } = useHover({});
  const { pricePlanFeatureEnabled, company } = useActiveCompany<true>();
  const numberOfUpdates = useInboxStore("updates", (state) => state.metadata.unseen_count);
  const numberOfTasks = useInboxStore("tasks", (state) => state.metadata.unseen_count);
  const inboxTotal = numberOfUpdates + numberOfTasks;
  const showCustomers = isPosthogFeatureFlagEnabled(FeatureFlag.CustomersPage);
  const showProducts = isPosthogFeatureFlagEnabled(FeatureFlag.ProductsPage);
  const showAILabs = isPosthogFeatureFlagEnabled(FeatureFlag.AILabs);
  const showArPayments = company.features.arPaymentsEnabled || IS_LOCAL_DEVELOPMENT;
  const showApPayments = company.features.apPaymentsEnabled || IS_LOCAL_DEVELOPMENT;

  const toggleSection = (section: string) => {
    setOpenSection(section === openSection ? "none" : section);
  };

  return (
    <div className={rootRecipe({ minimized })}>
      <div className={headerContainerStyle}>
        <div className={headerStyle} {...hoverProps}>
          <Minimizer minimized={minimized} onToggle={onToggleMinimized} hovered={isHeaderHovered} />
          <ProfileMenu minimized={minimized} />
        </div>
        <CompanyMenu />
      </div>

      <div className={contentContainerStyle}>
        <ul className={listStyle}>
          <RootNavItem
            title="Inbox"
            route={Route.inbox}
            icon={<Inbox2 size={20} />}
            suffix={
              <div className={inboxBadgeStyle}>
                <Text variant="bodyXS" color="white">
                  {inboxTotal}
                </Text>
              </div>
            }
          />

          <RootNavItem title="Dashboard" route={Route.home} icon={<Dashboard2 size={20} />} />
          {/** To be revisited
          <NavSection
            title="Dashboard"
            icon={<Dashboard2 size={20} />}
            routes={[Route.home]}
            localStoreKey={DASHBOARD_KEY}
            sectionGap
          >
            <DashboardSubNavItem
              title="Cash activity"
              type={DynamicReportType.CashActivityReport}
            />
            <DashboardSubNavItem title="Profit & loss" type={DynamicReportType.ProfitAndLoss} />
            <DashboardSubNavItem title="Balance sheet" type={DynamicReportType.BalanceSheet} />
          </NavSection>
           */}

          <RootNavItem title="Checklists" route={Route.checklist} icon={<CheckList2 size={20} />} />

          <RootNavItem
            title="Transactions"
            route={Route.transactions}
            icon={<Transactions size={20} />}
          />

          <NavSection
            title="Analysis"
            icon={<Analysis size={20} />}
            routes={[Route.spending, Route.revenue, Route.people]}
            open={openSection === "analysis"}
            onClick={() => toggleSection("analysis")}
          >
            <SubNavItem
              title="Spending"
              route={Route.spending}
              featureGated={
                !pricePlanFeatureEnabled.has(PricingFeatures.spend_explorer_vendor_insights)
              }
            />
            <SubNavItem title="Revenue" route={Route.revenue} />
            <SubNavItem
              title="People"
              route={Route.people}
              featureGated={!pricePlanFeatureEnabled.has(PricingFeatures.people_insights)}
            />
          </NavSection>

          {showAILabs && (
            <RootNavItem
              title={AI_LABS_SECTION_TITLE}
              route={Route.aiLabs}
              icon={<Sparkle2 size={20} />}
              suffix={<AlphaTag />}
            />
          )}
          {membershipRole !== MembershipRole.ClientViewer && (
            // will eventually want to handle this through access in VerifyRouteAccessProvider
            // but for the time being for Decimal launch removing this is just about
            // cleaning up the UI and not really about access control, if they happen upon
            // these pages somehow it's okay if we show them
            //
            // doing this through the access requires a few more changes due to how
            // some of the routes are set up currently with URLs not related to the
            // parent section they are in in the new side nav, product is aware of this
            // and may approve changing those URLs soon
            <NavSection
              title="Accounting"
              icon={<Calculator2 size={20} />}
              routes={ACCOUNTING_ROUTES.map((r) => r.path)}
              open={openSection === "accounting"}
              onClick={() => toggleSection("accounting")}
              sectionGap
            >
              {ACCOUNTING_ROUTES.map((r) => (
                <SubNavItem key={r.path} title={r.displayText} route={r.path} />
              ))}
            </NavSection>
          )}

          <NavSection
            title="Revenue"
            icon={<BarChart size={20} />}
            routes={[Route.invoices, Route.customers, Route.arAging]}
            open={openSection === "revenue"}
            onClick={() => toggleSection("revenue")}
          >
            <SubNavItem title="Invoices" route={Route.invoices} />
            {showCustomers && <SubNavItem title="Customers" route={Route.customers} />}
            {showProducts && <SubNavItem title="Products" route={Route.products} />}
            <SubNavItem title="Revenue recognition" route={Route.revenueRecognition} />
            <SubNavItem title="Accrual revenue" route={Route.accrualRevenue} />
            <SubNavItem title="AR aging" route={Route.arAging} />
            {showArPayments && <SubNavItem title="Invoice Payments" route={Route.newPayment} />}
          </NavSection>

          <NavSection
            title="Expenses"
            icon={<Burn2 size={20} />}
            routes={[Route.bills, Route.vendors, Route.prepaidExpenses, Route.apAging]}
            open={openSection === "expenses"}
            onClick={() => toggleSection("expenses")}
          >
            <SubNavItem title="Bills" route={Route.bills} />
            <SubNavItem title="Vendors" route={Route.vendors} />
            <SubNavItem title="Prepaid expenses" route={Route.prepaidExpenses} />
            <SubNavItem title="AP aging" route={Route.apAging} />
            {showApPayments && (
              <SubNavItem
                title="Bill Payments"
                route={`${Route.newPayment}?matchedSource=${MatchedSource.Bill}` as Route}
              />
            )}
          </NavSection>

          <RootNavItem title="Payroll" route={Route.payroll} icon={<Payroll size={20} />} />

          <RootNavItem title="Downloads" route={Route.reports} icon={<Download2 size={20} />} />

          <RootNavItem
            title="Integrations"
            route={Route.integrations}
            icon={<PlugOutline2 size={20} />}
            sectionGap
          />
          <NavSection
            title="Settings"
            icon={<Settings2 size={20} />}
            routes={[
              Route.companySettings,
              Route.billing,
              Route.automationSettings,
              Route.notificationSettings,
            ]}
            open={openSection === "settings"}
            onClick={() => toggleSection("settings")}
          >
            <SubNavItem title="Company" route={Route.companySettings} />
            <SubNavItem title="Billing" route={Route.billing} />
            <SubNavItem title="Accounting" route={Route.automationSettings} />
            <SubNavItem title="Notifications" route={Route.notificationSettings} />
          </NavSection>
        </ul>
      </div>
      <div className={footerContainerStyle}>
        {!minimized && <TrialInfoPanel />}
        <ul className={footerListStyle}>
          <CmdKItem />
        </ul>
      </div>
    </div>
  );
};

import React, { useCallback } from "react";
import {
  useAddItemsToStripeSubscriptionMutation,
  useCreateStripeCheckoutSessionMutation,
} from "../graphql.generated";
import { useToasts } from "@puzzle/ui";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { PuzzlePriceBillingInterval } from "graphql/types";
import useAppRouter from "lib/useAppRouter";
import Analytics from "lib/analytics";
import useSelf from "components/users/useSelf";
import { useRouter } from "next/router";
import { reportError } from "lib/errors";
import {
  sendUpgradePlanAnalyticsEvent,
  joinWithCommasAnd,
  createStripeCheckoutSessionProps,
  getStripeIds,
} from "./utils";
import { usePlansPricingModal } from "./usePlansPricingModal";
import { useAddOnConfirmationDialog } from "../AddOnConfirmationDialog/useAddOnConfirmationDialog";

export const useCheckoutSummary = () => {
  const { self } = useSelf();
  const router = useRouter();
  const { company, refetchActiveCompany, refetchUserCompanies } = useActiveCompany<true>();
  const puzzleSubscriptionState = company.puzzleSubscriptionState;
  const { goToBilling } = useAppRouter();
  const {
    preSelectedAddOns,
    selectedCycle,
    hideUpgradeModal,
    selectedPlanId,
    selectedPlan,
    isbookkeepingEnabled,
  } = usePlansPricingModal();
  const [addItemsToStripeSubscription] = useAddItemsToStripeSubscriptionMutation();
  const [createStripeCheckoutSession] = useCreateStripeCheckoutSessionMutation();
  const { toast } = useToasts();
  const { showAddOnConfirmationDialog } = useAddOnConfirmationDialog();

  const checkoutSubscribeToPlan = useCallback(async () => {
    if (selectedPlan) {
      sendUpgradePlanAnalyticsEvent(
        company.id,
        self?.id ?? undefined,
        selectedPlan.id,
        selectedPlan.isFree
      );
      try {
        const stripeCheckoutSession = await createStripeCheckoutSession({
          variables: {
            input: createStripeCheckoutSessionProps(
              company.id,
              selectedPlan,
              preSelectedAddOns ?? [],
              isbookkeepingEnabled,
              selectedCycle
            ),
          },
        });

        const url = stripeCheckoutSession.data?.createStripeCheckoutSession.url;
        if (url) {
          router.push(url);
        } else {
          // If theres no URL, then something went absolutely wrong
          // or theres no plane associated to that priceId.
          reportError("Could not generate Stripe checkout URL", {
            company: company.id,
            selectedPlan,
            preSelectedAddOns,
          });
        }
      } catch (e: any) {
        reportError(e);
        toast({
          status: "warning",
          message: "Oops! It seems there are no changes to your current subscription.",
        });

        return;
      }
    }
  }, [
    company.id,
    createStripeCheckoutSession,
    isbookkeepingEnabled,
    preSelectedAddOns,
    router,
    selectedCycle,
    selectedPlan,
    self?.id,
    toast,
  ]);

  const onCheckout = useCallback(async () => {
    Analytics.checkoutReviewCtaClicked();
    const isSubscribingToAddOn = selectedPlanId === puzzleSubscriptionState?.billedPlan?.plan.id;
    if (isSubscribingToAddOn) {
      showAddOnConfirmationDialog();
    } else if (selectedPlanId) {
      await checkoutSubscribeToPlan();
    }
  }, [
    checkoutSubscribeToPlan,
    puzzleSubscriptionState?.billedPlan?.plan.id,
    selectedPlanId,
    showAddOnConfirmationDialog,
  ]);
  const addAddOnsToStripeSubscription = useCallback(async () => {
    const stripeIds = getStripeIds(preSelectedAddOns ?? [], selectedCycle);

    try {
      await addItemsToStripeSubscription({
        variables: {
          input: {
            companyId: company.id,
            stripePriceIds: stripeIds,
          },
        },
      });
      refetchActiveCompany();
      toast({
        duration: 10000,
        message: (
          <>
            You are now subscribed to{" "}
            {joinWithCommasAnd((preSelectedAddOns ?? []).map((addon) => addon.name))}{" "}
            {selectedCycle === PuzzlePriceBillingInterval.Year ? "yearly" : "monthly"}.
          </>
        ),
        onAction: refetchActiveCompany,
        actionText: "View in Plans & Billing",
        title: "Subscription updated",
      });
      hideUpgradeModal();
    } catch (e) {
      toast({
        duration: 5000,
        message: "Your addon billing cycle must match your subscription cycle.",
        title: "Oops!",
        status: "error",
      });
    }
  }, [
    addItemsToStripeSubscription,
    company.id,
    hideUpgradeModal,
    preSelectedAddOns,
    refetchActiveCompany,
    selectedCycle,
    toast,
  ]);

  return {
    onCheckout,
    addAddOnsToStripeSubscription,
  };
};

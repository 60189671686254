import { FeedItem } from "@knocklabs/client";
import React from "react";
import { AdditionalServicesFeedItemData } from "../shared";
import { Stack, Text } from "@puzzle/ui";
import { AdditionalServices } from "graphql/types";
import { ErrorPane } from "./ErrorPane";
import { Widget } from "@typeform/embed-react";
import { Box } from "ve";

type AdditionalServicesPaneProps = {
  item: FeedItem<AdditionalServicesFeedItemData>;
}

const TYPEFORM_PUZZLE_BOOKKEEPING_FORM_ID = "ZhXqVqTG";

export const AdditionalServicesPane = ({ item }: AdditionalServicesPaneProps) => {
  if (item.data?.service === AdditionalServices.Bookkeeping) {
    return (
      <Stack css={{ gap: "$3", padding: "$5", height: "100%" }}>
        <Text variant="headingL">Complete the bookkeeping pairing survey</Text>
        <Text color="gray400" css={{ lineHeight: "$bodyS" }}>
          You've indicated that you'd like to add bookkeeping services to your Puzzle experience.
          <br />
          Fill out the following short survey and Puzzle will match you with the optimal accounting
          pro for your business.
        </Text>
        <Box css={{ flex: "0 1 643px", display: "flex" }}>
          <Widget id={TYPEFORM_PUZZLE_BOOKKEEPING_FORM_ID} style={{ flex: "1 0 auto" }} />
        </Box>
      </Stack>
    );
  }
  return <ErrorPane />;
};

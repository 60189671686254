import React from "react";
import Image from "next/image";
import { DialogProps, Dialog, Text } from "@puzzle/ui";
import UpgradeButton from "./UpgradeButton";

import spotlightGateImage from "public/images/featureGate/spotlight_featuregate.png";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface SpotlightFeatureGateModalProps extends DialogProps {
  onClose: () => void;
}

export const SpotlightFeatureGateModal = ({
  onClose,
  ...props
}: SpotlightFeatureGateModalProps) => {
  return (
    <Dialog size={"xlarge"} {...props}>
      <Dialog.Title css={{ textVariant: "$headingS" }} onClose={onClose}>
        Spotlight financial changes
      </Dialog.Title>

      <Dialog.Body
        css={{
          padding: "$3",
          display: "flex",
          flexDirection: "column",
          gap: "$2h",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image src={spotlightGateImage} alt="spending" width={1096} height={342} />

        <Text
          css={{
            fontSize: "bodyM",
            lineHeight: "headingL",
            color: "$gray200",
            textAlign: "center",
          }}
        >
          Highlights your company's most important cash activity from the most recent period
        </Text>
        <UpgradeButton onClick={onClose} />
      </Dialog.Body>
    </Dialog>
  );
};

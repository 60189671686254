export enum LaunchpadSource {
  DashboardLaunchpadBanner = "Dashboard Launchpad Banner",
  TopNavBar = "Top Nav Bar",
  LaunchpadFullScreen = "Launchpad full screen",
}

export enum LaunchpadStepAction {
  Viewed = "Viewed",
  Skipped = "Skipped",
  Completed = "Completed",
}

export enum LaunchpadTask {
  SetBookkeepingPlan = "Set your bookkeeping plan",
  SetTaxPlan = "Set your tas plan",
  VerifyBankBalances = "Verify your opening balances",
  RreviewCategorizeTransactions = "Review & categorize transactions",
  ReviewVariancesWithSpotlight = "Review variances with Spotlight",
  ReviewMonthlyCloseChecklist = "Review your monthly close checklist",
  InviteYourTeam = "Invite your team",
  ConnectFinancialAccounts = "Connect all of your financial accounts",
  GetPuzzleCertified = "Get Puzzle Certified",
  ConfigureYourAccountingSettings = "Configure your accounting settings",
}

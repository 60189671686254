import Icon, { IconProps } from "./Icon";

export default function Checkbox({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  fill = "none",
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} fill={fill} viewBox={viewBox} {...iconProps}>
      <rect
        x="0.75"
        y="0.75"
        width={width}
        height={height}
        rx="3.25"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
    </Icon>
  );
}

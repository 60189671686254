import React from "react";
import { Button, ButtonGroup, Text, styled } from "@puzzle/ui";
import { useQueryState } from "next-usequerystate";
import useMonetization from "../useMonetization";

const StepButton = styled(Button, {
  textVariant: "bodyXS",

  variants: {
    size: {
      medium: {
        padding: "$1 $2 $1 $1",
      },
    },
    variant: {
      active: {
        color: "$white",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 33.85%, rgba(0, 0, 0, 0.20) 100%), rgba(150, 109, 213, 0.40)",
        "&:hover, &:active, &:focus": {
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 33.85%, rgba(0, 0, 0, 0.20) 100%), rgba(150, 109, 213, 0.40)",
        },
      },
      shadow: {
        color: "$white",
        opacity: "0.4",
        background: "transparent",
        "&:hover, &:active, &:focus": {
          background: "transparent",
        },
      },
    },
  },
  defaultVariants: {
    variant: "active",
    size: "medium",
  },
});

const StepNumber = styled(Text, {
  borderRadius: "50%",
  width: "24px",
  height: "24px",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "$mauve600",
  marginRight: "$1",

  variants: {
    variant: {
      active: {
        background: "rgba(255, 255, 255, 0.20)",
      },
    },
  },

  defaultVariants: {
    variant: "active",
  },
});

const STEPS = ["Choose your plan", "Review plan & services"];

// Just to keep it simple, this could evolve into a new type
// that reflects a lot new steps.
type StepChooserProps = {
  step: 1 | 2;
}

export const StepChooser = ({ step }: StepChooserProps) => {
  const { clearAddons } = useMonetization();
  const [, setSelectedPlan] = useQueryState("selectedPlan");
  const [, setSelectedCycle] = useQueryState("billingCycle");
  const handleStepClick = (newStep: number) => {
    if (step === newStep || newStep === 2) return;
    clearAddons();
    setSelectedPlan(null, { shallow: true });
  };
  return (
    <ButtonGroup
      css={{
        gap: "0",
        borderRadius: "40px",
        background: "rgba(0,0,0,0.2)",
        display: "inline-flex",
      }}
    >
      {STEPS.map((stepTitle, i) => (
        <StepButton
          onClick={() => handleStepClick(i + 1)}
          variant={i + 1 !== step ? "shadow" : "active"}
          key={`payment-step-${i}`}
          shape="pill"
        >
          <Text variant="bodyXS">
            <StepNumber>{i + 1}</StepNumber>
            {stepTitle}
          </Text>
        </StepButton>
      ))}
    </ButtonGroup>
  );
};

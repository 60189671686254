import { useCallback, useMemo } from "react";
import { useToasts } from "@puzzle/ui";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

import { useUpdateOnboardingStepMutation } from "components/companies/graphql.generated";
import { OnboardingStepStatus, OnboardingStep } from "graphql/types";

const errorMessage =
  "Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again in a few minutes.";

const useUpdateCompanyOnboardingStep = () => {
  const [updateOnboardingStep] = useUpdateOnboardingStepMutation();
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();
  return {
    mutation: useCallback(
      (step: OnboardingStep, status: OnboardingStepStatus) => {
        return updateOnboardingStep({
          variables: {
            input: {
              companyId: company.id,
              step,
              status,
            },
          },

          onError() {
            toast({ message: errorMessage, status: "warning" });
          },
        });
      },
      [updateOnboardingStep, toast, company]
    ),
  };
};

export const STEP_REQUIRED = {
  configureAccountSettings: false,
  connectFinancialAccounts: false,
  getPuzzleCertified: false,
  inviteTeam: false,
  reviewMonthEndChecklist: true,
  reviewTransaction: true,
  setBookkeepingPlan: true,
  verifyBankBalance: true,
};
export const STEP_COUNT = Object.keys(STEP_REQUIRED).length;

export const countNumCompletedSteps = (
  onboardingProgress: Record<string, OnboardingStepStatus | null | undefined>
) => {
  return Object.values(onboardingProgress).filter(
    (status) => status === OnboardingStepStatus.Completed
  ).length;
};

export const countNumSkippedSteps = (
  onboardingProgress: Record<string, OnboardingStepStatus | null | undefined>
) => {
  return Object.values(onboardingProgress).filter(
    (status) => status === OnboardingStepStatus.Skipped
  ).length;
};

export const countOverallCompletedSteps = (
  onboardingProgress: Record<string, OnboardingStepStatus | null | undefined>
) => {
  return Object.keys(onboardingProgress).filter((step) => {
    if (onboardingProgress[step] === OnboardingStepStatus.Completed) {
      return true;
    }
    if (
      !STEP_REQUIRED[step as keyof typeof STEP_REQUIRED] &&
      onboardingProgress[step] === OnboardingStepStatus.Skipped
    ) {
      return true;
    }
    return false;
  }).length;
};

export const useOnboardingProgress = () => {
  const { company } = useActiveCompany<true>();
  const { mutation } = useUpdateCompanyOnboardingStep();

  const { __typename: _, ...onboardingProgress } = company?.attributes?.onboardingProgress ?? {}; // eslint-disable-line  @typescript-eslint/no-unused-vars

  const { completionFraction, skipFraction, showLaunchbarButton } = useMemo(() => {
    const completionFraction = countNumCompletedSteps(onboardingProgress) / STEP_COUNT;
    const skipFraction = countNumSkippedSteps(onboardingProgress) / STEP_COUNT;
    const showLaunchbarButton = countOverallCompletedSteps(onboardingProgress) < STEP_COUNT;
    return { completionFraction, skipFraction, showLaunchbarButton };
  }, [company?.attributes?.onboardingProgress]);

  return {
    showLaunchbarButton,
    completionFraction,
    skipFraction,
    onboardingProgress,
    updateOnboardingStep: mutation,
  };
};

import React, { ComponentProps } from "react";
import { LockOutline, LongArrowLeft } from "@puzzle/icons";
import { Button, Text, styled } from "@puzzle/ui";
import { Box, S, color, vars } from "ve";

export const Title = styled("h1", {
  textVariant: "$heading2",
  color: "$gray100",
});

export const Description = styled("p", {
  textVariant: "$bodyS",
  color: "$gray400",
});

export const Body = styled("div", {
  marginBottom: "$14",
});

export const BodyFlex = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$2",
  marginBottom: "$14",
});

export const Expand = styled("div", {
  flex: "1 0 auto",
});

export const Footer = styled("div", {
  position: "fixed",
  bottom: 0,
  left: 0,
  height: "80px",
  display: "flex",
  justifyItems: "space-between",
  alignItems: "center",
  padding: "$2h",
  borderTop: "1px solid rgba(255,255,255,0.03)",
  margin: 0,
  width: "100%",
  right: 0,
  "@l": {
    width: "auto",
    right: "480px",
  },
});

export type UseSandboxCompanyButtonProps = ComponentProps<typeof Button> & { text?: string };

export const UseSandboxCompanyButton = (props: UseSandboxCompanyButtonProps) =>
  props.disabled ? (
    <div>Preparing your company, you will be able to seed with test data in a moment...</div>
  ) : (
    <Button {...props} variant="primary">
      {props.text ?? "Seed With Sandbox Data"}
    </Button>
  );

export const BackButton = (props: ComponentProps<typeof Button>) => (
  <Button {...props} variant="minimal" prefix={<LongArrowLeft />}>
    Back
  </Button>
);

export const NextButton = (props: ComponentProps<typeof Button>) => (
  <Button {...props}>Next</Button>
);

export const SkipButton = ({ css = {}, ...rest }: ComponentProps<typeof Button>) => (
  <Button {...rest} variant="minimal" css={{ marginTop: "$2", ...css }} />
);

export const ConnectButton = ({ css = {}, ...rest }: ComponentProps<typeof Button>) => (
  <Button {...rest} variant="secondary" shape="pill" css={{ marginTop: "$2", ...css }} />
);

export const SecureMessage = ({ css = {}, ...rest }: ComponentProps<typeof Box>) => (
  <Box
    {...rest}
    css={{ display: "flex", padding: S["1h"], gap: S["1h"], borderRadius: vars.radii[2], ...css }}
  >
    <Box css={{ alignSelf: "center", color: color.gray400 }}>
      <LockOutline size={16} />
    </Box>
    <Text variant="bodyS" color="gray400" css={{ display: "block" }}>
      Puzzle connects to your accounts in compliance with <Text color="gray200">SOC 2</Text>{" "}
      security standards. Puzzle also does <Text color="gray200">NOT</Text> store passwords and
      account information.
    </Text>
  </Box>
);

type AnyOtherPlatformMessageProps = {
  title?: string;
  message?: string;
}

export const AnyOtherPlatformMessage = ({
  title = "Other platforms",
  message = "You can add them manually anytime later",
}: AnyOtherPlatformMessageProps) => (
  <Box
    css={{
      padding: S["2h"],
      display: "flex",
      flexDirection: "column",
      gap: S["0h"],
      borderRadius: vars.radii[2],
      border: `1px solid ${color.gray700}`,
    }}
  >
    <Text variant="headingS" color="gray50">
      {title}
    </Text>
    <Text variant="bodyXS" color="gray300">
      {message}
    </Text>
  </Box>
);

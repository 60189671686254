/* eslint-disable @typescript-eslint/consistent-type-definitions */
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { clamp, noop } from "lodash";

export interface StepOption {
  name: string;
  label: string;
  disabled?: boolean;
  // TODO generic or somewhere, or infer?
  component: (props: React.PropsWithChildren<any>) => React.ReactNode;
}
interface ExposedStepOption extends StepOption {
  active: boolean;
  completed: boolean;
}

export interface WizardContextType<T extends StepOption = StepOption> {
  activeStepIndex: number;
  activeStep: T & ExposedStepOption;
  steps: (T & ExposedStepOption)[];
  goToStep: (step: number | string, markCompleted?: boolean) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}

type WizardProps = {
  initialStep?: number | string;
  lastCompletedStep?: number | string;
  steps: StepOption[];
  onChangeStep?: (step: ExposedStepOption, index: number) => Promise<void>;
  onComplete?: () => Promise<void>;
  render: (context: WizardContextType) => ReactNode;
};

const WizardContext = React.createContext<WizardContextType | null>(null);

const Wizard = ({
  initialStep = 0,
  lastCompletedStep = -1,
  steps = [],
  onChangeStep = noop as (step: ExposedStepOption, index: number) => Promise<void>,
  onComplete = noop as () => Promise<void>,
  render,
}: React.PropsWithChildren<WizardProps>) => {
  const [activeStepIndex, setActiveStepIndex] = useState(() =>
    typeof initialStep === "number"
      ? initialStep
      : steps.findIndex((step) => step.name === initialStep)
  );

  if (activeStepIndex < 0 || activeStepIndex > steps.length - 1) {
    throw new Error("activeStepIndex out of bounds");
  }

  // Previously, lastCompletedIndex had to be controlled by the consumer.
  // This now also supports internal tracking.
  const externalLastCompletedStepIndex = useMemo(() => {
    return typeof lastCompletedStep === "number"
      ? lastCompletedStep
      : steps.findIndex((step) => step.name === lastCompletedStep);
  }, [lastCompletedStep, steps]);
  const [internalLastCompletedStepIndex, setInternalLastCompletedStepIndex] = useState(
    externalLastCompletedStepIndex
  );
  const lastCompletedStepIndex = useMemo(
    () => Math.max(internalLastCompletedStepIndex, externalLastCompletedStepIndex),
    [externalLastCompletedStepIndex, internalLastCompletedStepIndex]
  );

  if (lastCompletedStepIndex < -1 || lastCompletedStepIndex > steps.length - 1) {
    throw new Error("lastCompletedStepIndex out of bounds");
  }

  const exposedSteps = useMemo(() => {
    return steps.map((step, index) => ({
      ...step,
      active: index === activeStepIndex,
      completed: index <= lastCompletedStepIndex,
    }));
  }, [activeStepIndex, lastCompletedStepIndex, steps]);

  const activeStep = exposedSteps[activeStepIndex];

  const goToStep = useCallback(
    (step: number | string, markCompleted = false) => {
      const index = typeof step === "number" ? step : steps.findIndex(({ name }) => name === step);
      const targetIndex = clamp(index, 0, steps.length - 1);
      if (targetIndex === activeStepIndex) {
        return;
      }

      if (markCompleted) {
        setInternalLastCompletedStepIndex(targetIndex - 1);
      }
      setActiveStepIndex(targetIndex);
      onChangeStep(exposedSteps[targetIndex], targetIndex);
    },
    [steps, activeStepIndex, onChangeStep, exposedSteps]
  );

  const goToNextStep = useCallback(() => {
    if (activeStepIndex === steps.length - 1) {
      onComplete();
    } else {
      goToStep(activeStepIndex + 1, true);
    }
  }, [goToStep, steps, activeStepIndex, onComplete]);

  const goToPreviousStep = useCallback(() => {
    goToStep(activeStepIndex - 1);
  }, [goToStep, activeStepIndex]);

  const context = useMemo(
    () => ({
      activeStepIndex,
      activeStep,
      steps: exposedSteps,
      goToStep,
      goToNextStep,
      goToPreviousStep,
    }),
    [activeStepIndex, activeStep, exposedSteps, goToStep, goToNextStep, goToPreviousStep]
  );

  const children = useMemo(() => {
    return render(context);
  }, [context, render]);

  return <WizardContext.Provider value={context}>{children}</WizardContext.Provider>;
};

export default Wizard;

export const useWizardContext = <T extends StepOption = StepOption>() => {
  const context = React.useContext(WizardContext);
  if (context === null) {
    throw new Error("useWizardContext must be used as a child within a Wizard component");
  }
  // Allow additional StepOption field types to pass through
  return context as WizardContextType<T>;
};

import { useEffect, useCallback } from "react";
import { hotkeys } from "@puzzle/ui";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { BasicTransactionFragment } from "../graphql.generated";
import {
  RowSelectionType,
  MOUSE_HOVER_CLASSNAME,
  KBD_HOVER_CLASSNAME,
} from "../TransactionsMRT/TransactionsPaginated";
import { useDebouncedCallback } from "use-debounce";

type UseTransactionsHotkeysProps = {
  focusedTransactionId: string | null;
  transactionsByPage: BasicTransactionFragment[][] | null;
  paginationPageIndex: number;
  rowSelection: RowSelectionType;
  activeTransactionId: string | null;
  isAnyRowHovered: boolean;
  setIsBulkRecategorizeOpen: (open: boolean) => void;
  toggleRowSelection: (transactions: BasicTransactionFragment[]) => void;
  setActiveTransactionId: (id: string | null) => void;
  setActiveTransaction: (id: string) => void;
  clearSelection: () => void;
  id?: string;
  setFocusedTransactionId: (id: string | null) => void;
  tableContainerRef: React.RefObject<HTMLDivElement>;
  rowRefs: Map<string, HTMLTableRowElement[]>;
  didUserKbdNavigate: boolean;
  setDidUserKbdNavigate: (didUserKbdNavigate: boolean) => void;
}

enum Direction {
  Up = "up",
  Down = "down",
}

export const useTransactionsHotkeys = ({
  focusedTransactionId,
  transactionsByPage,
  paginationPageIndex,
  rowSelection,
  activeTransactionId,
  isAnyRowHovered,
  setIsBulkRecategorizeOpen,
  toggleRowSelection,
  setActiveTransactionId,
  setActiveTransaction,
  clearSelection,
  id,
  setFocusedTransactionId,
  tableContainerRef,
  rowRefs,
  didUserKbdNavigate,
  setDidUserKbdNavigate,
}: UseTransactionsHotkeysProps) => {
  /* 
      ----------------- 💪 HELPER FUNCTIONS 💪 -----------------
  */

  const debouncedRowScrollBehavior = useDebouncedCallback(
    (isDirectionUp: boolean, offset: number, currentFocusedRows: HTMLTableRowElement[]) => {
      const currentRow = currentFocusedRows[0];
      const adjacentRow = isDirectionUp
        ? currentRow?.previousElementSibling
        : currentRow?.nextElementSibling;

      if (adjacentRow) {
        const rect = adjacentRow.getBoundingClientRect();
        const viewportHeight =
          window.visualViewport?.height ||
          window.innerHeight ||
          document.documentElement.clientHeight;

        const isInViewport = isDirectionUp
          ? rect.top >= offset && rect.bottom <= viewportHeight
          : rect.top >= 0 && rect.bottom <= viewportHeight - offset;

        if (!isInViewport) {
          adjacentRow.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    },
    60
  );

  const handleArrowNavigation = useCallback(
    (direction: Direction) => {
      if (!didUserKbdNavigate) {
        setDidUserKbdNavigate(true);
      }
      const tableRect = tableContainerRef.current?.getBoundingClientRect();
      if (!tableRect) return;
      const viewportHeight = window.innerHeight;
      const isDirectionUp = direction === Direction.Up;
      const offset = isDirectionUp ? tableRect.top : viewportHeight - tableRect.bottom;

      const currentFocusedRows = focusedTransactionId
        ? rowRefs.get(focusedTransactionId) || []
        : [];

      // Remove existing hover classes from currently focused rows
      currentFocusedRows.forEach((row) => {
        row.classList.remove(KBD_HOVER_CLASSNAME);
        row.classList.remove(MOUSE_HOVER_CLASSNAME);
      });

      // If isDirectionUp, get the first focused row, else get the last focused row
      const focusedRow = isDirectionUp
        ? currentFocusedRows[0]
        : currentFocusedRows[currentFocusedRows.length - 1];

      // If isDirectionUp, get the row before the focused row, else get the row after the focused row
      const adjacentRow = isDirectionUp
        ? focusedRow?.previousElementSibling
        : focusedRow?.nextElementSibling;
      const transactionIdOfAdjacentRow = adjacentRow?.getAttribute("data-transaction-id");

      // Also get any rows that have the same data-transaction-id as the adjacent row (for splits)
      const newFocusedRows = transactionIdOfAdjacentRow
        ? rowRefs.get(transactionIdOfAdjacentRow) || []
        : [];
      newFocusedRows.forEach((row) => {
        row.classList.add(KBD_HOVER_CLASSNAME);
      });

      // Check if there's an active transaction.
      // If so, start the navigation from that transaction, regardless of the focused transaction.
      const startingTransactionId = activeTransactionId || focusedTransactionId;

      // Get current transaction index
      const allTransactionsOnThisPage = transactionsByPage?.[paginationPageIndex] || [];
      const currentTransactionIndex = allTransactionsOnThisPage?.findIndex(
        (transaction) => transaction.id === startingTransactionId
      );

      // Check if the current transaction is at the upper or lower bound
      const isAtBound = isDirectionUp
        ? currentTransactionIndex === 0
        : currentTransactionIndex === allTransactionsOnThisPage.length - 1;

      // If we're not at the first or last transaction, scroll to the adjacent row
      if (!isAtBound) {
        // Next, update the focused transaction ASAP
        const newIndex = isDirectionUp ? currentTransactionIndex - 1 : currentTransactionIndex + 1;
        setFocusedTransactionId(allTransactionsOnThisPage[newIndex]?.id);

        // Scroll behavior
        debouncedRowScrollBehavior(isDirectionUp, offset, currentFocusedRows);
      }
    },
    [transactionsByPage, paginationPageIndex, focusedTransactionId, activeTransactionId]
  );

  const handleShiftArrowNavigation = useCallback(
    (direction: Direction) => {
      if (!focusedTransactionId) return;

      const currentPageTransactions = transactionsByPage?.[paginationPageIndex] || [];
      const currentIndex = currentPageTransactions.findIndex(
        (transaction) => transaction.id === focusedTransactionId
      );

      // If current row is unselected, select it
      if (!rowSelection[focusedTransactionId]) {
        const transaction = currentPageTransactions.find((t) => t.id === focusedTransactionId);
        toggleRowSelection(transaction ? [transaction] : []);
        return;
      }

      // Find the adjacent transaction based on direction
      const isDirectionUp = direction === Direction.Up;
      const adjacentIndex = isDirectionUp ? currentIndex - 1 : currentIndex + 1;
      const adjacentTransaction = currentPageTransactions[adjacentIndex];

      // If there is an adjacent row and it is unselected, select it and move the focus
      if (adjacentTransaction && !rowSelection[adjacentTransaction.id]) {
        setFocusedTransactionId(adjacentTransaction.id);
        toggleRowSelection([adjacentTransaction]);
        handleArrowNavigation(direction);
      } else {
        // If the adjacent row is already selected, just move the focus
        handleArrowNavigation(direction);
      }
    },
    [focusedTransactionId, transactionsByPage, paginationPageIndex, rowSelection]
  );

  /* 
      ----------------- 🔑 HOTKEYS 🔑 -----------------
  */

  // HOTKEYS THAT HAVE `IGNORE` AND `ALWAYS ALLOW` RESTRICTIONS AND ARE TRACKED FOR ANALYTICS
  useEffect(() => {
    const restrictedAndTrackedHotkeys: Record<string, ((e: KeyboardEvent) => void) | (() => void)> =
      isPosthogFeatureFlagEnabled(FeatureFlag.TransactionsTableHotkeys)
        ? {
            C: (e: KeyboardEvent) => {
              e.preventDefault();

              // if there are selected transactions, open the recategorization modal for them
              if (Object.keys(rowSelection).length > 0) {
                setIsBulkRecategorizeOpen(true);
              }
              // if there is an active transaction, open the recategorization modal for it
              else if (activeTransactionId) {
                const currentPageTransactions = transactionsByPage?.[paginationPageIndex] || [];
                const transaction = currentPageTransactions.find(
                  (t) => t.id === activeTransactionId
                );
                if (transaction) {
                  Promise.resolve()
                    // must happen synchronously to ensure the transaction is selected before the modal opens
                    .then(() => toggleRowSelection([transaction]))
                    .then(() => setIsBulkRecategorizeOpen(true));
                }
              }
              // if there are no selected transactions, open the recategorization modal for the focused transaction
              else if (focusedTransactionId) {
                const currentPageTransactions = transactionsByPage?.[paginationPageIndex] || [];
                const transaction = currentPageTransactions.find(
                  (t) => t.id === focusedTransactionId
                );
                if (transaction) {
                  Promise.resolve()
                    // must happen synchronously to ensure the transaction is selected before the modal opens
                    .then(() => toggleRowSelection([transaction]))
                    .then(() => setIsBulkRecategorizeOpen(true));
                }
              }
              return "Categorize";
            },
          }
        : {}; // an empty object if the feature flag is not enabled

    const unsubscribeRestrictedAndTrackedHotkeys = hotkeys(window, restrictedAndTrackedHotkeys, {
      ignore: {
        elements: ["input", "select", "textarea"],
        whenDialogIsOpen: true,
        whenMenuIsOpen: true,
      },
      alwaysAllow: {
        types: ["checkbox", "radio"],
      },
      shouldTrackAnalytics: true,
    });

    return () => {
      unsubscribeRestrictedAndTrackedHotkeys();
    };
  }, [focusedTransactionId, transactionsByPage, paginationPageIndex, rowSelection]);

  // HOTKEYS THAT HAVE `IGNORE` AND `ALWAYS ALLOW` RESTRICTIONS
  useEffect(() => {
    const restrictedHotkeys: Record<string, ((e: KeyboardEvent) => void) | (() => void)> =
      isPosthogFeatureFlagEnabled(FeatureFlag.TransactionsTableHotkeys)
        ? {
            J: () => handleArrowNavigation(Direction.Down),
            K: () => handleArrowNavigation(Direction.Up),
            ArrowUp: (e: KeyboardEvent) => {
              e.preventDefault(); // Prevent the table from scrolling. We are handling the scroll ourselves.
              handleArrowNavigation(Direction.Up);
            },
            ArrowDown: (e: KeyboardEvent) => {
              e.preventDefault(); // Prevent the table from scrolling. We are handling the scroll ourselves.
              handleArrowNavigation(Direction.Down);
            },
            "Shift+J": () => handleShiftArrowNavigation(Direction.Down),
            "Shift+K": () => handleShiftArrowNavigation(Direction.Up),
            "Shift+ArrowDown": () => handleShiftArrowNavigation(Direction.Down),
            "Shift+ArrowUp": () => handleShiftArrowNavigation(Direction.Up),
            Enter: () => {
              // Set the active transaction and open the drawer to view its details
              if (focusedTransactionId) {
                setActiveTransactionId(focusedTransactionId);
                setActiveTransaction(focusedTransactionId);
              }
            },
            X: () => {
              // Select the active transaction (tick its checkbox)
              if (activeTransactionId) {
                const currentPageTransactions = transactionsByPage?.[paginationPageIndex] || [];
                const transaction = currentPageTransactions.find(
                  (t) => t.id === activeTransactionId
                );
                toggleRowSelection(transaction ? [transaction] : []);
              }
              // Select the focused transaction (tick its checkbox)
              else if (focusedTransactionId) {
                const currentPageTransactions = transactionsByPage?.[paginationPageIndex] || [];
                const transaction = currentPageTransactions.find(
                  (t) => t.id === focusedTransactionId
                );
                toggleRowSelection(transaction ? [transaction] : []);
              }
            },
            Escape: () => {
              // If the side drawer IS NOT open, clear all selected rows
              if (!id) {
                clearSelection();
              }
            },
          }
        : {}; // an empty object if the feature flag is not enabled

    const unsubscribeRestrictedHotkeys = hotkeys(window, restrictedHotkeys, {
      ignore: {
        elements: ["input", "select", "textarea"],
        whenDialogIsOpen: true,
        whenMenuIsOpen: true,
      },
      alwaysAllow: {
        types: ["checkbox", "radio"],
      },
    });

    return () => {
      unsubscribeRestrictedHotkeys();
    };
  }, [focusedTransactionId, transactionsByPage, paginationPageIndex, rowSelection]);

  // HOTKEYS THAT FIRE ANYWHERE BUT HAVE A SPECIAL SIMULTANEOUS FUNCTION ON THIS PAGE
  useEffect(() => {
    const globalHotkeys: Record<string, ((e: KeyboardEvent) => void) | (() => void)> =
      isPosthogFeatureFlagEnabled(FeatureFlag.TransactionsTableHotkeys)
        ? {
            "Meta+k": () => {
              const hasSelectedRows = Object.keys(rowSelection).length > 0;
              // If a transaction is active AND there are no selected rows, select the active transaction
              // This is so shortcuts that act upon selected rows will work from the cmd+k modal
              if (activeTransactionId && !hasSelectedRows) {
                const currentPageTransactions = transactionsByPage?.[paginationPageIndex] || [];
                const transaction = currentPageTransactions.find(
                  (t) => t.id === activeTransactionId
                );
                if (transaction) {
                  toggleRowSelection([transaction]);
                }
              }
              // If a row is focused AND there are no selected rows AND is any row hovered
              // select the hovered row
              // This is so shortcuts that act upon selected rows will work from the cmd+k modal
              else if (focusedTransactionId && isAnyRowHovered && !hasSelectedRows) {
                const currentPageTransactions = transactionsByPage?.[paginationPageIndex] || [];
                const transaction = currentPageTransactions.find(
                  (t) => t.id === focusedTransactionId
                );
                if (transaction) {
                  toggleRowSelection([transaction]);
                }
              }
            },
          }
        : {}; // an empty object if the feature flag is not enabled

    const unsubscribeGlobalHotkeys = hotkeys(window, globalHotkeys, {});
    return () => {
      unsubscribeGlobalHotkeys();
    };
  }, [
    focusedTransactionId,
    transactionsByPage,
    isAnyRowHovered,
    paginationPageIndex,
    activeTransactionId,
    rowSelection,
  ]);
};

export type ReportSectionName = "quickbooks" | "people" | "modern accounting" | "transactions";

// NOTE: I don't believe these are safe enough for minifiers to remove unused code.
// Next.js will replace these inline, but to consumers they're still unknown variables.
// Maybe I'm wrong. Worth verifying...
export const IS_LOCAL_DEVELOPMENT = process.env.PUZZLE_ENV === "local" || !process.env.ROOT_URL;
export const IS_SERVER = typeof window === "undefined";
export const IS_TEST = process.env.NODE_ENV === "test";
export const IS_PROD = process.env.PUZZLE_ENV === "production";
export const IS_STAGING = process.env.PUZZLE_ENV === "staging";
export const IS_DEV = process.env.PUZZLE_ENV === "development";

const DEFAULT_LOCAL_URL = "http://localhost:3000";
const DEFAULT_LOCAL_SERVER_URL = "http://localhost:8888";
const DEMO_URL =
  (IS_LOCAL_DEVELOPMENT || IS_DEV
    ? process.env.ROOT_URL || DEFAULT_LOCAL_URL
    : "https://staging.southparkdata.com") + "/demo?autoSubmit=true";

const baseConfig = {
  ROOT_URL: process.env.ROOT_URL || DEFAULT_LOCAL_URL,
  DEMO_URL,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
  AUTH0_SCOPE: process.env.AUTH0_SCOPE,
  AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
  REDIRECT_URI: process.env.REDIRECT_URI,
  IS_LOCAL_DEVELOPMENT,
  POST_LOGOUT_REDIRECT_URI: process.env.POST_LOGOUT_REDIRECT_URI,
  GRAPHQL_ENDPOINT: process.env.GRAPHQL_ENDPOINT ?? "http://localhost:3002/graphql",
  CLIENT_GRAPHQL_ENDPOINT: "/api/proxy/graphql",
  BACKEND_URI:
    process.env.BACKEND_URI ??
    (IS_LOCAL_DEVELOPMENT ? DEFAULT_LOCAL_SERVER_URL : process.env.ROOT_URL),
  PUBLIC_ROUTES: ["/login"],
  TOKEN_REFRESH_ENABLED: false,
  PUZZLE_ENV: process.env.PUZZLE_ENV,
  BUGSNAG_API_KEY: process.env.BUGSNAG_API_KEY,
  LTSE_LOGIN_REDIRECT: "/ltse",
  IS_TEST,
  IS_SSR: IS_SERVER,
  PUZZLE_ONLY_REPORTS: process.env.PUZZLE_ONLY_REPORTS || ([] as ReportSectionName[]),
  DISABLED_REPORTS: process.env.DISABLED_REPORTS || ([] as ReportSectionName[]),
  HEAP_CLIENT_ID: process.env.HEAP_CLIENT_ID,
  HIGHLIGHT_ID: process.env.HIGHLIGHT_ID,
  POSTHOG_KEY: process.env.POSTHOG_KEY,
  RUDDER_KEY: process.env.RUDDER_KEY,
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
  AUTH0_CLIENT_SECRET: "",
  AUTH0_ROLLING_DURATION_SECONDS: parseInt(
    process.env.AUTH0_ROLLING_DURATION_SECONDS || `${60 * 60 * 24 * 3}`
  ),
  AUTH0_ABSOLUTE_DURATION_SECONDS: parseInt(
    process.env.AUTH0_ABSOLUTE_DURATION_SECONDS || `${60 * 60 * 12}`
  ),
  SESSION_COOKIE_SECRET: "",
  SESSION_COOKIE_LIFETIME: "",
  PRIVACY_POLICY: "https://www.puzzle.io/legal/privacy",
  KNOCK_API: process.env.KNOCK_API,
  KNOCK_TASKS_FEED_ID: process.env.KNOCK_TASKS_FEED_ID,
  KNOCK_UPDATES_FEED_ID: process.env.KNOCK_UPDATES_FEED_ID,
  NOTIFICATIONS_ENABLED: Boolean(process.env.KNOCK_API),
  INTERCOM_APP_ID: process.env.INTERCOM_APP_ID,
  /**
   * Used to generate emails or authenticate the Sendgrid event webhook.
   * Just rename to EMAIL_SECRET
   */
  EMAIL_TEMPLATE_KEY: process.env.EMAIL_TEMPLATE_KEY,
  DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN || "pub27f943e24a4fd106505b42390291be78",
  GTM_ID: process.env.GTM_ID, // Google Tag Manager
  GTM_ENV_PARAMS: process.env.GTM_ENV_PARAMS, // Google Tag Manager params https://tagmanager.google.com/#/admin/accounts/6134024340/containers/134342292/environments?containerDraftId=7,
  SELF_SERVE_USER_DEMO: false,
  RECAPTCHA_KEY: process.env.RECAPTCHA_KEY,
  GOOGLE_CLOUD_PROJECT: process.env.GOOGLE_CLOUD_PROJECT, //Set by the google on GCP
  RECAPTCHA_TRESHOLD: Number(process.env.RECAPTCHA_TRESHOLD ?? "0.5"),
  USE_BLOCKED_AT: Number(process.env.USE_BLOCKED_AT),
  TRACE_ENABLED: Boolean(process.env.TRACE_ENABLED),
  IS_DEBUGGING_REACT: Boolean(process.env.IS_DEBUGGING_REACT),
};

let envConfig = {};
if (IS_SERVER) {
  /**
   * Settings exposed to the server.
   */
  envConfig = {
    AUTH0_CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET,
    SESSION_COOKIE_SECRET: process.env.SESSION_COOKIE_SECRET,
    SESSION_COOKIE_LIFETIME: process.env.SESSION_COOKIE_LIFETIME,
    SELF_SERVE_USER_DEMO: process.env.SELF_SERVE_USER_DEMO === "true",
  };
}

export default {
  ...baseConfig,
  ...envConfig,
};

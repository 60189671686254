import React, { useEffect, useMemo } from "react";
import Tag from "components/common/Tag";
import { Button } from "@puzzle/ui";
import { formatCardName, capitalize } from "@puzzle/utils";
import { FilterStatus, useTransactionsPage } from "./TransactionsProvider";
import { compact } from "lodash";
import { RecurrencePeriod, VendorFragment } from "graphql/types";
import { getAmountTagDispalyText } from "components/common/AmountMenu";
import { rootStyle } from "./activeFilters.css";
import { vars } from "ve";

const VendorTag = ({ vendor }: { vendor: VendorFragment }) => {
  const { removeVendor } = useTransactionsPage();

  return vendor ? (
    <Tag onRemove={() => removeVendor(vendor.id)} variant="filledPill">
      {vendor.name}
    </Tag>
  ) : null;
};

const useVendorTags = (vendors: VendorFragment[]) => {
  return useMemo(
    () => vendors.map((vendor) => <VendorTag key={vendor.id} vendor={vendor} />),
    [vendors]
  );
};

// const useClassTags
export const ActiveFilters = () => {
  const {
    accountsById,
    cardsById,
    categoriesByPermaKey,
    filter,
    setFilter,
    resetFilter,
    removeAccount,
    removeCard,
    removeCategory,
    removeClassFilters,
    removeRecurrence,
    selectedVendors,
    selectedClassFilters,
    setFilterCount,
  } = useTransactionsPage();

  const categoryTags = useMemo(
    () =>
      compact(
        filter.status !== FilterStatus.NotCategorized
          ? filter.ledgerCoaKeys.map((id: string) => {
              const category = categoriesByPermaKey?.[id];
              return (
                category && (
                  <Tag key={id} onRemove={() => removeCategory(id)} variant="filledPill">
                    {category.name}
                  </Tag>
                )
              );
            })
          : []
      ),
    [categoriesByPermaKey, filter.ledgerCoaKeys, filter.status, removeCategory]
  );

  const vendorTags = useVendorTags(selectedVendors);
  const classFiltersTags = useMemo(() => {
    return selectedClassFilters.map((classFilter) => {
      return (
        <Tag
          key={classFilter.id}
          onRemove={() => removeClassFilters(classFilter.id)}
          variant="filledPill"
        >
          {classFilter.name}
        </Tag>
      );
    });
  }, [removeClassFilters, selectedClassFilters]);

  const accountTags = useMemo(
    () =>
      compact(
        filter.accountIds.map((id) => {
          const account = accountsById[id];
          return (
            account && (
              <Tag key={id} onRemove={() => removeAccount(id)} variant="filledPill">
                {account.name} - {account.mask}
              </Tag>
            )
          );
        })
      ),
    [accountsById, filter.accountIds, removeAccount]
  );

  const cardTags = useMemo(
    () =>
      compact(
        filter.cardIds.map((id) => {
          const card = cardsById[id];
          return (
            card && (
              <Tag key={id} onRemove={() => removeCard(id)} variant="filledPill">
                {formatCardName(card)}
              </Tag>
            )
          );
        })
      ),
    [cardsById, filter.cardIds, removeCard]
  );

  const notFinalizedTag = useMemo(
    () =>
      filter.status === FilterStatus.NotFinalized && (
        <Tag onRemove={() => setFilter({ status: null })} variant="filledPill">
          Not Finalized
        </Tag>
      ),
    [filter.status, setFilter]
  );

  const finalizedTag = useMemo(
    () =>
      filter.status === FilterStatus.Finalized && (
        <Tag onRemove={() => setFilter({ status: null })} variant="filledPill">
          Finalized
        </Tag>
      ),
    [filter.status, setFilter]
  );

  // NOTE: isLinked being true doesn't work as expected.
  // It only works for false/null
  const isLinkedTag = useMemo(
    () =>
      filter.isLinked === false && (
        <Tag onRemove={() => setFilter({ isLinked: null })} variant="filledPill">
          Unlinked
        </Tag>
      ),
    [filter.isLinked, setFilter]
  );

  const postedTag = useMemo(
    () =>
      filter.posted && (
        <Tag onRemove={() => setFilter({ posted: null })} variant="filledPill">
          Date pending
        </Tag>
      ),
    [filter.posted, setFilter]
  );

  const recurrenceTags = useMemo(
    () =>
      compact(
        filter.recurrences?.map((recurrence, i) => (
          <Tag
            onRemove={() => removeRecurrence(recurrence)}
            variant="filledPill"
            key={recurrence ?? i}
          >
            {recurrence === undefined
              ? "Unknown"
              : recurrence === RecurrencePeriod.None
              ? "Non-recurring"
              : capitalize(recurrence.toLowerCase())}
          </Tag>
        ))
      ),
    [filter.recurrences, removeRecurrence]
  );

  const amountTag = useMemo(() => {
    const displayText = getAmountTagDispalyText(filter);

    return (
      displayText && (
        <Tag variant="filledPill" onRemove={() => setFilter({ minAmount: null, maxAmount: null })}>
          {displayText}
        </Tag>
      )
    );
  }, [filter, setFilter]);

  const documentationTag = useMemo(
    () =>
      typeof filter.hasDocumentation === "boolean" && (
        <Tag onRemove={() => setFilter({ hasDocumentation: null })} variant="filledPill">
          {filter.hasDocumentation ? "Has documentation" : "No documentation"}
        </Tag>
      ),
    [filter.hasDocumentation, setFilter]
  );

  const accrualDateTag = useMemo(
    () =>
      filter.showAccrualDate && (
        <Tag onRemove={() => setFilter({ showAccrualDate: null })} variant="filledPill">
          Accrual date
        </Tag>
      ),
    [filter.showAccrualDate, setFilter]
  );

  const availableDateTag = useMemo(
    () =>
      filter.showAvailableDate && (
        <Tag onRemove={() => setFilter({ showAvailableDate: null })} variant="filledPill">
          Available date (Stripe only)
        </Tag>
      ),
    [filter.showAvailableDate, setFilter]
  );

  const inputMethodTag = useMemo(
    () =>
      typeof filter.isManual === "boolean" && (
        <Tag onRemove={() => setFilter({ isManual: null })} variant="filledPill">
          {filter.isManual ? "Manual transactions" : "Automatic transactions"}
        </Tag>
      ),
    [filter.isManual, setFilter]
  );

  const isBillPaymentTag = useMemo(
    () =>
      typeof filter.isBillPayment === "boolean" && (
        <Tag onRemove={() => setFilter({ isBillPayment: null })} variant="filledPill">
          {filter.isBillPayment ? "Bill payment" : "Not a bill payment"}
        </Tag>
      ),
    [filter.isBillPayment, setFilter]
  );

  const booleanTags = useMemo(() => {
    const tags = [];

    if (filter.status === FilterStatus.NotCategorized) {
      tags.push(
        <Tag
          key={FilterStatus.NotCategorized}
          onRemove={() => setFilter({ status: null })}
          variant="filledPill"
        >
          Not categorized
        </Tag>
      );
    }

    if (filter.categorizedByAI) {
      tags.push(
        <Tag
          key="categorizedByAI"
          onRemove={() => setFilter({ categorizedByAI: null })}
          variant="filledPill"
        >
          Categorized by AI
        </Tag>
      );
    }

    if (filter.assignedToMe) {
      tags.push(
        <Tag
          key="assignedToMe"
          onRemove={() => setFilter({ assignedToMe: null })}
          variant="filledPill"
        >
          Assigned to me
        </Tag>
      );
    }

    if (filter.assignedToOthers) {
      tags.push(
        <Tag
          key="assignedToOthers"
          onRemove={() => setFilter({ assignedToOthers: null })}
          variant="filledPill"
        >
          Assigned to others
        </Tag>
      );
    }

    return tags;
  }, [
    filter.status,
    filter.categorizedByAI,
    filter.assignedToMe,
    filter.assignedToOthers,
    setFilter,
  ]);

  const [showReset, totalTagsLength] = useMemo(() => {
    const totalTags = compact([
      ...categoryTags,
      ...vendorTags,
      ...accountTags,
      ...cardTags,
      ...recurrenceTags,
      ...classFiltersTags,
      ...booleanTags,
      notFinalizedTag,
      isLinkedTag,
      postedTag,
      finalizedTag,
      amountTag,
      documentationTag,
      accrualDateTag,
      availableDateTag,
      inputMethodTag,
      isBillPaymentTag,
    ]);

    const totalLength = totalTags.length;

    return [totalLength > 0, totalLength];
  }, [
    categoryTags,
    vendorTags,
    accountTags,
    cardTags,
    notFinalizedTag,
    classFiltersTags,
    booleanTags,
    isLinkedTag,
    postedTag,
    finalizedTag,
    amountTag,
    documentationTag,
    accrualDateTag,
    availableDateTag,
    inputMethodTag,
    isBillPaymentTag,
    recurrenceTags,
  ]);

  useEffect(() => {
    setFilterCount(totalTagsLength);
  }, [totalTagsLength]);

  return (
    <div
      className={rootStyle}
      style={{
        marginTop: totalTagsLength > 0 ? vars.space.$1h : 0,
        marginBottom: totalTagsLength > 0 ? vars.space.$0h : vars.space.$2,
      }}
    >
      {categoryTags}
      {vendorTags}
      {classFiltersTags}
      {booleanTags}
      {accountTags}
      {cardTags}
      {notFinalizedTag}
      {isLinkedTag}
      {postedTag}
      {finalizedTag}
      {amountTag}
      {documentationTag}
      {accrualDateTag}
      {availableDateTag}
      {inputMethodTag}
      {isBillPaymentTag}
      {recurrenceTags}
      {showReset && (
        <Button size="mini" variant="minimal" onClick={resetFilter}>
          Clear filters
        </Button>
      )}
    </div>
  );
};

import React from "react";

import { color } from "ve";

export const CircularProgress = ({
  progress = 0,
  progressSecondary = 0,
  size = 20,
  trackColor = color.white10,
  progressColor = color.greenalpha,
  progressSecondaryColor = color.white30,
  strokeWidth = 2,
}: {
  progress?: number; // In [0, 1]
  progressSecondary?: number; // in [0, 1]
  size?: number;
  trackColor?: string;
  progressColor?: string;
  progressSecondaryColor?: string;
  strokeWidth?: number;
}) => {
  const clampedProgress = Math.min(1, Math.max(0, progress)); // Clamp to [0, 1]
  const clampedProgressSecondary = Math.min(
    // progress and secondary progress summed must be <= 1
    1 - progress,
    Math.min(1, Math.max(0, progressSecondary)) // Clamp to [0, 1]
  );

  // Calculate the progress path length
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  // Compute the start rotation of the secondary progress indicator.
  // This will be -90 + the progress indicator's end rotation.
  const secondaryStartRotation = (-90 + clampedProgress * 360) % 360;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
    >
      {/* Background track */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={trackColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
      {/* Progress indicator */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={progressColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        style={{
          strokeDasharray: circumference,
          strokeDashoffset: circumference - clampedProgress * circumference,
          transition: "stroke-dashoffset 0.3s ease",
        }}
      />
      {/* Secondary progress indicator */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={progressSecondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        transform={`rotate(${secondaryStartRotation} ${size / 2} ${size / 2})`}
        style={{
          strokeDasharray: circumference,
          strokeDashoffset: circumference - clampedProgressSecondary * circumference,
          transition: "stroke-dashoffset 0.3s ease",
        }}
      />
    </svg>
  );
};

import React from "react";
import { Stack, Text } from "@puzzle/ui";
import { PLANS } from "./plans";
import { PlanBox } from "./PlanBox";
import { SubscriptionCycleSwitch } from "./SubscriptionCycleSwitch";
import { isPosthogFeatureFlagEnabled, FeatureFlag } from "lib/analytics";

export const PlanSelectStep = () => {
  const allowYearlySubscription = isPosthogFeatureFlagEnabled(FeatureFlag.SubscriptionYearly);

  return (
    <Stack css={{ margin: "$10 auto", alignItems: "center" }} gap="5">
      <Stack css={{ gap: "$2h", alignItems: "center" }}>
        <Text
          css={{
            fontSize: "40px",
            textAlign: "center",
            fontWeight: "bold",
            color: "white",
            lineHeight: "normal",
          }}
        >
          The Accounting Solution For Every Stage
        </Text>

        <Text color="white" variant="headingM">
          Get Accounting And Financial Health Automation With Every Plan
        </Text>
      </Stack>
      {allowYearlySubscription && <SubscriptionCycleSwitch />}
      <Stack
        direction="horizontal"
        css={{
          justifyContent: "center",
          gap: "$0",
          marginTop: "$3",
          "@xs": {
            flexDirection: "column",
          },
          "@s": {
            flexDirection: "column",
          },
          "@m": {
            flexDirection: "row",
          },
        }}
      >
        {PLANS.map((tierProps) => (
          <PlanBox key={tierProps.title} {...tierProps} />
        ))}
      </Stack>
    </Stack>
  );
};

import React, { useState } from "react";
import { Box, Button, S, Separator as BaseSeparator, Stack, Text } from "ve";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { Tag } from "@puzzle/ui";
import Link from "components/common/Link";
import links from "lib/links";
import { PuzzleAddon, PuzzlePriceBillingInterval } from "graphql/types";
import { StepChooser } from "./StepChooser";
import { usePlansPricingModal } from "./usePlansPricingModal";
import { SubscriptionCycleSwitch } from "./SubscriptionCycleSwitch";
import {
  detailsPanelStyle,
  planPanelsStyle,
  planTitleContainerStyle,
  reviewStepContainerStyle,
  summaryPanelStyle,
} from "./plansPricingModal.css";
import { calcPriceRounded } from "./utils";
import { BasicBadge, YearlyDiscountBadge } from "./Badges";
import { AddOnBox as BaseAddOnBox } from "./AddOnBox";
import { AdditionalServices } from "./AdditionalServices";
import { CheckoutSummary } from "./CheckoutSummary";
import { formatMoney } from "@puzzle/utils";
import { AddOnConfirmationDialog } from "../AddOnConfirmationDialog/AddOnConfirmationDialog";

const REVIEW_STEP_MAX_WIDTH = 1140;

const Separator = () => <BaseSeparator css={{ marginTop: S.$1, marginBottom: S.$3 }} />;

const AddOnBox = ({
  addOn,
  selectedCycle,
}: {
  addOn: PuzzleAddon;
  selectedCycle: PuzzlePriceBillingInterval;
}) => {
  const disabled = selectedCycle === PuzzlePriceBillingInterval.Year && !addOn.annualStripePriceId;
  const tooltip = disabled
    ? `${addOn.name} add-on is only available on a monthly plan.`
    : undefined;
  return <BaseAddOnBox {...addOn} key={addOn.id} tooltipMessage={tooltip} />;
};

export const ReviewStep = () => {
  const { company } = useActiveCompany<true>();
  const puzzleSubscriptionState = company.puzzleSubscriptionState;
  const {
    selectedPlan,
    selectedPlanId,
    selectedCycle,
    allowYearlySubscription,
    isbookkeepingEnabled,
    setIsBookkeepingEnabled,
  } = usePlansPricingModal();
  const [showAdditionalServices, setShowAdditionalServices] = useState(false);
  const cycle = selectedCycle ?? PuzzlePriceBillingInterval.Month;
  const planPrice = selectedPlan
    ? calcPriceRounded(cycle, selectedPlan, selectedCycle === PuzzlePriceBillingInterval.Year)
    : 0;

  const formattedPlanPrice = formatMoney({
    currency: "USD",
    amount: planPrice,
  });
  const showDiscountBadge =
    selectedCycle === PuzzlePriceBillingInterval.Year && !selectedPlan?.isFree;
  const isPlanCurrentSubscription =
    selectedPlanId === puzzleSubscriptionState?.billedPlan?.plan.id &&
    !puzzleSubscriptionState?.billedPlan?.plan.isFree;

  return (
    <>
      <AddOnConfirmationDialog />
      <Stack
        gap={S["5"]}
        className={reviewStepContainerStyle}
        css={{
          maxWidth: REVIEW_STEP_MAX_WIDTH,
        }}
      >
        <Stack
          gap={S["4"]}
          css={{
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Text variant="heading1" color="white">
            Review your plan & services
          </Text>
          <Text variant="bodyL" color="gray200">
            Confirm your plan and services selection before checking out
          </Text>
          <StepChooser step={2} />
          <Box className={planPanelsStyle}>
            <Stack className={detailsPanelStyle}>
              {allowYearlySubscription && (
                <>
                  <Stack
                    direction="horizontal"
                    css={{
                      justifyContent: "space-between",
                    }}
                  >
                    <Text variant="heading3" color="gray200">
                      Your billing cycle
                    </Text>
                    <SubscriptionCycleSwitch />
                  </Stack>
                  <Separator />
                </>
              )}
              <Text variant="heading3" color="gray200">
                Your plan
              </Text>
              <Stack gap={S.$1} className={planTitleContainerStyle}>
                <Stack
                  direction="horizontal"
                  css={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Text variant="heading2h" color="gray200">
                    <Stack direction="horizontal" gap={S.$2} css={{ alignItems: "center" }}>
                      {selectedPlan?.displayName}{" "}
                      {isPlanCurrentSubscription && (
                        <Tag variant="outlined" color="vivid">
                          Active subscription
                        </Tag>
                      )}
                    </Stack>
                  </Text>

                  {selectedPlan?.isFree ? (
                    <Text variant="heading3" color="gray200">
                      "Free"
                    </Text>
                  ) : (
                    <Stack
                      direction="horizontal"
                      gap={S.$2}
                      css={{
                        alignItems: "center",
                      }}
                    >
                      {!isPlanCurrentSubscription && (
                        <>
                          <Text variant="heading3" color="gray200">
                            {formattedPlanPrice} / month
                          </Text>

                          {showDiscountBadge && <YearlyDiscountBadge />}
                        </>
                      )}
                    </Stack>
                  )}
                </Stack>
                <Stack direction="horizontal" gap={S.$1}>
                  {selectedPlan?.badges?.map((badge, i) => (
                    <BasicBadge text={badge} key={i} />
                  ))}
                </Stack>
              </Stack>
              {(selectedPlan?.availableAddons ?? []).length > 0 && (
                <>
                  <Separator />
                  <Text variant="heading3" color="gray200">
                    Add-ons
                  </Text>
                  <Text variant="headingM" color="gray400">
                    Enhance your current subscription with add-ons from the
                    <Text variant="headingM" color="gray200">
                      {" "}
                      Advanced{" "}
                    </Text>
                    plan. You can add or remove them anytime.
                  </Text>
                  {[
                    ...(puzzleSubscriptionState?.billedPlan?.addons ?? []),
                    ...(selectedPlan?.availableAddons ?? []),
                  ].map((addOn) => (
                    <AddOnBox key={addOn.id} addOn={addOn} selectedCycle={selectedCycle} />
                  ))}
                </>
              )}

              <Separator />
              <Text variant="heading3" color="gray200">
                Professional Accounting Services
              </Text>
              <div>
                <Text variant="headingM" color="gray400">
                  Don’t want to do it yourself? We will reach out to understand your needs and pair
                  you with the optimal accounting professional from our accounting partner network
                  to manage your Puzzle account and ensure you have a hands-off experience.{" "}
                </Text>
                <Link onClick={() => setShowAdditionalServices((prev) => !prev)}>
                  <Text color="blue500" variant="bodyM">
                    {showAdditionalServices ? "Show less" : "Show more"}
                  </Text>
                </Link>
              </div>

              <Stack direction="horizontal" gap={S.$1}>
                <Button
                  data-testid="bookkeeping-request-button-yes"
                  variant={isbookkeepingEnabled ? "primary" : "secondary"}
                  onClick={() => setIsBookkeepingEnabled(true)}
                >
                  Yes
                </Button>
                <Button
                  data-testid="bookkeeping-request-button-yes"
                  variant={!isbookkeepingEnabled ? "primary" : "secondary"}
                  onClick={() => setIsBookkeepingEnabled(false)}
                >
                  No
                </Button>
              </Stack>

              {showAdditionalServices && <AdditionalServices />}
            </Stack>
            <Stack className={summaryPanelStyle}>
              {selectedPlan && (
                <CheckoutSummary isPlanCurrentSubscription={isPlanCurrentSubscription} />
              )}
              <Stack gap={S.$2}>
                <Link href={links.faqPage} target="_blank" rel="noopener noreferrer">
                  <Text color="blue500" variant="bodyM">
                    FAQs
                  </Text>
                </Link>
                <Link href={links.contactSales} target="_blank" rel="noopener noreferrer">
                  <Text id="plan-review-lets-chat" color="blue500" variant="bodyM">
                    Contact sales
                  </Text>
                </Link>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

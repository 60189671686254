import React from "react";
import links from "lib/links";
import Link from "components/common/Link";
import { isPosthogFeatureFlagEnabled, FeatureFlag } from "lib/analytics";
import { Box, S, Stack, Text } from "ve";
import { contentContainer, modalTitleStyle } from "./plansPricingModal.css";
import { PlanBox } from "./PlanBox";
import { usePlansPricingModal } from "./usePlansPricingModal";
import { SubscriptionCycleSwitch } from "./SubscriptionCycleSwitch";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { getUpgradeButtonLabel } from "./utils";
import { PuzzlePlanModalVersion } from "graphql/types";

const MAXIMUM_CARDS_FOR_LARGE_SIZE = 3;

export const PlanSelectStep = () => {
  const { company } = useActiveCompany<true>();
  const allowYearlySubscription = isPosthogFeatureFlagEnabled(FeatureFlag.SubscriptionYearly);
  const { availablePlans } = usePlansPricingModal();
  return (
    <Stack className={contentContainer} gap={S.$5}>
      <Text className={modalTitleStyle}>The accounting solution for every stage</Text>
      {allowYearlySubscription && <SubscriptionCycleSwitch />}
      <Box
        style={{
          justifyContent: "center",
          display: "flex",
          flexWrap: "wrap",
          rowGap: 44,
        }}
      >
        {availablePlans.map((plan, index) => (
          <PlanBox
            size={availablePlans.length > MAXIMUM_CARDS_FOR_LARGE_SIZE ? "small" : "large"}
            key={plan.name}
            plan={plan}
            disabled={company.puzzleSubscriptionState.billedPlan?.plan.id === plan.id}
            upgradeButtonLabel={getUpgradeButtonLabel(
              plan,
              company?.puzzleSubscriptionState?.billedPlan?.plan
            )}
            featuresHeader={
              index === 0 ? (
                "Includes:"
              ) : (
                <span>
                  Everything from{" "}
                  <Text color="white" weight="heavy">
                    {availablePlans[index - 1].displayName}
                  </Text>
                  , plus:
                </span>
              )
            }
          />
        ))}
      </Box>
      {company?.puzzlePlanModalVersion === PuzzlePlanModalVersion.V2 && (
        <Link
          css={{ display: "flex", padding: S.$2, textDecoration: "none !important" }}
          href={links.pricingPage}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text color="green600" variant="headingS">
            Compare plans in detail
          </Text>
        </Link>
      )}
    </Stack>
  );
};

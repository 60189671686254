import React, { useState } from "react";
import useGusto from "./useGusto";
import { IntegrationLoading } from "../setup";
import { IntegrationConnectionCondition, IntegrationType } from "graphql/types";
import { SelectIngestDateModal } from "../setup/modals/IngestionDateModal/IngestDateModal";
import { usePollIntegration } from "../shared/usePollIntegration";

type ConnectGustoPageProps = {
  code: string;
  companyId: string;
  isOnboarding: boolean;
};

const ConnectGustoPage = ({ companyId, code, isOnboarding }: ConnectGustoPageProps) => {
  const { connectGusto, connectionError, connection, loading } = useGusto({ companyId });
  const [isReconnect, setIsReconnect] = useState(false);
  const { financialInstitutions, accounts, startPolling } = usePollIntegration(
    IntegrationType.Gusto,
    !isOnboarding
  );
  const [openSelectDate, onOpenSelectDateChange] = useState(false);

  const connect = async () => {
    if (!code) {
      return;
    }
    if (connection?.id) {
      setIsReconnect(true);
    }

    const result = await connectGusto({
      companyId,
      code,
      condition: isOnboarding ? undefined : IntegrationConnectionCondition.WaitingForUserEpoch,
    });

    if (!result || !result?.data || result.errors) {
      throw new Error("Error connecting gusto");
    }
    if (financialInstitutions.length === 0) {
      startPolling(1000);
    }

    onOpenSelectDateChange(true);
  };

  return (
    <>
      <IntegrationLoading
        integrationType={IntegrationType.Gusto}
        title="Connecting to Gusto"
        connectIntegration={connect}
        connection={connection}
        loading={loading}
        error={!!connectionError}
      />
      {!isOnboarding && (
        <SelectIngestDateModal
          open={openSelectDate}
          accounts={accounts}
          onOpenChange={onOpenSelectDateChange}
          isReconnect={isReconnect}
          connectionId={connection?.id}
          accountsLoading={accounts.length === 0 ?? false}
        />
      )}
    </>
  );
};

export default ConnectGustoPage;

import {
  AllInOneService,
  BookkeepingService,
  RDTaxCreditsService,
  TaxFillingService,
} from "@puzzle/icons";
import { Tag } from "@puzzle/ui";
import React from "react";
import { Box, Text, Stack, S } from "ve";
import {
  additionalServiceItemIconStyle,
  additionalServiceItemStyle,
} from "./plansPricingModal.css";

type AccountingService = {
  icon: React.ReactNode;
  title: string;
  titleTag?: React.ReactNode;
  subtitle: string;
  subtitleHelp?: string;
  description: string | React.ReactNode;
};

const SERVICES: AccountingService[] = [
  {
    icon: <AllInOneService />,
    title: "All-in-one",
    titleTag: (
      <Tag variant="outlined" color="vivid" size="small">
        Best value
      </Tag>
    ),
    subtitle: "Starting from $599 / month",
    description:
      "Gain peace of mind knowing all your accounting needs are covered with a full service bookkeeping, tax preparation, and R&D tax credits package from a trusted partner.",
  },
  {
    icon: <BookkeepingService />,
    title: "Bookkeeping",
    subtitle: "Starting at $199 / month",
    description: (
      <>
        Get paired with a trusted bookkeeper from our network of partners who specialize in{" "}
        <Text color="gray200">cash and accrual</Text> bookkeeping for startups.
      </>
    ),
  },
  {
    icon: <TaxFillingService />,
    title: "Tax Filling",
    subtitle: "Starting at $1499 / year",
    description:
      "Get paired with specialized tax professionals and gain peace of mind knowing your tax and compliance needs are met.",
  },
  {
    icon: <RDTaxCreditsService />,
    title: "R&D Tax Credits",
    subtitle: "0.75% of QRE",
    description:
      "Our partner takes care of the R&D tax credit and tax filing process for you. You only need to answer a few questions and they will file the necessary paperwork.",
  },
];

export const AdditionalServices = () => {
  return (
    <Stack gap={S.$2h}>
      {SERVICES.map((service, index) => (
        <Stack direction="horizontal" key={service.title} className={additionalServiceItemStyle}>
          <Box className={additionalServiceItemIconStyle}>{service.icon}</Box>
          <Stack gap={S.$1} css={{ padding: S.$2h }}>
            <Stack direction="horizontal" gap={S.$1}>
              <Text variant="headingM" color="gray200">
                {service.title}
              </Text>
              <Text variant="headingM" color="gray200">
                {service.titleTag}
              </Text>
            </Stack>
            <Text variant="bodyM" color="gray200">
              {service.subtitle}
            </Text>
            <Text variant="bodyM">{service.description}</Text>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

import React from "react";

import { iconButtonRecipe, IconButtonVariants } from "./iconButtonStyles.css";

const IconButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> &
    IconButtonVariants & {
      fullWidthContent?: boolean;
      children?: React.ReactNode;
      css?: React.CSSProperties;
    }
>(({ children, css, variant, circle, size, roundness, ...buttonProps }, ref) => {
  const buttonClass = iconButtonRecipe({ variant, circle, size, roundness });

  return (
    <button className={buttonClass} style={css} {...buttonProps} ref={ref}>
      {children}
    </button>
  );
});

IconButton.displayName = "IconButton";
export { IconButton };

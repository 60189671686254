import React from "react";
import Icon, { IconProps } from "./Icon";

export default function GradientDollar({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        d="M1.45801 15.625H0.708008V16.375H1.45801V15.625ZM1.45801 3.95834V3.20834H0.708008V3.95834H1.45801ZM18.5413 3.95834H19.2913V3.20834H18.5413V3.95834ZM18.5413 15.625V16.375H19.2913V15.625H18.5413ZM3.95801 6.12501H3.20801V7.62501H3.95801V6.12501ZM4.79134 7.62501C5.20555 7.62501 5.54134 7.28922 5.54134 6.87501C5.54134 6.4608 5.20555 6.12501 4.79134 6.12501V7.62501ZM15.208 12.375H14.458V13.875H15.208V12.375ZM16.0413 13.875C16.4556 13.875 16.7913 13.5392 16.7913 13.125C16.7913 12.7108 16.4556 12.375 16.0413 12.375V13.875ZM2.20801 15.625V3.95834H0.708008V15.625H2.20801ZM17.7913 3.95834V15.625H19.2913V3.95834H17.7913ZM18.5413 14.875H1.45801V16.375H18.5413V14.875ZM1.45801 4.70834H18.5413V3.20834H1.45801V4.70834ZM3.95801 7.62501H4.79134V6.12501H3.95801V7.62501ZM15.208 13.875H16.0413V12.375H15.208V13.875ZM10.9163 10C10.9163 10.5063 10.5059 10.9167 9.99967 10.9167V12.4167C11.3344 12.4167 12.4163 11.3347 12.4163 10H10.9163ZM9.99967 10.9167C9.49341 10.9167 9.08301 10.5063 9.08301 10H7.58301C7.58301 11.3347 8.66499 12.4167 9.99967 12.4167V10.9167ZM9.08301 10C9.08301 9.49375 9.49341 9.08334 9.99967 9.08334V7.58334C8.66499 7.58334 7.58301 8.66532 7.58301 10H9.08301ZM9.99967 9.08334C10.5059 9.08334 10.9163 9.49375 10.9163 10H12.4163C12.4163 8.66532 11.3344 7.58334 9.99967 7.58334V9.08334Z"
        fill="url(#paint0_linear_165_17236)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_165_17236"
          x1="9.99967"
          y1="3.95834"
          x2="9.99967"
          y2="15.625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FAAC" />
          <stop offset="1" stopColor="#B99AEB" />
        </linearGradient>
      </defs>
    </Icon>
  );
}

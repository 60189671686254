import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { compact, omit, uniqueId } from "lodash";
import { MutationHookOptions, useApolloClient } from "@apollo/client";
import { useDebounce } from "use-debounce";
import { getFetchPolicyForKey, ONE_HOUR } from "apollo/getFetchPolicyForKey";

import {
  CreateCustomerInput,
  CustomerFragment,
  CustomerFragmentDoc,
  CustomerQueryInput,
  CustomerSortOrder,
  CustomerSource,
  CustomerStatus,
  CustomerType,
  UpdateCustomerInput,
} from "graphql/types";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

import {
  CreateCustomerMutation,
  CreateCustomerMutationVariables,
  CustomerQuery,
  CustomerQueryVariables,
  CustomersDocument,
  CustomersQuery,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
  useCreateCustomerMutation,
  useCustomerLazyQuery,
  useCustomersQuery,
  usePagedCustomersQuery,
  useGetCustomerFormQuery,
  useUpdateCustomerMutation,
} from "./graphql.generated";
import { queryTypes, useQueryStates, UseQueryStatesKeysMap } from "next-usequerystate";
import { SortingState } from "@tanstack/react-table";
import { useSetState, useUpdateEffect } from "react-use";
import { mergeDeep } from "@apollo/client/utilities";

type CreateCustomerInputWithoutCompany = Omit<CreateCustomerInput, "companyId" | "id">;
type UpdateCustomerInputWithoutCompany = Omit<UpdateCustomerInput, "companyId">;
type GetSingleCustomerInput = Omit<CustomerQueryInput, "companyId">;

const DEFAULT_PER_PAGE = 50;

export const makeOptimisticCustomer = ({
  name,
  ...rest
}: CreateCustomerInputWithoutCompany): CustomerFragment => ({
  __typename: "Customer",
  id: uniqueId("customer"),
  name,
  ...rest,
});

export const makeOptimisticUpdateCustomer = ({
  name,
  ...rest
}: UpdateCustomerInputWithoutCompany): CustomerFragment => ({
  __typename: "Customer",
  name,
  ...rest,
});

export function useCreateCustomer(
  baseOptions?: MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>
) {
  const { company } = useActiveCompany<true>();

  const [_createCustomer, result] = useCreateCustomerMutation({
    optimisticResponse: ({ input }) => ({
      createCustomer: {
        customer: makeOptimisticCustomer(input),
      },
    }),

    ...baseOptions,
  });

  const createCustomer = useCallback(
    ({ name, ...rest }: CreateCustomerInputWithoutCompany) =>
      _createCustomer({
        variables: {
          input: {
            companyId: company.id,
            name,
            ...rest,
          },
        },

        update(cache, { data }) {
          const customer = data?.createCustomer.customer;
          if (!customer) {
            return;
          }
          const variables = { companyId: company.id };

          const customerData = cache.readQuery<CustomersQuery>({
            query: CustomersDocument,
            variables,
          });

          cache.writeQuery<CustomersQuery>({
            variables,
            query: CustomersDocument,
            data: {
              customers: { items: [...(customerData?.customers.items || []), customer] },
            },
          });
        },
      }),
    [_createCustomer, company.id]
  );

  return [createCustomer, result] as const;
}

export function useCustomer(
  baseOptions?: MutationHookOptions<CustomerQuery, CustomerQueryVariables>
) {
  const { company } = useActiveCompany<true>();

  const [_getSingleCustomer, result] = useCustomerLazyQuery(baseOptions);

  const getSingleCustomer = useCallback(
    ({ id }: GetSingleCustomerInput) => {
      return _getSingleCustomer({
        variables: {
          input: {
            id,
            companyId: company.id,
          },
        },
      });
    },
    [company.id, _getSingleCustomer]
  );

  return [getSingleCustomer, result] as const;
}

export function useUpdateCustomer(
  baseOptions?: MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>
) {
  const { company } = useActiveCompany<true>();

  const [_updateCustomer, result] = useUpdateCustomerMutation({
    optimisticResponse: ({ input }) => ({
      updateCustomer: {
        customer: makeOptimisticUpdateCustomer(input),
      },
    }),

    ...baseOptions,
  });

  const updateCustomer = useCallback(
    ({ name, ...rest }: UpdateCustomerInputWithoutCompany) =>
      _updateCustomer({
        variables: {
          input: {
            companyId: company.id,
            name,
            ...rest,
          },
        },

        update(cache, { data }) {
          const customer = data?.updateCustomer.customer;
          if (!customer) {
            return;
          }
          const variables = { companyId: company.id };

          const customerData = cache.readQuery<CustomersQuery>({
            query: CustomersDocument,
            variables,
          });

          cache.writeQuery<CustomersQuery>({
            variables,
            query: CustomersDocument,
            data: {
              customers: { items: [...(customerData?.customers.items || []), customer] },
            },
          });
        },
      }),
    [_updateCustomer, company.id]
  );

  return [updateCustomer, result] as const;
}

const CachedTerms = new Set<string>();
export const useCustomerSearch = (filterInput: string) => {
  const [debouncedFilter] = useDebounce(filterInput, 500);
  const { company } = useActiveCompany<true>();

  const filter = useMemo(
    () => (CachedTerms.has(filterInput) ? filterInput : debouncedFilter),
    [debouncedFilter, filterInput]
  );

  const result = useCustomersQuery({
    fetchPolicy: filter ? "cache-and-network" : getFetchPolicyForKey("topCustomers", ONE_HOUR),

    variables: {
      companyId: company.id,
      filterBy: {
        name: filter,
      },
      sortBy: filter ? CustomerSortOrder.NameAsc : CustomerSortOrder.InsertOrderDesc,
    },
  });

  useEffect(() => {
    if (!result.loading) {
      CachedTerms.add(filter);
    }
  }, [filter, result.loading]);

  const loading = Boolean(
    (result.loading && !result.data) ||
      (filterInput && filterInput !== result.variables?.filterBy?.name)
  );

  return useMemo(
    () => ({
      ...result,
      loading,
    }),
    [loading, result]
  );
};

export function useCustomersFilter(customerIds: string[]) {
  const { company } = useActiveCompany<true>();
  const client = useApolloClient();
  const initialCustomersFetched = useRef<boolean>(customerIds.length === 0);

  // Fetches specific customers to show their tags and preselect the dropdown
  const { data: customersData } = useCustomersQuery({
    fetchPolicy: "cache-first",
    skip: initialCustomersFetched.current,

    variables: {
      companyId: company.id,
      filterBy: { ids: customerIds },
    },
  });

  if (customersData) {
    initialCustomersFetched.current = true;
  }

  const selectedCustomers = useMemo(
    () =>
      compact(
        customerIds.map((id) => {
          return client.readFragment<CustomerFragment>({
            id: `Customer:${id}`,
            fragmentName: "customer",
            fragment: CustomerFragmentDoc,
          });
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, customerIds, customersData]
  );

  return { selectedCustomers };
}

export function useGetCustomer(id: string) {
  const { company } = useActiveCompany<true>();

  const { data, loading } = useGetCustomerFormQuery({
    variables: {
      input: {
        companyId: company.id,
        id,
      },
    },
  });

  const customer = data?.customer;

  return { loading, customer };
}

export type CustomersPageFilterState = {
  name?: string | null;
  sort: CustomerSortOrder;
  type?: CustomerType | null;
  status?: CustomerStatus | null;
  source?: CustomerSource | null;
};

const useCustomersPageQueryFilterState = () => {
  return useQueryStates<UseQueryStatesKeysMap>({
    name: queryTypes.string,
    sort: queryTypes.stringEnum<CustomerSortOrder>(Object.values(CustomerSortOrder)),
    type: queryTypes.stringEnum<CustomerType>(Object.values(CustomerType)),
    status: queryTypes.stringEnum<CustomerStatus>(Object.values(CustomerStatus)),
    source: queryTypes.stringEnum<CustomerSource>(Object.values(CustomerSource)),
  });
};

export const useCustomersPageFilter = () => {
  const [queryParamState, setQueryParamState] = useCustomersPageQueryFilterState();
  const initialState: CustomersPageFilterState = {
    sort: CustomerSortOrder.NameAsc,
    name: queryParamState.name ?? undefined,
    status: queryParamState.status ?? undefined,
    type: queryParamState.type ?? undefined,
    source: queryParamState.source ?? undefined,
  };
  const [sortOptions, setSortOptions] = useState<SortingState>(() => {
    let desc = false;

    switch (queryParamState.sort) {
      case CustomerSortOrder.NameAsc:
        desc = false;
        break;
      case CustomerSortOrder.NameDesc:
        desc = true;
        break;
    }

    return [{ id: "name", desc }];
  });

  const sortBy: CustomerSortOrder = useMemo(() => {
    const { desc } = sortOptions?.[0] || {};
    return desc ? CustomerSortOrder.NameDesc : CustomerSortOrder.NameAsc;
  }, [sortOptions]);

  const [filter, setFilter] = useSetState<CustomersPageFilterState>(initialState);
  useUpdateEffect(() => {
    setQueryParamState({ ...filter, sort: sortBy });
  }, [filter, sortBy]);

  return useMemo(() => {
    return {
      filter,
      setFilter,
      sortOptions,
      setSortOptions,
      sortBy,
    };
  }, [sortOptions, setSortOptions, sortBy, filter, setFilter]);
};

export const useFetchCustomersPaged = (
  filter: CustomersPageFilterState,
  sortBy?: CustomerSortOrder
) => {
  const { company } = useActiveCompany<true>();

  const queryVariables = useMemo(() => {
    return {
      sortBy,
      page: {
        count: DEFAULT_PER_PAGE,
      },
      filterBy: {
        ...omit(filter, "sort"),
      },
    };
  }, [filter, sortBy]);

  const { data, loading, fetchMore, refetch } = usePagedCustomersQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      companyId: company.id,
      ...queryVariables,
    },
  });

  const hasMore = data?.customers?.pageInfo.hasNextPage;
  const fetchNextPage = useCallback(() => {
    fetchMore({
      variables: mergeDeep(queryVariables, {
        page: {
          after: data?.customers?.pageInfo.endCursor,
        },
      }),
    });
  }, [fetchMore, data, queryVariables]);

  return useMemo(() => {
    return {
      data: data?.customers?.items,
      loading,
      hasMore,
      fetchNextPage,
      refetch,
    };
  }, [data, loading, hasMore, fetchNextPage, refetch]);
};

export const useSortableCustomersPage = () => {
  const { sortOptions, setSortOptions, sortBy, filter, setFilter } = useCustomersPageFilter();
  const {
    data: customersData,
    loading: customersLoading,
    hasMore,
    fetchNextPage,
    refetch,
  } = useFetchCustomersPaged(filter, sortBy);

  return useMemo(() => {
    return {
      customersData,
      customersLoading,
      hasMore,
      fetchNextPage,
      refetch,
      sortOptions,
      setSortOptions,
      sortBy,
      filter,
      setFilter,
    };
  }, [
    customersData,
    customersLoading,
    hasMore,
    fetchNextPage,
    refetch,
    sortOptions,
    setSortOptions,
    sortBy,
    filter,
    setFilter,
  ]);
};

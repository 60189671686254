import React, { useState, useMemo } from "react";
import { useToggle } from "react-use";
import { parseDate } from "@internationalized/date";

import { styled, Dialog, Button, Select } from "@puzzle/ui";
import { ConnectIcon } from "@puzzle/icons";
import { formatMoney, formatAccountNameWithInstitution } from "@puzzle/utils";

import { BasicTransactionFragment } from "../dashboard/Transactions/graphql.generated";
import useSearchOptions from "components/dashboard/Transactions/hooks/useSearchOptions";

import { AccountCell } from "./Cells/AccountCell";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import useAppRouter from "lib/useAppRouter";
import { useCreateManualCreditCardOffsetTransactionMutation } from "components/dashboard/Accounting/Reconciliation/graphql.generated";
import { useTransactionsPage } from "components/dashboard/Transactions/TransactionsProvider";

const CollapsibleContent = styled("div", {
  textVariant: "$bodyS",
  color: "$gray300",
  paddingTop: "$1",
  display: "flex",
  flexDirection: "column",
  gap: "$2",
  p: {
    // ughhhh CSS reset
    margin: 0,
  },
});

const AccountLabel = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
});

const AccountLabelRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  color: "$gray300",
  justifyContent: "space-between",
  marginBottom: "24px",
  fontSize: "13px",
});

const TxnDescriptorLabel = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "$1",
  marginBottom: "12px",
  fontSize: "$bodyM",
  color: "$gray50",
});

const CreditCardHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  marginTop: "$2",
  marginBottom: "$0h",
  textVariant: "$bodyM",
  color: "$gray200",
  cursor: "pointer",
  fontSize: "13px",
  fontWeight: "$bold",
});

const FooterAlign = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const DropdownFooterAlign = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginRight: "$2",
});

const BulletPoint = styled("div", {
  fontSize: "13px",
  lineHeight: "20px",
  marginBottom: "12px",
  marginTop: "12px",
  display: "grid",
  color: "$gray300",
  gridTemplateColumns: "10px auto",
});
const ButtonMargin = styled("div", {
  marginLeft: "12px",
});
const ItalicSection = styled("div", {
  fontStyle: "italic",
});

export enum CCOffsetSteps {
  Offset,
  Credit,
}

type CCOffsetModalProps = Omit<
  React.ComponentPropsWithoutRef<typeof Dialog>,
  "children" | "onChange" | "onKeyDown"
> & {
  transaction?: BasicTransactionFragment | null;
  isFinalizing?: boolean;
  initialStep?: CCOffsetSteps;
};

export const CCOffsetModal = ({
  open: _open,
  onOpenChange: _onOpenChange,
  transaction,
  isFinalizing = false,
  initialStep,
  ...props
}: CCOffsetModalProps) => {
  const { refetch: refetchTransactions } = useTransactionsPage();
  const [internalOpen, toggleInternalOpen] = useToggle(false);
  const [selectedCard, setSelectedCard] = useState<string>();
  const [step, setStep] = useState(initialStep);
  const [createManualCreditCardOffsetTxn] = useCreateManualCreditCardOffsetTransactionMutation();

  const { accountsById, creditCardAccounts } = useSearchOptions();
  const selectedCardAccount =
    typeof selectedCard !== "undefined" ? accountsById[selectedCard] : undefined;

  const creditCardPaymentAccountMap = useMemo(
    () =>
      creditCardAccounts.map((acc) => {
        return { value: acc.id, label: formatAccountNameWithInstitution(acc), account: acc };
      }),
    [creditCardAccounts]
  );
  const { goToIntegrations } = useAppRouter();

  const onOpenChange = _onOpenChange ?? toggleInternalOpen;
  const open = _open ?? internalOpen;

  const dateFormatter = useCompanyDateFormatter({ dateStyle: "long" });

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      width={600}
      // Prevents things like tables calling onRowClick
      onClick={(e) => e.stopPropagation()}
      {...props}
    >
      {step === CCOffsetSteps.Offset && (
        <>
          <Dialog.Title>Add offsetting transaction</Dialog.Title>
          <Dialog.Body>
            <CollapsibleContent>
              <p>
                In some instances, credit card providers do not transmit the credit card payment
                transactions to Puzzle. In such cases, you will need to create the card payment
                transaction, called “offsetting transaction.”
              </p>
              <p>
                Before doing so, please verify the following:
                <BulletPoint>
                  <div>&#8226;</div>
                  No payment transaction has been reflected on your Transactions page
                </BulletPoint>
                <BulletPoint>
                  <div>&#8226;</div>
                  At least three business days have elapsed from the date of the bank transaction -
                  as there are sometimes delays between a payment leaving your bank and posting to
                  your credit card.
                </BulletPoint>
                Currently the ability to remove offsetting transactions is limited without
                contacting Puzzle.
              </p>
            </CollapsibleContent>
          </Dialog.Body>
          <Dialog.Footer>
            <FooterAlign>
              <Button
                variant="secondary"
                onClick={() => {
                  onOpenChange(false);
                  setStep(CCOffsetSteps.Offset);
                }}
              >
                Cancel
              </Button>
              <ButtonMargin>
                <Button
                  variant="primary"
                  onClick={() => {
                    setStep(CCOffsetSteps.Credit);
                  }}
                >
                  Continue
                </Button>
              </ButtonMargin>
            </FooterAlign>
          </Dialog.Footer>
        </>
      )}
      {step === CCOffsetSteps.Credit && (
        <>
          <Dialog.Title>Add offsetting transaction</Dialog.Title>
          <Dialog.Body>
            <CollapsibleContent>
              <p>Which credit card account is this payment associated with?</p>
              <p>
                Identifying the card being paid allows Puzzle to associate this payment with a
                specfic card, increasing accuracy and creating a stronger audit trail.
              </p>
            </CollapsibleContent>
            {transaction && (
              <>
                <TxnDescriptorLabel>
                  <span>{transaction.descriptor}</span>
                  <span>{formatMoney({ currency: "USD", amount: transaction.amount })}</span>
                </TxnDescriptorLabel>
                <AccountLabelRow>
                  <AccountLabel>
                    <AccountCell account={transaction.account}></AccountCell>{" "}
                    {transaction.account.name}
                  </AccountLabel>
                  <span>{dateFormatter.format(parseDate(transaction.date))}</span>
                </AccountLabelRow>

                <CreditCardHeader> Credit card account paid</CreditCardHeader>

                <Select
                  size="large"
                  value={
                    selectedCardAccount && formatAccountNameWithInstitution(selectedCardAccount)
                  }
                  onSelectionChange={(value) => {
                    setSelectedCard(value);
                  }}
                  options={creditCardPaymentAccountMap}
                  placeholder="Select credit card account"
                  footer={
                    <DropdownFooterAlign>
                      <Button size="mini" variant="minimal" onClick={() => goToIntegrations()}>
                        Connect another account
                      </Button>
                      <ConnectIcon />
                    </DropdownFooterAlign>
                  }
                />
              </>
            )}
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              variant="primary"
              disabled={typeof selectedCardAccount === "undefined"}
              onClick={async () => {
                await createManualCreditCardOffsetTxn({
                  variables: {
                    input: {
                      transactionId: transaction!.id,
                      destinationAccountId: selectedCardAccount!.id,
                    },
                  },
                  update: (cache, { data }) => {
                    cache.modify({
                      id: cache.identify(transaction!.detail),
                      fields: {
                        linkedTransaction: () => data?.createManualCreditCardOffsetTransaction,
                      },
                    });
                  },
                });
                await refetchTransactions();

                onOpenChange(false);
              }}
            >
              Add
            </Button>
          </Dialog.Footer>
        </>
      )}
    </Dialog>
  );
};

import React from "react";

import { Stepper } from "components/intro/components/Stepper";

const steps = [
  {
    label: "Tell us about your bookkeeping plan",
    _first: true,
  },
  {
    label: "OpenAI data sharing (optional)",
  },
  {
    label: "Explore Puzzle's features",
    _last: true,
  },
];

export type StepperPaneProps = {
  activeIndex: number;
  farthestViewedIndex: number;
  farthestCompletedIndex: number;
  jumpTo: (val: number) => void;
}

export const StepperPane = ({
  activeIndex,
  farthestViewedIndex,
  farthestCompletedIndex,
  jumpTo,
}: StepperPaneProps) => {
  const enhancedSteps = steps.map((step, index) => {
    return {
      ...step,
      active: index === activeIndex,
      _visited: index <= farthestViewedIndex,
      completed: index <= farthestCompletedIndex,
    };
  });

  return (
    <div>
      {enhancedSteps.map((step, index) => (
        <Stepper key={step.label} {...step} onClick={() => jumpTo(index)} />
      ))}
    </div>
  );
};

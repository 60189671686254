import React from "react";
import Icon, { IconProps } from "./Icon";

export default function ChartRising3({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} viewBox={viewBox} fill="none" {...iconProps}>
      <path
        d="M2.29199 3.95831V16.0416H17.7087M5.62533 12.7083L9.16699 9.16665L10.8337 10.8333L16.042 5.62498M16.042 5.62498H12.292M16.042 5.62498V9.37498"
        stroke="url(#paint0_linear_165_17230)"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <defs>
        <linearGradient
          id="paint0_linear_165_17230"
          x1="10.0003"
          y1="3.95831"
          x2="10.0003"
          y2="16.0416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FAAC" />
          <stop offset="1" stopColor="#B99AEB" />
        </linearGradient>
      </defs>
    </Icon>
  );
}

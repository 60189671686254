import React from "react";
import { useManualSync } from "../useManualSync";
import { IntegrationType } from "graphql/types";
import { ManualSyncStatus } from "../ManualSyncStatus";
import { useRippling } from "./useRippling";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

const RipplingManualSyncStatus = ({ companyId }: { companyId: string }) => {
  const { company, isOnPaidPlan } = useActiveCompany<true>();
  const { connection, startPolling, stopPolling, refetch } = useRippling({ companyId });

  const { isSyncing, getSyncedAt, triggerSync } = useManualSync({
    connection,
    integrationName: IntegrationType.Rippling,
    startPolling,
    stopPolling,
    refetch,
  });

  return (
    <ManualSyncStatus
      connection={connection}
      isSyncing={isSyncing}
      syncedAt={getSyncedAt()}
      triggerSync={triggerSync}
      hideTriggerSync={!isOnPaidPlan && !company.features.ripplingAddOnSelected}
    />
  );
};

export default RipplingManualSyncStatus;

import React from "react";

import { Check, CircledIcon, Exclamation } from "@puzzle/icons";
import { SynchronizingThick } from "components/common/Synchronizing";
import { IntegrationConnectionWithAccountStatsFragment, SyncRunStatus } from "graphql/types";
import { Stack, vars } from "ve";
import { ConnectionSubText } from "./DetailDrawer/shared";
import { Button } from "@puzzle/ui";
import { JUST_NOW } from "./useManualSync";

type ManualSyncProps = {
  isSyncing: boolean;
  triggerSync: () => void;
  connection?: IntegrationConnectionWithAccountStatsFragment | null;
  syncedAt: string;
  hideTriggerSync?: boolean;
}

export const ManualSyncStatus = ({
  isSyncing,
  triggerSync,
  connection,
  syncedAt,
  hideTriggerSync,
}: ManualSyncProps) => {
  if (!connection) return null;

  return (
    <Stack
      direction="horizontal"
      gap={vars.space["1h"]}
      css={{
        alignItems: "center",
      }}
    >
      <Stack direction="horizontal" gap={vars.space["1"]}>
        {isSyncing ? (
          <ConnectionSubText>Syncing...</ConnectionSubText>
        ) : (
          <>
            {connection.latestSync?.status === SyncRunStatus.Failed ? (
              <>
                <Exclamation fill={vars.colors.red500} size={14} />
                <ConnectionSubText> Sync failed. Last sync {syncedAt}</ConnectionSubText>
              </>
            ) : (
              <>
                {syncedAt === JUST_NOW && (
                  <CircledIcon backgroundColor={vars.colors.greenalpha} width={16} height={16}>
                    <Check color={vars.colors.mauve800} size={8} />
                  </CircledIcon>
                )}
                <ConnectionSubText>Last synced {syncedAt}</ConnectionSubText>
              </>
            )}
          </>
        )}
      </Stack>
      {!hideTriggerSync && (
        <Button
          icon={true}
          variant="secondary"
          css={{
            height: 20,
            width: 20,
            padding: vars.space["$1h"],
          }}
          disabled={isSyncing}
          onClick={async () => {
            if (isSyncing) return;
            await triggerSync();
          }}
        >
          <SynchronizingThick animate={isSyncing} />
        </Button>
      )}
    </Stack>
  );
};

import React from "react";
import Icon, { IconProps } from "./Icon";

export default function PlayCircleFilled({
  viewBox = "0 0 51 50",
  width = 50,
  height = 50,
  fill = "white",
  stroke = "black",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox} width={width} height={height}>
      <path
        d="M50 25C50 38.531 39.031 49.5 25.5 49.5C11.969 49.5 1 38.531 1 25C1 11.469 11.969 0.5 25.5 0.5C39.031 0.5 50 11.469 50 25ZM22.7358 16.5443C21.5742 15.7522 20 16.5842 20 17.9902V32.0098C20 33.4158 21.5742 34.2478 22.7358 33.4557L33.0169 26.4459C34.0358 25.7512 34.0358 24.2488 33.0169 23.5541L22.7358 16.5443Z"
        fill={fill}
        stroke={stroke}
      />
    </Icon>
  );
}

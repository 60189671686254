import { WatchQueryFetchPolicy } from "@apollo/client";
import type { ActiveCompanyFragment } from "components/companies/graphql.generated";

import { useFirstTransactionQuery } from "components/companies/graphql.generated";
import {
  Category,
  CategoryFragment,
  Transaction,
  TransactionDetail,
  TransactionSortOrder,
} from "graphql/types";
import { parseDate, today } from "@puzzle/utils";

// TODO: Do we have app configs?
// imo (TG): it would be best if we could control these values on the server
// imo (PS): This logic shouldn't be on the client.
// The client should ask GW if we should go through additional steps.
// This could happen in recategorizeTransaction or as a separate query.
export const CAPITALIZABLE_CATEGORIES = ["computers_and_hardware", "furniture", "fixed_assets"];

// TODO: Do we have app configs?
// imo (TG): it would be best if we could control these values on the server
const CAPITALIZABLE_TXN_AMOUNT_THRESHOLD = 2500;

export const isCapitalizable = (
  transaction: Pick<Transaction, "id" | "amount"> & {
    detail: Pick<TransactionDetail, "category">;
  },
  category: Pick<Category, "permaKey"> = transaction.detail.category
): boolean => {
  const isCapitalizableCategory = CAPITALIZABLE_CATEGORIES.includes(category.permaKey);
  const txnAmount = transaction?.amount ? parseFloat(`${-transaction.amount}`) : 0;

  if (txnAmount <= 0) {
    // if less than zero, then we received given money (since it was positive before), so cant be capitalizable
    // if equal to zero, then it was previously undefined
    return false;
  }

  const largeEnoughTxn = txnAmount > CAPITALIZABLE_TXN_AMOUNT_THRESHOLD;

  return isCapitalizableCategory && largeEnoughTxn;
};

export const isCreditCard = (
  transaction: Pick<Transaction, "id" | "amount"> & {
    detail: Pick<TransactionDetail, "category">;
  },
  category: Pick<CategoryFragment, "coaKey"> = transaction.detail.category
): boolean => {
  return category.coaKey === "credit_card_payment";
};

export const useGetTransactionListAllTimeRange = (company: ActiveCompanyFragment) => {
  const queryParams = (sortBy: TransactionSortOrder) => {
    return {
      fetchPolicy: "no-cache" as WatchQueryFetchPolicy,
      variables: {
        companyId: company.id,
        page: {
          after: "0",
          count: 1,
        },
        sortBy: sortBy,
      },
    };
  };

  const { data: firstTransactionDateData } = useFirstTransactionQuery(
    queryParams(TransactionSortOrder.DateAsc)
  );
  const { data: lastTransactionDateData } = useFirstTransactionQuery(
    queryParams(TransactionSortOrder.DateDesc)
  );
  let allTimeRange = [
    today(company.timeZone || "America/Los_Angeles"),
    today(company.timeZone || "America/Los_Angeles"),
  ];
  if (
    firstTransactionDateData?.company?.transactions.nodes &&
    firstTransactionDateData?.company?.transactions.nodes.length > 0
  ) {
    const oldestTransaction = firstTransactionDateData?.company?.transactions.nodes[0];
    allTimeRange = [parseDate(oldestTransaction.date), allTimeRange[1]];
  }
  if (
    lastTransactionDateData?.company?.transactions.nodes &&
    lastTransactionDateData?.company?.transactions.nodes.length > 0
  ) {
    const newestTransaction = lastTransactionDateData?.company?.transactions.nodes[0];
    allTimeRange = [allTimeRange[0], parseDate(newestTransaction.date)];
  }
  return allTimeRange;
};

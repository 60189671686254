import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";

import Config, { IS_STAGING, IS_LOCAL_DEVELOPMENT, IS_DEV, IS_PROD, IS_TEST } from "lib/config";

// We might want to consider using the react specific plugin here too
// More info in https://www.npmjs.com/package/@datadog/browser-rum-react

const IS_DEBUGGING = false || !IS_PROD;

// RUM Sampling configuration
const SAMPLE_RATE = 10;
const DEBUGGING_SAMPLE_RATE = IS_DEBUGGING ? 100 : 0;
const SESSION_SAMPLE_RATE = IS_PROD ? SAMPLE_RATE : DEBUGGING_SAMPLE_RATE;

// Tracing Sampling configuration
const matchesURL = (url: string) => {
  return url.endsWith(Config.CLIENT_GRAPHQL_ENDPOINT);
};
// For OTEL tracing with OpenTelemetry
const OTEL_TRACER_TYPE = "tracecontext";
const ALLOWED_TRACING_URLS = [
  {
    match: matchesURL,
    propagatorTypes: [OTEL_TRACER_TYPE],
  },
];
// For RUM tracing with Datadog
// const ALLOWED_TRACING_URLS = [matchesURL];
const TRACE_SAMPLE_RATE = 10;
const DEBUGGING_TRACE_SAMPLE_RATE = IS_DEBUGGING ? 100 : 0;
const SESSION_TRACE_SAMPLE_RATE = IS_PROD ? TRACE_SAMPLE_RATE : DEBUGGING_TRACE_SAMPLE_RATE;

// Other configurations
const HAS_INTERACTIONS_TRACKING = false;
const HAS_SESSION_REPLAY = false;
const SESSION_REPLAY_SAMPLE_RATE = 10;

// Setup configuration
const DATADOG_RUM_APPLICATION_ID = "1588c6ea-d710-48f6-a054-ea3245209a2b";
const DATADOG_SITE = "us5.datadoghq.com";
const APP_NAME_IN_DATADOG = "app.puzzle.io";

// TODO: This should be updated with the version of the app. It is optional though.
const VERSION = "1.0.0";

// List of all the custom attributes that we want to send to datadog
// @param IS_DECIMAL: Boolean that indicates if the company is a decimal company
// @param COMPANY_COUNT: Number of companies that the user has access to
export const CUSTOM_CONTEXT_ATTRIBUTES = {
  IS_DECIMAL: "isDecimal",
  COMPANY_COUNT: "companyCount",
};

const getDevEnv = () => {
  if (IS_LOCAL_DEVELOPMENT) {
    return "local";
  }
  if (IS_DEV) {
    return "development";
  }
  if (IS_PROD) {
    return "production";
  }
  if (IS_STAGING) {
    return "staging";
  }
  if (IS_TEST) {
    return "test";
  }
  return "production";
};

// Initialize the datadogRum
// More info on the configuration options can be found here:
// https://docs.datadoghq.com/real_user_monitoring/browser/setup/client/?tab=rum
const config: RumInitConfiguration = {
  clientToken: Config.DATADOG_CLIENT_TOKEN,
  applicationId: DATADOG_RUM_APPLICATION_ID,
  site: DATADOG_SITE,
  service: APP_NAME_IN_DATADOG,
  env: getDevEnv(),
  version: VERSION,
  sessionSampleRate: SESSION_SAMPLE_RATE,
  sessionReplaySampleRate: HAS_SESSION_REPLAY ? SESSION_REPLAY_SAMPLE_RATE : 0,
  trackUserInteractions: HAS_INTERACTIONS_TRACKING,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  // Tracing Configuration
  // NOTE: couldn't find the required type (PropagatorType) exported anywere on datadog's or OTEL libraries
  allowedTracingUrls: ALLOWED_TRACING_URLS as any,
  traceSampleRate: SESSION_TRACE_SAMPLE_RATE,
  traceContextInjection: "sampled",
};

if (IS_DEBUGGING) {
  console.log("Datadog RUM initialized with config:", config);
}

datadogRum.init(config);

import React from "react";
import { FinancialInstitutionFragment, IntegrationType } from "graphql/types";
import { IntegrationsListItemBaseProps } from "../List";
import { usePlaid } from "../plaid";
import InstitutionLogo from "./InstitutionLogo";
import { IntegrationDisplayComponent } from "../shared/types";

type DisplayBankListItemProps = {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  financialInstitution: FinancialInstitutionFragment;
  companyId: string;
}

const DisplayBankListItem = ({
  DisplayComponent,
  financialInstitution,
  companyId,
}: DisplayBankListItemProps) => {
  const { onClickConnect } = usePlaid({
    financialInstitutionId: financialInstitution.id,
    companyId,
  });
  const institutionName = financialInstitution.name;

  return (
    <>
      <DisplayComponent
        integrationType={IntegrationType.Plaid}
        title={institutionName}
        connectIntegration={onClickConnect}
        logo={<InstitutionLogo institution={financialInstitution} />}
      />
    </>
  );
};

export default DisplayBankListItem;

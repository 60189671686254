import React, { useCallback, useMemo, useState } from "react";
import { AccountWithIntegrationFragment, IntegrationConnectionStatus } from "graphql/types";
import { useToasts } from "@puzzle/ui";
import { useFinancialInstitutions } from "../shared";
import RemoveAccountConfirmationModal from "../shared/RemoveAccountConfirmationModal";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import Analytics from "../../../lib/analytics";
import { getIntegrationCategoryForAccountType } from "../setup/utils";
import { IntegrationCategory } from "../setup/types";
import FooterButton from "./FooterButton";
import useAppRouter from "../../../lib/useAppRouter";

const RemoveAccountButton = ({ account }: { account: AccountWithIntegrationFragment }) => {
  const { toast } = useToasts();
  const { company } = useActiveCompany<true>();
  const {
    removeAccount,
    disconnectAccount: stopSyncing,
    addPendingAccount,
    removePendingAccount,
  } = useFinancialInstitutions();
  const { goToIntegrations } = useAppRouter();
  const [isRemoving, setIsRemoving] = useState(false);

  const shouldShowButton = useMemo(() => {
    if (!account) {
      return false;
    }

    return true;
  }, [account]);

  const remove = useCallback(async () => {
    if (!account) {
      return;
    }
    setIsRemoving(true);
    addPendingAccount({
      accountId: account.id,
      pendingLabel: "Removing",
      manageAccountTooltip: "This account is in the process of being removed.",
    });
    removeAccount(account.id, company.id).then((result) => {
      Analytics.accountRemoved({ accountId: account.id });
      removePendingAccount(account.id);

      if (result?.data?.removeAccount.success) {
        toast({ message: "Successfully removed account.", status: "success" });
      } else {
        toast({ message: "Failed to remove account.", status: "error" });
      }

      setIsRemoving(false);
    });

    toast({
      message: "Removing your account. This may take a few minutes.",
      status: "success",
    });
    goToIntegrations();
  }, [
    account,
    addPendingAccount,
    company.id,
    goToIntegrations,
    removeAccount,
    removePendingAccount,
    toast,
  ]);

  const disabledOptions = useMemo(() => {
    const category = getIntegrationCategoryForAccountType(account.type);
    if (category !== IntegrationCategory.BanksAndCreditCards) {
      return {
        disabled: true,
        reason: `Removing is not available for this account type.\n\nIf you are missing data, try disconnecting and reconnecting.\n\nIf you need to delete data associated with this account, you can do so within the relevant page of the app`,
      };
    }

    if (!account.manuallyAdded && account.status !== IntegrationConnectionStatus.Disconnected) {
      return {
        disabled: true,
        reason: "Please deactivate this account before removing it.",
      };
    }

    return { disabled: false, reason: undefined };
  }, [account]);

  const stopSyncingAccount = useCallback(async () => {
    if (!account) {
      return;
    }
    const result = await stopSyncing(account.id);

    if (result?.errors) {
      toast({ message: "Failed to deactivate account.", status: "error" });
    } else {
      toast({ message: "Successfully deactivated account.", status: "success" });
    }
  }, [account, stopSyncing, toast]);

  if (!shouldShowButton) {
    return null;
  }

  return (
    <>
      <FooterButton
        label="Remove"
        variant="negative"
        description={`Remove all existing data and prevent resyncing.`}
        onClick={() => setIsRemoving(true)}
        canBeUndone={false}
        disabled={disabledOptions.disabled}
        disabledTooltip={disabledOptions.reason}
      />

      <RemoveAccountConfirmationModal
        account={account}
        open={isRemoving}
        onOpenChange={setIsRemoving}
        onRemove={remove}
        onConfirm={stopSyncingAccount}
      />
    </>
  );
};

export default RemoveAccountButton;

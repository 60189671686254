import { keyBy, omit } from "lodash";
import {
  useCreateProductMutation,
  useProductsQuery,
  ProductsDocument,
  ProductsQuery,
  usePagedProductsQuery,
  useUpdateProductMutation,
  useGetProductFormQuery,
} from "./graphql.generated";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCallback, useMemo, useState } from "react";
import { useSetState, useUpdateEffect } from "react-use";
import { queryTypes, useQueryStates, UseQueryStatesKeysMap } from "next-usequerystate";
import {
  CreateProductInput,
  Product,
  ProductFragment,
  ProductServiceType,
  ProductSortOrder,
  ProductStatus,
  UpdateProductInput,
} from "graphql/types";
import { SortingState } from "@tanstack/react-table";
import { mergeDeep } from "@apollo/client/utilities";
import { ApolloCache } from "@apollo/client";

const DEFAULT_PER_PAGE = 50;

function updateCache(
  cache: ApolloCache<Product>,
  companyId: string,
  data?: ProductFragment | null
) {
  if (!data) {
    return;
  }

  const variables = { companyId };

  const productData = cache.readQuery<ProductsQuery>({
    query: ProductsDocument,
    variables,
  });

  cache.writeQuery<ProductsQuery>({
    variables,
    query: ProductsDocument,
    data: {
      products: { items: [...(productData?.products.items || []), data] },
    },
  });
}

export default function useProducts() {
  const [_createProduct] = useCreateProductMutation();
  const [_updateProduct] = useUpdateProductMutation();
  const { company } = useActiveCompany<true>();
  const { data, loading } = useProductsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { companyId: company.id },
  });

  const createProduct = (input: string | CreateProductInput) =>
    _createProduct({
      variables: {
        input: {
          ...(typeof input === "string" ? { name: input } : input),
          companyId: company.id,
        },
      },

      update(cache, { data }) {
        const product = data?.createProduct.product;
        updateCache(cache, company.id, product);
      },
    });

  const updateProduct = (input: UpdateProductInput) =>
    _updateProduct({
      variables: {
        input: {
          ...input,
          companyId: company.id,
        },
      },

      update(cache, { data }) {
        const product = data?.updateProduct.product;
        updateCache(cache, company.id, product);
      },
    });

  return {
    createProduct,
    updateProduct,
    products: data?.products.items ?? [],
    productsById: keyBy(data?.products.items, "id"),
    loading,
  };
}

export function useGetProduct(productId: string) {
  const { company } = useActiveCompany<true>();

  return useGetProductFormQuery({
    variables: {
      companyId: company.id,
      productId,
    },
  });
}

export const useFetchProductsPaged = (
  filter: ProductsPageFilterState,
  sortBy?: ProductSortOrder
) => {
  const { company } = useActiveCompany<true>();

  const queryVariables = useMemo(() => {
    return {
      sortBy,
      page: {
        count: DEFAULT_PER_PAGE,
      },
      filterBy: {
        ...omit(filter, "sort"),
      },
    };
  }, [filter, sortBy]);

  const { data, loading, fetchMore, refetch } = usePagedProductsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      companyId: company.id,
      ...queryVariables,
    },
  });

  const hasMore = data?.products?.pageInfo.hasNextPage;
  const fetchNextPage = useCallback(() => {
    fetchMore({
      variables: mergeDeep(queryVariables, {
        page: {
          after: data?.products?.pageInfo.endCursor,
        },
      }),
    });
  }, [fetchMore, data, queryVariables]);

  return useMemo(() => {
    return {
      data: data?.products?.items,
      loading,
      hasMore,
      fetchNextPage,
      refetch,
    };
  }, [data, loading, hasMore, fetchNextPage, refetch]);
};

export type ProductsPageFilterState = {
  name?: string | null;
  sort: ProductSortOrder;
  status?: ProductStatus | null;
  serviceType?: ProductServiceType | null;
};

const useProductsPageQueryFilterState = () => {
  return useQueryStates<UseQueryStatesKeysMap>({
    name: queryTypes.string,
    sort: queryTypes.stringEnum<ProductSortOrder>(Object.values(ProductSortOrder)),
    status: queryTypes.stringEnum<ProductStatus>(Object.values(ProductStatus)),
    serviceType: queryTypes.stringEnum<ProductServiceType>(Object.values(ProductServiceType)),
  });
};

export const useProductsPageFilter = () => {
  const [queryParamState, setQueryParamState] = useProductsPageQueryFilterState();
  const initialState: ProductsPageFilterState = {
    sort: ProductSortOrder.NameAsc,
    name: queryParamState.name ?? undefined,
    status: queryParamState.status ?? undefined,
    serviceType: queryParamState.type ?? undefined,
  };
  const [sortOptions, setSortOptions] = useState<SortingState>(() => {
    let desc = false;

    switch (queryParamState.sort) {
      case ProductSortOrder.NameAsc:
        desc = false;
        break;
      case ProductSortOrder.NameDesc:
        desc = true;
        break;
    }

    return [{ id: "name", desc }];
  });

  const sortBy: ProductSortOrder = useMemo(() => {
    const { desc } = sortOptions?.[0] || {};
    return desc ? ProductSortOrder.NameDesc : ProductSortOrder.NameAsc;
  }, [sortOptions]);

  const [filter, setFilter] = useSetState<ProductsPageFilterState>(initialState);
  useUpdateEffect(() => {
    setQueryParamState({ ...filter, sort: sortBy });
  }, [filter, sortBy]);

  return useMemo(() => {
    return {
      filter,
      setFilter,
      sortOptions,
      setSortOptions,
      sortBy,
    };
  }, [sortOptions, setSortOptions, sortBy, filter, setFilter]);
};

export const useSortableProductsPage = () => {
  const { sortOptions, setSortOptions, sortBy, filter, setFilter } = useProductsPageFilter();
  const {
    data: productsData,
    loading: productsLoading,
    hasMore,
    fetchNextPage,
    refetch,
  } = useFetchProductsPaged(filter, sortBy);

  return useMemo(() => {
    return {
      productsData,
      productsLoading,
      hasMore,
      fetchNextPage,
      refetch,
      sortOptions,
      setSortOptions,
      sortBy,
      filter,
      setFilter,
    };
  }, [
    productsData,
    productsLoading,
    hasMore,
    fetchNextPage,
    refetch,
    sortOptions,
    setSortOptions,
    sortBy,
    filter,
    setFilter,
  ]);
};

import React from "react";
import HoverCard from "components/common/HoverCard";
import { MoneyIn, MoneyOut, Split } from "@puzzle/icons";
import { zIndex } from "@puzzle/utils";
import { S, color, vars } from "ve";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

type DescriptionCellProps = {
  colWidth: number;
  paddingWidth: number;
  text: string;
  isNegative?: boolean;
  isSplit?: boolean;
};

export const DescriptionCell = ({
  colWidth,
  paddingWidth,
  isNegative,
  text,
  isSplit,
}: DescriptionCellProps) => {
  return (
    <div
      style={{
        width: colWidth - paddingWidth,
        display: "flex",
        alignItems: "center",
      }}
    >
      <span
        style={{
          color: isNegative ? color.red600 : color.green700,
          marginRight: S.$1,
          lineHeight: 0,
        }}
      >
        {isSplit ? <Split /> : isNegative ? <MoneyOut /> : <MoneyIn />}
      </span>
      <HoverCard
        side="top"
        arrow={true}
        openDelay={350}
        closeDelay={100}
        style={{
          zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("tooltip") : "auto",
          maxWidth: colWidth,
          wordBreak: "break-word",
        }}
        trigger={
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {text}
          </span>
        }
      >
        <HoverCard.Body
          style={{
            background: color.mauve900,
            padding: `${S.$1} ${S.$2}`,
            margin: 0,
          }}
        >
          <p
            style={{
              color: color.gray100,
              fontSize: vars.fontSizes.bodyS,
              lineHeight: vars.lineHeights.bodyS,
              letterSpacing: vars.letterSpacings.bodyS,
              margin: 0,
              padding: 0,
            }}
          >
            <span>{text}</span>
          </p>
        </HoverCard.Body>
      </HoverCard>
    </div>
  );
};

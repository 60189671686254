import React from "react";
import { Box, Button, Text, S } from "ve";
import { Close, UserEducation } from "@puzzle/icons";
import { useBreakpoint } from "@puzzle/ui";
import { useLocalStorage } from "react-use";
import { kebabCase } from "lodash";
import Analytics from "lib/analytics";
import { YouTubePlayer, YouTubePlayerProps } from "components/common/YouTubePlayer";
import {
  bulletPointsStyle,
  buttonContainerStyle,
  closeButtonStyle,
  descriptionContainerStyle,
  descriptionItemsContainerStyle,
  descriptionItemWithIconStyle,
  descriptionStyle,
  titleContainerStyle,
  titleIconStyle,
} from "./EducationBanner.css";

type DescriptionWithIcon = {
  content: string;
  icon: React.ReactNode;
};

export const buildBannerDescription = ({
  heading,
  items,
  footer,
}: {
  heading: string;
  items: string[] | DescriptionWithIcon[];
  footer?: React.ReactNode;
}) => {
  const isBulletPoint = typeof items[0] === "string";
  return (
    <Box className={descriptionItemsContainerStyle}>
      <span>{heading}</span>
      {isBulletPoint ? (
        <ul className={bulletPointsStyle}>
          {(items as string[]).map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      ) : (
        (items as DescriptionWithIcon[]).map((item, i) => (
          <Box key={i} className={descriptionItemWithIconStyle}>
            {item.icon}
            <span>{item.content}</span>
          </Box>
        ))
      )}
      {footer}
    </Box>
  );
};

export type EducationBannerProps = {
  feature: string;
  learnMore?: string;
  tutorial?: string;
  thumbnail?: YouTubePlayerProps["customThumbnailUrl"];
  thumbnailWidth?: YouTubePlayerProps["width"];
  thumbnailHeight?: YouTubePlayerProps["height"];
  showPlayButton?: YouTubePlayerProps["showPlayButton"];
  actionButton?: React.ReactNode;
  title: string;
  description: string | string[] | React.ReactNode;
  showCloseButton?: boolean;
  css?: React.CSSProperties;
  onClose?: () => void;
}

const EducationBanner = ({
  feature,
  learnMore,
  tutorial = "",
  thumbnail,
  actionButton,
  title,
  description,
  showCloseButton = false,
  showPlayButton = false,
  thumbnailWidth = 320,
  thumbnailHeight = 180,
  css,
  onClose,
}: EducationBannerProps) => {
  const [closed, setClosed] = useLocalStorage(
    `pz:education-banner-closed:${kebabCase(feature)}`,
    false
  );
  const breakpoint = useBreakpoint();
  const isSmallScreen = ["xs", "s", "m", "l"].includes(breakpoint);
  const showPlayer = thumbnail || tutorial;

  const analyticsEvent = {
    feature,
    location: "Intro Banner",
  };

  const handleOnClose = () => {
    setClosed(true);
    onClose?.();
    Analytics.featureOnboardingDismissalClicked(analyticsEvent);
  };

  const handleAction = () => {
    Analytics.featureOnboardingPrimaryCtaClicked(analyticsEvent);
  };

  const handleLearnMore = () => {
    window.open(learnMore, "_blank");
    Analytics.featureOnboardingLearnMoreClicked(analyticsEvent);
  };

  const handleTutorial = () => {
    Analytics.featureOnboardingVideoClicked(analyticsEvent);
  };

  return (
    <Box
      css={{
        display: showCloseButton && closed ? "none" : "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: showCloseButton ? "auto" : "100%",
        paddingBottom: showCloseButton ? S["3"] : 0,
        ...css,
      }}
    >
      <Box
        css={{
          display: "flex",
          position: "relative",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: "center",
          width: showCloseButton ? "100%" : "unset",
          background:
            "linear-gradient(90deg, rgba(171,125,227,0.24) 0%, rgba(68,207,142,0.24) 100%)",
          borderRadius: S["1h"],
          padding: S["3"],
          gap: S["3"],
        }}
      >
        {showPlayer && (
          <YouTubePlayer
            videoUrl={tutorial}
            onPlay={handleTutorial}
            customThumbnailUrl={thumbnail}
            width={thumbnailWidth}
            height={thumbnailHeight}
            showPlayButton={showPlayButton}
          />
        )}
        <Box
          css={{
            display: "flex",
            flexDirection: "column",
            gap: S["2"],
          }}
        >
          <Box className={titleContainerStyle}>
            <Box className={titleIconStyle}>
              <UserEducation width={20} height={20} />
            </Box>
            <Text variant="headingM">{title}</Text>
          </Box>
          <Box className={descriptionContainerStyle}>
            {Array.isArray(description) ? (
              description.map((paragraph, i) => (
                <Text key={i} className={descriptionStyle}>
                  {paragraph}
                </Text>
              ))
            ) : (
              <Text className={descriptionStyle}>{description}</Text>
            )}
          </Box>
          <Box className={buttonContainerStyle}>
            {actionButton && <Box onClick={handleAction}>{actionButton}</Box>}
            {learnMore && (
              <Button
                size="compact"
                variant="secondary"
                css={{ borderRadius: S["0h"] }}
                onClick={handleLearnMore}
              >
                Learn More
              </Button>
            )}
          </Box>
        </Box>
        {showCloseButton && <Close className={closeButtonStyle} onClick={handleOnClose} />}
      </Box>
    </Box>
  );
};

export default EducationBanner;

import { Ellipsis } from "@puzzle/icons";
import { colors } from "@puzzle/theme";
import { Presence } from "@radix-ui/react-presence";
import { VariantProps } from "@stitches/react";
import React, { useState } from "react";
import { usePrevious } from "react-use";
import Button from "./Button";
import Menu from "./Menu";
import { keyframes, styled } from "./stitches";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { zIndex } from "@puzzle/utils";

const scaleFadeIn = keyframes({
  from: { transform: "scale(0.95)", opacity: 0 },
  to: { transform: "scale(1)", opacity: 1 },
});

const scaleFadeOut = keyframes({
  from: { transform: "scale(1)", opacity: 1 },
  to: { transform: "scale(0.95)", opacity: 0 },
});

const durations = {
  open: 200,
  close: 150,
};

const ContainerRoot = styled("div", {
  display: "flex",
  position: "fixed",
  bottom: "$10",
  left: 0,
  width: "100vw",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "$3",
  zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("floatingActionBar") : 1200,
  pointerEvents: "none",

  '&[data-state="open"]': {
    animation: `${scaleFadeIn} ${durations.open}ms cubic-bezier(0.4, 0, 0.2, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `${scaleFadeOut} ${durations.close}ms cubic-bezier(0.4, 0, 0.2, 1)`,
  },
});
const Root = ({
  children,
  present = true,
  ...props
}: React.PropsWithChildren<Partial<Omit<React.ComponentProps<typeof Presence>, "children">>>) => {
  // Don't show the initial state while animating out
  const previousChildren = usePrevious(children);
  return (
    <Presence present={present} {...props}>
      <ContainerRoot data-state={present ? "open" : "closed"}>
        {present ? children : previousChildren}
      </ContainerRoot>
    </Presence>
  );
};

const BaseActionBar = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$2",
  backgroundColor: "#32313D",
  // FIXME base color isn't in our theme. 100% looks red, but it's used a lot so we should standardize
  border: "1px solid rgba(18, 23, 24, 0.05)",
  padding: "$1 $2",
  width: "fit-content",
  borderRadius: "$1",
  boxShadow: "0px 4px 12px rgba(18, 23, 24, 0.1), 0px 16px 40px rgba(18, 23, 24, 0.2)",
  pointerEvents: "initial",
});

const MultiActionBarRoot = styled(BaseActionBar, {
  color: "$gray400",
});
const MultiActionBar = ({
  children,
  actions,
  ...props
}: React.PropsWithChildren<VariantProps<typeof MultiActionBarRoot>> & {
  actions: {
    icon?: React.ReactElement;
    label?: string;
    subMenu?: boolean;
    warning?: boolean;
    disabled?: boolean;
    hide?: boolean;
    onClick: React.MouseEventHandler;
  }[];
}) => {
  const [open, onOpenChange] = useState(false);
  return (
    <MultiActionBarRoot {...props}>
      {children}
      {actions.map(({ icon, label, subMenu, warning, hide, onClick, ...props }) =>
        !subMenu && !hide ? (
          <Button
            size="compact"
            variant="secondary"
            prefix={icon}
            key={label}
            onClick={onClick}
            {...props}
          >
            {label}
          </Button>
        ) : (
          !hide && (
            <Menu
              side="top"
              align="end"
              negative={warning ? true : false}
              open={open}
              key={`${label}-menu`}
              onOpenChange={onOpenChange}
              trigger={
                <Button
                  variant="minimal"
                  key={`${label}-button`}
                  css={{
                    width: "32px",
                    height: "32px",
                    justifyContent: "center",
                    lineHeight: 0,
                  }}
                >
                  <Ellipsis color={colors.gray500} />
                </Button>
              }
            >
              <Menu.Item onClick={onClick} key={`${label}-item`}>
                {label}
              </Menu.Item>
            </Menu>
          )
        )
      )}
    </MultiActionBarRoot>
  );
};

const ActionBarRoot = styled(BaseActionBar, {
  color: "$green800",
  cursor: "pointer",
  fontWeight: "$bold",
  outline: "none",
  transition: "all 0.1s ease-in",
  "&:hover, &:focus": {
    color: "$green700",
  },
});
const ActionBar = (
  props: React.PropsWithChildren<VariantProps<typeof ActionBarRoot>> &
    React.HTMLAttributes<HTMLButtonElement>
) => {
  return <ActionBarRoot {...props} as="button" />;
};

export const FloatingActions = Object.assign(Root, {
  ActionBar,
  MultiActionBar,
});

import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Calendar({
  viewBox = "0 0 16 16",
  color = "currentColor",
  width = 16,
  height = 16,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        fill={"none"}
      >
        <g clipPath="url(#clip0_1582_870)">
          <path
            d="M7.29175 11.9581H4.04175C2.93718 11.9581 2.04175 11.0627 2.04175 9.95812V4.95811M2.04175 4.95811V4.62478C2.04175 3.52021 2.93718 2.62478 4.04175 2.62478H9.95841C11.063 2.62478 11.9584 3.52021 11.9584 4.62478V4.95811M2.04175 4.95811H11.9584M11.9584 4.95811V7.29145M4.37508 2.62478V1.45811M9.62508 2.62478V1.45811M10.7917 9.04145V10.7914M10.7917 10.7914V12.5414M10.7917 10.7914H9.04175M10.7917 10.7914H12.5417"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1582_870">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}

import React, { useState } from "react";
import { Download2, Add } from "@puzzle/icons";
import { TransactionReportExportModal } from "components/reports/modals/TransactionReportExportModal";
import { ColumnVisibility } from "./shared";
import { ColumnVisibilityMenu } from "./ColumnVisibilityMenu";
import { Button, color } from "ve";
import { ImportTransactionsModal } from "./ImportTransactionsModal/ImportTransactionsModal";
import Analytics from "lib/analytics";
import { bottomRowControlsTable } from "./TransactionsMRT/topToolbar.css";

export type ColumnVisbilityProps = {
  columnVisibility: ColumnVisibility;
  setColumnVisibility: React.Dispatch<React.SetStateAction<ColumnVisibility | undefined>>;
};

export const TableControls = ({ columnVisibility, setColumnVisibility }: ColumnVisbilityProps) => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);

  return (
    <div className={bottomRowControlsTable}>
      <Button
        variant="secondaryAlpha"
        size="miniSquare"
        shape="buttonMoreSquare"
        onClick={() => setShowExportModal(true)}
        style={{ display: "flex", alignItems: "center" }}
      >
        <span className="sr-only">Download transactions table</span>
        <Download2 size={14} color={color.white70} style={{ display: "flex" }} />
      </Button>

      <ColumnVisibilityMenu
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
      />

      <TransactionReportExportModal
        open={showExportModal}
        onConfirm={() => setShowExportModal(false)}
        onCancel={() => setShowExportModal(false)}
        title="Consolidated Transactions"
      />

      <Button
        data-testid="import-transactions-button"
        variant="primary"
        size="miniSquare"
        shape="buttonMoreSquare"
        onClick={() => {
          setImportModalIsOpen(true);
          Analytics.transactionImportModalOpened();
        }}
        style={{ display: "flex", alignItems: "center" }}
      >
        <span className="sr-only">Import transactions</span>
        <Add size={16} color={color.mauve950} style={{ display: "flex" }} />
      </Button>

      <ImportTransactionsModal open={importModalIsOpen} onOpenChange={setImportModalIsOpen} />
    </div>
  );
};

import { useEffect, useCallback, useState, useMemo } from "react";
import { SubmitHandler, UseFormReturn, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { startCase } from "lodash";

import { useToasts } from "@puzzle/ui";

import { FixedAssetDepreciationMethod, FixedAssetType } from "graphql/types";
import { CalendarDateString } from "scalars";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import Analytics from "lib/analytics";

import {
  GetFixedAssetsDocument,
  PagedFixedAssetV2Fragment,
  useDeleteFixedAssetMutation,
  useInServiceFixedAssetMutation,
  useUpdateFixedAssetMutation,
} from "../graphql.generated";
import { assetSchema } from "./usePendingAssetForm";

type FormValues = {
  assets: {
    id: string;
    description: string;
    type?: FixedAssetType;
    inServiceAt?: CalendarDateString;
    capitalizedAt?: CalendarDateString;
    usefulLifeMonths?: string;
    originalValue?: string;
    salvageValue?: string;
    depreciationMethod?: FixedAssetDepreciationMethod;
  }[];
};

const validationSchema = yup.object({
  lines: yup.array().of(assetSchema).min(1),
});

export const USEFUL_LIFE_OPTIONS = Array(99)
  .fill(0)
  .map((_, i) => {
    const duration = (i + 1).toString();
    return {
      value: duration,
      label: `${duration} years`,
    };
  });

export const CATEGORY_OPTIONS = Object.values(FixedAssetType)
  .filter((faType) => faType !== FixedAssetType.Other)
  .map((faType) => ({
    label: startCase(faType).replaceAll("And", "&"),
    value: faType,
  }));

/**
 * Syncs existing pending assets into form state.
 */
const useExistingAssetsSync = ({
  form,
  existingAssets,
}: {
  form: UseFormReturn<FormValues>;
  existingAssets?: PagedFixedAssetV2Fragment[];
}) => {
  useEffect(() => {
    if (!existingAssets) {
      return;
    }

    form.reset({
      assets: existingAssets.map((asset) => ({
        id: asset.id,
        description: asset.description ?? "",
        type: asset.type ?? undefined,
        inServiceAt: asset.inServiceAt ?? undefined,
        capitalizedAt: asset.capitalizedAt ?? undefined,
        salvageValue: asset.salvageValue ?? undefined,
        originalValue: asset.originalValue ?? undefined,
        depreciationMethod: asset.depreciationMethod ?? undefined,
        usefulLifeMonths: asset.usefulLifeMonths
          ? (asset.usefulLifeMonths / 12).toString()
          : undefined,
      })),
    });

    // Revalidate
    form.trigger();
  }, [existingAssets, form]);
};

export function usePendingAssetsForm({
  existingAssets,
}: {
  existingAssets?: PagedFixedAssetV2Fragment[];
}) {
  const form = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),

    defaultValues: {
      assets: [],
    },
  });

  useExistingAssetsSync({ form, existingAssets });

  const { toast } = useToasts();
  const [submittingAssetId, setSubmittingAssetId] = useState<string>();
  const [updateAsset] = useUpdateFixedAssetMutation();
  const { company } = useActiveCompany<true>();
  const [inServiceAsset] = useInServiceFixedAssetMutation({
    refetchQueries: [GetFixedAssetsDocument],
  });
  const [deleteFixedAsset] = useDeleteFixedAssetMutation({
    refetchQueries: [GetFixedAssetsDocument],
  });

  const onSubmitAsset = useCallback(
    (assetId: string) => {
      const handle: SubmitHandler<FormValues> = async (formData) => {
        const asset = formData.assets.find(({ id }) => id === assetId);

        if (!asset) return;

        setSubmittingAssetId(asset.id);

        await updateAsset({
          variables: {
            input: {
              ...asset,
              usefulLifeMonths: parseInt(asset.usefulLifeMonths ?? "0") * 12,
              currency: "USD",
            },
          },
          onCompleted: async ({ updateFixedAsset }) => {
            Analytics.fixedAssetUpdated({ fixedAssetId: updateFixedAsset.id });

            await inServiceAsset({
              variables: { id: asset.id },
              onError: ({ message }) => {
                toast({
                  status: "error",
                  message: "Unable to place fixed asset in service.",
                });
                Analytics.fixedAssetPlacedInServiceFailed({
                  fixedAssetId: asset.id,
                  errorMessage: message,
                });
                setSubmittingAssetId(undefined);
              },
              onCompleted: ({ inServiceFixedAsset }) => {
                toast({
                  status: "success",
                  message: "Fixed asset successfully placed in service.",
                });
                Analytics.fixedAssetPlacedInService({
                  fixedAssetId: inServiceFixedAsset.id,
                });
                setSubmittingAssetId(undefined);
              },
            });
          },
          onError: ({ message }) => {
            toast({ status: "error", message: "Unable to update fixed asset." });
            Analytics.fixedAssetUpdateFailed({
              fixedAssetId: asset.id,
              errorMessage: message,
            });
            setSubmittingAssetId(undefined);
          },
        });
      };

      return form.handleSubmit((data) => handle(data))();
    },
    [form, updateAsset, inServiceAsset, toast]
  );

  const onDeleteAsset = useCallback(
    async (id: string) => {
      setSubmittingAssetId(id);

      await deleteFixedAsset({
        variables: {
          input: {
            id,
            companyId: company.id,
          },
        },
        onError: () => {
          toast({ status: "error", message: "Unable to delete fixed asset." });
        },
        onCompleted: () => {
          toast({ status: "success", message: "Fixed asset successfully deleted." });
          Analytics.fixedAssetDeleted({ fixedAssetId: id });
        },
      });

      setSubmittingAssetId(undefined);
    },
    [deleteFixedAsset, toast, company.id]
  );

  return useMemo(
    () => ({ ...form, onSubmitAsset, onDeleteAsset, submittingAssetId }),
    [form, onSubmitAsset, submittingAssetId, onDeleteAsset]
  );
}

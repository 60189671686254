import React, { useCallback, useMemo, useState, useEffect } from "react";
import { SortingRule, TableState, UseExpandedRowProps, Row, UseTableRowProps } from "react-table";
import { useQueryState } from "next-usequerystate";
import { CalendarDate } from "@internationalized/date";

import { pluralize, capitalize } from "@puzzle/utils";
import { styled } from "@puzzle/ui";
import { ChevronDown, ChevronRight } from "@puzzle/icons";

import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { DataTable } from "components/common/DataTable";
import { AccountTransactionDetailFragment } from "./graphql.generated";
import { LedgerAccountFragment } from "../shared/chartOfAccounts.graphql.generated";
import { CHUNK_RENDER_SIZE, glAmountToDisplayString } from "./LedgerAccountViewBody";
import { getAccountLabel } from "../shared/utils";
import { LedgerAccountTransactionJournal } from "./LedgerAccountTransactionJournal";
import { useChunkRenderer } from "lib/performance/useChunkRenderer";

const MutedCell = styled("div", {
  color: "$gray500",
});

const ValueCell = styled("div", {
  textAlign: "right",
});

const AccountBody = styled("div", {
  width: "100%",
  height: "$3",
});

const AccountEntriesCountRow = styled("div", {
  textAlign: "right",
  width: "100%",
  paddingTop: "$1",
  paddingBottom: "$1",
  height: "$3",
});

const AccountRow = styled("div", {
  display: "inline-flex",
  justifyContent: "space-between",
  background: "$mauve850",
  color: "$gray400",
  width: "100%",
  padding: "$1",
  height: "$5",
});

const TotalText = styled("div", {
  fontStyle: "italic",
  display: "inline-flex",
});

const NumberText = styled("div", {
  fontStyle: "italic",
  display: "inline-flex",
  marginLeft: "$2",
});

const TableContainer = styled("div", {
  "tfoot tr td": {
    color: "$gray400",
  },
});

const EventTypes: Partial<Record<string, string>> = {
  bank_transaction: "Bank transaction",
  credit_card: "Credit card",
  credit_card_transaction: "Credit card transaction",
  payroll: "Payroll",
  managed_platform_shortfall_collection: "Managed platform shortfall collection",
  new_account: "New account",
  new_company: "New company",
  new_entity: "New entity",
  new_integration_bank: "New integration bank",
  new_integration_credit_card: "New integration credit card",
  new_integration_investment: "New integration investment",
  new_integration_payroll: "New integration payroll",
  new_integration_payment_processor: "New integration payment processor",
  payment_available: "Payment available",
  payment_processor_balance_adjustment: "Payment processor balance adjustment",
  payment_processor_dispute_opened: "Payment processor dispute opened",
  payment_processor_dispute_won: "Payment processor dispute won",
  payment_processor_dispute_lost: "Payment processor dispute lost",
  payment_processor_fee_claimed: "Payment processor fee claimed",
  payment_processor_reserve_funds_adjustment: "Payment processor reserve funds adjustment",
  payment_processor_settlement_initiated: "Payment processor settlement initiated",
  payment_processor_settlement_linked: "Payment processor settlement linked",
  payment_processor_fallback: "Payment processor fallback",
  refund_available: "Refund available",
  payment_failure_refund_available: "Payment failure refund available",
  credit_card_payment_linked: "Credit card payment linked",
  beginning_balance: "Beginning balance",
  payroll_linked: "Payroll linked",
  investment_transaction: "Investment transaction",
  set_balance: "Set balance",
  delete_event: "Delete event",
  contractor_pay: "Contractor pay",
  user_beginning_balance: "User beginning balance",
  manual_journal_entry: "Manual journal entry",
  historical_journal_entry: "Historical journal entry",
  bill_received: "Bill", // todo: is this what we want?
  bill_voided: "Bill voided", // todo: is this what we want?
};

const convertEventTypeToString = (eventType: string): string => {
  return EventTypes[eventType] || capitalize(eventType.replace(/_/g, " "));
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface LedgerAccountDataTableRow
  extends UseExpandedRowProps<AccountTransactionDetailFragment>,
    AccountTransactionDetailFragment,
    UseTableRowProps<AccountTransactionDetailFragment> {
  credit: string;
  debit: string;
}

type LedgerAccountTableProps = {
  startDate?: CalendarDate;
  endDate?: CalendarDate;
  idx: number;
  account: LedgerAccountFragment;
  numEntries: number;
  accountTotals: Record<string, number>;
  eventsDataByAccDisplayId: Record<string, AccountTransactionDetailFragment[]>;
  chartOfAccountsById: Record<string, LedgerAccountFragment>;
  onItemUpdated: () => void;
};

const LedgerAccountTable = ({
  startDate,
  endDate,
  idx,
  account,
  numEntries,
  accountTotals,
  eventsDataByAccDisplayId,
  chartOfAccountsById,
  onItemUpdated,
}: LedgerAccountTableProps) => {
  const dateFormatter = useCompanyDateFormatter({
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const shortDateFormatter = useCompanyDateFormatter({
    dateStyle: "medium",
  });
  const [sortBy, setSortBy] = useState<SortingRule<never>[]>([]);
  const [journalIdQueryParam] = useQueryState("journalId");
  const items = eventsDataByAccDisplayId[account.displayId];
  const dataRows = useChunkRenderer({
    items,
    chunkSize: CHUNK_RENDER_SIZE,
  });

  const tableOptions = useMemo(
    () => ({
      expandSubRows: true,
      // Expand row if getting deep linked to
      initialState: {
        expanded: dataRows.reduce((res: { [key in string]: boolean }, detail, i) => {
          res[i] = detail.journal.id === journalIdQueryParam;

          return res;
        }, {}),
      },
    }),
    [dataRows, journalIdQueryParam]
  );

  useEffect(() => {
    if (journalIdQueryParam) {
      const hasMatchingJournal = dataRows.find(({ journal }) => journal.id === journalIdQueryParam);
      if (hasMatchingJournal) {
        const t = setTimeout(() => {
          document
            .querySelector(`[data-journal-id="${journalIdQueryParam}"]`)
            ?.scrollIntoView({ behavior: "smooth", block: "center" });
          return () => {
            clearTimeout(t);
          };
        }, 250);
      }
    }
  }, [dataRows, journalIdQueryParam]);

  const subtotals: Record<string, number> = useMemo(() => {
    return dataRows.reduce(
      (acc, account) => {
        const value = Number(account.amount);
        const type = value > 0 ? "debit" : "credit";
        acc[type] += value;
        return acc;
      },
      { credit: 0, debit: 0 }
    );
  }, [dataRows]);

  const renderRowSubNode = useCallback(
    (row: Row<AccountTransactionDetailFragment>) => {
      return (
        <LedgerAccountTransactionJournal
          journalId={row.original.journal.id}
          chartOfAccountsById={chartOfAccountsById}
          onItemUpdated={onItemUpdated}
        />
      );
    },
    [chartOfAccountsById, onItemUpdated]
  );

  const accountLabel = account.parentDisplayId
    ? getAccountLabel(account)
    : `⎿ ${getAccountLabel(account)}`;

  // @ts-expect-error fix row type
  const columns = useMemo<Column<LedgerAccountDataTableRow>[]>(() => {
    return [
      {
        Header: "",
        id: "isExpanded",
        width: "2%",
        align: "center",
        style: { borderRight: "none" },
        Cell: ({ row }: { row: LedgerAccountDataTableRow }) => {
          return (
            <MutedCell
              css={{ top: "2px", position: "relative" }}
              data-journal-id={row.original.journal.id}
            >
              {row.isExpanded ? (
                <ChevronDown color="currentColor" />
              ) : (
                <ChevronRight color="currentColor" />
              )}
            </MutedCell>
          );
        },
      },
      {
        Header: "Date",
        id: "effectiveAt",
        accessor: "effectiveAt",
        width: "10%",
        style: { borderLeft: "none" },
        Cell: ({ value }: { value: string }) => (
          <MutedCell>{shortDateFormatter.format(new Date(value))}</MutedCell>
        ),
        defaultCanSort: true,
        Footer: () => {
          if (!startDate || !endDate) {
            return;
          }
          return dateFormatter.formatRange(startDate, endDate);
        },
        footerProps: {
          colSpan: 4,
          Cell: MutedCell,
        },
      },
      {
        Header: "Event Type",
        id: "type",
        accessor: (row: LedgerAccountDataTableRow) => convertEventTypeToString(row.type || ""),
        defaultCanSort: true,
        width: "10%",
        footerProps: {
          colSpan: 0,
        },
      },
      {
        Header: "Description",
        id: "descriptor",
        defaultCanSort: true,
        width: "21.5%",
        accessor: (row: LedgerAccountDataTableRow) => {
          return (
            row.description ||
            row.transaction?.descriptor ||
            row.event?.invoice?.description ||
            row.event?.prepaidExpense?.description ||
            row.event?.fixedAsset?.description
          );
        },
        footerProps: {
          colSpan: 0,
        },
      },
      {
        Header: "Journal Memo",
        defaultCanSort: true,
        width: "21.5%",
        accessor: (row: LedgerAccountDataTableRow) => row.journal?.description,
        footerProps: {
          colSpan: 0,
        },
      },
      {
        Header: "Debit",
        id: "debit",
        accessor: (row: LedgerAccountDataTableRow) => {
          const value: number = Number(row.amount) > 0 ? Number(row.amount) : 0;
          return value > 0 ? value : "";
        },
        Cell: ({ value }: { value: string }) => (
          <ValueCell>{glAmountToDisplayString(value)}</ValueCell>
        ),
        defaultCanSort: true,
        width: "10%",
        colSpan: 0,
        align: "right",
        Footer: () => {
          return <ValueCell>{glAmountToDisplayString(subtotals["debit"] || 0)}</ValueCell>;
        },
      },
      {
        Header: "Credit",
        id: "credit",
        accessor: (row: LedgerAccountDataTableRow) => {
          const value: number = Number(row.amount) < 0 ? Number(row.amount) * -1 : 0;
          return value > 0 ? value : "";
        },
        Cell: ({ value }: { value: string }) => (
          <ValueCell>{glAmountToDisplayString(value)}</ValueCell>
        ),
        defaultCanSort: true,
        width: "10%",
        align: "right",
        Footer: () => {
          return <ValueCell>{glAmountToDisplayString(subtotals["credit"] || 0)}</ValueCell>;
        },
      },
      {
        Header: "Running Total",
        accessor: (row: LedgerAccountDataTableRow) => {
          return Number(row.amount);
        },
        Cell: ({
          row,
          flatRows,
        }: {
          row: Row<AccountTransactionDetailFragment>;
          flatRows: Row<AccountTransactionDetailFragment>[];
        }) => {
          let runningTotal = 0;
          for (let i = flatRows.indexOf(row); i >= 0; i--) {
            runningTotal += Number(flatRows[i].original.amount);
          }
          return <ValueCell>{glAmountToDisplayString(runningTotal || 0)}</ValueCell>;
        },
        align: "right",
        defaultCanSort: false,
        width: "15%",
      },
    ];
  }, [endDate, startDate, subtotals, dateFormatter, shortDateFormatter]);

  const onTableChange = useCallback(
    (state: TableState<AccountTransactionDetailFragment>) => {
      const newSortBy = state.sortBy;
      if (newSortBy.length && sortBy !== newSortBy) {
        setSortBy(newSortBy);
      }
    },
    [sortBy]
  );

  return (
    <AccountBody key={idx}>
      <AccountEntriesCountRow>{`Showing ${pluralize(
        numEntries,
        "entry",
        "entries"
      )}`}</AccountEntriesCountRow>
      <AccountRow>
        <div>{accountLabel}</div>
        <div>
          <TotalText>Total</TotalText>
          <NumberText>{glAmountToDisplayString(accountTotals[account.displayId])}</NumberText>
        </div>
      </AccountRow>
      <TableContainer>
        <DataTable<AccountTransactionDetailFragment>
          bordered
          options={tableOptions}
          data={dataRows}
          columns={columns}
          onChange={onTableChange}
          onRowClick={(row) => row.toggleRowExpanded()}
          renderRowSubNode={renderRowSubNode}
        />
      </TableContainer>
    </AccountBody>
  );
};

export default LedgerAccountTable;

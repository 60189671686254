import React from "react";
import { Composite, CompositeItem } from "reakit";

import { styled, Button, Text, colors } from "@puzzle/ui";
import { Crown } from "@puzzle/icons";
import { formatMoney, parseDate } from "@puzzle/utils";
import { useActiveCompany, PricingFeatures } from "components/companies/ActiveCompanyProvider";
import useMonetization from "components/monetization/useMonetization";

import { DisplayMoney } from "./shared";

import MetricCard from "./MetricCard";
import { Metric } from "graphql/types";
import { RevenueFormula } from "./shared";

// TODO There's a lot of copypasta in these tile files.
// Most of these are not used since revenue is not available in dashboard + separated in revenue page
// If we're not going to merge them soon, we should remove all unused components

const Description = styled("div", {
  marginBottom: "$1",
  color: "$gray100",
  verticalAlign: "middle",
  fontSize: "14px",
  fontWeight: "$bold",
});

const Options = styled(Composite, {
  display: "grid",
  gridAutoFlow: "row",

  gridGap: "$1",
  marginBottom: "$2",
  outline: "none",
});

const OptionLabel = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  fontWeight: "$bold",
  fontSize: "13px",
  lineHeight: "20rpx",
  marginBottom: "$0h",
  color: "$gray400",
});

const OptionDescription = styled("div", {
  textVariant: "$bodyXS",
  color: "$gray500",
});

const Option = styled(CompositeItem, {
  transition: "all .15s ease-in-out",

  appearance: "none",
  border: "2px solid transparent",
  borderRadius: `$1`,
  boxShadow: `inset 0 0 0 1px $colors$gray700`,
  background: "transparent",
  padding: "$1 $1h",
  cursor: "pointer",
  // fontSize: "inherit",
  display: "block",
  width: "100%",
  textAlign: "left",
  fontSize: "13px",
  lineHeight: "18px",
  outline: "none",

  variants: {
    variant: {
      default: {
        '&[aria-selected="true"], &[data-active="true"], &:focus': {
          borderColor: "$green800",
        },
        '&[aria-selected="false"], &:hover, &:focus-within': {
          boxShadow: `inset 0 0 0 1px #4C4565`,
        },
        '&[aria-selected="true"], &[data-active="true"], &:focus, &:hover': {
          [`${OptionLabel}`]: {
            color: "$gray100",
          },
          [`${OptionDescription}`]: {
            color: "$gray400",
          },
        },
      },

      accrualARR: {
        cursor: "default",
        boxShadow: "inset 0 0 0 0px",
      },
    },
  },
});

const DefaultText = styled("span", {
  color: "$gray600",
  marginLeft: "8px",
});

const OptionBox = styled("div", {
  whiteSpace: "nowrap",
  overflow: "hidden",
});

type RevenueProps = {
  displayRevenue?: Metric;
  loading: boolean;
  revenueFormula: RevenueFormula;
  beta?: boolean;
};

type Formula = {
  label: string;
  value: RevenueFormula;
  description: string;
  subtext?: string;
  disabled?: boolean;
  pricingFeature?: PricingFeatures;
};

const FORMULAS: Record<RevenueFormula, Formula> = {
  [RevenueFormula.ARR]: {
    label: "Annualized Revenue",
    value: RevenueFormula.ARR,
    description: "Annualized revenue is your prior month cash-basis operating revenue x 12.",
    subtext: "default",
  },
  [RevenueFormula.StripeARR]: {
    label: "Calculated ARR",
    value: RevenueFormula.StripeARR,
    description:
      "Calculated ARR is calculated as (monthly active Stripe subscriptions x 12) + (the sum of all annual active Stripe subscriptions) as of last month.",
    pricingFeature: PricingFeatures.revenue_insights_stripe_arr,
  },
  // not implemented Gateway-side
  [RevenueFormula.AccrualbasedArr]: {
    label: "Annualized Accrual Revenue",
    value: RevenueFormula.AccrualbasedArr,
    description:
      "Annualized accrual-basis revenue is a comprehensive view of revenue based on over-time revenue recognition schedules.",
    pricingFeature: PricingFeatures.revenue_insights_annualized_accrual_revenue,
  },
};

const RevenueContent = ({ revenueFormula }: Pick<RevenueProps, "revenueFormula">) => {
  const formula = FORMULAS[revenueFormula];
  const { description } = formula;
  return <OptionDescription>{description}</OptionDescription>;
};

const Revenue = ({ displayRevenue, revenueFormula, loading, beta }: RevenueProps) => {
  const { pricePlanFeatureEnabled } = useActiveCompany<true>();
  const { showUpgradeModal } = useMonetization();
  const visibleFormula = FORMULAS[revenueFormula];

  const isFeatureGated =
    visibleFormula.pricingFeature && !pricePlanFeatureEnabled.has(visibleFormula.pricingFeature);

  const lastUpdated = displayRevenue?.dataFromRange
    ? parseDate(displayRevenue.dataFromRange.toInclusive)
    : undefined;
  return (
    <MetricCard
      cardId="revenue"
      loading={loading}
      lastUpdated={lastUpdated}
      blurEnabled={false}
      blur={false}
      beta={beta}
      header={visibleFormula.label}
      expandedContent={<RevenueContent revenueFormula={revenueFormula} />}
    >
      {isFeatureGated ? (
        <Button
          variant="minimal"
          css={{ paddingLeft: "0" }}
          onClick={() => {
            showUpgradeModal();
          }}
        >
          <Crown color={colors.yellow400} />
          <Text color={colors.yellow400} css={{ marginLeft: "$1" }}>
            Upgrade to unlock
          </Text>
        </Button>
      ) : (
        <DisplayMoney>
          {displayRevenue ? formatMoney(displayRevenue, { truncateValue: true }) : null}
        </DisplayMoney>
      )}
    </MetricCard>
  );
};

export default Revenue;

import { Button, Dialog } from "@puzzle/ui";
import { GetCompanyAvailableClassesDocument } from "components/common/hooks/graphql.generated";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import React, { FormEventHandler, useCallback } from "react";
import { useRemoveClassMutation } from "../graphql.generated";

const DeleteClassModal = ({
  open,
  reportingClassId,
  reportingClassName,
  onOpenChange,
  onDelete,
  ...props
}: React.ComponentProps<typeof Dialog> & {
  reportingClassId?: string;
  reportingClassName?: string;
  onDelete?: () => void;
}) => {
  const { company } = useActiveCompany<true>();
  const [removeClass, { loading }] = useRemoveClassMutation({
    refetchQueries: [GetCompanyAvailableClassesDocument],
  });

  const submit = useCallback<FormEventHandler>(
    async (e) => {
      if (!reportingClassId) return;

      e.preventDefault();
      await removeClass({
        variables: {
          input: {
            companyId: company.id,
            reportingClassId: reportingClassId,
          },
        },
      });
      onDelete?.();
    },
    [company.id, reportingClassId, removeClass, onDelete]
  );

  if (!reportingClassId) return null;

  return (
    <Dialog
      {...props}
      size="small"
      open={open}
      onOpenChange={(open) => {
        onOpenChange?.(open);
      }}
    >
      <Dialog.Title>Delete {reportingClassName}?</Dialog.Title>
      <form onSubmit={submit}>
        <Dialog.Body>Are you sure you want to delete this group?</Dialog.Body>
        <Dialog.Footer align="right">
          <Dialog.Actions>
            <Dialog.Close asChild>
              <Button variant="minimal">Cancel</Button>
            </Dialog.Close>
            <Button type="submit" variant="negative" disabled={loading}>
              {loading ? "Deleting" : "Delete"}
            </Button>
          </Dialog.Actions>
        </Dialog.Footer>
      </form>
    </Dialog>
  );
};

export default DeleteClassModal;

import React from "react";
import { CaretDown, Ellipsis } from "@puzzle/icons";
import { Button, Stack, IconButton } from "ve";
import { Menu, useToasts } from "@puzzle/ui";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import {
  useDisposeFixedAssetMutation,
  useHoldFixedAssetMutation,
  useUnholdFixedAssetMutation,
} from "components/dashboard/Accounting/FixedAssetsV2/graphql.generated";
import { FixedAssetStatus } from "graphql/types";
import { isEditorRole } from "lib/roles";
import { useState } from "react";
import Loader from "components/common/Loader";
import Analytics from "lib/analytics";
import { GetFixedAssetByIdQuery } from "../../graphql.generated";
import { DisposeFixedAssetDialog } from "./DisposeFixedAssetDialog";
import { VoidFixedAssetDialog } from "./VoidFixedAssetDialog";

type InServiceAssetActionCellProps = {
  asset: GetFixedAssetByIdQuery;
  onCompleted: () => void;
  triggerStyle?: "kebab" | "button";
  onItemUpdated: () => void;
};

type InServiceAssetActionCellState = {
  isLoading: boolean;
  isDisposingAsset: boolean;
  isVoidingAsset: boolean;
};

export const InServiceAssetActionCell = ({
  asset,
  onCompleted,
  triggerStyle,
  onItemUpdated,
}: InServiceAssetActionCellProps) => {
  const { membershipRole } = useActiveCompany<true>();
  const [state, setState] = useState<InServiceAssetActionCellState>({
    isDisposingAsset: false,
    isLoading: false,
    isVoidingAsset: false,
  });

  const isEditor = isEditorRole(membershipRole);
  const { toast } = useToasts();

  const [holdFixedAsset, { loading: isPausing }] = useHoldFixedAssetMutation();
  const [unholdFixedAsset, { loading: isResuming }] = useUnholdFixedAssetMutation();
  const [_disposeFixedAsset, { loading: isDisposing }] = useDisposeFixedAssetMutation();

  if ([FixedAssetStatus.Deleted, FixedAssetStatus.Void].includes(asset.fixedAsset.status)) {
    return null;
  }

  const isPaused = asset.fixedAsset.status === FixedAssetStatus.OnHold;
  const isDisposable = [FixedAssetStatus.InService, FixedAssetStatus.OnHold].includes(
    asset.fixedAsset.status
  );
  const isVoidable = [
    FixedAssetStatus.Capitalized,
    FixedAssetStatus.InService,
    FixedAssetStatus.OnHold,
    FixedAssetStatus.Disposed,
  ].includes(asset.fixedAsset.status);

  const handleChangesToFixedAsset = () => {
    onItemUpdated();
    onCompleted();
  };

  return (
    <>
      <DisposeFixedAssetDialog
        open={state.isDisposingAsset}
        asset={asset}
        onCancel={() => {
          setState((currentState) => ({
            ...currentState,
            isDisposingAsset: false,
          }));
        }}
        onFixedAssetDisposed={handleChangesToFixedAsset}
      />

      <VoidFixedAssetDialog
        open={state.isVoidingAsset}
        asset={asset}
        onCancel={() => {
          setState((currentState) => ({
            ...currentState,
            isVoidingAsset: false,
          }));
        }}
        onFixedAssetVoided={handleChangesToFixedAsset}
      />

      <Stack gap="1" direction="horizontal" css={{ alignItems: "center" }}>
        {state.isLoading ? (
          <Loader size={18} />
        ) : (
          <Menu
            css={{ zIndex: 2 }}
            onClick={(e) => e.stopPropagation()}
            trigger={
              triggerStyle === "button" ? (
                <Button size="compact" variant="primary" suffixElement={<CaretDown />}>
                  Manage asset
                </Button>
              ) : (
                <IconButton css={{ transform: "rotate(90deg)" }}>
                  <Ellipsis />
                </IconButton>
              )
            }
          >
            {isEditor && (
              <>
                <Menu.Separator />
                {[FixedAssetStatus.InService, FixedAssetStatus.OnHold].includes(
                  asset.fixedAsset.status
                ) && (
                  <Menu.Item
                    onClick={() => {
                      if (isPausing || isResuming) return;

                      setState((currentState) => ({
                        ...currentState,
                        isLoading: true,
                      }));

                      if (isPaused) {
                        unholdFixedAsset({
                          variables: { input: { id: asset.fixedAsset.id } },
                          onCompleted: ({ unholdFixedAsset }) => {
                            setState((currentState) => ({
                              ...currentState,
                              isLoading: false,
                            }));
                            Analytics.fixedAssetResumed({ fixedAssetId: unholdFixedAsset.id });
                            toast({
                              status: "success",
                              message: "Schedule resumed successfully",
                            });
                            handleChangesToFixedAsset();
                          },
                        });
                      } else {
                        holdFixedAsset({
                          variables: { input: { id: asset.fixedAsset.id } },
                          onCompleted: ({ holdFixedAsset }) => {
                            setState((currentState) => ({
                              ...currentState,
                              isLoading: false,
                            }));
                            Analytics.fixedAssetPaused({ fixedAssetId: holdFixedAsset.id });
                            toast({
                              status: "success",
                              message: "Schedule paused successfully",
                            });
                            handleChangesToFixedAsset();
                          },
                        });
                      }
                    }}
                  >
                    {isPaused ? "Resume" : "Pause"}
                  </Menu.Item>
                )}
                {isDisposable && (
                  <Menu.Item
                    onClick={() => {
                      if (!isDisposing) {
                        setState((currentState) => ({
                          ...currentState,
                          isDisposingAsset: true,
                        }));
                      }
                    }}
                  >
                    Dispose
                  </Menu.Item>
                )}
                {isVoidable && (
                  <>
                    <Menu.Separator />
                    <Menu.Item
                      negative
                      onClick={() => {
                        setState((currentState) => ({
                          ...currentState,
                          isVoidingAsset: true,
                        }));
                      }}
                    >
                      Void
                    </Menu.Item>
                  </>
                )}
              </>
            )}
          </Menu>
        )}
      </Stack>
    </>
  );
};

import React, { useCallback, useMemo, useState } from "react";
import { ParseResult } from "papaparse";
import * as yup from "yup";
import { groupBy } from "lodash";

import { schemaValidators } from "components/common/import/utils";
import { reportError } from "lib/errors";
import { useBillDotCom } from "components/integrations/billdotcom/useBillDotCom";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import Analytics from "lib/analytics";

const ImportBillsContext = React.createContext<ReturnType<
  typeof useImportBillsContextValue
> | null>(null);

export type ImportMode = "manual" | "billDotCom";
export type ParsedBillCSVRow = {
  groupingId?: number;
  description?: string | null;
  dueDate?: string;
  externalId?: string | null;
  issueDate?: string;
  vendor?: string;
  amount?: string;
  currency?: string | null;
  lineAmount?: string | null;
  lineCurrency?: string | null;
  lineCoaKey?: string | null;
  lineDescription?: string | null;
  lineQuantity?: string | null;
  lineUnitPrice?: string | null;
};

type Mapper = Record<string, keyof ParsedBillCSVRow>;

const BREX_MAPPER: Mapper = {
  description: "description",
  "due date": "dueDate",
  id: "externalId",
  "creation date": "issueDate",
  "vendor name": "vendor",
  amount: "amount",
};

const RAMP_MAPPER: Mapper = {
  memo: "description",
  "due date": "dueDate",
  "bill url": "externalId",
  "invoice date": "issueDate",
  vendor: "vendor",
  amount: "amount",
  currency: "currency",
};

const BILL_DOT_COM_MAPPER: Mapper = {
  "invoice #": "description",
  "due date": "dueDate",
  "created date": "issueDate",
  vendor: "vendor",
  "balance due": "amount",
  currency: "currency",
};

export const BILL_MAPPERS = {
  BILL: BILL_DOT_COM_MAPPER,
  Ramp: RAMP_MAPPER,
  Brex: BREX_MAPPER,
  Other: undefined,
};

export type ParsedBillCSV =
  | {
      data: ParsedBillCSVRow[];
      meta: {
        isValid: boolean;
      };
    }
  | undefined;

export type Provider = keyof typeof BILL_MAPPERS;

const emptyStrToNullTransform = (value: string) => (value === "" ? null : value);
const emptyStrToNull = yup.string().nullable(true).transform(emptyStrToNullTransform);

const recordSchema = yup.object({
  groupingId: yup.number().required(),
  description: emptyStrToNull,
  dueDate: schemaValidators.date.required(),
  externalId: emptyStrToNull,
  issueDate: schemaValidators.date.required(),
  vendor: yup.string().required(),
  amount: schemaValidators.positiveOnlyAmount.required(),
  currency: emptyStrToNull,
  lineAmount: schemaValidators.positiveOnlyAmount.nullable(true).transform(emptyStrToNullTransform),
  lineCurrency: emptyStrToNull,
  lineCoaKey: emptyStrToNull,
  lineDescription: emptyStrToNull,
  lineQuantity: emptyStrToNull,
  lineUnitPrice: schemaValidators.positiveOnlyAmount
    .nullable(true)
    .transform(emptyStrToNullTransform),
});

export function parseCSV(
  parseResult: ParseResult<Record<string, string>>,
  provider: Provider,
  selectedFile?: File
): ParsedBillCSV {
  const invalidRows: Record<string, unknown>[] = [];
  const parsed: ParsedBillCSV = {
    data: [],
    meta: {
      isValid: true,
    },
  };

  const mapper = BILL_MAPPERS[provider];

  let groupingId = 0;
  const normalized = parseResult.data.map((row) => {
    const mapped: Partial<Record<keyof ParsedBillCSVRow, string>> = {};

    for (const key in row) {
      if (!mapper) return;

      const newKey = mapper[key.toLowerCase()];
      if (newKey) mapped[newKey] = row[key];
      if (!mapped["groupingId"]) {
        mapped["groupingId"] = groupingId.toString();
        groupingId++;
      }
    }

    mapped["lineDescription"] = mapped["description"];
    mapped["lineAmount"] = mapped["amount"];

    return mapped;
  });

  const go = mapper ? normalized : parseResult.data;
  const goByExternalId = groupBy(go, "externalId");

  parsed.data = go.map((row) => {
    const record = recordSchema.cast(row);
    const isValidSchema = recordSchema.isValidSync(record);
    const lineCount = record.externalId ? goByExternalId[record.externalId].length : 0;

    //if lineAmount is undefined and there's one row in bill, populate line amount with total
    if (!record.lineAmount && lineCount === 1) {
      record.lineAmount = record.amount;
    }
    //if lineAmount is undefined and there's more than one row in bill, mark invalid
    if (!isValidSchema || (!record.lineAmount && lineCount > 1)) {
      invalidRows.push(record);
      parsed.meta.isValid = false;
    }

    return record;
  });

  if (parsed.data.length === 0) {
    parsed.meta.isValid = false;
  }

  if (!parsed.meta.isValid) {
    Analytics.billImportInvalidFormat({
      fileName: selectedFile?.name ?? "",
      fileSize: selectedFile?.size ?? 0,
      contentType: selectedFile?.type ?? "",
    });
    reportError(`BillImportInvalidFormat`, { invalidRows, fileName: selectedFile?.name });
  }

  return parsed;
}

const useImportBillsContextValue = ({
  initialMode,
  onOpenChange,
}: {
  initialMode: ImportMode;
  onOpenChange?: (open: boolean) => void;
}) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [mode, setMode] = useState(initialMode);
  const [parsedCSV, setParsedCSV] = useState<ParsedBillCSV>();
  const { company } = useActiveCompany<true>();
  const { connection: billDotComConnection } = useBillDotCom({ companyId: company.id });
  const [provider, setProvider] = useState<Provider>(Object.keys(BILL_MAPPERS)[0] as Provider);

  const resetFileState = useCallback(() => {
    setSelectedFile(undefined);
    setParsedCSV(undefined);
  }, []);

  const value = useMemo(
    () => ({
      selectedFile,
      setSelectedFile,
      mode,
      setMode,
      parsedCSV,
      setParsedCSV,
      resetFileState,
      onOpenChange,
      billDotComConnection,
      provider,
      setProvider,
    }),
    [
      selectedFile,
      setSelectedFile,
      mode,
      setMode,
      parsedCSV,
      setParsedCSV,
      resetFileState,
      onOpenChange,
      billDotComConnection,
      provider,
      setProvider,
    ]
  );

  return value;
};

const ImportBillsProvider = ({
  children,
  initialMode,
  onOpenChange,
}: {
  children: React.ReactNode;
  initialMode: ImportMode;
  onOpenChange?: (open: boolean) => void;
}) => {
  return (
    <ImportBillsContext.Provider value={useImportBillsContextValue({ initialMode, onOpenChange })}>
      {children}
    </ImportBillsContext.Provider>
  );
};

export default ImportBillsProvider;

export const useImportBillsContext = () => {
  const context = React.useContext(ImportBillsContext);

  if (context === null) {
    throw new Error("ImportBillsContext not found");
  }

  return context;
};

import { Text, styled } from "@puzzle/ui";
import { PersistedHowMaintainBooks } from "components/dashboard/Dashboard/PostOnboardModal/Panes";
import React, { useCallback, useMemo, useState } from "react";
import { TaxPreparerQuestion } from "./TaxPreparerQuestion";
import Link from "components/common/Link";
import { BookkeeperPlan, TaxPreparerPlan } from "graphql/types";
import { AnalyticsTriggeredFrom } from "components/dashboard/Dashboard/PostOnboardModal/analytics";
import { useShowNeedHelpBanner } from "components/dashboard/Checklist/NeedHelpBanner";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import links from "lib/links";
import useCal from "components/users/useCal";
import { Box, S } from "ve";

const FeedbackContainer = styled("div", {
  marginBottom: "$1",
  "a, [data-intercom-id]": {
    textDecoration: "underline",
  },
});

export const BookkeepingTaxPrepPane = () => {
  const { company, updateCompany } = useActiveCompany<true>();
  const { calId } = useCal();
  const [activeTaxPreparerPlan, setActiveTaxPreparePlan] = useState(company.taxPreparerPlan);
  const { showInboxBookeepingPlanFeedback } = useShowNeedHelpBanner();

  const bookkeepingPlanFeedback = useMemo(
    () => ({
      [BookkeeperPlan.HasBookkeeper]: (
        <>
          If your bookkeeper has questions about Puzzle, they can{" "}
          <Link
            external
            href={`${links.freeConciergeOnboarding}?source=inbox-bookkeeping-tax-prep&userId=${calId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            set up an onboarding call
          </Link>{" "}
          with a CPA on our team.
        </>
      ),
      [BookkeeperPlan.WantsBookkeeper]: (
        <>
          Complete{" "}
          <Link
            external
            href="https://5kfeex7790a.typeform.com/to/ZhXqVqTG"
            target="_blank"
            rel="noopener noreferrer"
          >
            this survey
          </Link>{" "}
          to get customized recommendations.
        </>
      ),
      [BookkeeperPlan.DoItMyself]: (
        <>
          If you have questions about managing your own books, check out our Ask an Accountant page
          for support. If you are considering hiring a bookkeeper within the next 12 months, select
          "I want to hire a bookkeeper," and we start the non-binding process of finding a partner
          that meets your needs."
        </>
      ),
      [BookkeeperPlan.Unknown]: null,
    }),
    [calId]
  );

  const taxPrepPlanFeedback = useMemo(
    () => ({
      [TaxPreparerPlan.HasTaxPreparer]: (
        <>
          If your tax preparer has questions about Puzzle, they can{" "}
          <Link
            external
            href={`${links.freeConciergeOnboarding}?source=inbox-bookkeeping-tax-prep&userId=${calId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            set up an onboarding call
          </Link>{" "}
          with a CPA on our team.
        </>
      ),
      [TaxPreparerPlan.WantsTaxPreparer]: (
        <>
          Complete{" "}
          <Link
            external
            href="https://5kfeex7790a.typeform.com/to/ZhXqVqTG"
            target="_blank"
            rel="noopener noreferrer"
          >
            this survey
          </Link>{" "}
          to get customized recommendations.
        </>
      ),
      [TaxPreparerPlan.DoItMyself]: (
        <>
          We typically recommend hiring a tax preparer to ensure full compliance. If you will
          consider doing so within the next 12 months, select "I want to hire a tax preparer," and
          we can help you find options that meet your needs with no commitment.
        </>
      ),
      [TaxPreparerPlan.Unknown]: null,
    }),
    [calId]
  );

  const handleTaxPreparerClick = useCallback(
    (taxPreparerPlanValue?: string) => {
      setActiveTaxPreparePlan(taxPreparerPlanValue as TaxPreparerPlan);
      updateCompany({ taxPreparerPlan: taxPreparerPlanValue as TaxPreparerPlan });
    },
    [setActiveTaxPreparePlan, updateCompany]
  );

  return (
    <Box css={{ width: "75%", margin: "0 auto" }}>
      {showInboxBookeepingPlanFeedback && (
        <Box css={{ marginBottom: S["3"] }}>
          <PersistedHowMaintainBooks
            location={AnalyticsTriggeredFrom.inbox}
            feedbackContent={
              company.bookkeeperPlan ? (
                <FeedbackContainer>
                  <Text size="bodyS" lineHeight="bodyS">
                    {bookkeepingPlanFeedback[company.bookkeeperPlan]}
                  </Text>
                </FeedbackContainer>
              ) : null
            }
          />
        </Box>
      )}
      <TaxPreparerQuestion
        location={AnalyticsTriggeredFrom.inbox}
        onNext={handleTaxPreparerClick}
        activeButtonId={activeTaxPreparerPlan}
        feedbackContent={
          activeTaxPreparerPlan ? (
            <FeedbackContainer>
              <Text size="bodyS" lineHeight="bodyS">
                {taxPrepPlanFeedback[activeTaxPreparerPlan]}
              </Text>
            </FeedbackContainer>
          ) : null
        }
      />
    </Box>
  );
};

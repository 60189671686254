import React, { useState } from "react";
import { Button, TabItem, Tooltip, TooltipProps } from "@puzzle/ui";
import { parseDate } from "@puzzle/utils";
import { Filters } from "./Filters";
import { ReportExportModal } from "components/reports/modals/ReportExportModal";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useStickyReportContext } from "components/reports/StickyReportContext";
import { FilterTags } from "./Filters/FilterTags";
import { useDelta } from "./Filters/DeltaProvider";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { isEmpty } from "lodash";
import { dynamicReportTypeDisplays } from "./util";
import { DynamicReportType } from "graphql/types";
import { containerStyle, spotlightWarningStyle } from "./ReportContentFilter.css";
import { Box, S, Text } from "ve";

const isSpotlightImprovementsEnabled = isPosthogFeatureFlagEnabled(
  FeatureFlag.SpotlightImprovements
);

const IngestTooltip = ({
  children,
  enabled,
  ...props
}: TooltipProps & {
  enabled: boolean;
}) => {
  return (
    <Tooltip
      side="top"
      align="center"
      content="Your data is still processing and reports are still generating. We will notify you when its ready."
      open={enabled ? undefined : false}
      {...props}
    >
      {/* Wrap children around element as disabled elements don't trigger the tooltip */}
      <span>{children}</span>
    </Tooltip>
  );
};

const ReportContentFilters = ({
  reportType,
  enableClassesAndDepts,
  tab,
}: {
  reportType: DynamicReportType;
  enableClassesAndDepts: boolean;
  tab?: TabItem;
}) => {
  const { initialIngestCompleted } = useActiveCompany();
  const {
    stickyOptions: { preset, start, end, view, detailLevel, filter },
    activeGroupBy,
    setStickyOptions,
  } = useStickyReportContext();
  const { deltaOptions } = useDelta();

  const [showExportModal, setShowExportModal] = useState(false);
  const onClickExport = () => {
    setShowExportModal(true);
    Analytics.reportModalOpened({ reportType });
  };

  const spotlightWarningCss = () => {
    return { padding: `${S["0"]} ${S["2"]}` };
  };

  const filterTagCss = () => {
    if (isEmpty(filter?.segments?.segmentIds)) {
      return {};
    }
    return { padding: `${S["0h"]} ${S["2"]}` };
  };

  if (!tab?.value) return null;

  return (
    <>
      <div className={containerStyle}>
        <IngestTooltip enabled={!initialIngestCompleted}>
          <Filters
            tab={tab.value}
            disabled={!initialIngestCompleted}
            hideViewDropdown={
              isPosthogFeatureFlagEnabled(FeatureFlag.CashAcrrualReportDefaultFilter) &&
              tab.value === DynamicReportType.CashActivityReport
            }
          />
        </IngestTooltip>

        <IngestTooltip enabled={!initialIngestCompleted}>
          <Button
            disabled={!initialIngestCompleted}
            onClick={onClickExport}
            size="compact"
            variant="secondary"
            css={{ marginLeft: "auto" }}
          >
            Export report
          </Button>
          <ReportExportModal
            open={showExportModal}
            onConfirm={() => setShowExportModal(false)}
            onCancel={() => setShowExportModal(false)}
            title={dynamicReportTypeDisplays[reportType]}
            type={reportType ?? DynamicReportType.CashActivityReport}
            stickyFilters={{
              preset,
              groupBy: activeGroupBy,
              start: parseDate(start),
              end: parseDate(end),
              accountingBasis: view,
              level: detailLevel,
              filter,
            }}
          />
        </IngestTooltip>
      </div>
      {isSpotlightImprovementsEnabled && deltaOptions.enabled && (
        <Box css={spotlightWarningCss()}>
          <Text variant="bodyXXS" className={spotlightWarningStyle}>
            To change date range, turn off Spotlight
          </Text>
        </Box>
      )}
      {enableClassesAndDepts ? (
        <Box css={filterTagCss()}>
          <FilterTags filter={filter} onRemove={setStickyOptions} />
        </Box>
      ) : null}
    </>
  );
};

export default ReportContentFilters;

import React from "react";

import { Menu } from "@puzzle/ui";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

import { FullTransactionFragment } from "../graphql.generated";
import useTransactionPageUsers from "../hooks/useTransactionPageUsers";
import useAssignments from "../hooks/useAssignments";

export const AssignmentMenu = ({
  transaction,
  trigger,
  request,
}: {
  transaction: FullTransactionFragment;
  trigger: React.ReactElement;
  request: string;
}) => {
  const { company } = useActiveCompany<true>();
  const { activeUsers } = useTransactionPageUsers({ companyId: company.id });
  const { saveAssignmentDirect } = useAssignments(activeUsers, transaction);

  return (
    <Menu trigger={trigger}>
      {activeUsers.map((user) => (
        <Menu.Item
          onClick={() => {
            saveAssignmentDirect(request, transaction, user);
          }}
          key={user.id}
        >
          {user.name || user.email || "User"}
        </Menu.Item>
      ))}
    </Menu>
  );
};

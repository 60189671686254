import React from "react";

import { DateValue } from "@puzzle/utils";

import { useExpensesExceededFeatureGate } from "./useExpensesExceededFeatureGate";

type ExpensesExceededFeatureGateProps = {
  gate: React.ReactNode;
  dates: DateValue[];
}

// Placeholder, pending enhanced API and related logic.
// Disabled for now.
export const ExpensesExceededFeatureGate = ({
  children,
  gate,
  dates,
}: React.PropsWithChildren<ExpensesExceededFeatureGateProps>) => {
  const { anyDatesExpenseGated } = useExpensesExceededFeatureGate();

  const isFeatureGated = anyDatesExpenseGated(dates);

  return isFeatureGated ? <>{gate}</> : <>{children}</>;
};

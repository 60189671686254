import React, { useEffect } from "react";
import { styled, Button, IconButton, Popover, CurrencyInput, Field, useToasts } from "@puzzle/ui";
import { useToggle } from "react-use";
import Big from "big.js";
import { Controller, useForm } from "react-hook-form";

import { LongArrow, Pencil } from "@puzzle/icons";
import { formatAccountNameWithInstitution } from "@puzzle/utils";

import { AccountBalancePeriod, AccountFragment } from "graphql/types";

import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";

const Container = styled("form", {
  maxWidth: 340,
  color: "$gray400",
});

const Description = styled("div", {
  fontSize: "13px",
  lineHeight: "20px",
  marginBottom: "$1",
});

const Actions = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  gap: "$2",
  marginTop: "$3",
});

const Form = styled("div", {
  my: "$2",
  display: "flex",
  flexDirection: "column",
  gap: "$2",
});

const Amounts = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$1",
});

const Arrow = styled("div", {
  transform: "translateY(70%)",
});

type FormValues = {
  correctAmount: string;
};

// TODO This won't suffice for the Integrations drawer.
// The API only works in the context of reconciliations.
const ModifyBalance = ({
  period,
  onSave,
  trigger,
  initialAmount,
  account,
}: {
  period: AccountBalancePeriod;
  onSave?: (values: FormValues) => void;
  trigger?: React.ReactNode;
  initialAmount: string;
  account: AccountFragment;
}) => {
  const { toast } = useToasts();
  const [open, toggle] = useToggle(false);

  const { formState, control, handleSubmit, reset } = useForm<FormValues>({
    mode: "onChange",
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const isSaving = formState.isSubmitting;
  const onSubmit = handleSubmit(
    async (values) => {
      try {
        if (onSave) {
          onSave(values);
          toggle(false);
          return;
        }

        if (period === AccountBalancePeriod.Beginning) {
          // shouldn't happen, but just in case...
          return;
        }

        const newAmount = values.correctAmount;

        toggle(false);
      } catch (err) {
        toast({ message: (err as Error).message, status: "error" });
      }
    },
    (err) => console.error(err)
  );

  const periodText = period === "Beginning" ? "Opening" : period;
  const periodLowerCase = periodText.toLowerCase();
  const isBeginning = period === "Beginning";

  const longDateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <Popover
      side="top"
      open={open}
      onOpenChange={(open) => {
        if (!isSaving) {
          toggle(open);
        }
      }}
      trigger={
        trigger ?? (
          <IconButton size="fit-content" css={{ color: "$gray500" }}>
            <Pencil />
          </IconButton>
        )
      }
      align="start"
      sideOffset={8}
      css={{ background: "#242430" }}
    >
      <Popover.Title variant="dark">Modify {periodLowerCase} balance</Popover.Title>
      <Popover.Body>
        <Container onSubmit={onSubmit}>
          <Description>
            Please let us know what the balance in your {formatAccountNameWithInstitution(account)}{" "}
            account on {longDateFormatter.format(new Date(account.initialAccountBalance.date))} was.
            {/* TODO Not supported by API */}
            {/* and FIX date. */}
          </Description>

          <Form>
            {/* TODO Not supported by API, but probably not relevant until this is migrated to support the Integrations page. */}
            {/* {period === AccountBalancePeriod.Beginning && (
              <Field label={`${periodText} balance date`}>
                <DateInput value={date} inputReadOnly tabIndex={-1} />
              </Field>
            )} */}

            <Amounts css={{ alignItems: "baseline" }}>
              <Field
                label="Calculated balance"
                error={Big(initialAmount).lt(0) && isBeginning ? "Cannot be negative" : undefined}
              >
                <CurrencyInput
                  readOnly
                  truncateWhenInactive={false}
                  value={initialAmount}
                  focused={false}
                  autoFocus={false}
                />
              </Field>

              <Arrow css={{ alignSelf: "center" }}>
                <LongArrow />
              </Arrow>

              <Controller
                control={control}
                name="correctAmount"
                rules={{
                  required: true,
                  validate: (field) => {
                    return !isBeginning || Number(field) >= 0 || "Cannot be negative";
                  },
                }}
                render={({ field }) => {
                  return (
                    <Field
                      label="Corrected balance"
                      error={
                        formState.errors.correctAmount && formState.errors.correctAmount?.message
                      }
                    >
                      <CurrencyInput
                        placeholder="$0"
                        value={field.value}
                        truncateWhenInactive={false}
                        onValueChange={({ value }) => {
                          field.onChange({
                            target: { value, name: field.name },
                          });
                        }}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        autoFocus
                      />
                    </Field>
                  );
                }}
              />
            </Amounts>
          </Form>

          <Actions>
            <Popover.Close asChild>
              <Button size="compact" variant="secondary" disabled={isSaving}>
                Cancel
              </Button>
            </Popover.Close>
            <Button
              size="compact"
              variant="primary"
              type="submit"
              disabled={!formState.isValid || isSaving}
            >
              {Big(initialAmount).gt(0) ? "Confirm" : "Save"}
            </Button>
          </Actions>
        </Container>
      </Popover.Body>
    </Popover>
  );
};

export default ModifyBalance;

import React, { useMemo } from "react";
import { globalCss, keyframes } from "@puzzle/ui";
import { ThemeProvider, createTheme, StyledEngineProvider } from "@mui/material";

const shimmer = keyframes({
  "0%": {
    backgroundPosition: "-1000px 0",
  },
  "100%": {
    backgroundPosition: "1000px 0",
  },
});

const globalStyles = globalCss({
  // radix-ui dialogs apply pointer-events: none to the body
  "iframe[title='Plaid Link'], .intercom-lightweight-app-launcher": {
    pointerEvents: "all",
  },

  html: {
    fontSize: "16px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
  },

  "*": {
    boxSizing: "border-box",

    // Firefox only
    scrollbarColor: "$colors$gray500 transparent",
    fontFamily: "$inter",
  },

  // TODO really need a reset stylesheet
  button: {
    padding: "0",
    fontFamily: "inherit",
  },

  a: {
    textDecoration: "none",
  },

  body: {
    padding: "0",
    margin: "0",
    backgroundColor: "$mauve800",
    color: "$white",
    fontFamily: "$inter",
    fontSize: "$body",

    "& .MuiListItemText-secondary": {
      color: "$gray300",
    },

    MsOverflowStyle: "none",
  },

  "::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
  },

  "::-webkit-scrollbar-track, ::-webkit-scrollbar-corner": {
    background: "transparent",
  },

  "::-webkit-scrollbar-thumb": {
    backgroundColor: "$gray500",
    borderRadius: "$scrollThumb",
    border: "3px solid transparent",
  },

  ".MuiButtonGroup-grouped:hover": {
    borderColor: "#40404F !important",
  },

  ".loading": {
    "&.light-background, &.dark-background": {
      animation: `${shimmer} 4s infinite linear`,
    },

    "&.light-background": {
      background: `linear-gradient(-75deg, rgba(179, 178, 184, 0) 0%, rgba(179, 178, 184, 0.3) 51.28%, rgba(179, 178, 184, 0) 100%)`,
      backgroundSize: "1000px 100%",
      borderRadius: "8px",
    },

    "&.dark-background": {
      background: `linear-gradient(-75deg, rgba(179, 178, 184, 0) 0%, rgba(179, 178, 184, 0.3) 51.28%, rgba(179, 178, 184, 0) 100%)`,
      backgroundSize: "1000px 100%",
      borderRadius: "16px",
    },
  },

  ".sr-only": {
    position: "absolute",
    width: "1px",
    height: "1px",
    padding: "0",
    margin: "-1px",
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    whiteSpace: "nowrap",
    border: "0",
  },
});

type ThemeProviderProps = {
  children: React.ReactNode;
}

const FinalThemeProvider = ({ children }: ThemeProviderProps) => {
  globalStyles();

  const themeValue = useMemo(() => createTheme({}), []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeValue}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default FinalThemeProvider;

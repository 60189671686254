import React from "react";
import { Stack, Tag, Text } from "@puzzle/ui";
import { formatMoney, parseAbsolute } from "@puzzle/utils";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { Route } from "lib/routes";
import { BillLine } from "graphql/types";
import useCategories from "components/common/hooks/useCategories";
import Link from "components/common/Link";

const AssociatedBillLine = ({ billLine }: { billLine: BillLine }) => {
  const { timeZone } = useActiveCompany<true>();
  const { categoriesByPermaKey } = useCategories();
  const dateFormatter = useCompanyDateFormatter({
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });

  if (!billLine) {
    return null;
  }

  return (
    <Link href={`${Route.bills}/${billLine?.bill?.id}`} target="_blank" rel="noopener noreferrer">
      <Stack
        direction="horizontal"
        gap="2"
        css={{
          alignItems: "center",
          backgroundColor: "$porpoise400",
          border: "1px solid $mauve650",
          padding: "$2",
          borderRadius: "$2",
          marginTop: "$1h",
          cursor: "pointer",
        }}
      >
        <div>
          {billLine?.createdAt
            ? dateFormatter.format(parseAbsolute(billLine.createdAt, timeZone))
            : "-"}
        </div>
        <div>{billLine.description}</div>
        {billLine.coaKey &&
        categoriesByPermaKey &&
        categoriesByPermaKey[billLine.coaKey]?.displayName ? (
          <Tag size="medium">{categoriesByPermaKey[billLine.coaKey]?.displayName}</Tag>
        ) : null}
        <Text as="div" css={{ marginLeft: "auto" }}>
          {formatMoney({
            currency: "USD",
            amount: billLine?.amount.amount ?? 0,
          })}
        </Text>
      </Stack>
    </Link>
  );
};

export default AssociatedBillLine;

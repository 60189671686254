import { BalanceByReportColumn, LedgerReportLevel, LedgerReportLineFragment } from "graphql/types";

export { LedgerReportLevel as DetailLevel };
export type HighlightRule = {
  description: string;
  tooltip?: string;
  highlight?: (node: EnhancedLedgerReportLine, column?: string) => boolean;
}

export type DeltaOptions = {
  enabled: boolean;
  hidden: boolean;
  atLeastPercent: number;
  atLeastDollarAmount: number;
}

export type EnhancedBalanceByReportColumn = BalanceByReportColumn & { isPercent?: boolean };

export type EnhancedLedgerReportLine = Omit<
  LedgerReportLineFragment,
  "childIds" | "balanceByColumn"
> & {
  children: EnhancedLedgerReportLine[];
  ledgerCoaKeys: string[];
  accountIds: string[];
  vendorIds: string[];
  aggregateLineTypes: string[];
  balanceByColumn: EnhancedBalanceByReportColumn[];
};

export enum DeltaColumns {
  PercentDiff = "Change (%)",
  DollarDiff = "Change ($)",
  PercentOfExpenses = "% of Cash Out",
}

export type LedgerReportLineMap = Record<string, LedgerReportLineFragment>;

export type CellContent = {
  value?: string | number | React.ReactNode;
  highlight?: boolean;
  vendorHighlight?: boolean;
  active?: boolean;
  metadata?: {
    ruleIndex?: number;
    // [key: string]: any;
  };
  timePeriodKey?: string;
};

import React from "react";
import Icon, { IconProps } from "./Icon";

export default function CloseSchedule({
  viewBox = "0 0 14 15",
  width = 14,
  height = 15,
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} fill="none" viewBox={viewBox}>
      <path
        fill={fill}
        d="M1.6 5h8.8V3.4H1.6V5zm0 8.4c-.33 0-.613-.117-.848-.352A1.156 1.156 0 01.4 12.2V3.4c0-.322.12-.603.358-.842.24-.238.52-.358.842-.358h1.2V.6H4v1.6h4V.6h1.2v1.6h1.2c.322 0 .603.12.842.358.238.24.358.52.358.842v3.5a3.549 3.549 0 00-.584-.183 5.52 5.52 0 00-.616-.1V6.2H1.6v6h4.167c.055.222.13.43.225.625.094.195.202.386.325.575H1.6zm8.396.8c-.886 0-1.64-.312-2.263-.937a3.094 3.094 0 01-.933-2.267c0-.886.312-1.64.937-2.263a3.094 3.094 0 012.267-.933c.886 0 1.64.312 2.263.937.622.625.933 1.38.933 2.267 0 .886-.313 1.64-.937 2.263a3.094 3.094 0 01-2.267.933zm.92-1.717l.567-.566-1.083-1.084V9.4h-.8v1.767l1.317 1.316z"
      ></path>
    </Icon>
  );
}

import React, { useState } from "react";

import { Menu, MenuButton, styled, Tooltip } from "@puzzle/ui";
import { Pause, CloseSchedule, VoidSchedule, CaretRight } from "@puzzle/icons";

import { color } from "ve";

import { ContractRevenueScheduleStatus } from "graphql/types";

import { ScheduleWarningMode } from "../ScheduleWarningMessage";
import { ContractRevenueScheduleFragment } from "../graphql.generated";
import {
  useVoidSchedule,
  usePauseSchedule,
  useActivateSchedule,
  useCloseSchedule,
} from "../useScheduleActions";
import { VoidOrCloseScheduleModal } from "../VoidOrCloseScheduleModal";
import { Box, S } from "ve";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { parseDate } from "@puzzle/utils";

type Props = {
  existingSchedule?: ContractRevenueScheduleFragment;
  onOpenChange?: (open: boolean) => void;
  submitLoading: boolean;
};

const MenuItem = styled(Menu.Item, {
  display: "flex",
  alignItems: "center",
  gap: "$1h",
});

export const ScheduleDrawerActions = ({ existingSchedule, onOpenChange, submitLoading }: Props) => {
  const voidSchedule = useVoidSchedule();
  const pauseSchedule = usePauseSchedule();
  const activateSchedule = useActivateSchedule();
  const closeSchedule = useCloseSchedule();
  const { isWithinLockedPeriod } = useActiveCompany();
  const [mode, setMode] = useState<ScheduleWarningMode>();
  const canActivate = activateSchedule.isPossibleWhen(existingSchedule?.status);
  const loading =
    voidSchedule.loading ||
    pauseSchedule.loading ||
    activateSchedule.loading ||
    closeSchedule.loading ||
    submitLoading;

  const isInLockedPeriod =
    !!existingSchedule &&
    !!existingSchedule.startDay &&
    isWithinLockedPeriod(parseDate(existingSchedule.startDay));

  const scheduleIsNotClosed = existingSchedule?.status !== ContractRevenueScheduleStatus.Closed;
  const scheduleStatusIsDraft = existingSchedule?.status === ContractRevenueScheduleStatus.Draft;

  return (
    <Box css={{ display: "flex", justifyContent: "flex-end", gap: S["2"] }}>
      <VoidOrCloseScheduleModal
        existingSchedule={existingSchedule}
        open={Boolean(mode)}
        mode={mode}
        onDone={() => {
          setMode(undefined);
          onOpenChange?.(false);
        }}
        onOpenChange={(open) => {
          if (!open) {
            setMode(undefined);
          }
        }}
      />
      <Tooltip content={isInLockedPeriod ? "Cannot void schedules in a locked period" : ""}>
        <Menu
          side="right"
          align="end"
          trigger={
            <MenuButton variant="primary" size="compact" loading={loading}>
              Manage
            </MenuButton>
          }
        >
          {scheduleIsNotClosed ? (
            <>
              <MenuItem
                disabled={scheduleStatusIsDraft}
                onSelect={async () => {
                  const payload = { scheduleId: existingSchedule?.id };
                  canActivate
                    ? await activateSchedule.mutation(payload)
                    : await pauseSchedule.mutation(payload);

                  onOpenChange?.(false);
                }}
              >
                {canActivate ? (
                  <>
                    <CaretRight size={12} />
                    Resume recognition
                  </>
                ) : (
                  <>
                    <Pause />
                    Pause Recognition
                  </>
                )}
              </MenuItem>
              <MenuItem onSelect={() => setMode("close")} disabled={scheduleStatusIsDraft}>
                <CloseSchedule color={color.gray100} />
                Recognize & close
              </MenuItem>
              <Menu.Separator />
            </>
          ) : null}
          <Tooltip content={isInLockedPeriod ? "Cannot void schedules in an locked period" : ""}>
            <div>
              <MenuItem
                negative={!isInLockedPeriod}
                disabled={isInLockedPeriod}
                onSelect={() => {
                  if (scheduleStatusIsDraft) {
                    voidSchedule.mutation({ scheduleId: existingSchedule.id });
                    onOpenChange?.(false);
                    return;
                  }

                  setMode("void");
                }}
              >
                <VoidSchedule />
                {scheduleStatusIsDraft ? "Delete" : "Void"}
              </MenuItem>
            </div>
          </Tooltip>
        </Menu>
      </Tooltip>
    </Box>
  );
};

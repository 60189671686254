import { IntegrationType, usePushIntegrationsQuery } from "graphql/types";
import { useMemo } from "react";
import { UseIntegrationHook, useFinancialInstitutions } from "../shared";
import { ConnectionInfo, UsePushIntegrationsResult } from "./types";

export const usePushIntegrations: UseIntegrationHook<UsePushIntegrationsResult> = ({
  companyId,
}): UsePushIntegrationsResult => {
  const { data, loading, refetch } = usePushIntegrationsQuery({
    variables: { companyId },
  });
  const { integrationConnections } = useFinancialInstitutions();

  const { integrations } = data || {};

  const connectionMap = useMemo(() => {
    const map = new Map<IntegrationType, ConnectionInfo>();
    const pushIntegrations = integrations?.pushIntegrations ?? [];

    for (const pushIntegration of pushIntegrations) {
      const connectionId = pushIntegration.connection?.id;
      const connection = connectionId
        ? integrationConnections.find((ic) => ic.id === connectionId)
        : undefined;

      map.set(pushIntegration.type, {
        connection,
        connectUrl: pushIntegration.connectUrl ?? undefined,
      });
    }

    return map;
  }, [integrationConnections, integrations?.pushIntegrations]);

  return {
    loading,
    refetch,
    connectionMap,
  };
};

export default usePushIntegrations;

import React, { useCallback, useMemo, useState } from "react";
import { AccountWithIntegrationFragment, IntegrationConnectionStatus } from "graphql/types";
import { useToasts } from "@puzzle/ui";
import { useFinancialInstitutions } from "../shared";
import FooterButton from "./FooterButton";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { getIntegrationCategoryForAccountType } from "../setup/utils";
import { IntegrationCategory } from "../setup/types";
import ResetAccountConfirmationModal from "./ResetAccountConfirmationModal";
import useAppRouter from "../../../lib/useAppRouter";

const ResetAccountButton = ({ account }: { account: AccountWithIntegrationFragment }) => {
  const { toast } = useToasts();
  const { company } = useActiveCompany<true>();
  const { resetAccount, addPendingAccount, removePendingAccount } = useFinancialInstitutions();
  const { goToIntegrations } = useAppRouter();
  const [showModal, setShowModal] = useState(false);

  const reset = useCallback(async () => {
    if (!account) {
      return;
    }
    addPendingAccount({
      accountId: account.id,
      pendingLabel: "Resetting",
      manageAccountTooltip: "This account is currently resetting.",
    });
    resetAccount(account.id, company.id).then((result) => {
      removePendingAccount(account.id);

      if (result?.errors) {
        toast({ message: "Failed to reset account.", status: "error" });
      } else {
        toast({
          message:
            "Successfully reset account. Please allow a few minutes for to resync your data.",
          status: "success",
        });
      }
    });

    toast({
      message: "Resetting your account. This may take a few minutes.",
      status: "success",
    });
    goToIntegrations();
  }, [
    account,
    addPendingAccount,
    resetAccount,
    company.id,
    toast,
    goToIntegrations,
    removePendingAccount,
  ]);

  const shouldShowButton = useMemo(() => {
    if (!account) {
      return false;
    }

    if (!company.features.resetAccountsEnabled) {
      return false;
    }

    return true;
  }, [account, company.features.resetAccountsEnabled]);

  const disabledOptions = useMemo(() => {
    if (company.isSimulated && !account.manuallyAdded) {
      return {
        disabled: true,
        reason: "Resetting is not available for demo data.",
      };
    }

    const category = getIntegrationCategoryForAccountType(account.type);
    if (category !== IntegrationCategory.BanksAndCreditCards) {
      return {
        disabled: true,
        reason: `Resetting is not available for this account type.\n\nIf you are missing data, try disconnecting and reconnecting.\n\nIf you need to delete data associated with this account, you can do so within the relevant page of the app`,
      };
    }

    const connectionStatus = account.connection?.status;
    if (!connectionStatus || connectionStatus === IntegrationConnectionStatus.Disconnected) {
      return {
        disabled: true,
        reason: "Please reconnect the integration before resetting the account.",
      };
    }

    return { disabled: false, reason: undefined };
  }, [account, company.isSimulated]);

  if (!shouldShowButton) {
    return null;
  }

  return (
    <>
      <FooterButton
        label="Reset"
        variant="negative"
        description={`Remove all existing data and resync from scratch.`}
        onClick={() => setShowModal(true)}
        canBeUndone={false}
        disabled={disabledOptions.disabled}
        disabledTooltip={disabledOptions.reason}
      />

      <ResetAccountConfirmationModal
        open={showModal}
        onOpenChange={setShowModal}
        onConfirm={() => {
          reset();
        }}
      />
    </>
  );
};

export default ResetAccountButton;

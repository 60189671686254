import React from "react";
import { Dialog } from "@puzzle/ui";
import { MultiAccountIngestDateModal } from "./MultiAccountIngestDateModal";
import { SelectStartDateModal } from "./AccountIngestDateModal";
import { IntegrationAccountFragment, shouldEnableCutoverV2 } from "./ingestionDateUtils";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import useSelf from "components/users/useSelf";

export type SelectIngestDateModalProps = React.ComponentPropsWithoutRef<typeof Dialog> & {
  accounts: IntegrationAccountFragment[];
  isReconnect?: boolean;
  accountsLoading?: boolean;
  connectionId?: string;
  onDatesSet?: () => void;
  successToastMessageOverride?: string;
};
export const SelectIngestDateModal = ({
  open,
  onOpenChange,
  accounts,
  connectionId,
  isReconnect = false,
  successToastMessageOverride,
  onDatesSet,
  ...props
}: SelectIngestDateModalProps) => {
  const { company } = useActiveCompany<true>();
  const { isPuzzlenaut } = useSelf();

  const isCutoverV2Enabled = shouldEnableCutoverV2(company?.coaType, isPuzzlenaut);

  if (!open) return null;
  if (accounts.length > 1 && isCutoverV2Enabled) {
    return (
      <MultiAccountIngestDateModal
        open={open}
        accounts={accounts}
        onOpenChange={onOpenChange}
        isReconnect={isReconnect}
        connectionId={connectionId}
        successToastMessageOverride={successToastMessageOverride}
      />
    );
  }
  return (
    <SelectStartDateModal
      open={open}
      accounts={accounts}
      onOpenChange={onOpenChange}
      isReconnect={isReconnect}
      connectionId={connectionId}
      onDatesSet={onDatesSet}
      successToastMessageOverride={successToastMessageOverride}
    />
  );
};

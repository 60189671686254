import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { compact, uniq } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { parseAbsolute, parseDate, toCalendarDate, today } from "@internationalized/date";
import Big from "big.js";

import {
  styled,
  Button,
  Dialog,
  Input,
  Select,
  Text,
  useToasts,
  DateInput,
  CurrencyInput,
  Tag,
  Stack,
  useDialogReset,
  RadioGroup,
  AutocompleteMenu,
  OptionCard,
} from "@puzzle/ui";
import { Add } from "@puzzle/icons";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import useCategories from "components/common/hooks/useCategories";
import { RulesPageUserRuleFragment, useGetMatchRuleQuery } from "./graphql.generated";
import { CreateRuleInput, useCreateRule } from "./hooks";
import {
  VendorFragment,
  VendorFragmentDoc,
  CategorizationRuleApplicationType,
  CategoryFragment,
} from "graphql/types";

import { useExpenseCategories } from "components/common/CategoriesFilter";
import AccountTypeSelect from "./AccountTypeSelect";
import {
  accountTypeLabels,
  accountTypeOptions,
  accountTypeToSelectableAccountType,
  SelectableAccountTypes,
  selectableAccountTypeToAccountTypes,
} from "./accountTypes";
import { BasicTransactionFragment } from "../graphql.generated";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { useApolloClient } from "@apollo/client";
import { VendorSelect } from "components/transactions/vendors";
import { useVendorFragment } from "components/common/hooks/vendors";
import { Box, color, S, vars } from "ve";
import { zIndex } from "@puzzle/utils";
import { newRuleDialogBody } from "./RuleModal.css";

const STRINGS = {
  editTitle: "Edit rule",
  newTitle: "New rule",
};

const Label = styled("div", {
  color: "$purple300",
  fontWeight: 500,
  fontSize: "$headingS",
  padding: "$2 $0",
  "&:first-child": {
    paddingTop: "$0",
    borderTop: "none",
  },
});
const Separator = styled("div", {
  borderTop: "1px solid $rhino600",
  marginRight: "$0",
  margin: "$0 -$3",
});

const SubLabel = styled("span", {
  color: "$gray400",
  textVariant: "$bodyS",
});

const MetaInfo = styled("span", {
  color: "$gray500",
  textVariant: "$bodyS",
  fontStyle: "italic",
});

const Row = styled("div", {
  display: "grid",
  alignItems: "center",
  gridAutoFlow: "column",
  gridTemplateColumns: "160px auto",
  gap: "$1h",
  paddingBottom: "$1h",
});

enum SelectOption {
  Contains = "contains",
  ExactMatch = "exact_match",
  Between = "between",
  GreaterThan = "greater_than",
  LessThan = "less_than",
}

export type RuleFormValues = CreateRuleInput;
const DEFAULT_INITIAL_VALUES: Partial<RuleFormValues> = {
  matchEntireString: false,
  appliesTo: [CategorizationRuleApplicationType.Transaction],
};

export const RuleModal = ({
  initialValues = DEFAULT_INITIAL_VALUES,
  open,
  onSaveSuccess,
  originTransaction,
  location,
  ...props
}: React.ComponentProps<typeof Dialog> & {
  id?: string;
  initialValues?: Partial<RuleFormValues>;
  title?: string;
  onSaveSuccess?: (rule: RulesPageUserRuleFragment) => void;
  originTransaction?: BasicTransactionFragment;
  location: string;
}) => {
  const client = useApolloClient();

  const idRef = useRef<string | undefined>(props.id);
  if (open) {
    idRef.current = props.id;
  }
  const id = idRef.current;

  const title = props.title || (id ? STRINGS.editTitle : STRINGS.newTitle);
  const { data } = useGetMatchRuleQuery({
    variables: id ? { id } : undefined,
    skip: !id,
    fetchPolicy: "cache-first",
  });
  const rule = data?.userCategorizationRule;

  const { company, timeZone, lockedPeriodDate } = useActiveCompany<true>();
  const { categories, categoriesByPermaKey } = useCategories();
  const { expenseCategories } = useExpenseCategories();
  const { toast } = useToasts();

  const [createRule] = useCreateRule({ affectedTransaction: originTransaction, location });
  const defaultValues = useMemo<RuleFormValues>(
    () => ({
      replaceRuleId: rule?.id || initialValues.replaceRuleId,
      vendorId: rule?.vendor?.id || initialValues.vendorId,
      ledgerCoaKey: rule?.category.coaKey || initialValues.ledgerCoaKey || undefined,
      effectiveAt: rule?.effectiveAt
        ? toCalendarDate(parseAbsolute(rule.effectiveAt, timeZone)).toString()
        : initialValues.effectiveAt
        ? parseDate(initialValues.effectiveAt).toString()
        : today(timeZone).toString(),
      matchEntireString: rule?.matchEntireString ?? initialValues.matchEntireString ?? false,
      rule: rule?.rule || initialValues.rule || "",
      accountTypes: compact(rule?.accountTypes ?? []),
      appliesTo: rule?.appliesTo || initialValues.appliesTo,
    }),
    [initialValues, rule, timeZone]
  );
  const { formState, control, register, handleSubmit, reset, trigger, watch } =
    useForm<RuleFormValues>({
      mode: "onChange",
      defaultValues,
    });

  const appliesTo = watch("appliesTo");
  const amountQualifierFormState = watch("amountQualifier");
  const isAmountQualifierUnselected = amountQualifierFormState === undefined;
  const shouldShow2AmountInputs = amountQualifierFormState === SelectOption.Between;
  const isTransaction = appliesTo?.includes(CategorizationRuleApplicationType.Transaction) ?? true;
  const selectedType = isTransaction ? "transactions" : "invoices";

  useDialogReset(open, () => {
    reset(defaultValues);
  });

  // This controls whether or not we include/validate the effectiveAt field.
  // It could be part of the form state, but we'd still need to use watch().
  const [applyEffectiveAt, setApplyEffectiveAt] = useState(Boolean(lockedPeriodDate) || false);
  useEffect(() => {
    // Retrigger validation of effectiveAt
    trigger("effectiveAt");
  }, [applyEffectiveAt, trigger]);

  useEffect(() => {
    if (rule) {
      setApplyEffectiveAt(Boolean(rule.effectiveAt));
    }
  }, [rule]);

  useEffect(() => {
    if (open) {
      Analytics.ruleModalOpened({
        isNew: Boolean(id),
        location,
      });
    }
  }, [defaultValues, id, location, open, reset]);

  const onSave = useCallback(
    (rule: RulesPageUserRuleFragment) => {
      if (onSaveSuccess) {
        onSaveSuccess(rule);
      } else {
        toast({
          title: `Rule has been saved. It may take several minutes to update the category for all applicable ${selectedType}.`,
          status: "success",
        });
      }
    },
    [onSaveSuccess, toast, selectedType]
  );

  const onSubmit = handleSubmit(async ({ vendorId, ...values }) => {
    const vendorFragment = client.readFragment<VendorFragment>({
      fragment: VendorFragmentDoc,
      fragmentName: "vendor",
      id: `Vendor:${vendorId}`,
    });
    const effectiveAt =
      applyEffectiveAt && values.effectiveAt
        ? parseDate(values.effectiveAt).toDate(timeZone).toISOString()
        : null;
    const getMinAndMaxAmounts = (
      amount1: string | null,
      amount2: string | null,
      amountQualifier: string | undefined
    ) => {
      switch (amountQualifier) {
        case SelectOption.Between:
          if (amount1 && amount2 && Big(amount1) > Big(amount2)) {
            // if the user has entered the amounts in the wrong order, swap them
            return {
              minAmountInclusive: amount2,
              maxAmountInclusive: amount1,
            };
          } else
            return {
              minAmountInclusive: amount1,
              maxAmountInclusive: amount2,
            };
        case SelectOption.GreaterThan:
          return {
            minAmountInclusive: amount1,
            maxAmountInclusive: null,
          };
        case SelectOption.LessThan:
          return {
            minAmountInclusive: null,
            maxAmountInclusive: amount1,
          };
        case SelectOption.ExactMatch:
          return {
            minAmountInclusive: amount1,
            maxAmountInclusive: amount1,
          };
        default:
          return {
            minAmountInclusive: null,
            maxAmountInclusive: null,
          };
      }
    };
    const input = {
      accountTypes: values.accountTypes,
      appliesTo: values.appliesTo,
      companyId: company.id,
      effectiveAt,
      ledgerCoaKey: values.ledgerCoaKey,
      matchEntireString: values.matchEntireString,
      replaceRuleId: id,
      rule: values.rule,
      vendorPermaName: vendorFragment?.permaName,
      ...getMinAndMaxAmounts(
        String(values.minAmountInclusive),
        String(values.maxAmountInclusive),
        values.amountQualifier
      ),
    };
    const result = await createRule({ variables: { input } });
    const rule = result.data?.createUserCategorizationRule.matchRule;
    if (rule) {
      onSave(rule);
      props.onOpenChange?.(false);
    } else {
      props.onOpenChange?.(false);
      toast({
        title: `Saving rule failed`,
        message:
          "Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again in a few minutes.",
        status: "warning",
      });
    }
  });

  return (
    <Dialog
      size="xsmall"
      open={open}
      {...props}
      style={{ zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("modal") : "auto" }}
    >
      <Dialog.Title>{title}</Dialog.Title>

      <Dialog.Body className={newRuleDialogBody}>
        <Label>Rule Type</Label>
        <Controller
          control={control}
          name="appliesTo"
          render={({ field }) => {
            return (
              <Box css={{ paddingBottom: S["2"] }}>
                <RadioGroup
                  aria-label="ruleType"
                  value={field.value?.[0] ?? CategorizationRuleApplicationType.Transaction}
                  onValueChange={(val) =>
                    field.onChange({ target: { value: [val], name: field.name } })
                  }
                  options={[
                    {
                      value: CategorizationRuleApplicationType.Transaction,
                      label: "Transaction",
                    },
                    {
                      value: CategorizationRuleApplicationType.Invoice,
                      label: "Invoice",
                    },
                  ]}
                />
              </Box>
            );
          }}
        />

        <Separator />
        <Label>Conditions</Label>

        {/* ----------------- DESCRIPTION ROW ----------------- */}
        <Row>
          <SubLabel>
            {selectedType === "transactions" ? "Description" : "Line item description"}
          </SubLabel>
          <Controller
            control={control}
            name="matchEntireString"
            render={({ field }) => {
              return (
                <Select
                  value={field.value ? SelectOption.ExactMatch : SelectOption.Contains}
                  size="mini"
                  onSelectionChange={(val) => {
                    field.onChange({
                      target: {
                        value: val === SelectOption.ExactMatch,
                        name: field.name,
                      },
                    });
                  }}
                  menuCss={{
                    zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z)
                      ? zIndex("modalMenu")
                      : "auto",
                  }}
                  options={[
                    { label: "Contains", value: SelectOption.Contains },
                    { label: "Equals", value: SelectOption.ExactMatch },
                  ]}
                />
              );
            }}
          />
          <Input
            size="small"
            placeholder="e.g. insurance"
            {...register("rule", { required: true })}
          />
        </Row>

        {/* ----------------- AMOUNT ROW ----------------- */}
        <Row>
          <SubLabel>
            {selectedType === "transactions" ? "Amount " : "Line item amount "}
            <MetaInfo>(optional)</MetaInfo>
          </SubLabel>
          <Controller
            control={control}
            name="amountQualifier"
            render={({ field }) => {
              return (
                <Select
                  value={field.value}
                  size="mini"
                  onSelectionChange={(val) => {
                    field.onChange({
                      target: {
                        value: val,
                        name: field.name,
                      },
                    });
                  }}
                  menuCss={{
                    zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z)
                      ? zIndex("modalMenu")
                      : "auto",
                  }}
                  options={[
                    { label: "Equals", value: SelectOption.ExactMatch },
                    { label: "Greater Than", value: SelectOption.GreaterThan },
                    { label: "Less Than", value: SelectOption.LessThan },
                    { label: "Between", value: SelectOption.Between },
                  ]}
                />
              );
            }}
          />

          <div>
            <Controller
              control={control}
              name="minAmountInclusive"
              render={({ field }) => {
                return (
                  <CurrencyInput
                    aria-label="Amount field 1"
                    size="small"
                    truncateWhenInactive={false}
                    placeholder="$0"
                    disabled={isAmountQualifierUnselected}
                    value={field.value}
                    onValueChange={({ value }) => {
                      field.onChange({
                        target: { value, name: field.name },
                      });
                    }}
                    allowNegative={false}
                  />
                );
              }}
            />

            {shouldShow2AmountInputs && (
              <div style={{ marginTop: S["1"] }}>
                <Controller
                  control={control}
                  name="maxAmountInclusive"
                  render={({ field }) => {
                    return (
                      <CurrencyInput
                        aria-label="Amount field 2"
                        size="small"
                        truncateWhenInactive={false}
                        placeholder="$0"
                        value={field.value}
                        onValueChange={({ value }) => {
                          field.onChange({
                            target: { value, name: field.name },
                          });
                        }}
                        allowNegative={false}
                      />
                    );
                  }}
                />
              </div>
            )}
          </div>
        </Row>

        {/* ----------------- SOURCE ROW ----------------- */}
        {isTransaction && (
          <Controller
            control={control}
            name="accountTypes"
            render={({ field }) => {
              const selectedAccountTypes = compact(
                uniq((field.value || []).map((type) => accountTypeToSelectableAccountType[type]))
              );

              const removeSelectableAccountType = (type: SelectableAccountTypes) => {
                field.onChange({
                  target: {
                    value: (field.value || []).filter(
                      (t) => accountTypeToSelectableAccountType[t] !== type
                    ),
                    name: field.name,
                  },
                });
              };

              return (
                <Row>
                  <SubLabel>
                    Source <MetaInfo>(optional)</MetaInfo>
                  </SubLabel>
                  <Stack direction="vertical" gap="1">
                    {selectedAccountTypes.length > 0 && (
                      <Box
                        css={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: S["1"],
                        }}
                      >
                        {selectedAccountTypes.map((type) => (
                          <Tag
                            variant="pill"
                            key={type}
                            onRemove={() => removeSelectableAccountType(type)}
                          >
                            {accountTypeLabels[type]}
                          </Tag>
                        ))}
                      </Box>
                    )}

                    {/* non-portalled; extra wrapper to prevent gap */}
                    <div>
                      <AccountTypeSelect
                        css={{
                          zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z)
                            ? zIndex("modalMenu")
                            : "auto",
                        }}
                        accountTypes={accountTypeOptions}
                        selection={selectedAccountTypes}
                        onSelectionChange={(accountTypes) => {
                          field.onChange({
                            target: {
                              value: accountTypes.flatMap(selectableAccountTypeToAccountTypes),
                              name: field.name,
                            },
                          });
                        }}
                      />
                    </div>
                  </Stack>
                </Row>
              );
            }}
          />
        )}
        <Separator />
        <Label>Actions</Label>

        <Row>
          <SubLabel>Assign category</SubLabel>
          <Controller
            control={control}
            name="ledgerCoaKey"
            rules={{ required: true }}
            render={({ field }) => {
              const selectedCategory = field.value
                ? categoriesByPermaKey?.[field.value]
                : undefined;

              const trigger = selectedCategory ? (
                <Tag variant="pill">{selectedCategory.name}</Tag>
              ) : (
                <Button
                  variant="minimal"
                  size="small"
                  css={{
                    color: "$gray500",
                  }}
                  prefix={<Add />}
                >
                  Add category
                </Button>
              );

              return (
                <AutocompleteMenu<CategoryFragment, false, false, false>
                  label="Pick a category"
                  placeholder="Change to..."
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(o) => o.permaKey}
                  value={field.value ? selectedCategory : undefined}
                  options={isTransaction ? categories : expenseCategories}
                  css={{
                    zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z)
                      ? zIndex("modalMenu")
                      : "auto",
                  }}
                  onChange={(_, value) => {
                    field.onChange({
                      target: {
                        value: value?.coaKey,
                        name: field.name,
                      },
                    });
                  }}
                  trigger={trigger}
                />
              );
            }}
          />
        </Row>

        {isTransaction && (
          <Row>
            <SubLabel>
              Assign vendor <MetaInfo>(optional)</MetaInfo>
            </SubLabel>
            <Controller
              control={control}
              name="vendorId"
              render={({ field }) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const { complete, data } = useVendorFragment(field.value);
                const value = complete ? data : undefined;

                const onSelect = (vendor: VendorFragment | null) => {
                  field.onChange({
                    target: {
                      name: field.name,
                      value: vendor?.id,
                    },
                  });
                };

                return (
                  <VendorSelect
                    value={value}
                    onSelect={onSelect}
                    menuCss={{
                      zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z)
                        ? zIndex("modalMenu")
                        : "auto",
                    }}
                    emptyState={
                      <Button
                        variant="minimal"
                        size="small"
                        css={{ color: "$gray500" }}
                        prefix={<Add />}
                      >
                        Add vendor
                      </Button>
                    }
                  />
                );
              }}
            />
          </Row>
        )}
        <Separator />
        <Label>Apply to</Label>
        <Stack gap="1" direction="vertical">
          {/* TODO RadioGroup with inner form controls? Not sure of the best a11y approach */}
          <OptionCard onClick={() => setApplyEffectiveAt(true)} checked={applyEffectiveAt}>
            <Box
              css={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: S["2"],
                fontSize: vars.fontSizes.headingS,
                color: color.gray50,
              }}
            >
              <div> Starting on</div>
              <Controller
                control={control}
                name="effectiveAt"
                rules={{
                  validate: (value) => Boolean(!applyEffectiveAt || value),
                }}
                render={({ field }) => {
                  return (
                    <DateInput
                      aria-label="Start date"
                      size="mini"
                      css={{ width: "144px" }}
                      onChange={(value) => {
                        field.onChange({
                          target: {
                            value: value && toCalendarDate(value).toString(),
                            name: field.name,
                          },
                        });

                        if (value) {
                          setApplyEffectiveAt(true);
                        }
                      }}
                      value={field.value ? parseDate(field.value) : undefined}
                      minDate={lockedPeriodDate}
                    />
                  );
                }}
              />
            </Box>
            <div />
            <MetaInfo>From the above date through future {selectedType}</MetaInfo>
          </OptionCard>

          {
            <OptionCard
              onClick={() => setApplyEffectiveAt(false)}
              checked={!applyEffectiveAt}
              disabled={Boolean(lockedPeriodDate)}
            >
              <Box
                css={{
                  color: lockedPeriodDate ? color.gray400 : color.gray50,
                  fontSize: vars.fontSizes.headingS,
                }}
              >
                To all {selectedType}
              </Box>
              <div />
              <MetaInfo>Includes all past and future {selectedType}</MetaInfo>
            </OptionCard>
          }

          <Text variant="bodyS" color="gray400">
            When a rule applies historically, it may take several minutes to update the category for
            all applicable {selectedType}. It will not update the category of {selectedType} that
            have already been finalized.
          </Text>
        </Stack>
      </Dialog.Body>

      <Dialog.Footer divider>
        <Dialog.Actions>
          {!formState.isSubmitting && (
            <Dialog.Close asChild>
              <Button variant="minimal">Cancel</Button>
            </Dialog.Close>
          )}

          <Button
            variant="primary"
            onClick={onSubmit}
            disabled={!formState.isValid || formState.isSubmitting}
          >
            {formState.isSubmitting ? "Saving..." : "Save"}
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog>
  );
};

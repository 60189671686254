import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useGetCalIdQuery } from "./graphql.generated";
import { useMemo } from "react";

type UseCalResult = {
  calId: string | undefined;
};

export default function useCal(): UseCalResult {
  const { company } = useActiveCompany<true>();
  const { data } = useGetCalIdQuery({
    variables: {
      input: {
        companyId: company.id,
      },
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const calId = useMemo(() => {
    return data?.getCalId;
  }, [data]);

  return {
    calId,
  };
}

import React from "react";
import {
  ConnectModal,
  ConnectModalBody,
  ConnectModalContainer,
  ConnectModalProps,
  ConnectModalSecurityMessage,
  ConnectModalTitle,
} from "components/intro/components/ConnectModal";
import { Text, styled } from "@puzzle/ui";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import Link from "components/common/Link";
import links from "lib/links";
import { BasicListItem } from "../../ListItem";
import { SetupQuickbooksListItem } from "../../quickbooks";

const List = styled("ol", { paddingLeft: "$2", marginTop: "0" });

export const ConnectAccountingModal = ({ onOpenChange, ...rest }: ConnectModalProps) => {
  const { company } = useActiveCompany<true>();

  return (
    <ConnectModal
      {...rest}
      onOpenChange={(open: boolean) => {
        onOpenChange?.(open);
      }}
    >
      <ConnectModalTitle>Add accounting integration</ConnectModalTitle>

      <ConnectModalBody>
        <Text>
          Connecting to an existing accounting system enables you to easily switch from your
          previous accounting system to Puzzle. You can:
        </Text>
        <List>
          <li>
            View your historical financial statements (in their original format) within Puzzle's
            Reports page to maintain your historical records within Puzzle, even after you cancel
            your subscription to your previous provider.
          </li>
          <li>
            Convert your historical financial statements to Puzzle's format by requesting conversion
            services.{" "}
            <Link
              href={links.historicalFinancialMigration}
              css={{ color: "$green600 !important", textDecoration: "none !important" }}
              target="_blank"
            >
              Request conversion
            </Link>
          </li>
        </List>
        <ConnectModalSecurityMessage />
        <ConnectModalContainer>
          <SetupQuickbooksListItem companyId={company.id} DisplayComponent={BasicListItem} />
        </ConnectModalContainer>
      </ConnectModalBody>
    </ConnectModal>
  );
};

import { useEffect, useMemo } from "react";
import { useToasts } from "@puzzle/ui";
import { CompanyFinancialMetricsStatus } from "graphql/types";
import { useCompanyArrQuery } from "./graphql.generated";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

export default function useCompanyArr(options?: { skipPolling?: boolean; pollInterval?: number }) {
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();
  const companyId = company.id;

  const pollInterval = options?.skipPolling === true ? 0 : options?.pollInterval ?? 5 * 1000;

  const { data, error, stopPolling, loading, called } = useCompanyArrQuery({
    context: { batch: false },
    variables: {
      input: {
        companyId,
        asOf: undefined,
      },
    },
    pollInterval: pollInterval,
    fetchPolicy: "cache-and-network",
  });

  const { status, metrics } = data?.companyArr || {};

  const ready = useMemo(
    () => (!loading || called) && metrics && status === CompanyFinancialMetricsStatus.Ready,
    [metrics, status, loading, called]
  );

  useEffect(() => {
    if (error) {
      toast({ status: "error", message: "There was an error retrieving your ARR" });
    }
    if (ready && pollInterval > 0) {
      stopPolling();
    }
  }, [toast, error, ready, stopPolling, pollInterval]);

  return {
    loading: !ready,
    ...metrics,
  };
}

import { QUESTIONS, type Question, type MonthEndResponseItem } from "./types";

export const AI_LABS_SECTION_TITLE = "Puzzle AI";
export const DEV_MODE = false;
export const DEFAULT_PROMPT = "month_end_review";
export const API_ID = "6";
export const API_EXPLORE_LINK =
  "https://puzzle-financial.stoplight.io/docs/puzzle-accounting-api/fslanc6eqatsw-introduction";

export const QUESTIONS_LIST: Question[] = [
  {
    id: "1",
    title: QUESTIONS.REVIEW_MONTH_END_CLOSE,
    responseLabel: (formattedDate: string) =>
      `Here’s the summary of the month-end accuracy review for ${formattedDate}:`,
    enabled: true,
    promptName: "month_end_review",
  },
  {
    id: "2",
    title: QUESTIONS.BUSINESS_STATUS_ELON,
    responseLabel: (formattedDate: string) =>
      `Here’s how Elon Mask would describe your business for ${formattedDate}. I’ll keep this short and to the point:`,
    enabled: true,
    promptName: "investor_update_harsh",
  },
  {
    id: "3",
    title: QUESTIONS.BUSINESS_STATUS_INVESTOR,
    responseLabel: (formattedDate: string) =>
      `Here’s the summary of the business status for ${formattedDate}:`,
    enabled: true,
    promptName: "investor_update_balanced",
  },
  {
    id: "4",
    title: QUESTIONS.BUSINESS_STATUS_FRIENDLY,
    responseLabel: (formattedDate: string) =>
      `Here’s the summary of the business status for ${formattedDate}:`,
    enabled: true,
    promptName: "investor_update_kind",
  },
  {
    id: "5",
    title: QUESTIONS.VALIDATE_BANK_RECONCILIATIONS,
    responseLabel: (formattedDate: string) =>
      `Here’s the validated reconciliations for ${formattedDate}:`,
    enabled: false,
    promptName: "upcoming",
  },
  {
    id: API_ID,
    title: QUESTIONS.EXPLORE_API,
    responseLabel: (formattedDate: string) => `Here’s the TODO for ${formattedDate}:`,
    enabled: true,
    promptName: "outside_link",
  },
];

export const DUMMY_RESULTS: MonthEndResponseItem[] = [
  {
    id: "1",
    item: "Professional Services Spike (November)",
    confidence: "Very High",
    reason:
      "Professional services rose by about $30k month-over-month (a ~44% increase from October). This could reflect a large, one-time consultancy engagement or delayed invoice recognition. Such a spike significantly affects month-end results and margin calculations.",
    recommendation:
      "Review all November service invoices and confirm the timing and necessity of these services. If not one-off, consider cost-control measures or seeking alternative, more cost-effective resources.",
  },
  {
    id: "2",
    item: "Professional Services Spike (November)",
    confidence: "Very High",
    reason:
      "Professional services rose by about $30k month-over-month (a ~44% increase from October). This could reflect a large, one-time consultancy engagement or delayed invoice recognition. Such a spike significantly affects month-end results and margin calculations.",
    recommendation:
      "Review all November service invoices and confirm the timing and necessity of these services. If not one-off, consider cost-control measures or seeking alternative, more cost-effective resources.",
  },
];

import { FeedItem } from "@knocklabs/client";
import React from "react";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { Route } from "lib/routes";
import { DirectIngestFeedItemData } from "../shared";
import { MiniPane } from "./MiniPane";
import useAccount from "components/integrations/shared/useAccount";
import { formatAccountName } from "@puzzle/utils";
import { compact } from "lodash";

export const DirectIngestPane = ({ item }: { item: FeedItem<DirectIngestFeedItemData> }) => {
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "long" });
  const { account } = useAccount({ id: item.data?.accountId });

  return (
    <MiniPane
      title="Transactions imported"
      data={compact([
        account && {
          label: "Account",
          value: formatAccountName(account),
        },
        {
          label: "Number of Transactions",
          value: item.data?.count,
        },
        {
          label: "Date added",
          value: dateFormatter.format(new Date(item.inserted_at)),
        },
      ])}
      headerButtonOptions={{
        label: "Go to Transactions",
        // TODO query filter
        href: Route.transactions,
      }}
    />
  );
};

import React from "react";
import { styled, Text, Help } from "@puzzle/ui";
import { ChevronRight } from "@puzzle/icons";

import Link from "components/common/Link";
import { AttachmentSideBar } from "components/common/AttachmentSidebar";
import { SidebarToggle } from "components/common/SidebarToggle";
import { Route } from "lib/routes";
import { AssociatedEntity } from "graphql/types";
import { getReconListByAccountPath } from "../shared";
import { create } from "zustand";
import { SingleLedgerReconciliationFragment } from "../graphql.generated";
import { Box, S, color } from "ve";

type ReconLayoutState = {
  sidebarOpen: boolean;
  toggleSidebar: () => void;
  inMemoryFile?: File;
  setInMemoryFile: (file?: File) => void;
}

const useReconLayout = create<ReconLayoutState>((set) => ({
  sidebarOpen: false,
  toggleSidebar: () => set(({ sidebarOpen }) => ({ sidebarOpen: !sidebarOpen })),
  setInMemoryFile: (file) => set({ inMemoryFile: file }),
}));

export const Header = styled("header", {
  display: "flex",
  padding: "$1h $2h",
  alignItems: "center",
  boxShadow: "inset 0px -0.5px 0px $colors$mauve400",
  fontSize: "$bodyM",
  gap: "$1",
});

export const Container = styled("div", {
  display: "flex",
  height: "100%",
  overflow: "hidden",
});

const Instruction = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$2",
  padding: "$2",
});

export const HeaderLayout = ({
  children,
  activeLedgerAccount,
}: React.PropsWithChildren<{ activeLedgerAccount?: { id: string; displayName: string } }>) => {
  return (
    <Header>
      <div>
        <Link href={Route.ledgerReconciliations}>
          <Text color="gray200">
            <Text weight="heavy">Reconciliations:</Text> All accounts
          </Text>
        </Link>
        <ChevronRight fill="#D1D1D5" css={{ position: "relative", top: "1px" }} />
        <Link href={activeLedgerAccount && getReconListByAccountPath(activeLedgerAccount.id)}>
          <Text color="gray400">{activeLedgerAccount?.displayName}</Text>
        </Link>
      </div>
      <Box css={{ marginLeft: "auto", display: "flex", alignItems: "center", gap: S["1"] }}>
        {children}
      </Box>
    </Header>
  );
};

export const Layout = ({ children }: React.PropsWithChildren) => {
  return (
    <Box
      id="top"
      css={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {children}
    </Box>
  );
};

const helpCss = { position: "relative", top: "2px", left: "-6px" };

export const Content = ({
  children,
  activeRecon,
  canEditFiles,
  headerElement,
}: React.PropsWithChildren<{
  activeRecon?: SingleLedgerReconciliationFragment;
  canEditFiles: boolean;
  headerElement?: React.ReactElement;
}>) => {
  const { sidebarOpen, toggleSidebar, inMemoryFile, setInMemoryFile } = useReconLayout();
  return (
    <Container>
      <Box
        css={{
          transition: "200ms",
          position: "relative",
          left: "1px",
          boxShadow: `0.5px 0px 0px ${color.mauve400}`,
          display: "flex",
          flexDirection: "column",
          width: sidebarOpen ? "600px" : "70px",
        }}
      >
        {sidebarOpen ? (
          <>
            <Instruction css={{ whiteSpace: "nowrap" }}>
              <Text>View your statement or account activity</Text>
              <Help
                css={helpCss}
                content="A statement or other source of account activity serves as the source of truth for your reconciliation. Uploading a file is optional but encouraged for recordkeeping. The transactions included in the reconciliation should match the source transactions through the ending balance date."
              />
              <SidebarToggle
                open={sidebarOpen}
                onClick={toggleSidebar}
                css={{ marginLeft: "auto" }}
              />
            </Instruction>
            <AttachmentSideBar
              entity={activeRecon}
              uploadEntityType={AssociatedEntity.LedgerReconciliation}
              inMemoryFile={inMemoryFile}
              setInMemoryFile={setInMemoryFile}
              sidebarOpen={sidebarOpen}
              isEditor={canEditFiles}
            />
          </>
        ) : (
          <Box css={{ paddingTop: S["2"], display: "flex", justifyContent: "center" }}>
            <SidebarToggle open={sidebarOpen} onClick={() => toggleSidebar()} />
          </Box>
        )}
      </Box>
      <Box
        css={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: `0px ${S["2"]} ${S["7"]} ${S["2"]}`,
          gap: S["2"],
        }}
      >
        <Instruction css={{ padding: "$2 0 0 0" }}>
          <Text>Compare Puzzle to your statement or account activity</Text>
          <Help
            css={helpCss}
            content="Puzzle populates a default value for the statement balance based on what is received via the integration API. We populate the default date based on our best guess of reconciliation cycles. You can modify both fields."
          />
        </Instruction>{" "}
        <Box css={{ maxWidth: activeRecon ? 800 : 500 }}>
          <Box
            css={{
              borderRadius: S["2"],
              border: `1px solid ${color.gray700}`,
              overflow: "hidden",
              width: "100%"
            }}
          >
            {headerElement}
          </Box>
        </Box>
        {children}
      </Box>
    </Container>
  );
};

export const ReconLayout = {
  Content,
  Layout,
  HeaderLayout,
};

import Icon, { IconProps } from "./Icon";

export default function Move({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  fill = "none",
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} fill={fill} viewBox={viewBox} {...iconProps}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m9 5.75 3-3 3 3M5.75 9l-3 3 3 3m12.5-6 3 3-3 3M15 18.25l-3 3-3-3M12 4v8m0 0v8m0-8H4m8 0h8"
      />
    </Icon>
  );
}

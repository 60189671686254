/* eslint-disable react/display-name */
import React from "react";
import { AccountFragment, AccountType } from "graphql/types";
import CreditCard from "../icons/CreditCard";
import { styled, Tooltip, CSS } from "@puzzle/ui";
import { BankOutline, PaymentProcessor } from "@puzzle/icons";
import { formatAccountName } from "@puzzle/utils";

const Container = styled("div", {
  color: "$gray400",
  lineHeight: 0,
  width: "fit-content",
});

export const AccountRoot = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
});

export const AccountCell = React.memo(
  ({ account, css }: { account: Pick<AccountFragment, "name" | "mask" | "type">; css?: CSS }) => {
    
    const icons: Partial<Record<AccountType, React.ReactElement>> = {
      [AccountType.Depository]: <BankOutline />,
      [AccountType.Credit]: <CreditCard />,
      [AccountType.PaymentProcessing]: <PaymentProcessor />,
    };

    return (
      <Tooltip content={formatAccountName(account)} side="right">
        <Container css={css}>{icons[account.type]}</Container>
      </Tooltip>
    );
  }
);

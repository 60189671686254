import { useCallback } from "react";
import { useToasts } from "@puzzle/ui";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import {
  AvailableClassSegmentFragment,
  useApplyClassToEntityMutation,
} from "components/common/hooks/graphql.generated";
import { TaggableEntity } from "components/common/Classifications/TagEntity";
import Analytics from "lib/analytics";

const useAddClassToEntity = ({ entity }: { entity?: TaggableEntity }) => {
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();
  const [_applyClassToEntity, { loading, reset }] = useApplyClassToEntityMutation();

  const showSuccessToast = useCallback(
    (className: string, entityName: string) => {
      toast({
        message: `${className} applied to ${entityName}`,
        status: "success",
      });
    },
    [toast]
  );

  const showErrorToast = useCallback(() => {
    toast({
      message:
        "Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again in a few minutes.",
      status: "warning",
    });
  }, [toast]);

  const applyClassToEntity = useCallback(
    async (reportingClassSegment: AvailableClassSegmentFragment) => {
      if (!entity) return;
      await _applyClassToEntity({
        variables: {
          input: {
            companyId: company.id,
            reportingClassSegmentId: reportingClassSegment.id,
            entityId: entity.id,
            entityType: entity.type,
          },
        },
        onCompleted: () => {
          Analytics.addClassTag({
            actionType: "UserCreated",
            entityType: entity.type,
          });
          showSuccessToast(reportingClassSegment.name, entity.name);
        },
        onError: () => {
          showErrorToast();
        },
      });
    },
    [_applyClassToEntity, company.id, entity, showSuccessToast, showErrorToast]
  );

  return {
    applyClassToEntity,
    applyClassToEntityLoading: loading,
    reset,
  };
};

export default useAddClassToEntity;

import { colors } from "ve/theme/colors";
import { CalendarDate } from "@internationalized/date";
import { AccountFragment, AccountWithIntegrationFragment, CoaType } from "graphql/types";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";

export enum IngestionStartOption {
  ALL_TIME = "allTime",
  CUSTOM = "custom",
  LAST_TRANSACTION = "lastTransaction",
  LEDGER_START = "ledgerStart",
}
export type IntegrationAccountFragment = AccountFragment | AccountWithIntegrationFragment;
export type EpochInput = {
  cutoffBefore: CalendarDate | null;
  cutoffAtOrAfter: CalendarDate | null;
  accountId: string;
  shouldUpdateHistory: boolean;
};

export const IngestStartTooltips = {
  [IngestionStartOption.ALL_TIME]: "Ingest all data for this account",
  [IngestionStartOption.CUSTOM]:
    "Setting the ingestion start date earlier than the Puzzle ledger start date may create duplicate transactions if the historical ledger already includes all transactions before the Puzzle ledger start date. \n\nSetting the ingestion start date later than the Puzzle ledger start date may result in missing transactions between the two dates unless they are added manually.",
  [IngestionStartOption.LAST_TRANSACTION]:
    "Last transaction date represents the most recent transaction we have successfully ingested into our system from this account.",
  [IngestionStartOption.LEDGER_START]:
    "Aligning the ingestion start date with the Puzzle ledger start date prevents missing or duplicated transactions between your historical ledger (converted from other platforms) and Puzzle's general ledger. \n\nNote: The only potential missing transactions are those that occurred before the Puzzle ledger start date but were not included in the historical ledger. These transactions can be added manually if needed.",
};

const segmentBorder = `1px solid ${colors.gray500}`;
const roundedLeft = "200px 0px 0px 200px";
const roundedRight = "0px 200px 200px 0px";

const chartColors = {
  historicalLedger: colors.purple800,
  dataIngestion: colors.green800,
  noData: colors.gray400,
};

export enum CutoverSegmentType {
  HISTORICAL_LEDGER = "HISTORICAL_LEDGER",
  DATA_INGESTION = "DATA_INGESTION",
  NO_DATA = "NO_DATA",
  DATA_TO_BE_REMOVED = "DATA_TO_BE_REMOVED",
}

export const CutoverSegmentTypeToLegend = {
  [CutoverSegmentType.HISTORICAL_LEDGER]: {
    label: "Historical ledger",
    styles: {
      backgroundColor: colors.purple800,
    },
  },
  [CutoverSegmentType.DATA_INGESTION]: {
    label: "Data ingestion",
    styles: {
      backgroundColor: colors.green800,
    },
  },
  [CutoverSegmentType.NO_DATA]: {
    label: "No historical ledger or data ingestion",
    styles: {
      backgroundColor: colors.gray400,
    },
  },
  [CutoverSegmentType.DATA_TO_BE_REMOVED]: {
    label: "Data to be removed",
    styles: {
      border: segmentBorder,
    },
  },
};

export enum CutoverScenarios {
  INGEST_LATER_THAN_LEDGER = "INGEST_LATER_THAN_LEDGER",
  INGEST_BEFORE_LEDGER = "INGEST_BEFORE_LEDGER",
  INGEST_LATER_THAN_LEDGER_REMOVE = "INGEST_LATER_THAN_LEDGER_REMOVE",
  INGEST_BEFORE_LEDGER_REMOVE = "INGEST_BEFORE_LEDGER_REMOVE",
}

export const getCutoverScenario = (
  ledgerStartDate: CalendarDate,
  ingestStartDate: CalendarDate,
  removeTransactions: boolean
) => {
  if (ingestStartDate.compare(ledgerStartDate) > 0) {
    if (removeTransactions) {
      return CutoverScenarios.INGEST_LATER_THAN_LEDGER_REMOVE;
    }
    return CutoverScenarios.INGEST_LATER_THAN_LEDGER;
  }
  if (removeTransactions) {
    return CutoverScenarios.INGEST_BEFORE_LEDGER_REMOVE;
  }
  return CutoverScenarios.INGEST_BEFORE_LEDGER;
};

type BarData = {
  tooltip: string;
  type: CutoverSegmentType;
  styles?: React.CSSProperties;
}
export type BarDataOrNull = BarData | null;

export const getCutoverScenarioData = (
  scenario: CutoverScenarios,
  ledgerStartDate: string,
  ingestStartDate: string
): BarDataOrNull[][] => {
  const historicalLedgerTooltip = `Puzzle uses historical ledger as the only data source for this account before ${ledgerStartDate}`;
  const noDataTooltip = `Puzzle uses neither historical ledger nor data ingestion as data sources for this account between ${ledgerStartDate} and ${ingestStartDate}`;
  const dataIngestionTooltip = `Puzzle uses data ingestion as the only data source for this account from ${ingestStartDate}`;
  const dataToBeRemovedTooltip = (date: string) =>
    `Transactions associated with this account before ${date} will be removed`;
  const bothSourcesTooltip = (startDate: string, endDate: string) =>
    `Puzzle uses both historical ledger and data ingestion as data sources from ${startDate} to ${endDate}`;

  switch (scenario) {
    case CutoverScenarios.INGEST_LATER_THAN_LEDGER:
      return [
        [
          {
            tooltip: historicalLedgerTooltip,
            type: CutoverSegmentType.HISTORICAL_LEDGER,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.HISTORICAL_LEDGER].styles,
              borderRadius: roundedLeft,
            },
          },
          {
            tooltip: noDataTooltip,
            type: CutoverSegmentType.NO_DATA,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.NO_DATA].styles,
              backgroundColor: chartColors.noData,
            },
          },
          {
            tooltip: dataIngestionTooltip,
            type: CutoverSegmentType.DATA_INGESTION,
            styles: {
              backgroundColor: chartColors.dataIngestion,
              borderRadius: roundedRight,
            },
          },
        ],
      ];
    case CutoverScenarios.INGEST_BEFORE_LEDGER:
      return [
        [
          {
            tooltip: historicalLedgerTooltip,
            type: CutoverSegmentType.HISTORICAL_LEDGER,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.HISTORICAL_LEDGER].styles,
              borderRadius: roundedLeft,
            },
          },
          {
            tooltip: bothSourcesTooltip(ingestStartDate, ledgerStartDate),
            type: CutoverSegmentType.HISTORICAL_LEDGER,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.HISTORICAL_LEDGER].styles,
              borderRadius: roundedRight,
            },
          },
          null,
        ],
        [
          null,
          {
            tooltip: bothSourcesTooltip(ingestStartDate, ledgerStartDate),
            type: CutoverSegmentType.DATA_INGESTION,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.DATA_INGESTION].styles,
              borderRadius: roundedLeft,
            },
          },
          {
            tooltip: dataIngestionTooltip,
            type: CutoverSegmentType.DATA_INGESTION,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.DATA_INGESTION].styles,
              borderRadius: roundedRight,
            },
          },
        ],
      ];
    case CutoverScenarios.INGEST_LATER_THAN_LEDGER_REMOVE:
      return [
        [
          {
            tooltip: dataToBeRemovedTooltip(ledgerStartDate),
            type: CutoverSegmentType.DATA_TO_BE_REMOVED,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.DATA_TO_BE_REMOVED].styles,
              borderRadius: roundedLeft,
              borderRight: "none",
            },
          },
          {
            tooltip: dataToBeRemovedTooltip(ledgerStartDate),
            type: CutoverSegmentType.DATA_TO_BE_REMOVED,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.DATA_TO_BE_REMOVED].styles,
              borderTop: segmentBorder,
              borderBottom: segmentBorder,
            },
          },
          {
            tooltip: dataIngestionTooltip,
            type: CutoverSegmentType.DATA_INGESTION,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.DATA_INGESTION].styles,
              borderRadius: roundedRight,
            },
          },
        ],
      ];
    case CutoverScenarios.INGEST_BEFORE_LEDGER_REMOVE:
      return [
        [
          {
            tooltip: dataToBeRemovedTooltip(ingestStartDate),
            type: CutoverSegmentType.DATA_TO_BE_REMOVED,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.DATA_TO_BE_REMOVED].styles,

              borderRadius: roundedLeft,
              borderRight: "none",
            },
          },

          {
            tooltip: bothSourcesTooltip(ingestStartDate, ledgerStartDate),
            type: CutoverSegmentType.HISTORICAL_LEDGER,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.HISTORICAL_LEDGER].styles,
              borderRadius: roundedRight,
            },
          },
          null,
        ],
        [
          null,
          {
            tooltip: bothSourcesTooltip(ingestStartDate, ledgerStartDate),
            type: CutoverSegmentType.DATA_INGESTION,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.DATA_INGESTION].styles,
              borderRadius: roundedLeft,
            },
          },
          {
            tooltip: dataIngestionTooltip,
            type: CutoverSegmentType.DATA_INGESTION,
            styles: {
              ...CutoverSegmentTypeToLegend[CutoverSegmentType.DATA_INGESTION].styles,
              borderRadius: roundedRight,
            },
          },
        ],
      ];
  }
};

export const getCutoverScenarioDescription = (
  cutoverScenario: CutoverScenarios,
  ledgerStartDateString: string,
  ingestStartDateString: string
) => {
  if (
    cutoverScenario === CutoverScenarios.INGEST_LATER_THAN_LEDGER ||
    cutoverScenario === CutoverScenarios.INGEST_LATER_THAN_LEDGER_REMOVE
  ) {
    return `The ingestion start date for this account is set later than Puzzle ledger start date, which may result in missing transactions from ${ledgerStartDateString} to ${ingestStartDateString}.`;
  }
  return `The ingestion start date for this account is set earlier than Puzzle ledger start date, which may result in duplicated transactions from ${ingestStartDateString} to ${ledgerStartDateString}.`;
};

export const shouldEnableCutoverV2 = (coaType?: CoaType | null, isPuzzleNaut = false) => {
  const isFFEnabled = !!isPosthogFeatureFlagEnabled(FeatureFlag.CutoverDateV2);
  if (isPuzzleNaut && isFFEnabled) return true;

  return !!(isFFEnabled && coaType && coaType === CoaType.Decimal);
};

export const getIngestStartDateLabel = (option: IngestionStartOption) => {
  switch (option) {
    case IngestionStartOption.LEDGER_START:
      return "(Puzzle ledger start date)";
    case IngestionStartOption.ALL_TIME:
      return "Since the account was opened";
    case IngestionStartOption.LAST_TRANSACTION:
      return "(Last ingested transaction from this account)";
  }
};

export const INGEST_END_DATE_ALL_TIME_LABEL = "Up to date";

import React, { useCallback } from "react";
import { formatMoney } from "@puzzle/utils";
import { Box, Button, S, Separator, Stack, Text, color } from "ve";
import { YearlyDiscountBadge } from "../PricingModal/Badges";
import {
  planNameStyle,
  specialHeaderBoxRecipe,
  tierContentStackRecipe,
} from "./plansPricingModal.css";
import { getSubscriptionCycleLabel } from "./utils";
import { PuzzlePlanFragment } from "components/companies/graphql.generated";
import { PuzzlePriceBillingInterval } from "graphql/types";
import { usePlansPricingModal } from "./usePlansPricingModal";
import { Check } from "@puzzle/icons";
import Link from "components/common/Link";

// TO be moved to the use monetization that will be created in the followed PRs
const CUSTOM_PLAN_DEMO_CALL_URL = "";
const PLAN_BOX_LARGE_SIZE = 350;
const BOOK_A_DEMO_URL = "https://meetings.hubspot.com/luke-frye/puzzle-demo";

type PlanBoxProps = {
  plan: PuzzlePlanFragment;
  disabled?: boolean;
  upgradeButtonLabel: string;
  featuresHeader: string | React.ReactNode;
  size: "small" | "large";
};

type UpgradeButtonProps = {
  isCustom?: boolean;
  planName: string;
  onClick: () => void;
  variant: "gold-outline" | "gold";
  label: string;
  disabled?: boolean;
};

const UpgradeButton = ({
  isCustom,
  planName,
  onClick,
  variant,
  label,
  disabled,
}: UpgradeButtonProps) => {
  if (isCustom && !disabled) {
    return (
      <Link
        external
        href={BOOK_A_DEMO_URL}
        target="_blank"
        css={{
          textDecoration: "none !important",
        }}
      >
        <Button
          data-testid={`upgrade-button-tier-${planName.toLowerCase()}`}
          variant="gold"
          fullWidth
          onClick={onClick}
        >
          {label}
        </Button>
      </Link>
    );
  }
  return (
    <Button
      data-testid={`upgrade-button-tier-${planName.toLowerCase()}`}
      fullWidth
      variant={!disabled ? variant : undefined}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

const FeatureItem = ({ label }: { label: string }) => {
  return (
    <Text color="gray200" variant="headingS">
      <Stack direction="horizontal" gap={S.$1}>
        <Box css={{ width: 12 }}>
          <Check color={color.greenalpha} size={12} />
        </Box>{" "}
        {label}
      </Stack>
    </Text>
  );
};

export const PlanBox = ({
  plan,
  featuresHeader,
  disabled,
  upgradeButtonLabel,
  size,
}: PlanBoxProps) => {
  const {
    id,
    name,
    monthlyPrice,
    annualPrice,
    isFree,
    description,
    displayName,
    isCustom,
    features,
    seats,
    expenseLimit,
    isSignatureOffer,
  } = plan;

  // TODO isMostPopular to be implemented by the BE
  const isMostPopular = false;

  const { selectedCycle, setSelectedPlanId, hideUpgradeModal } = usePlansPricingModal();
  const price = selectedCycle === PuzzlePriceBillingInterval.Month ? monthlyPrice : annualPrice;
  const formatedPrice = formatMoney(
    {
      currency: price?.currency,
      amount: price?.amount ?? "0",
    },
    { truncateValue: true }
  );
  const isSpecial = isSignatureOffer || isMostPopular;
  const handleOnSelectPlan = useCallback(async () => {
    if (isCustom) {
      hideUpgradeModal();
    } else {
      setSelectedPlanId(id);
    }
  }, [hideUpgradeModal, id, isCustom, setSelectedPlanId]);

  const shouldShowYearlyDiscount = selectedCycle === PuzzlePriceBillingInterval.Year && !isFree;
  return (
    <Box
      css={{
        flexShrink: 1,
        flexBasis: size === "small" ? S["33"] : PLAN_BOX_LARGE_SIZE,
      }}
    >
      <Box
        className={specialHeaderBoxRecipe({
          background: isSpecial ? (isSignatureOffer ? "green" : "purple") : "transparent",
        })}
      >
        {isSignatureOffer && "SIGNATURE OFFERING"}
        {isMostPopular && "MOST POPULAR"}
      </Box>
      <Stack
        gap="0"
        className={tierContentStackRecipe({
          borderRadius: isSpecial ? "special" : "default",
          border: isSignatureOffer ? "green" : "default",
        })}
      >
        <Stack
          gap={S["1h"]}
          css={{
            padding: S["2h"],
            background: color.black20,
            minHeight: S["15"],
          }}
        >
          <Text data-testid={`title-tier-${name.toLowerCase()}`} className={planNameStyle}>
            {displayName}
          </Text>
          <Text variant="bodyXS" color="gray400">
            {description}
          </Text>
        </Stack>

        <Stack gap={S.$2} css={{ padding: S.$2h }}>
          {isCustom && (
            <Text variant="heading1" color="gray200" weight="bold">
              Custom
            </Text>
          )}
          {!isCustom && (
            <Stack
              gap={S.$0h}
              direction="horizontal"
              css={{
                alignItems: "center",
              }}
            >
              <Text variant="heading1" color="gray200" weight="bold">
                {formatedPrice}
              </Text>
              <Text variant="headingS" color="gray400">
                {getSubscriptionCycleLabel(selectedCycle)}
              </Text>
              {shouldShowYearlyDiscount && <YearlyDiscountBadge />}
            </Stack>
          )}
          <UpgradeButton
            isCustom={isCustom}
            onClick={handleOnSelectPlan}
            planName={name}
            disabled={disabled}
            variant={isFree ? "gold-outline" : "gold"}
            label={upgradeButtonLabel}
          />
          {expenseLimit && <FeatureItem label={expenseLimit} />}
          {seats && <FeatureItem label={seats} />}
          <Separator />
          {featuresHeader}
          {features.map((feature) => (
            <FeatureItem key={feature} label={feature} />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

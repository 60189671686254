import React from "react";

import { Box, color, S, Text, vars } from "ve";

export const YearlyDiscountBadge = () => {
  return (
    <Box
      css={{
        backgroundColor: color.greenBackground,
        borderRadius: vars.radii[4],
        padding: `${S.$0h} ${S.$1h}`,
      }}
    >
      <Text variant="bodyXS" color="green600">
        -15%
      </Text>
    </Box>
  );
};

export const BasicBadge = ({ text, css }: { text: string; css?: React.CSSProperties }) => {
  return (
    <Box
      css={{
        ...css,
        backgroundColor: color.black,
        borderRadius: vars.radii[4],
        padding: `${S.$0h} ${S.$1h}`,
        boxShadow: `0px -1px 0px 0px ${color.white10} inset`,
      }}
    >
      <Text variant="headingM" color="gray400">
        {text}
      </Text>
    </Box>
  );
};

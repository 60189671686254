import React, { useCallback } from "react";
import links from "lib/links";
import Link from "components/common/Link";
import { isPosthogFeatureFlagEnabled, FeatureFlag } from "lib/analytics";
import { Box, S, Stack, Text, vars } from "ve";
import { SubscriptionCycleSwitch } from "../PricingModal/SubscriptionCycleSwitch";
import { PackagingPlanV2, PlanBox } from "./PlanBox";
import useMonetization from "../useMonetization";
import { Plan } from "../types";
import { reportError } from "lib/errors";
import { useToasts } from "@puzzle/ui";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useRouter } from "next/router";
import { PuzzleBillingPlansV2 } from "./PuzzleBillingPlansV2";
import { contentContainer, modalTitleStyle } from "./pricingPackageV2Modal.css";

export const PlanSelectStep = ({ onClose }: { onClose: () => void }) => {
  const { toast } = useToasts();
  const router = useRouter();
  const { company } = useActiveCompany<true>();
  const { selectedCycle, createStripeCheckoutSession } = useMonetization();
  const allowYearlySubscription = isPosthogFeatureFlagEnabled(FeatureFlag.SubscriptionYearly);
  const onSubscribe = useCallback(
    async (plan: PackagingPlanV2) => {
      if (plan.isFree || plan.isCustom) {
        onClose();
        return;
      }
      const stripeCheckoutSession = await createStripeCheckoutSession({
        plan: {
          title: plan.name,
          priceId: plan.stripeMonthlyId,
          priceYearId: plan.stripeAnnualId,
        } as unknown as Plan,
        addons: undefined,
        bookkeeping: false,
        subscriptionCycle: selectedCycle,
      });

      if (stripeCheckoutSession === false) {
        reportError("No stripeIds to upgrade");
        toast({
          status: "warning",
          message: "Oops! It seems there are no changes to your current subscription.",
        });
        return;
      }

      const url = stripeCheckoutSession.data?.createStripeCheckoutSession.url;
      if (!url) {
        reportError("Could not generate Stripe checkout URL", {
          company: company.id,
          plan,
        });
        return;
      }

      await router.push(url);
    },
    [company.id, createStripeCheckoutSession, onClose, router, selectedCycle, toast]
  );

  return (
    <Stack className={contentContainer} gap={S.$5}>
      <Text className={modalTitleStyle}>The Accounting Solution For Every Stage</Text>
      {allowYearlySubscription && <SubscriptionCycleSwitch />}
      <Box
        style={{
          justifyContent: "center",
          display: "flex",
          flexWrap: "wrap",
          rowGap: 44,
        }}
      >
        {PuzzleBillingPlansV2.map((plan) => (
          <PlanBox key={plan.name} plan={plan} onSubscribe={() => onSubscribe(plan)} />
        ))}
      </Box>
      <Link
        css={{ display: "flex", padding: vars.space["2"], textDecoration: "none !important" }}
        href={links.pricingPage}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text color="green600" variant="headingS">
          Compare plans in detail
        </Text>
      </Link>
    </Stack>
  );
};

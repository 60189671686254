import React, { useEffect } from "react";
import { styled } from "@puzzle/ui";
import Analytics from "lib/analytics";
import { ReportsContainer } from "../Report";
import { IntroTour } from "./IntroTour";
import { ChartTile } from "./ChartTile";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useGetDashboardChartDataQuery } from "./ChartTile/graphql.generated";
import { StatCards } from "./StatCards";
import { StatCardsMinimal } from "./StatCardsMinimal";
import { CoaType } from "graphql/types";

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$2",
});

export const Dashboard = () => {
  const { company } = useActiveCompany<true>();

  const { data: dashboardChartData, loading: dashboardChartsLoading } =
    useGetDashboardChartDataQuery({
      variables: {
        companyId: company.id,
      },
      context: {
        batch: false,
      },
    });

  useEffect(() => {
    Analytics.dashboardViewed();
  }, []);

  const showSecondRowTiles = !!company?.features.metricCardsAdditional;
  const chartsLoading = !!dashboardChartsLoading && !dashboardChartData;
  const hasDecimalCoaType = company?.coaType === CoaType.Decimal; // is a Decimal company

  return (
    <IntroTour>
      <Wrapper>
        {hasDecimalCoaType ? (
          <StatCardsMinimal
            charts={dashboardChartData?.company?.charts || undefined}
            chartsLoading={chartsLoading}
          />
        ) : (
          <StatCards
            charts={dashboardChartData?.company?.charts || undefined}
            chartsLoading={chartsLoading}
          />
        )}

        {!showSecondRowTiles && (
          <ChartTile
            charts={dashboardChartData?.company?.charts || undefined}
            loading={chartsLoading}
          />
        )}
        <ReportsContainer />
      </Wrapper>
    </IntroTour>
  );
};

import { UniversalProps } from "components/common/utils/types";
import {
  AccountSubType,
  AccountType,
  AccountWithIntegrationFragment,
  IntegrationConnectionFragment,
  IntegrationType,
} from "graphql/types";

export enum IntegrationCategory {
  BanksAndCreditCards = "Banks and Credit Cards",
  Accounting = "Accounting",
  PayrollAndBenefits = "Payroll and Benefits",
  AccountsPayable = "Accounts Payable and Accounts Receivable",
  Equity = "Equity",
  Revenue = "Revenues",
  Other = "Other",
}

export type FinancialInstitutionGroup = {
  financialInstitutionName: string;
  accounts: AccountWithIntegrationFragment[];
  integrationConnections: IntegrationConnectionFragment[];
}

export const integrationsByCategory: Record<IntegrationCategory, IntegrationType[]> = Object.freeze(
  {
    [IntegrationCategory.BanksAndCreditCards]: [
      IntegrationType.Finicity,
      IntegrationType.Ramp,
      IntegrationType.Plaid,
      IntegrationType.Mercury,
      IntegrationType.Meow,
    ],
    [IntegrationCategory.Accounting]: [IntegrationType.QuickBooks],
    [IntegrationCategory.PayrollAndBenefits]: [
      IntegrationType.Gusto,
      IntegrationType.Rippling,
      IntegrationType.TriNet,
    ],
    [IntegrationCategory.Revenue]: [IntegrationType.Stripe, IntegrationType.Salesforce],
    [IntegrationCategory.AccountsPayable]: [IntegrationType.BillDotCom, IntegrationType.Expensify],
    [IntegrationCategory.Equity]: [IntegrationType.Captable, IntegrationType.Carta],
    [IntegrationCategory.Other]: [],
  }
);

export type IntegrationsPage = (props: React.PropsWithChildren<UniversalProps>) => React.ReactNode;

/**
 * Types for creating manual account integrations.
 */
export enum ManualAccountType {
  Checking = "Checking",
  Savings = "Savings",
  CreditCard = "Credit Card",
  Investment = "Investment",
  // Treasury, removed inclusion for now.
}

/**
 * Maps a manual account type its account type + subtype.
 */
export const ManualAccountTypesMap: Record<
  ManualAccountType,
  { type: AccountType; subType?: AccountSubType }
> = {
  Checking: {
    type: AccountType.Depository,
    subType: AccountSubType.Checking,
  },
  Savings: {
    type: AccountType.Depository,
    subType: AccountSubType.Savings,
  },
  "Credit Card": {
    type: AccountType.Credit,
    subType: AccountSubType.CreditCard,
  },
  Investment: {
    type: AccountType.Investment,
  },
};

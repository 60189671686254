import React, { useState } from "react";
import useMercury from "./useMercuryIntegration";
import { IntegrationLoading } from "../setup";
import { IntegrationConnectionCondition, IntegrationType } from "graphql/types";
import { SelectIngestDateModal } from "../setup/modals/IngestionDateModal/IngestDateModal";
import { usePollIntegration } from "../shared/usePollIntegration";

type ConnectMercuryPageProps = {
  companyId: string;
  code: string;
  state: string;
  isOnboarding: boolean;
};

const ConnectMercuryPage = ({ companyId, code, state, isOnboarding }: ConnectMercuryPageProps) => {
  const { connectMercury, connectionError, connection, loading } = useMercury({
    companyId,
  });
  const [isReconnect, setIsReconnect] = useState(false);
  const { financialInstitutions, accounts, startPolling } = usePollIntegration(
    IntegrationType.Mercury,
    !isOnboarding
  );
  const [openSelectDate, onOpenSelectDateChange] = useState(false);

  const connect = async () => {
    if (!code || !state) {
      return;
    }

    if (financialInstitutions.length !== 0) {
      setIsReconnect(true);
    }

    const result = await connectMercury({
      companyId,
      code,
      state,
      condition: isOnboarding ? undefined : IntegrationConnectionCondition.WaitingForUserEpoch,
    });

    if (!result || !result?.data || result.errors) {
      throw new Error("Error connecting mercury");
    }
    if (financialInstitutions.length === 0) {
      startPolling(1000);
    }
    onOpenSelectDateChange(true);
  };

  return (
    <>
      <IntegrationLoading
        integrationType={IntegrationType.Mercury}
        title="Connecting to Mercury"
        connectIntegration={connect}
        connection={connection}
        loading={loading}
        error={!!connectionError}
      />
      {!isOnboarding && (
        <SelectIngestDateModal
          open={openSelectDate}
          accounts={accounts}
          onOpenChange={onOpenSelectDateChange}
          isReconnect={isReconnect}
          connectionId={connection?.id}
          accountsLoading={accounts.length === 0 ?? false}
        />
      )}
    </>
  );
};

export default ConnectMercuryPage;

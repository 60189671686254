import React, { useMemo } from "react";
import { Drawer } from "@puzzle/ui";

import { DetailPane } from "./DetailPane";
import Loader from "../../common/Loader";
import { useFinancialInstitutions } from "../shared";

export const DetailDrawer = ({
  onClose,
  open,
  detailType,
  detailId,
  financialInstitutionId,
  ...props
}: {
  onClose: () => void;
  open: boolean;
  detailType: string;
  detailId: string;
  financialInstitutionId?: string;
}) => {
  const { integrationConnections, accounts, accountsLoading, integrationConnectionsLoading } =
    useFinancialInstitutions();
  const integrationInfo = useMemo(() => {
    if (!integrationConnectionsLoading && detailType === "connection") {
      const matchingIntegrationConnection = integrationConnections.find((ic) => ic.id === detailId);

      if (!matchingIntegrationConnection) {
        return onClose();
      }

      return {
        integrationType: matchingIntegrationConnection.type,
        connectionId: detailId,
      };
    }
    return { integrationType: undefined, connectionId: undefined };
  }, [detailType, detailId, integrationConnections, onClose, integrationConnectionsLoading]);

  const accountId = useMemo(() => {
    if (!accountsLoading && detailType === "account") {
      const matchingAccount = accounts.find((a) => a.id === detailId);

      if (!matchingAccount) {
        onClose();
        return;
      }

      return detailId;
    }
  }, [detailType, detailId, accounts, accountsLoading, onClose]);

  return (
    <Drawer
      open={open}
      onOpenChange={(open) => !open && onClose()}
      {...props}
      css={{ overflowY: "auto", paddingBottom: "$12" }}
    >
      {integrationConnectionsLoading || accountsLoading ? (
        <Loader />
      ) : (
        <DetailPane
          integrationType={integrationInfo?.integrationType}
          integrationConnectionId={integrationInfo?.connectionId}
          integrationConnections={integrationConnections}
          financialInstitutionId={financialInstitutionId}
          accountId={accountId}
          onClose={onClose}
        />
      )}
    </Drawer>
  );
};

import React, { useCallback, useMemo, useState } from "react";
import { AccountDetailsProps } from "./types";
import { Help, useToasts } from "@puzzle/ui";

import { useFinancialInstitutions } from "../shared";
import { getIntegrationTypeForAccount } from "../ListItem/ListItemUtils";
import RemoveAccountEpochConfirmationModal from "./RemoveAccountEpochConfirmationModal";
import { useSetAccountEpochMutation } from "graphql/types";
import { getDisplayNameForIntegrationType, isPushIntegationType } from "../setup/utils";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { CalendarDateString, parseDate } from "@puzzle/utils";
import { SelectIngestDateModal } from "../setup/modals/IngestionDateModal/IngestDateModal";
import { Text } from "ve/Text";
import { editIngestStartContainerStyle } from "./integrationDetails.css";
import { Stack } from "ve/Stack";
import { S } from "ve";
import Link from "components/common/Link";
import { IngestRangeForAccount } from "../setup/modals/IngestionDateModal/IngestRangeForAccount";
import { shouldEnableCutoverV2 } from "../setup/modals/IngestionDateModal/ingestionDateUtils";
import useSelf from "components/users/useSelf";

const getIntegrationHelpText = (integrationDisplayName: string, hasEpoch: boolean) => {
  if (hasEpoch) {
    return `Puzzle started ingesting data from ${integrationDisplayName} for this account on the specified date`;
  }
  return `Puzzle is ingesting all available historical data associated with this account. This may result in duplicates if data has already been manually entered or ingested by other integrations. To avoid this, specify an ingestion start date to exclude data prior to that date`;
};

const AccountEpochsSection = ({ account }: AccountDetailsProps) => {
  const { refetchAccountsAndIntegrations } = useFinancialInstitutions();
  const { company, membershipRole } = useActiveCompany<true>();
  const { toast } = useToasts();
  const [isRemoving, setIsRemoving] = useState(false);
  const [showSetEpochModal, setShowSetEpochModal] = useState(false);
  const [setAccountEpochMutation, { loading }] = useSetAccountEpochMutation();
  const { isPuzzlenaut } = useSelf();

  const isCutoverV2Enabled = shouldEnableCutoverV2(company?.coaType, isPuzzlenaut);
  const dateFormatter = useCompanyDateFormatter({
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const integrationType = getIntegrationTypeForAccount(account);
  const integrationDisplayName = getDisplayNameForIntegrationType(integrationType);

  const accountEpochs = useMemo(
    () => account.epochs.filter((epoch) => epoch.cutoffBefore || epoch.cutoffAtOrAfter),
    [account.epochs]
  );

  const canRemoveEpoch = useMemo(() => {
    // TODO: CORE-4308 should change so that only admins with membershipRole bookkeeper or admin can clear epochs
    // Currently restricted to puzzle admins and will need change on GW
    // const isAdmin = membershipRole === MembershipRole.Admin || membershipRole === MembershipRole.Bookkeeper;
    // if (!isAdmin) {
    //   return false;
    // }

    if (company.startIngestionDate) {
      //Admin has set a start ingestion date, only they can remove epochs
      return false;
    }
    if (account.epochs.length === 1 && !account.epochs[0].shouldCorrectHistory) {
      return true;
    }

    return false;
  }, [account.epochs, company.startIngestionDate]);

  const clearEpoch = useCallback(async () => {
    const accountId = account.id;

    setIsRemoving(true);
    const result = await setAccountEpochMutation({
      variables: { input: { accountId, cutoffBefore: null, shouldUpdateHistory: true } },
    });

    if (result?.errors) {
      toast({ message: "Failed to remove ingestion start date.", status: "error" });
    } else {
      toast({ message: "Successfully removed ingestion start date.", status: "success" });
    }

    setIsRemoving(false);
    refetchAccountsAndIntegrations();
  }, [refetchAccountsAndIntegrations, account, toast, setAccountEpochMutation]);

  const labels = accountEpochs.map((epoch) => {
    if (epoch.cutoffBefore && !epoch.cutoffAtOrAfter) {
      return `Prior to ${dateFormatter.format(
        parseDate(epoch.cutoffBefore as CalendarDateString)
      )}`;
    }

    if (!epoch.cutoffBefore && epoch.cutoffAtOrAfter) {
      return `On or after ${dateFormatter.format(
        parseDate(epoch.cutoffAtOrAfter as CalendarDateString)
      )}`;
    }

    if (epoch.cutoffBefore && epoch.cutoffAtOrAfter) {
      return `Between ${dateFormatter.format(
        parseDate(epoch.cutoffBefore as CalendarDateString)
      )} and ${dateFormatter.format(parseDate(epoch.cutoffAtOrAfter as CalendarDateString))}`;
    }
  });

  const setEpochSuccessToastMessageOverride = isPushIntegationType(integrationType)
    ? "Ingestion start date has been set"
    : undefined;

  if (account.manuallyAdded) {
    return null;
  }

  const getStartIngestDateText = () => {
    if (
      account.epoch?.cutoffBefore &&
      company.startIngestionDate &&
      account.epoch?.cutoffBefore === company.startIngestionDate
    ) {
      return `${dateFormatter.format(
        parseDate(company.startIngestionDate)
      )} (Puzzle ledger start date)`;
    }

    if (account.epoch?.cutoffBefore) {
      return `${dateFormatter.format(parseDate(account.epoch.cutoffBefore))}`;
    }

    return "Not set (Ingest all historical transactions)";
  };

  return (
    <>
      {!isCutoverV2Enabled && (
        <>
          <Text
            variant="bodyXXS"
            color="white50"
            weight="bold"
            style={{ textTransform: "uppercase", marginBottom: S["$1"] }}
          >
            Ingestion Start Date
          </Text>
          <Stack className={editIngestStartContainerStyle}>
            <Stack gap={S["$0h"]}>
              <Stack direction="horizontal" css={{ gap: S["$1"], alignItems: "center" }}>
                <Text>{getStartIngestDateText()}</Text>
                <Help
                  content={getIntegrationHelpText(
                    integrationDisplayName,
                    !!account.epoch?.cutoffBefore
                  )}
                />
              </Stack>
              {canRemoveEpoch && (
                <Link onClick={() => setIsRemoving(true)} color="negative" underline>
                  Remove
                </Link>
              )}
            </Stack>
          </Stack>
        </>
      )}
      {isCutoverV2Enabled && (
        <IngestRangeForAccount account={account} onEditRange={() => setShowSetEpochModal(true)} />
      )}

      <RemoveAccountEpochConfirmationModal
        integrationType={integrationDisplayName}
        epochLabel={labels[0] ?? ""}
        onConfirm={clearEpoch}
        open={isRemoving}
        onOpenChange={setIsRemoving}
        loading={loading}
      />

      <SelectIngestDateModal
        open={showSetEpochModal}
        accounts={[account]}
        connectionId={account.connection?.id}
        onOpenChange={setShowSetEpochModal}
        isReconnect={false}
        accountsLoading={!account}
        successToastMessageOverride={setEpochSuccessToastMessageOverride}
      />
    </>
  );
};

export default AccountEpochsSection;

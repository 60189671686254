import React, { useState, useMemo, useCallback } from "react";
import * as yup from "yup";
import { Controller, UseFormReturn, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button, Dialog, useToasts, Input, Field, styled } from "@puzzle/ui";

import {
  AccountType,
  CreateManualAccountError,
  PayrollSortOrder,
  useCreateManualAccountMutation,
} from "graphql/types";
import { reportError } from "lib/errors";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

import { PayrollFormValues } from "./UpsertPayrollDrawer";
import { PayrollsDocument, PayrollsQuery } from "./graphql.generated";

const schema = yup.object({
  institutionName: yup.string().required(),
  accountName: yup.string().required(),
});

type FormValues = {
  institutionName: string;
  accountName: string;
};

const FormField = styled("div", {
  marginBottom: "$2",

  "&:last-of-type": {
    marginBottom: 0,
  },
});

export const AddPayrollAccountModal = ({
  open: _open,
  onOpenChange: _onOpenChange,
  payrollForm,
  ...props
}: React.ComponentProps<typeof Dialog> & {
  payrollForm: UseFormReturn<PayrollFormValues>;
}) => {
  const [createManualAccount, { loading }] = useCreateManualAccountMutation();
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();
  const [internalOpen, setInternalOpen] = useState(_open);
  const open = _open ?? internalOpen;
  const onOpenChange = _onOpenChange ?? setInternalOpen;
  const form = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      institutionName: "",
      accountName: "Unknown",
    },
  });

  const showErrorToast = useCallback(() => {
    toast({ message: "Failed to create the account. Please try again.", status: "error" });
  }, [toast]);

  const handleCreate = useMemo(() => {
    return form.handleSubmit(async ({ institutionName, accountName }) => {
      return await createManualAccount({
        variables: {
          input: {
            companyId: company.id,
            institutionName: institutionName,
            type: AccountType.Payroll,
            name: accountName,
            balance: "0",
            mask: "0000",
          },
        },
        update(cache, { data }) {
          const account = data?.createManualAccount.account;

          const variables = {
            companyId: company.id,
            sortBy: PayrollSortOrder.CheckDateOrderDesc,
          };

          const cachedData = cache.readQuery<PayrollsQuery>({
            query: PayrollsDocument,
            variables,
          });

          if (!cachedData || !cachedData.company || !account) return;

          const newlyAddedAccount = {
            __typename: "PayrollProvider",
            institutionId: account.financialInstitution.id,
            institutionName: account.financialInstitution.name,
            accountId: account.id,
          } as const;

          cache.writeQuery<PayrollsQuery>({
            variables,
            query: PayrollsDocument,
            data: {
              ...cachedData,
              company: {
                ...cachedData.company,
                payrollProviders: [...cachedData.company.payrollProviders, newlyAddedAccount],
              },
            },
          });
        },
        onCompleted: ({ createManualAccount }) => {
          const accountId = createManualAccount.account?.id;

          if (createManualAccount.error === CreateManualAccountError.DuplicateAccount) {
            toast({ message: "Account already exists", status: "error" });
            return;
          }

          if (!accountId) {
            showErrorToast();
            return;
          }

          toast({ message: "Account created", status: "success" });
          onOpenChange(false);
          payrollForm.setValue("payrollAccountId", accountId);
        },
        onError: (error) => {
          reportError(error);
          showErrorToast();
        },
      });
    });
  }, [form, company.id, createManualAccount, toast, onOpenChange, payrollForm, showErrorToast]);

  return (
    <Dialog
      {...props}
      size="xsmall"
      open={open}
      onOpenChange={(open) => {
        onOpenChange(open);
      }}
    >
      <form>
        <Dialog.Title>Add a manual payroll account</Dialog.Title>
        <Dialog.Body>
          <div>
            <FormField>
              <Field label="Payroll provider">
                <Controller
                  control={form.control}
                  name="institutionName"
                  render={({ field }) => {
                    return <Input size="small" {...field} />;
                  }}
                />
              </Field>
            </FormField>

            <FormField>
              <Field label="Account name">
                <Controller
                  control={form.control}
                  name="accountName"
                  render={({ field }) => {
                    return <Input size="small" {...field} />;
                  }}
                />
              </Field>
            </FormField>
          </div>
        </Dialog.Body>
        <Dialog.Footer align="right">
          <Dialog.Actions>
            <Dialog.Close asChild>
              <Button variant="minimal">Cancel</Button>
            </Dialog.Close>
            <Button
              disabled={!form.formState.isValid}
              loading={loading}
              onClick={handleCreate}
              type="submit"
            >
              Create
            </Button>
          </Dialog.Actions>
        </Dialog.Footer>
      </form>
    </Dialog>
  );
};

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useMonthlySpendingQuery } from "components/companies/graphql.generated";
import { useMemo } from "react";
import { getAveragePercentage } from "./util";
import { IS_LOCAL_DEVELOPMENT } from "lib/config";

const SPENDING_BILLING_POLLING_INTERVAL = IS_LOCAL_DEVELOPMENT ? 0 : 1000 * 300; // Every 5 minutes.

type useBillingProps = {
  loading: boolean;
  monthlySpendingAverage: string | null | undefined;
  progress: number;
  exceeding: boolean;
};

export const useBilling = (): useBillingProps => {
  const { company } = useActiveCompany<true>();
  const { data, loading } = useMonthlySpendingQuery({
    pollInterval: SPENDING_BILLING_POLLING_INTERVAL,
    fetchPolicy: "cache-and-network",
    variables: { input: { companyId: company.id } },
  });

  const averagePercentage = useMemo(
    () => getAveragePercentage(Number(data?.monthlySpending.threeMonthAverage)),
    [data?.monthlySpending]
  );

  return {
    loading,
    monthlySpendingAverage: data?.monthlySpending?.threeMonthAverage,
    progress: averagePercentage.progress,
    exceeding: averagePercentage.exceeding,
  };
};

import React, { useMemo, useState } from "react";
import { CalendarDate } from "@internationalized/date";
import { styled, Tooltip } from "@puzzle/ui";
import { CalendarWarning, LockTransaction } from "@puzzle/icons";
import { toCalendarMonthString } from "@puzzle/utils";
import { LockDayWarning } from "components/dashboard/Accounting/LockedPeriods/LockedPeriod";
import { WarningDialog } from "components/dashboard/Accounting/LockedPeriods/WarningDialog";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";

const IconContainer = styled("a", { marginTop: "$0h" });

// This could've just used a partial Transaction fragment as a prop?
export const LockedPeriodStatus = ({
  transactionDate,
  posted,
  transactionId,
  onClick,
  onClose,
}: {
  transactionDate: CalendarDate;
  posted: boolean;
  transactionId: string;
  onClick?: React.MouseEventHandler;
  onClose?: () => void;
}) => {
  const [openWarning, setOpenWarning] = useState(false);
  const { isWithinLockedPeriod, lockedPeriodDate } = useActiveCompany<true>();
  const inLockedPeriod = useMemo(
    () => isWithinLockedPeriod(transactionDate),
    [isWithinLockedPeriod, transactionDate]
  );

  const dateFormatter = useCompanyDateFormatter({
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  if (!lockedPeriodDate || !inLockedPeriod) {
    return null;
  }

  //we don't have a way of tracking a company's previously unlocked period
  //commenting this section until we figure out how to track it
  /*
  if (!inLockedPeriod && transactionPostedAt === null) {
    <>
      <Tooltip content="This date may need adjusting. Click to review">
        <IconContainer
          onClick={(e) => {
            e.stopPropagation();
            setOpenWarning(true);
          }}
        >
          <CalendarWarning />
        </IconContainer>
      </Tooltip>
      <WarningDialog
        companyId={companyId}
        period={(lockedPeriod)}
        warnings={[LockDayWarning.TransactionWarning]}
        open={openWarning}
        onOpenChange={setOpenWarning}
        transactionId={transactionId}
      />
    </>;
  } else {
    return null;
  }
  */

  if (posted) {
    return (
      <Tooltip
        content={`You cannot take some actions on transactions posted on or before ${dateFormatter.format(
          lockedPeriodDate
        )}. Your company's admin can update the locked period under Accounting.`}
      >
        <a>
          <LockTransaction />
        </a>
      </Tooltip>
    );
  } else {
    return (
      <>
        <Tooltip content="This date may need adjusting. Click to review">
          <IconContainer
            onClick={(e) => {
              e.stopPropagation();
              setOpenWarning(true);
            }}
          >
            <CalendarWarning />
          </IconContainer>
        </Tooltip>
        <WarningDialog
          period={toCalendarMonthString(lockedPeriodDate)}
          warnings={[LockDayWarning.TransactionWarning]}
          open={openWarning}
          onOpenChange={setOpenWarning}
          transactionId={transactionId}
          transactionDate={transactionDate}
          onClose={onClose}
        />
      </>
    );
  }
};

import React from "react";
import IntegrationLogo from "../shared/IntegrationLogo";
import { BrandIcon, BrandName, BRAND_BACKGROUND_COLORS } from "@puzzle/icons";

const LOGO_SIZE = "48px";

const Logo = () => {
  return (
    <IntegrationLogo color={BRAND_BACKGROUND_COLORS[BrandName.Salesforce]}>
      <BrandIcon name={BrandName.Salesforce} width={LOGO_SIZE} height={LOGO_SIZE} />
    </IntegrationLogo>
  );
};

export default Logo;

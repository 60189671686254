import React, { useMemo, useCallback, useState, useEffect } from "react";
import { compact, partition } from "lodash";
import { Dialog, Button, Checkbox, styled, useToasts } from "@puzzle/ui";
import {
  formatAccountName,
  formatMoney,
  parseDate,
  pluralize,
  toCalendarDate,
  zIndex,
} from "@puzzle/utils";
import { reportError } from "lib/errors";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { AccountCell } from "components/transactions/Cells/AccountCell";
import { useTransactionsPage } from "./TransactionsProvider";
import { BasicTransactionFragment, useDeleteTransactionsMutation } from "./graphql.generated";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { Box, S, Text, vars } from "ve";
import { RowSelectionType } from "./TransactionsMRT/TransactionsPaginated";

const OptionList = styled("ul", {
  height: "100%",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "$2h",
  margin: "$0",
  padding: "$0 $1 $0 $0",
  "&::-webkit-scrollbar-thumb": {
    background: "#3A3A52",
    border: "0.5px solid rgba(255, 255, 255, 0.16)",
    boxShadow: "0px 8px 24px $colors$blackA20, 0px 4px 8px $colors$blackA10",
  },
});

const OptionRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  lineHeight: "$headingM",
});

const Header = styled(Text, {
  fontSize: "$bodyM",
  color: "$gray300",
  fontWeight: "$headingM",
  marginLeft: "$0",
});

type DeleteModalProps = {
  transactions: BasicTransactionFragment[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  selectAll?: boolean;
  toggleRowSelection: (rows: BasicTransactionFragment[]) => void;
  rowSelection: RowSelectionType;
};

const TransactionRow = ({
  row,
  eligible,
  showHeader,
  showGroupTitle,
  toggleRowSelection,
  rowSelection,
}: {
  row: BasicTransactionFragment;
  eligible: boolean;
  showHeader?: boolean;
  showGroupTitle?: boolean;
  toggleRowSelection: (rows: BasicTransactionFragment[]) => void;
  rowSelection: RowSelectionType;
}) => {
  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <Box
      css={{
        display: "flex",
        flexDirection: "row",
        paddingLeft: showHeader ? S["2"] : showGroupTitle ? S["1"] : 0,
      }}
    >
      <Checkbox
        checked={rowSelection[row.id] || false}
        disabled={!eligible}
        css={{ marginTop: "2px" }}
        onClick={() => {
          toggleRowSelection([row]);
        }}
      />
      <Box
        css={{
          display: "flex",
          flexDirection: "column",
          gap: S["1"],
          marginLeft: S["1"],
          width: "100%",
        }}
        key={row.id}
      >
        <OptionRow css={{ color: eligible ? "$gray100" : "$gray500" }}>
          <Text variant="bodyXS">{row.descriptor}</Text>
          <Text variant="bodyXS">{formatMoney({ currency: "USD", amount: row.amount })}</Text>
        </OptionRow>
        <OptionRow css={{ color: eligible ? "$gray300" : "$gray500" }}>
          <OptionRow css={{ gap: "$1" }}>
            <AccountCell account={row.account}></AccountCell>
            <Text variant="bodyXS">{formatAccountName(row.account)}</Text>
          </OptionRow>

          <Text variant="bodyXS">{dateFormatter.format(toCalendarDate(parseDate(row.date)))}</Text>
        </OptionRow>
      </Box>
    </Box>
  );
};

const TransactionList = ({
  transactions,
  totalCount,
  eligible,
  label,
  toggleRowSelection,
  rowSelection,
}: {
  transactions: BasicTransactionFragment[];
  totalCount: number;
  eligible: boolean;
  label: string;
  toggleRowSelection: (rows: BasicTransactionFragment[]) => void;
  rowSelection: RowSelectionType;
}) => {
  const showHeader = transactions.length !== totalCount && transactions.length !== 0;
  return (
    <>
      {showHeader && (
        <Header
          css={{ paddingBottom: eligible ? "$0h" : "$0", paddingTop: eligible ? "$0" : "$1h" }}
        >
          {label}
        </Header>
      )}
      {/* TODO memoize */}
      {eligible &&
        transactions.map((row) => {
          return (
            <TransactionRow
              row={row}
              eligible={eligible}
              key={row.detail.transactionId}
              showHeader={showHeader}
              toggleRowSelection={toggleRowSelection}
              rowSelection={rowSelection}
            />
          );
        })}
    </>
  );
};

const TransactionBody = ({
  totalAmount,
  totalCount,
  eligible,
  ineligible,
  toggleRowSelection,
  rowSelection,
}: {
  totalAmount: number;
  totalCount: number;
  eligible: BasicTransactionFragment[];
  ineligible: BasicTransactionFragment[];
  toggleRowSelection: (rows: BasicTransactionFragment[]) => void;
  rowSelection: RowSelectionType;
}) => {
  return (
    <>
      {eligible.length > 0 && (
        <>
          <p>
            {`You are about to remove transactions totaling
              ${formatMoney({ currency: "USD", amount: totalAmount })}. 
              These transactions will no longer appear on your General Ledger or any of your
              reports. Removing transactions cannot be undone.`}
          </p>
          Please review the following transactions scheduled for removal:
        </>
      )}
      <Box
        css={{
          border: "1px solid #44445C",
          borderRadius: vars.radii[1],
          height: "220px",
          marginTop: S["1"],
          marginLeft: 0,
          padding: `${S["2"]} ${S["1"]} ${S["2"]} ${S["2"]}`,
        }}
      >
        <OptionList>
          <TransactionList
            transactions={eligible}
            totalCount={totalCount}
            eligible={true}
            label="Eligible for removal"
            toggleRowSelection={toggleRowSelection}
            rowSelection={rowSelection}
          />
          <TransactionList
            transactions={ineligible}
            totalCount={totalCount}
            eligible={false}
            label="Ineligible for removal"
            toggleRowSelection={toggleRowSelection}
            rowSelection={rowSelection}
          />
        </OptionList>
      </Box>
    </>
  );
};

export const DeleteTransactionsModalPaginated = ({
  transactions,
  open,
  onOpenChange,
  selectAll,
  toggleRowSelection,
  rowSelection,
}: DeleteModalProps) => {
  const [deleteTransactionsMutation] = useDeleteTransactionsMutation();
  const { refetch, companyId } = useTransactionsPage();
  const { toast } = useToasts();
  const [warning, setWarning] = useState(false);

  const [eligibleTransactions, ineligible] = useMemo(
    () => partition(transactions, (t) => t.detail.deletable),
    [transactions]
  );

  const [eligibleList, setList] = useState<BasicTransactionFragment[]>([]);

  useEffect(
    () => {
      if (open && transactions) {
        return setList(eligibleTransactions);
      }
      return setList([]);
    },
    // This doesn't work correctly with transactions/eligible
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, onOpenChange]
  );

  const totalAmount = useMemo(
    () => eligibleTransactions.reduce((prev, row) => prev + Number(row.amount), 0),
    [eligibleTransactions]
  );
  const totalCount = useMemo(
    () => eligibleList.length + ineligible.length,
    [eligibleList, ineligible]
  );

  const handleClose = useCallback(() => {
    onOpenChange(false);
    setWarning(false);
  }, [onOpenChange]);

  const handleDelete = useCallback(
    async (e: React.MouseEvent) => {
      const input = {
        companyId,
        transactionIds: compact(eligibleTransactions.map((t) => t.detail.transactionId)),
        unposted: false,
      };

      await deleteTransactionsMutation({
        variables: { input },
        update: (cache) => {
          const cacheIds = eligibleTransactions.map((t) => {
            return cache.identify({ id: t.detail.transactionId, __typename: "Transaction" });
          });
          cacheIds.forEach((id) => cache.evict({ id }));
          cache.gc();
          if (!selectAll) {
            toggleRowSelection([...eligibleTransactions, ...ineligible]);
          }
        },
        onCompleted: () => {
          handleClose();
          toast({
            message: `${pluralize(eligibleTransactions.length, "transaction")} removed`,
          });
          refetch();
          Analytics.transactionsRemoved({
            transactionIds: compact(eligibleTransactions.map((t) => t.detail.transactionId)),
          });
        },
        onError: (e) => {
          reportError(e);
          setWarning(true);
        },
      });
    },
    [
      companyId,
      eligibleTransactions,
      deleteTransactionsMutation,
      selectAll,
      ineligible,
      handleClose,
      toast,
      refetch,
    ]
  );

  return (
    <Dialog
      style={{ zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("modal") : "auto" }}
      open={open}
      onOpenChange={(open) => {
        onOpenChange(open);
        setWarning(false);
      }}
      size="xsmall"
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          handleClose();
        }
      }}
    >
      <Dialog.Title>
        {eligibleTransactions.length > 0
          ? eligibleTransactions.length === transactions.length
            ? `Remove ${pluralize(transactions.length, "transaction")}`
            : `Remove eligible ${pluralize(eligibleTransactions.length, "transaction")}`
          : "Unable to remove transactions"}
      </Dialog.Title>

      <Dialog.Body css={{ paddingTop: "$0h", fontSize: "$bodyS" }}>
        <TransactionBody
          totalAmount={totalAmount}
          totalCount={totalCount}
          eligible={eligibleList}
          ineligible={ineligible}
          toggleRowSelection={toggleRowSelection}
          rowSelection={rowSelection}
        />
      </Dialog.Body>

      <Dialog.Footer
        css={{
          paddingTop: "$0",
        }}
      >
        <Box css={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          {eligibleTransactions.length > 0 ? (
            <Button variant="negative" css={{ width: "432px" }} onClick={handleDelete}>
              Permanently remove
            </Button>
          ) : (
            <Button variant="secondary" css={{ width: "432px" }} onClick={handleClose}>
              Close
            </Button>
          )}
          {warning && (
            <Text variant="bodyXS" color="red500" style={{ paddingTop: S["0h"] }}>
              Unable to remove transactions. Please try again
            </Text>
          )}
        </Box>
      </Dialog.Footer>
    </Dialog>
  );
};

export default DeleteTransactionsModalPaginated;

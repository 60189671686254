import React, { memo, useMemo, useState } from "react";
import Head from "next/head";
import dynamic from "next/dynamic";

import { styled, useBreakpoint, Tag, Text, Button } from "@puzzle/ui";

import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

import { ErrorBoundary } from "lib/errors";

import { SidebarV2 } from "../SidebarV2/SidebarV2";
import Breadcrumbs from "../Breadcrumbs";
import GlobalBanner from "../GlobalBanner";
import { useUser } from "@auth0/nextjs-auth0";
import Link from "components/common/Link";
import { useRouter } from "next/router";
import HighTransactionVolumeBanner from "../HighTransactionVolumeBanner";
import { ExplorePuzzleDialog } from "components/dashboard/Dashboard/PostOnboardModal/ExplorePuzzleDialog";
import { TrialExpiredModal } from "components/monetization/TrialExpiredModal";
import { TrialExpiredModalV2 } from "components/monetization/TrialExpiredModalV2";
import { ShortcutsModal } from "components/common/ShortcutsModal";
import { CmdK } from "components/common/cmdk";
import { MoreAboutYouModal } from "components/common/MoreAboutYouModal/MoreAboutYouModal";
import { HeaderButtons } from "components/layout/private/topbar/HeaderButtons";
import { Hotkeys } from "components/common/Hotkeys";
import { ShortcutActionsProvider } from "components/common/ShortcutActionsProvider";
import {
  MinimizeSidebarButton,
  useNavSidebarState,
} from "components/layout/private/topbar/MinimizeSidebarButton";
import { EducationMenu, EducationMenuProps } from "../topbar/EducationMenu";
import { MonetizationModal } from "components/monetization/MonetizationModal";
import { useLaunchpadStore } from "components/launchpad/launchpadStore";

const LaunchpadOverlay = dynamic(() => import("components/launchpad/LaunchpadOverlay"));

const Layout = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
});

const Container = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  boxSizing: "border-box",
  maxHeight: "100vh",
  width: "100%",
  minWidth: 0,
});

const Content = styled("div", {
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  padding: "$3 $4 $6 $5",
  overflow: "auto",

  defaultVariants: {
    minimal: false,
  },

  variants: {
    minimal: {
      true: {
        padding: 0,
      },
    },
    false: {},
  },
});

const Header = styled("div", {
  width: "100%",
  maxHeight: "60px",
  display: "flex",
  padding: "$2",
  paddingLeft: "$5",
  alignItems: "center",
  "@media print": {
    display: "none",
  },

  defaultVariants: {
    bordered: true,
  },

  variants: {
    bordered: {
      true: {
        boxShadow: "0px 0.5px 0px #4A405E",
      },
      false: {},
    },
  },
});

const Title = styled("div", {
  margin: "0",
  marginRight: "$4",
  lineHeight: "28px",
  color: "$gray100",
  fontSize: "$headingL",
  fontWeight: "$bold",
  display: "flex",
  gap: "$1",
});

const Description = styled("div", {
  margin: "0",
  marginTop: "$0h",
  fontSize: "14px",
  lineHeight: "20px",
  color: "$gray400",
});

const DemoBannerBox = styled("div", {
  position: "fixed",
  backgroundColor: "$mauve300",
  alignItems: "center",
  justifyContent: "space-between",
  top: "0",
  left: "0",
  right: "0",
  display: "flex",
  padding: "$1h $1h $1h $2h",
  zIndex: 1,
});

const DemoBanner = memo(() => {
  const router = useRouter();
  return (
    <DemoBannerBox>
      <Text>
        DEMO: Welcome to the Puzzle Demo.{" "}
        <Link underline href="https://puzzle.io/founders">
          Learn more about Puzzle.
        </Link>
      </Text>
      <Button onClick={() => router.push("https://app.puzzle.io/signup")} size="compact">
        Sign up for free
      </Button>
    </DemoBannerBox>
  );
});

const MainContent = ({
  custom,
  title,
  description,
  beta,
  education,
  minimal,
  showEducationMenu,
  banner,
  children,
}: {
  custom?: boolean;
  title?: string;
  description?: string;
  beta?: string;
  education?: EducationMenuProps;
  minimal: boolean;
  showEducationMenu: boolean;
  banner: React.ReactNode;
  children: React.ReactNode;
}) => {
  if (custom) {
    return (
      <>
        {banner}
        {children}
      </>
    );
  }

  return (
    <>
      {banner}

      {title && (
        <Header
          // wait, when is this off?
          bordered
        >
          <Title>
            <MinimizeSidebarButton />

            <Text style={{ display: "flex", alignItems: "center" }}>{title}</Text>

            {description && <Description>{description}</Description>}
            {showEducationMenu && education?.feature && <EducationMenu {...education} />}
            {beta && (
              <Tag variant="outlined" color="vivid">
                {beta}
              </Tag>
            )}
          </Title>
          <HeaderButtons hangRight />
        </Header>
      )}

      <Content minimal={minimal}>{children}</Content>
    </>
  );
};

// Why is this neccessary?
DemoBanner.displayName = "DemoBanner";

const PrivateLayout = ({
  children,
  description,
  custom,
  title,
  documentTitle = title,
  beta,
  minimal = false,
  education,
}: React.PropsWithChildren<{
  custom?: boolean;
  title?: string;
  documentTitle?: string;
  description?: string;
  beta?: string;
  minimal?: boolean;
  education?: EducationMenuProps;
}>) => {
  const { user } = useUser();
  const breakpoint = useBreakpoint();
  const isPermanentSidebar = breakpoint !== "xs";
  const [explorePuzzleDialogOpen, setExplorePuzzleDialogOpen] = useState(false);
  const { isLaunchpadOverlayVisible } = useLaunchpadStore();
  const showEducationMenu = isPosthogFeatureFlagEnabled(FeatureFlag.EducationBanners) && education;

  const { isNavMinimized: _minimized, setIsNavMinimized: setMinimized } = useNavSidebarState();

  const isDemoEnvironment = useMemo(() => {
    if (user && user.email)
      return Boolean(user["https://app.puzzle.io"] && user.email.split("@")[1] === "puzzledemo.io");
    return false;
  }, [user]);

  const minimized = isPermanentSidebar && _minimized;

  // Nest hamburger menu inside global banner to position it under the banners
  const banner = <GlobalBanner minimized={minimized} />;

  return (
    <div>
      {isDemoEnvironment && <DemoBanner />}
      <Layout css={{ marginTop: isDemoEnvironment ? "56px" : "unset" }}>
        <Head>{documentTitle && <title>Puzzle - {documentTitle}</title>}</Head>
        {useMemo(
          () => (
            <nav>
              <SidebarV2
                minimized={_minimized}
                onToggleMinimized={() => setMinimized(!minimized)}
                setExplorePuzzleDialogOpen={setExplorePuzzleDialogOpen}
              />
            </nav>
          ),
          [minimized, setMinimized]
        )}

        <ErrorBoundary>
          {isPosthogFeatureFlagEnabled(FeatureFlag.TrialExpiredModalV2) ? (
            <TrialExpiredModalV2 />
          ) : (
            <TrialExpiredModal />
          )}
          <ShortcutActionsProvider>
            <ShortcutsModal />
            <Hotkeys />
            <CmdK />
          </ShortcutActionsProvider>
          <MonetizationModal />
          <MoreAboutYouModal />
          <Container>
            <HighTransactionVolumeBanner />
            <ExplorePuzzleDialog
              open={explorePuzzleDialogOpen}
              onOpenChange={(open) => {
                if (!open) {
                  setExplorePuzzleDialogOpen(false);
                }
              }}
              onClose={() => setExplorePuzzleDialogOpen(false)}
            />

            {isLaunchpadOverlayVisible ? (
              <LaunchpadOverlay />
            ) : (
              <MainContent
                custom={custom}
                title={title}
                description={description}
                beta={beta}
                education={education}
                minimal={minimal}
                showEducationMenu={!!showEducationMenu}
                banner={banner}
              >
                {children}
              </MainContent>
            )}
          </Container>
        </ErrorBoundary>
      </Layout>
    </div>
  );
};

export default PrivateLayout;

PrivateLayout.Header = Header;
PrivateLayout.Title = Title;
PrivateLayout.Content = Content;
PrivateLayout.Breadcrumbs = Breadcrumbs;

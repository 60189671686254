import { parseDate } from "@puzzle/utils";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import React from "react";
import { IconButton } from "ve/Button";
import { Stack } from "ve/Stack";
import {
  ingestRangeDescriptionStyle,
  ingestRangeLabelStyle,
  singleAccountStartStyle,
} from "./ingestionDateModal.css";
import { S } from "ve";
import { INGEST_END_DATE_ALL_TIME_LABEL, IntegrationAccountFragment } from "./ingestionDateUtils";
import { Edit } from "@puzzle/icons";
import { IntegrationConnectionCondition } from "graphql/types";

const LABEL_WIDTH = "150px";
const UNSET_DATE_TEXT = "Please choose";
type IngestRangeForAccountProps = {
  account: IntegrationAccountFragment;
  onEditRange: (account: IntegrationAccountFragment) => void;
};

export const IngestRangeForAccount = ({ account, onEditRange }: IngestRangeForAccountProps) => {
  const { company, isEditor } = useActiveCompany<true>();
  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  const waitingForEpoch =
    account.connection?.condition === IntegrationConnectionCondition.WaitingForUserEpoch;

  const getIngestEndDateText = () => {
    if (!account.epoch || waitingForEpoch) return UNSET_DATE_TEXT;

    return account.epoch.cutoffAtOrAfter
      ? dateFormatter.format(parseDate(account.epoch.cutoffAtOrAfter))
      : INGEST_END_DATE_ALL_TIME_LABEL;
  };

  const getIngestStartDateText = () => {
    if (!account.epoch || waitingForEpoch) return UNSET_DATE_TEXT;
    if (
      account.epoch?.cutoffBefore &&
      company.startIngestionDate &&
      account.epoch?.cutoffBefore === company.startIngestionDate
    ) {
      return `${dateFormatter.format(
        parseDate(company.startIngestionDate)
      )} (Puzzle ledger start date)`;
    }

    if (account.epoch?.cutoffBefore) {
      return `${dateFormatter.format(parseDate(account.epoch.cutoffBefore))}`;
    }

    return UNSET_DATE_TEXT;
  };

  return (
    <>
      <Stack
        direction="horizontal"
        style={{ alignItems: "center", margin: 0 }}
        className={singleAccountStartStyle}
      >
        <dl
          style={{
            display: "grid",
            gridTemplateColumns: `${LABEL_WIDTH} auto`,
            gap: S["1"],
            margin: 0,
          }}
        >
          <dt className={ingestRangeLabelStyle}>Ingestion start date</dt>
          <dd className={ingestRangeDescriptionStyle}>{getIngestStartDateText()}</dd>

          <dt className={ingestRangeLabelStyle}>Ingestion end date</dt>
          <dd className={ingestRangeDescriptionStyle}>{getIngestEndDateText()}</dd>
        </dl>

        {isEditor && (
          <IconButton
            onClick={() => onEditRange(account)}
            size="compact"
            style={{ marginLeft: "auto" }}
          >
            <span className="sr-only">Edit Ingest Range</span>
            <Edit />
          </IconButton>
        )}
      </Stack>
    </>
  );
};

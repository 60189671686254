/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import { DateRangePicker, Toolbar } from "@puzzle/ui";
import { useTransactionsPage } from "./TransactionsProvider";
import ExtendedFilter from "./ExtendedFilter";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { zIndex } from "@puzzle/utils";
import { DateRangePickerVariant } from "@pz/ui/dates/DateRangePicker";
import { topRowFilters, topRowFiltersMain } from "./TransactionsMRT/topToolbar.css";

const SearchBar = () => {
  const { filter, setFilter } = useTransactionsPage();

  return useMemo(
    () => (
      <Toolbar.Search
        placeholder="Search"
        value={filter.descriptor}
        onChange={(descriptor) => setFilter({ descriptor })}
      />
    ),
    [filter.descriptor, setFilter]
  );
};

const FilterToolbar = React.memo(() => {
  const { filter, setFilter, rangePresets, totals, loading } = useTransactionsPage();
  const { company } = useActiveCompany();

  const dateFilter = useMemo(
    () => (
      <DateRangePicker
        value={[filter.start, filter.end]}
        presets={rangePresets}
        preset={filter.rangePreset ?? undefined}
        onChange={([start, end], rangePreset) => {
          setFilter({ start, end, rangePreset });
        }}
        customLabelFormat="M/d/yy"
        variant={DateRangePickerVariant.Minimal}
        showInputs
        popoverCss={{
          zIndex: zIndex("menu"),
        }}
      />
    ),
    [filter.end, filter.rangePreset, filter.start, rangePresets, setFilter]
  );

  return (
    <Toolbar className={topRowFilters}>
      <div className={topRowFiltersMain}>
        {dateFilter}
        <SearchBar />
        <ExtendedFilter
          hasAICategorizationFeatureEnabled={company?.features.assistedCategorizationEnabled}
        />
      </div>
      <Toolbar.ToggleButton
        pressed={filter.showHighPriorityReview === true}
        onPressedChange={(pressed) =>
          setFilter({
            showHighPriorityReview: pressed ? true : null,
            ledgerCoaKeys: pressed ? filter.ledgerCoaKeys : [],
            minAmount: pressed ? filter.minAmount : null,
          })
        }
        css={{
          borderColor: "$purple700",
          boxShadow: !filter.showHighPriorityReview
            ? "0 0 10px 5px rgba(128, 0, 128, 0.2)"
            : "none",
        }}
      >
        High Priority Review
      </Toolbar.ToggleButton>
    </Toolbar>
  );
});

export default FilterToolbar;

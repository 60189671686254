import {
  AllInOneService,
  BookkeepingService,
  RDTaxCreditsService,
  TaxFillingService,
} from "@puzzle/icons";
import { Stack, Tag, Text } from "@puzzle/ui";
import React from "react";
import { Box, vars } from "ve";

type AccountingService = {
  icon: React.ReactNode;
  title: string;
  titleTag?: React.ReactNode;
  subtitle: string;
  subtitleHelp?: string;
  description: string | React.ReactNode;
}

const SERVICES: AccountingService[] = [
  {
    icon: <AllInOneService />,
    title: "All-in-one",
    titleTag: (
      <Tag variant="outlined" color="vivid" size="small">
        Best value
      </Tag>
    ),
    subtitle: "Starting from $599 / month",
    description:
      "Gain peace of mind knowing all your accounting needs are covered with a full service bookkeeping, tax preparation, and R&D tax credits package from a trusted partner.",
  },
  {
    icon: <BookkeepingService />,
    title: "Bookkeeping",
    subtitle: "Starting at $199 / month",
    description: (
      <>
        Get paired with a trusted bookkeeper from our network of partners who specialize in{" "}
        <Text color="gray200">cash and accrual</Text> bookkeeping for startups.
      </>
    ),
  },
  {
    icon: <TaxFillingService />,
    title: "Tax Filling",
    subtitle: "Starting at $1499 / year",
    description:
      "Get paired with specialized tax professionals and gain peace of mind knowing your tax and compliance needs are met.",
  },
  {
    icon: <RDTaxCreditsService />,
    title: "R&D Tax Credits",
    subtitle: "0.75% of QRE",
    description:
      "Our partner takes care of the R&D tax credit and tax filing process for you. You only need to answer a few questions and they will file the necessary paperwork.",
  },
];

export const AdditionalServices = () => {
  return (
    <Stack css={{ gap: "$2h" }}>
      {SERVICES.map((service, index) => (
        <Stack
          key={`service-${index}-${service.title}`}
          css={{
            borderRadius: "$2",
            border: "1px solid $white100",
            background: "rgba(255, 255, 255, 0.04)",
            flexDirection: "row",
          }}
        >
          <Box
            css={{
              flex: "1 0 280px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: vars.radii[2],
              borderBottomLeftRadius: vars.radii[2],
              background: "rgba(26, 25, 42, 0.80)",
            }}
          >
            {service.icon}
          </Box>
          <Stack css={{ padding: "$2h", gap: "$1" }}>
            <Text
              css={{ display: "flex", gap: "$1", alignItems: "center" }}
              variant="headingM"
              color="gray200"
            >
              {service.title} {service.titleTag}
            </Text>
            <Text variant="bodyM" color="gray200">
              {service.subtitle}
            </Text>
            <Text variant="bodyM">{service.description}</Text>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

import React, { ReactNode } from "react";
import { Button, SiteStatusMessage, SiteStatusMessageProps, styled } from "@puzzle/ui";
import { Arrow } from "@puzzle/icons";
import useSelf from "components/users/useSelf";
import { ExpensesExceededStatusMessage } from "components/featureGate/ExpensesExceededStatusMessage";
import { Route } from "lib/routes";
import { useSendEmailVerificationCodeMutation } from "graphql/types";
import useAppRouter from "lib/useAppRouter";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useSiteStatusMessagesQuery } from "graphql/queries/siteStatusMessages.generated";
import { useUpdate } from "react-use";
import { dismissSiteStatusMessage, isSiteStatusMessageDismissed } from "lib/cookies";

export const DRAWER_WIDTH = 280;
export const MINIMIZED_DRAWER_WIDTH = 72;

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",

  variants: {
    minimized: {
      true: {
        left: MINIMIZED_DRAWER_WIDTH,
      },
      false: {
        left: DRAWER_WIDTH,
      },
    },
  },

  defaultVariants: {
    minimized: false,
  },

  "@media print": {
    display: "none",
  },
});

const VerificationBanner = styled("div", {
  textVariant: "$bodyM",
  fontFamily: "$inter",
  fontSize: 12,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  px: "$2",
  py: "$1",
  width: "inherit",
  maxWidth: "inherit",
  height: 40,
  color: "#1e54b7",
  backgroundColor: "#eff3fe",
});

const BannerButton = styled(Button, {
  "&&": {
    borderColor: "#1E54B7 !important",
    color: "#467CDF",
    "&:hover, &:focus": {
      color: "#5A90F3",
    },
  },
});

const Markdown = (props: React.ComponentProps<typeof ReactMarkdown>) => {
  return (
    <ReactMarkdown
      linkTarget="_blank"
      remarkPlugins={[remarkGfm]}
      // only allow links, paragraphs, italics and bold for now
      disallowedElements={[
        "blockquote",
        "br",
        "code",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "hr",
        "img",
        "li",
        "ol",
        "pre",
        "ul",
        "del",
        "input",
        "table",
        "tbody",
        "td",
        "th",
        "thead",
        "tr",
      ]}
      {...props}
    />
  );
};

type GlobalBannerProps = {
  children?: ReactNode;
  minimized: boolean;
}

const GlobalBanner = ({ children, minimized }: GlobalBannerProps) => {
  const { goToEmailVerification } = useAppRouter();
  const forceUpdate = useUpdate();

  const { self } = useSelf();
  const [sendEmailVerificationCode] = useSendEmailVerificationCodeMutation();
  const showEmailVerificationMessage = !self?.emailVerified;

  const { data: siteStatusMessagesData } = useSiteStatusMessagesQuery();
  const showSiteStatusMessages =
    siteStatusMessagesData && siteStatusMessagesData.siteStatusMessages.length > 0;

  if (!showEmailVerificationMessage && !showSiteStatusMessages) {
    return <>{children}</>;
  }

  return (
    <Wrapper minimized={minimized}>
      <ExpensesExceededStatusMessage />

      {/* Email verification banner */}
      {showEmailVerificationMessage && (
        <VerificationBanner>
          Verify your email and secure your account
          <BannerButton
            variant="secondary"
            size="mini"
            suffix={<Arrow fill="#5A90F3" />}
            href={Route.emailVerification}
            onClick={() => {
              sendEmailVerificationCode();
              goToEmailVerification();
            }}
          >
            Verify
          </BannerButton>
        </VerificationBanner>
      )}

      {/* Manually set / Site wide messages and announcements */}
      {siteStatusMessagesData?.siteStatusMessages.map((message) => {
        const dismissed = isSiteStatusMessageDismissed(message.id);
        if (dismissed) {
          return null;
        }

        return (
          <SiteStatusMessage
            key={message.id}
            type={message.type.toLowerCase() as unknown as SiteStatusMessageProps["type"]}
            onClose={() => {
              dismissSiteStatusMessage(message.id);
              forceUpdate();
            }}
          >
            <Markdown>{message.message}</Markdown>
          </SiteStatusMessage>
        );
      })}

      <div>{children}</div>
    </Wrapper>
  );
};

export default GlobalBanner;

import React from "react";
import { IntegrationLogo } from "../shared";
import { Mercury } from "@puzzle/icons";

const MercuryLogo = ({ color, circular }: { color?: string; circular?: boolean }) => {
  return (
    <IntegrationLogo color={color ?? "#5466F9"} circular={circular}>
      <Mercury />
    </IntegrationLogo>
  );
};

export default MercuryLogo;

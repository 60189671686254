import { useEffect, useCallback, useState, useMemo } from "react";
import { SubmitHandler, UseFormReturn, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToasts } from "@puzzle/ui";

import { FixedAssetDepreciationMethod, FixedAssetType } from "graphql/types";
import { CalendarDateString } from "scalars";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import Analytics from "lib/analytics";

import {
  GetFixedAssetsDocument,
  PagedFixedAssetV2Fragment,
  useDeleteFixedAssetMutation,
  useInServiceFixedAssetMutation,
  useUpdateFixedAssetMutation,
} from "../graphql.generated";

type FormValues = {
  id: string;
  description: string;
  type?: FixedAssetType;
  inServiceAt?: CalendarDateString;
  capitalizedAt?: CalendarDateString;
  usefulLifeMonths?: string;
  originalValue?: string;
  salvageValue?: string;
  depreciationMethod?: FixedAssetDepreciationMethod;
};

export type PendingAssetForm = UseFormReturn<FormValues> & {
  onSubmitAsset: (assetId: string) => Promise<void>;
  onDeleteAsset: (id: string) => Promise<void>;
  submittingAssetId?: string;
};

export const assetSchema = yup.object({
  description: yup.string().required().min(1),
  type: yup.string().required().oneOf(Object.values(FixedAssetType)),
  inServiceAt: yup.string().required(),
  capitalizedAt: yup.string().required(),
  usefulLifeMonths: yup.string().required(),
  originalValue: yup.string(),
  salvageValue: yup.string(),
  depreciationMethod: yup.string().oneOf(Object.values(FixedAssetDepreciationMethod)),
});

/**
 * Syncs existing pending assets into form state.
 */
const useExistingAssetSync = ({
  form,
  asset,
}: {
  form: UseFormReturn<FormValues>;
  asset?: PagedFixedAssetV2Fragment;
}) => {
  useEffect(() => {
    if (!asset) {
      return;
    }

    form.reset({
      id: asset.id,
      description: asset.description ?? "",
      type: asset.type ?? undefined,
      inServiceAt: asset.inServiceAt ?? undefined,
      capitalizedAt: asset.capitalizedAt ?? undefined,
      salvageValue: asset.salvageValue ?? undefined,
      originalValue: asset.originalValue ?? undefined,
      depreciationMethod: asset.depreciationMethod ?? undefined,
      usefulLifeMonths: asset.usefulLifeMonths
        ? (asset.usefulLifeMonths / 12).toString()
        : undefined,
    });

    // Revalidate
    form.trigger();
  }, [form, asset]);
};

export function usePendingAssetForm({
  asset,
  onCompleted,
}: {
  asset?: PagedFixedAssetV2Fragment;
  onCompleted?: (open: boolean) => void;
}) {
  const form = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(assetSchema),

    defaultValues: {},
  });

  useExistingAssetSync({ form, asset });

  const { toast } = useToasts();
  const [submittingAssetId, setSubmittingAssetId] = useState<string>();
  const [updateAsset] = useUpdateFixedAssetMutation();
  const { company } = useActiveCompany<true>();
  const [inServiceAsset] = useInServiceFixedAssetMutation({
    refetchQueries: [GetFixedAssetsDocument],
  });
  const [deleteFixedAsset] = useDeleteFixedAssetMutation({
    refetchQueries: [GetFixedAssetsDocument],
  });

  const onSubmitAsset = useCallback(() => {
    const handle: SubmitHandler<FormValues> = async (formData) => {
      if (!formData.id) return;

      setSubmittingAssetId(formData.id);

      await updateAsset({
        variables: {
          input: {
            ...formData,
            usefulLifeMonths: parseInt(formData.usefulLifeMonths ?? "0") * 12,
            currency: "USD",
          },
        },
        onCompleted: async ({ updateFixedAsset }) => {
          Analytics.fixedAssetUpdated({ fixedAssetId: updateFixedAsset.id });

          await inServiceAsset({
            variables: { id: formData.id },
            onError: ({ message }) => {
              toast({
                status: "error",
                message: "Unable to place fixed asset in service.",
              });
              Analytics.fixedAssetPlacedInServiceFailed({
                fixedAssetId: formData.id,
                errorMessage: message,
              });
              setSubmittingAssetId(undefined);
            },
            onCompleted: ({ inServiceFixedAsset }) => {
              toast({
                status: "success",
                message: "Fixed asset successfully placed in service.",
              });
              Analytics.fixedAssetPlacedInService({
                fixedAssetId: inServiceFixedAsset.id,
              });
              onCompleted?.(false);
              setSubmittingAssetId(undefined);
            },
          });
        },
        onError: ({ message }) => {
          toast({ status: "error", message: "Unable to update fixed asset." });
          Analytics.fixedAssetUpdateFailed({
            fixedAssetId: formData.id,
            errorMessage: message,
          });
          setSubmittingAssetId(undefined);
        },
      });
    };

    return form.handleSubmit((data) => handle(data))();
  }, [form, updateAsset, inServiceAsset, toast, onCompleted]);

  const onDeleteAsset = useCallback(
    async (id: string) => {
      setSubmittingAssetId(id);

      await deleteFixedAsset({
        variables: {
          input: {
            id,
            companyId: company.id,
          },
        },
        onError: () => {
          toast({ status: "error", message: "Unable to delete fixed asset." });
          setSubmittingAssetId(undefined);
        },
        onCompleted: () => {
          toast({ status: "success", message: "Fixed asset successfully deleted." });
          Analytics.fixedAssetDeleted({ fixedAssetId: id });
          onCompleted?.(false);
          setSubmittingAssetId(undefined);
        },
      });
    },
    [deleteFixedAsset, toast, company.id, onCompleted]
  );

  return useMemo(
    () => ({ ...form, onSubmitAsset, onDeleteAsset, submittingAssetId }),
    [form, onSubmitAsset, submittingAssetId, onDeleteAsset]
  );
}

import React from "react";
import useQuickbooks from "./useQuickbooks";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import Logo from "./Logo";
import { InfoModal } from "./modals";
import { IntegrationDisplayComponent } from "../shared/types";

type QuickbooksListItemProps = {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}

export const TITLE = "QuickBooks Online";
export const SUBTITLE =
  "Import financials from QuickBooks Online. Puzzle imports QuickBooks reports only as a reference.";

const QuickbooksListItem = ({ DisplayComponent, companyId }: QuickbooksListItemProps) => {
  const { onClickConnect, connection, loading, openInfoModal, setOpenInfoModal } = useQuickbooks({
    companyId,
  });
  return (
    <>
      <DisplayComponent
        integrationType={IntegrationType.QuickBooks}
        connectIntegration={() => {
          if (connection?.status) {
            onClickConnect();
          } else {
            setOpenInfoModal(true);
          }
        }}
        connection={connection}
        title={TITLE}
        logo={<Logo />}
        loading={loading}
      />
      <InfoModal
        open={openInfoModal}
        onContinue={onClickConnect}
        onCancel={() => setOpenInfoModal(false)}
      />
    </>
  );
};

export default QuickbooksListItem;

import React from "react";
import { Box, Button, Stack, Text } from "ve";
import { stepChooserButtonStyle, stepChooserStyle, stepNumberStyle } from "./plansPricingModal.css";
import { usePlansPricingModal } from "./usePlansPricingModal";

const STEPS = ["Choose your plan", "Review plan & services"];
const BACKGROUND_GRADIENT =
  "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 33.85%, rgba(0, 0, 0, 0.20) 100%), rgba(150, 109, 213, 0.40)";

// Just to keep it simple, this could evolve into a new type
// that reflects a lot new steps.
type StepChooserProps = {
  step: 1 | 2;
};

export const StepChooser = ({ step }: StepChooserProps) => {
  const { setSelectedPlanId } = usePlansPricingModal();
  const handleStepClick = async (newStep: number) => {
    if (step === newStep || newStep === 2) return;
    setSelectedPlanId(null);
  };

  return (
    <Stack gap="0" direction="horizontal" className={stepChooserStyle}>
      {STEPS.map((stepTitle, i) => {
        const isActive = i + 1 === step;
        return (
          <Button
            onClick={() => handleStepClick(i + 1)}
            className={stepChooserButtonStyle}
            css={{
              opacity: isActive ? "1" : "0.4",
              background: isActive ? BACKGROUND_GRADIENT : "transparent",
            }}
            key={`payment-step-${i}`}
            shape="pill"
          >
            <Text variant="bodyXS">
              <Stack direction="horizontal" gap="0" css={{ alignItems: "center" }}>
                <Box className={stepNumberStyle}>{i + 1}</Box>
                {stepTitle}
              </Stack>
            </Text>
          </Button>
        );
      })}
    </Stack>
  );
};

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { PuzzlePlanFragment } from "components/companies/graphql.generated";
import {
  AdditionalServices,
  MembershipRole,
  PuzzleAddon,
  PuzzlePriceBillingInterval,
} from "graphql/types";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { IS_PROD } from "lib/config";
import { queryTypes, useQueryStates } from "next-usequerystate";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useRequestAdditionalServicesMutation } from "../graphql.generated";
import { getAddonsArrayFromQueryParam, sendNewSubscriptionEvent } from "./utils";
import links from "lib/links";

export enum MonetizationUrlKey {
  SHOW_UPGRADE_MODAL = "showUpgradeModal",
  SELECTED_PLAN = "selectedPlan",
  BILING_CYCLE = "billingCycle",
  PRE_SELECTED_ADD_ONS = "preSelectedAddOns",
  BOOKEEPING = "bookkeeping",
}

export type UsePlanPricingModalResult = {
  selectedPlan?: PuzzlePlanFragment;
  availablePlans: PuzzlePlanFragment[];
  selectedPlanId?: string | null;
  isUpgradeModalOpen: boolean;
  preSelectedAddOnIds: string[];
  preSelectedAddOns?: PuzzleAddon[];
  selectedCycle: PuzzlePriceBillingInterval;
  hideUpgradeModal: () => void;
  setSelectedCycle: (newSelectedCycle: PuzzlePriceBillingInterval) => void;
  setSelectedPlanId: (planId: string | null) => Promise<void>;
  toggleAddOn: (addOnId: string) => Promise<void>;
  allowYearlySubscription?: boolean;
  isbookkeepingEnabled: boolean;
  setIsBookkeepingEnabled: (isEnabled: boolean) => void;
  showUpgradeModal: (isAddingAddonToCurrentSubscription?: boolean) => void;
  isOnboardingCallVisible: boolean;
  hideOnboardingCallModal: () => void;
};

export const usePlansPricingModal = (): UsePlanPricingModalResult => {
  const router = useRouter();
  const { company, membershipRole } = useActiveCompany<true>();
  const [isOnboardingCallVisible, setIsOnboardingCallVisible] = useState(false);
  const puzzleSubscriptionState = company.puzzleSubscriptionState;
  const allowYearlySubscription = isPosthogFeatureFlagEnabled(FeatureFlag.SubscriptionYearly);
  const [_requestAdditionalServices] = useRequestAdditionalServicesMutation();

  const [queryState, setQueryState] = useQueryStates({
    [MonetizationUrlKey.SHOW_UPGRADE_MODAL]: queryTypes.boolean.withDefault(false),
    [MonetizationUrlKey.SELECTED_PLAN]: queryTypes.string,
    [MonetizationUrlKey.BOOKEEPING]: queryTypes.boolean.withDefault(false),
    [MonetizationUrlKey.BILING_CYCLE]: queryTypes
      .stringEnum(Object.values(PuzzlePriceBillingInterval))
      .withDefault(PuzzlePriceBillingInterval.Month),
    [MonetizationUrlKey.PRE_SELECTED_ADD_ONS]: queryTypes
      .array(queryTypes.string, ".")
      .withDefault([]),
  });

  const selectedPlanId = queryState[MonetizationUrlKey.SELECTED_PLAN];
  const isUpgradeModalOpen = queryState[MonetizationUrlKey.SHOW_UPGRADE_MODAL];
  const _selectedCycle = queryState[MonetizationUrlKey.BILING_CYCLE];
  const preSelectedAddOnIds = queryState[MonetizationUrlKey.PRE_SELECTED_ADD_ONS];
  const isbookkeepingEnabled = queryState[MonetizationUrlKey.BOOKEEPING];

  const effectiveSelectedCycle = useMemo(() => {
    return allowYearlySubscription
      ? _selectedCycle || PuzzlePriceBillingInterval.Year
      : PuzzlePriceBillingInterval.Month;
  }, [allowYearlySubscription, _selectedCycle]);

  const availablePlans: PuzzlePlanFragment[] = useMemo((): PuzzlePlanFragment[] => {
    const allPlans = [
      ...(puzzleSubscriptionState?.upgrades ?? []),
      ...(puzzleSubscriptionState?.downgrades ?? []),
    ];

    if (puzzleSubscriptionState?.billedPlan?.plan) {
      allPlans.push(puzzleSubscriptionState.billedPlan.plan);
    }

    return allPlans.sort((planA, planB) => planA.tier - planB.tier);
  }, [
    puzzleSubscriptionState?.upgrades,
    puzzleSubscriptionState?.downgrades,
    puzzleSubscriptionState?.billedPlan,
  ]);

  const selectedPlan = useMemo(() => {
    return availablePlans.find((plan) => plan.id === selectedPlanId);
  }, [availablePlans, selectedPlanId]);

  const preSelectedAddOns = useMemo(() => {
    return selectedPlan?.availableAddons.filter((addOn) => preSelectedAddOnIds.includes(addOn.id));
  }, [selectedPlan?.availableAddons, preSelectedAddOnIds]);

  const setSelectedPlanId = async (planId: string | null) => {
    await setQueryState({
      [MonetizationUrlKey.SELECTED_PLAN]: planId,
      [MonetizationUrlKey.PRE_SELECTED_ADD_ONS]: [],
    });
  };

  const setSelectedCycle = (newSelectedCycle: PuzzlePriceBillingInterval) => {
    setQueryState({
      [MonetizationUrlKey.BILING_CYCLE]: newSelectedCycle,
    });
  };

  const showUpgradeModal = (isAddingAddonToCurrentSubscription?: boolean) => {
    if (membershipRole === MembershipRole.Admin) {
      const billedPlan = company.puzzleSubscriptionState.billedPlan;
      if (!isAddingAddonToCurrentSubscription && billedPlan && !billedPlan?.plan.isFree) {
        router.push(links.stripeCustomerPortal);
        return;
      }
      setQueryState({
        [MonetizationUrlKey.SHOW_UPGRADE_MODAL]: true,
      });
    }
  };

  const hideUpgradeModal = () => {
    setQueryState({
      [MonetizationUrlKey.SHOW_UPGRADE_MODAL]: null,
      [MonetizationUrlKey.SELECTED_PLAN]: null,
      [MonetizationUrlKey.BILING_CYCLE]: null,
      [MonetizationUrlKey.PRE_SELECTED_ADD_ONS]: null,
      [MonetizationUrlKey.BOOKEEPING]: null,
    });
  };

  const toggleAddOn = async (addOnId: string) => {
    const oldAddOns = [...preSelectedAddOnIds];
    const newAddons = oldAddOns.includes(addOnId)
      ? oldAddOns.filter((a) => a !== addOnId)
      : [...oldAddOns, addOnId];
    await setQueryState({
      [MonetizationUrlKey.PRE_SELECTED_ADD_ONS]: newAddons,
    });
  };

  const setIsBookkeepingEnabled = (isEnabled: boolean) =>
    setQueryState({
      [MonetizationUrlKey.BOOKEEPING]: isEnabled,
    });

  const hideOnboardingCallModal = () => {
    setIsOnboardingCallVisible(false);
    router.replace(router.pathname, undefined, { shallow: true });
  };

  useEffect(() => {
    /**
     * If paymentSuccess query param is present and its true and plan its present
     * find what plan it its and show a toast for the user.
     */
    if (router.query.paymentSuccess && router.query.paymentSuccess === "true") {
      const selectedPlan = router.query.plan;
      const selectedPlanStripeId = router.query.planId;
      const addonsSelected = getAddonsArrayFromQueryParam(router.query.addOns);

      /**
       * If bookkeeping is requested, then call requestAdditionalServices
       * that will trigger a new inbox task to complete a survey
       */
      if (router.query.bookkeeping && router.query.bookkeeping === "true") {
        _requestAdditionalServices({
          variables: {
            input: { companyId: company.id, services: [AdditionalServices.Bookkeeping] },
          },
        });
      }

      if (selectedPlan || addonsSelected.length > 0) {
        setIsOnboardingCallVisible(true);
      }

      // Notify Google of the subscription for ad targeting purposes
      if (IS_PROD && selectedPlanStripeId) {
        sendNewSubscriptionEvent(selectedPlanStripeId);
      }
    }
  }, [
    _requestAdditionalServices,
    company.id,
    router.query.addOns,
    router.query.addon,
    router.query.bookkeeping,
    router.query.cycle,
    router.query.paymentSuccess,
    router.query.plan,
    router.query.planId,
  ]);

  return {
    selectedPlan,
    availablePlans,
    selectedPlanId,
    //Preventing show the upgrade modal if the url param is set but the user is not an admin
    isUpgradeModalOpen: isUpgradeModalOpen && membershipRole === MembershipRole.Admin,
    preSelectedAddOnIds,
    preSelectedAddOns,
    selectedCycle: effectiveSelectedCycle,
    hideUpgradeModal,
    setSelectedCycle,
    setSelectedPlanId,
    toggleAddOn,
    allowYearlySubscription,
    isbookkeepingEnabled,
    setIsBookkeepingEnabled,
    showUpgradeModal,
    isOnboardingCallVisible,
    hideOnboardingCallModal,
  };
};

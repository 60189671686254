import React from "react";
import Icon, { IconProps } from "./Icon";

export default function SparkleOutlined({
  className,
  rotate,
  size = 20,
  viewBox = "0 0 20 20",
  ...props
}: IconProps) {
  return (
    <Icon className={className} rotate={rotate} viewBox={viewBox} size={size} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3337 9.99999C12.5466 9.99999 10.0003 12.5463 10.0003 18.3333C10.0003 12.5463 7.45403 9.99999 1.66699 9.99999C7.45403 9.99999 10.0003 7.45369 10.0003 1.66666C10.0003 7.45369 12.5466 9.99999 18.3337 9.99999Z"
        stroke="url(#paint0_linear_165_17233)"
        strokeWidth="1.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_165_17233"
          x1="10.0003"
          y1="1.66666"
          x2="10.0003"
          y2="18.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DD4EB9" />
          <stop offset="1" stopColor="#B99AEB" />
        </linearGradient>
      </defs>
    </Icon>
  );
}

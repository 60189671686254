import React from "react";

type BoxProps = {
  children?: React.ReactNode;
  css?: React.CSSProperties;
} & React.HTMLProps<HTMLDivElement>

export const Box = ({ css, children, ...props }: BoxProps) => {
  return (
    <div style={css} {...props}>
      {children}
    </div>
  );
};

import { parseDate } from "@internationalized/date";
import { PuzzleSubscriptionStateFragment } from "components/companies/graphql.generated";
import { differenceInDays } from "date-fns";
import { IS_PROD } from "lib/config";

const TOTAL_YEAR_DAYS = 365;

export const getTrialInfoDate = (
  subscriptionState: PuzzleSubscriptionStateFragment,
  timeZone: string
) => {
  const { activePlan, billedPlan } = subscriptionState;
  const isOnPaidPlan = billedPlan && !billedPlan?.plan.isFree;
  const isOnFreeTrial = activePlan?.plan.isTrial;

  if (!isOnFreeTrial || isOnPaidPlan || !activePlan?.startDate || !activePlan?.endDate) {
    return null;
  }

  const daysLeftOnFreeTrial = differenceInDays(
    parseDate(activePlan?.endDate).toDate(timeZone),
    parseDate(activePlan?.startDate).toDate(timeZone)
  );

  // If the end date on the premium free trial plan is null (sometimes it is),
  // or if we are in production and the premium free trial plan has an end date of
  // more than one year, we should hide this panel. (For Puzzle companies,
  // we are setting the Premium Free Trial end date to 10 years after the company's
  // creation date)
  if (IS_PROD && daysLeftOnFreeTrial > TOTAL_YEAR_DAYS) {
    return null;
  }

  return daysLeftOnFreeTrial;
};

/* eslint-disable react/display-name */
import React, { useCallback, useMemo, useRef } from "react";
import { Column, Row, TableDispatch } from "react-table";
import { styled, colors } from "@puzzle/ui";
import { formatMoney, formatNumber, parseDate } from "@puzzle/utils";
import { uniq } from "lodash";
import { mix } from "polished";
import { useUpdateEffect } from "react-use";

import Analytics from "lib/analytics";
import { DynamicReportType, LedgerReportColumnBy, LedgerReportLineType } from "graphql/types";

import { DataTable } from "components/common/DataTable";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import ToggleGroup from "components/common/ToggleGroup";
import { useStickyReportContext } from "components/reports/StickyReportContext";
import { MonthlyExpensesExceededBanner } from "components/featureGate/MonthlyExpensesExceededBanner";
import { ExpensesExceededFeatureGate } from "components/featureGate/ExpensesExceededFeatureGate";

import { EnhancedLedgerReportLine, DetailLevel } from "./types";
import { UseDynamicReportResult } from "./useLedgerReport";
import {
  FooterValueCell,
  BodyValueCell,
  TitleFooter,
  TitleCell,
  CellValueProps,
  FooterValueProps,
} from "./Cells";
import { useDelta } from "./Filters/DeltaProvider";
import { formatTitleForNode } from "./util";
import ReportColHeader from "./ReportColHeader";
import { Box, S } from "ve";
import { useChartOfAccounts } from "../Accounting/shared/useChartOfAccounts";

const TableGutter = styled("div", {
  width: "fit-content",
  padding: "0 0 $4",

  "> table > tbody > .spacer-row": {
    display: "table-row",

    td: {
      paddingTop: "$2",
    },

    "&:last-child td": {
      padding: "0",
    },
  },
});

const TableStyles = styled("div", {
  overflowX: "auto",
  overflowY: "hidden",
  position: "relative",
  zIndex: "0",
  fontSize: "12px",

  ".MuiCollapse-root": {
    // position: sticky doesn't place nicely with overflow: hidden
    overflow: "visible",
    /* safari*/
    WebkitClipPath: "inset(0)",
    clipPath: "inset(0)",
    /* Edge */
    clip: "rect(0px, auto, auto, 0px)",
  },

  [`&& ${DataTable}`]: {
    td: {
      color: "$gray400",
      fontSize: "13px",
      letterSpacing: "normal",
      fontWeight: "$bold",
      lineHeight: "14px",
      padding: "0",
    },

    th: {
      color: "$gray400",
      paddingBottom: "$2",
      "&:nth-child(2)": {
        overflow: "visible",
      },
    },

    "td, th": {
      "&:first-child": {
        paddingLeft: "$6h",
        letterSpacing: "0.2px",
      },
    },

    "&&": {
      "tr, tr:hover": {
        borderBottom: "none",

        "td, th": {
          backgroundColor: "#1b1c29",
        },
      },

      "table[data-is-leaf='true']": {
        "td, th": {
          backgroundColor: "#191A27",
        },
      },

      'tfoot tr, tbody tr[role="row"]': {
        "&:hover td": {
          backgroundColor: mix(0.12, colors.gray50, "#1b1c29"),
        },
      },

      "a, button": {
        fontWeight: "$bold",

        "@media print": {
          fontSize: "$bodyL",
        },
      },
    },
  },

  variants: {
    isInProgress: {
      true: {
        // TODO this isn't right at all
        // Need some refactoring in order to add the divider...
        // "th, td": {
        //   "&:nth-last-child(2) > *": {
        //     borderLeft: "1px solid #2F2F39",
        //   },
        // },
      },
      false: {},
    },
  },
});

const ReportLevelContainer = styled("div", {
  paddingLeft: "$3",
  paddingTop: "$3",
  "@media print": {
    [`${ToggleGroup.Root}, ${ToggleGroup.Item}`]: {
      display: "none",
    },
  },
});

const ReportLevel = React.memo(() => {
  const levels: `${DetailLevel}`[] = ["Compact", "Summary", "Detailed"];
  const { stickyOptions, setStickyOptions } = useStickyReportContext();

  return (
    <ReportLevelContainer>
      <ToggleGroup.Root
        type="single"
        data-testid="ReportDetailLevel"
        onValueChange={(value) => value && setStickyOptions({ detailLevel: value as DetailLevel })}
        value={stickyOptions.detailLevel}
        css={{
          width: "432px",
          marginBottom: "$3",
        }}
      >
        {levels.map((label) => {
          const value = DetailLevel[label].toString();
          return (
            <ToggleGroup.Item key={value} value={value} title={label}>
              {label}
            </ToggleGroup.Item>
          );
        })}
      </ToggleGroup.Root>
    </ReportLevelContainer>
  );
});

const ReportContent = ({
  report: {
    columns: _columns,
    hiddenColumns,
    timePeriods,
    enhancedRootNodes,
    isInProgress,
    columnBy,
  },
  reportType,
}: {
  report: UseDynamicReportResult;
  reportType: DynamicReportType;
}) => {
  const { deltaOptions } = useDelta();
  const { timeZone } = useActiveCompany();
  const { stickyOptions } = useStickyReportContext();
  const { categoriesByPermaKey } = useChartOfAccounts();
  const level = stickyOptions.detailLevel;

  useUpdateEffect(() => {
    Analytics.dashboardReportLevelChanged({ reportLevel: level });
  }, [level]);

  const columns = useMemo(() => {
    const sortedColumns =
      columnBy === LedgerReportColumnBy.Segment
        ? _columns.sort((a, b) => {
            if (a.includes("Total")) {
              // totals column is last
              return 1;
            } else if (b.includes("Total")) {
              return -1;
            } else if (a.includes("No ")) {
              return 1;
            } else if (b.includes("No ")) {
              return -1;
            }
            return a.localeCompare(b);
          })
        : _columns;
    return [
      {
        Header: "",
        id: "title",
        accessor: (row: EnhancedLedgerReportLine) => {
          return {
            title: formatTitleForNode(row),
          };
        },
        sticky: "left",
        width: 240,
        Footer: TitleFooter,
        Cell: TitleCell,
      },
      ...uniq(sortedColumns).map<Column<EnhancedLedgerReportLine>>((columnKey, i) => ({
        id: columnKey,
        Header: ({ column, columns }) => {
          return (
            <ReportColHeader
              columnKey={columnKey}
              i={i}
              columnBy={columnBy}
              reportType={reportType}
              timePeriods={timePeriods}
              deltaOptions={deltaOptions}
              timeZone={timeZone}
              isInProgress={isInProgress}
            />
          );
        },
        align: "right",
        verticalAlign: "bottom",
        width: 100,
        accessor: (row: EnhancedLedgerReportLine) => {
          const period = row.balanceByColumn.find((p) => p.columnKey === columnKey);
          const value = period?.balance.amount ?? 0;
          // I tried moving formatting into the cell, but the raw data is too complex and we're not even sorting.
          if (!Number.isFinite(Number(value))) {
            return period?.isPercent ? "-" : "New Value";
          }

          return period?.isPercent
            ? formatNumber(value, { style: "percent" })
            : formatMoney({ currency: "USD", amount: value }, { truncateValue: true });
        },
        Cell: (props: CellValueProps) =>
          BodyValueCell(props, { categoriesByPermaKey, stickyOptions }),
        // @ts-expect-error fix footer type
        Footer: (props: FooterValueProps) =>
          FooterValueCell(props, { categoriesByPermaKey, stickyOptions }),
      })),
      {
        // just a gutter
        width: 20,
        style: {
          padding: 0,
        },
        Header: "",
        accessor: "id",
        Cell: "",
        Footer: ({ row }: { row?: Row<EnhancedLedgerReportLine> }) => (row ? <>&nbsp;</> : null),
      },
    ];
  }, [_columns, columnBy, deltaOptions, isInProgress, reportType, timePeriods, timeZone]);

  const expanded = useMemo(() => {
    const result: { [key in string]: boolean } = {};
    const recurseNode = (node: EnhancedLedgerReportLine, indexes: number[], depth: number) => {
      const { lineType } = node;
      const shouldRecurse =
        level === DetailLevel.Detailed
          ? // detailed shows all categories but not vendors by default and shows all nodes in spotlight mode
            lineType === LedgerReportLineType.ParentAccountGroup ||
            lineType === LedgerReportLineType.Rollup ||
            deltaOptions.enabled
          : depth > 0;

      if (shouldRecurse) {
        result[indexes.join(".")] = true;
        node.children.forEach((node, j) => recurseNode(node, [...indexes, j], depth - 1));
      }
    };

    if (level !== DetailLevel.Compact) {
      enhancedRootNodes.forEach((node, i) => recurseNode(node, [i], 1));
    }

    return result;
  }, [enhancedRootNodes, level, deltaOptions.enabled]);

  const getRowProps = useCallback<
    (row: Row<EnhancedLedgerReportLine>) => React.HTMLAttributes<HTMLTableCellElement>
  >(
    (row) => {
      if (row.canExpand) {
        const rowProps = row.getToggleRowExpandedProps();
        return {
          ...rowProps,
          title: undefined,
          onClick: (e) => {
            Analytics.dashboardReportRowToggled({
              isExpanding: !row.isExpanded,
              depth: row.depth,
              highlightModeEnabled: deltaOptions.enabled,
              rowName: row.original.title,
              reportType,
              reportLevel: level,
            });
            (rowProps as any).onClick(e);
          },
        };
      }

      return {};
    },
    [deltaOptions.enabled, level, reportType]
  );

  const getSubRows = useCallback((node: EnhancedLedgerReportLine) => node.children, []);
  const dispatchRef = useRef<TableDispatch>();

  return (
    <div>
      <ExpensesExceededFeatureGate
        dates={[parseDate(stickyOptions.start), parseDate(stickyOptions.end)]}
        gate={
          <Box css={{ paddingTop: S["6"], paddingBottom: S["6"] }}>
            <MonthlyExpensesExceededBanner subtext="view financials for the selected period" />
          </Box>
        }
      >
        <ReportLevel />
        <TableStyles isInProgress={isInProgress}>
          <TableGutter>
            <DataTable<EnhancedLedgerReportLine>
              sticky
              css={{
                th: {
                  textOverflow: "inherit",

                  "> div": {
                    justifyContent: "end",
                  },
                },
                "[data-sticky-last-left-td]": {
                  "&::after": {
                    borderRight: "none !important",
                  },
                },
              }}
              dispatchRef={dispatchRef}
              data={enhancedRootNodes}
              columns={columns as Column<EnhancedLedgerReportLine>[]}
              rowTitleKey="title"
              options={{
                initialState: {
                  expanded,
                  hiddenColumns,
                },
                getSubRows,
                useControlledState: (state) => {
                  // react-table told me to put useMemo here
                  return useMemo(
                    () => ({
                      ...state,
                      hiddenColumns,
                    }),
                    // It's confused because hiddenColumns is out of scope?
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    [state, hiddenColumns]
                  );
                },
              }}
              // @ts-expect-error ts-ignore
              getRowProps={getRowProps}
            />
          </TableGutter>
        </TableStyles>
      </ExpensesExceededFeatureGate>
    </div>
  );
};

export default ReportContent;

import React from "react";
import Icon, { IconProps } from "./Icon";
import { getNumericRotate } from "./utils";

export function ChevronDoubleLeft({
  viewBox = "0 0 14 14",
  width = 14,
  height = 14,
  color = "currentColor",
  fill = "none",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} fill={fill} viewBox={viewBox}>
      <path
        d="M6.66667 5.33329L4 7.99996L6.66667 10.6666M11.3333 5.33329L8.66667 7.99996L11.3333 10.6666"
        stroke={stroke}
        strokeOpacity="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export function ChevronDoubleRight(props: IconProps) {
  const totalRotate = 180 + getNumericRotate(props.rotate);
  return <ChevronDoubleLeft {...props} rotate={totalRotate} />;
}

export function ChevronDoubleDown(props: IconProps) {
  const totalRotate = -90 + getNumericRotate(props.rotate);
  return <ChevronDoubleLeft {...props} rotate={totalRotate} />;
}

export function ChevronDoubleUp(props: IconProps) {
  const totalRotate = 90 + getNumericRotate(props.rotate);
  return <ChevronDoubleLeft {...props} rotate={totalRotate} />;
}

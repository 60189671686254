import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useCompositeState, Composite, CompositeItem } from "reakit";
import { today, startOfMonth, endOfMonth } from "@internationalized/date";
import { styled, Tooltip, NumberInput } from "@puzzle/ui";
import { formatMoney } from "@puzzle/utils";
import { Confirmed, Crown, Exclamation } from "@puzzle/icons";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

import { DisplayMoneyColumn, DisplayMoneySubrow, BurnTileGraphContainer } from "./shared";
import { BankBurnBarGraph, TotalBurnBarGraph } from "./BarChartGraphs";
import { chartDataPointPercentageDiff, metricOnDataPercentageDiff } from "./percentageDiffUtil";

import MetricCard, { Blurrable } from "./MetricCard";
import { Charts, Money, IntegrationConnectionStatus, MetricOnDate } from "graphql/types";
import { useExpandableCard } from "components/common/ExpandableCard";
import ToggleGroup from "components/common/ToggleGroup";
import UpgradeButton from "components/featureGate/UpgradeButton";
import { useActiveCompany, PricingFeatures } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { BurnFormula } from "./shared";
import Calculating from "./Calculating";
import { MetricTileDateGate } from "components/featureGate/MetricTileDateGate";
import useMonetization from "components/monetization/useMonetization";
import { EstimatedTag } from "./EstimatedTag";
import { DeltaIndicator } from "./DeltaIndicator";
import { EmptyGraph, EmptyGraphVariant } from "./EmptyGraph";

import { Box, Stack, Text, vars } from "ve";
import { useToggle } from "react-use";

const BurnCalcDescription = styled("div", {
  marginBottom: "$1",
  color: "$gray100",
  verticalAlign: "middle",
  fontSize: "14px",
  fontWeight: "$bold",
});

const Options = styled(Composite, {
  display: "grid",
  gridAutoFlow: "row",

  gridGap: "$1",
  marginBottom: "$2",
  outline: "none",
});

const OptionLabel = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  fontWeight: "$bold",
  fontSize: "13px",
  lineHeight: "20rpx",
  marginBottom: "$0h",
  color: "$gray400",
});

const OptionDescription = styled("div", {
  fontSize: "12px",
  lineHeight: "18rpx",
  letterSpacing: "0.2px",
  color: "$gray500",
});

const Option = styled(CompositeItem, {
  transition: "all .15s ease-in-out",
  appearance: "none",
  border: "2px solid transparent",
  borderRadius: `$1`,
  boxShadow: `inset 0 0 0 1px $colors$gray700`,
  background: "transparent",
  padding: "$1 $1h",
  cursor: "pointer",
  // fontSize: "inherit",
  display: "block",
  width: "100%",
  textAlign: "left",
  fontSize: "13px",
  lineHeight: "18px",
  outline: "none",
  '&[aria-selected="false"], &:hover, &:focus-within': {
    boxShadow: `inset 0 0 0 1px #4C4565`,
  },
  '&[aria-selected="true"], &[data-active="true"], &:focus': {
    borderColor: "$green800",
  },
  '&[aria-selected="true"], &[data-active="true"], &:focus, &:hover': {
    [`${OptionLabel}`]: {
      color: "$gray100",
      transition: "all .13s ease-in-out",
    },
    [`${OptionDescription}`]: {
      color: "$gray400",
      transition: "all .15s ease-in-out",
    },
  },
});

const DefaultText = styled("span", {
  color: "$gray600",
  marginLeft: "8px",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

const OptionBox = styled("div", {
  whiteSpace: "nowrap",
  overflow: "hidden",
});

type Formula = {
  label: string;
  value: BurnFormula;
  description: string | React.ReactNode;
  subtext?: string;
  pricingFeature?: PricingFeatures;
  isEstimated?: boolean;
};

type BurnProps = {
  displayBurn?: Money;
  loading: boolean;
  burnFormula: BurnFormula;
  setBurnFormula: React.Dispatch<React.SetStateAction<BurnFormula | undefined>>;
  showThreeMonthAverage: boolean;
  setShowThreeMonthAverage: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setCustomBurn: React.Dispatch<React.SetStateAction<string | undefined>>;
  customBurnValue?: string;
  formulas: Formula[];
  ingesting?: boolean;
  charts?: Charts;
  monthlyBankBurn?: MetricOnDate[];
  isGraphFeatureGated?: boolean;
};

enum Sum {
  LastMonth = "Last month total",
  Average = "3 month average",
}

const BurnContent = ({
  burnFormula,
  setBurnFormula,
  showThreeMonthAverage,
  setShowThreeMonthAverage,
  setCustomBurn,
  customBurnValue,
  formulas,
}: Pick<
  BurnProps,
  | "burnFormula"
  | "setBurnFormula"
  | "showThreeMonthAverage"
  | "setShowThreeMonthAverage"
  | "setCustomBurn"
  | "customBurnValue"
  | "formulas"
>) => {
  const { timeZone, pricePlanFeatureEnabled } = useActiveCompany<true>();
  const containerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLButtonElement>(null);
  const { expanded, toggleExpanded } = useExpandableCard();

  // TODO replace with radix
  const composite = useCompositeState({
    unstable_virtual: true,
  });
  const compositeRef = useRef(composite);

  useEffect(() => {
    const composite = compositeRef.current;
    const current = containerRef.current;
    if (expanded && current) {
      composite.setCurrentId(burnFormula);
      // focusing too early messes up animation
      setTimeout(() => {
        current.focus();
      }, 400);
    }
  }, [burnFormula, compositeRef, expanded]);

  const monthFormatter = useCompanyDateFormatter({ month: "short" });
  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
  });
  const currentDay = today(timeZone);

  const renderOption = useCallback(
    (formula: Formula, index: number) => {
      const { description, label, value, pricingFeature } = formula;

      const featureGated = pricingFeature && !pricePlanFeatureEnabled.has(pricingFeature);

      const active = burnFormula === value;

      const formatFullMonthRangeBefore = (months: number) => {
        return dateFormatter.formatRange(
          startOfMonth(currentDay.subtract({ months })),
          endOfMonth(currentDay.subtract({ months: 1 }))
        );
      };

      const options = [
        {
          value: Sum.Average,
          label: Sum.Average,
          fullText: `3 month average (${formatFullMonthRangeBefore(3)})`,
        },
        {
          value: Sum.LastMonth,
          label: Sum.LastMonth,
          fullText: `Last month total (${monthFormatter.format(
            currentDay.subtract({ months: 1 })
          )})`,
        },
      ];

      const showToggleButtonGroup: boolean = active && burnFormula !== "custom";
      return (
        <Option
          {...composite}
          data-active={active}
          id={value}
          key={value}
          onClick={() => {
            if (!featureGated) {
              setBurnFormula(value);
            }
          }}
          ref={active ? ref : undefined}
        >
          <OptionLabel>
            {
              <OptionBox>
                {label} {formula.subtext && <DefaultText>({formula.subtext})</DefaultText>}
                {featureGated && <Crown css={{ marginLeft: "$1", opacity: 0.4 }} />}
              </OptionBox>
            }
            {active && <Confirmed fill="#50FAAB" />}
          </OptionLabel>
          <OptionDescription>{description}</OptionDescription>
          {featureGated && <UpgradeButton css={{ marginTop: "$1" }} onClick={toggleExpanded} />}
          {!featureGated && active && value === "custom" ? (
            <Box css={{ display: "flex", justifyContent: "end" }}>
              <NumberInput
                size="compact"
                prefix="$"
                thousandSeparator={true}
                decimalScale={0}
                allowNegative={false}
                allowLeadingZeros={false}
                onValueChange={({ floatValue }) => {
                  setCustomBurn(floatValue?.toString() || "");
                }}
                value={customBurnValue ? Number(customBurnValue) : ""}
                placeholder="e.g. $100,000"
                textAlign="right"
                css={{ maxWidth: "10rem", marginTop: "$1h" }}
              />
            </Box>
          ) : null}
          {!featureGated && showToggleButtonGroup ? (
            <ToggleGroup.Root
              type="single"
              size="small"
              css={{ marginTop: "$0h" }}
              defaultValue={showThreeMonthAverage ? Sum.Average : Sum.LastMonth}
              onValueChange={(value: Sum) => {
                if (!value) {
                  return;
                }
                setShowThreeMonthAverage(value === Sum.Average);
              }}
            >
              {options.map((o) => (
                <Tooltip key={o.value} content={o.fullText} arrow={false}>
                  <div>
                    <ToggleGroup.Item key={o.value} value={o.value}>
                      {o.label}
                    </ToggleGroup.Item>
                  </div>
                </Tooltip>
              ))}
            </ToggleGroup.Root>
          ) : null}
        </Option>
      );
    },
    [
      burnFormula,
      monthFormatter,
      currentDay,
      composite,
      showThreeMonthAverage,
      dateFormatter,
      setBurnFormula,
      setShowThreeMonthAverage,
      customBurnValue,
      setCustomBurn,
      pricePlanFeatureEnabled,
      toggleExpanded,
    ]
  );

  return (
    <>
      <BurnCalcDescription>Choose a calculation option</BurnCalcDescription>
      <Options {...composite} aria-label="Burn Calculation" ref={containerRef}>
        {formulas.map(renderOption)}
      </Options>
    </>
  );
};

const Burn = ({
  displayBurn,
  burnFormula,
  setBurnFormula,
  loading,
  formulas,
  showThreeMonthAverage,
  setShowThreeMonthAverage,
  setCustomBurn,
  customBurnValue,
  ingesting,
  charts,
  monthlyBankBurn,
  isGraphFeatureGated,
}: BurnProps) => {
  const [blur, toggleBlur] = useToggle(false);
  const { timeZone, integrationConnections, company } = useActiveCompany<true>();
  const monthFormatter = useCompanyDateFormatter();
  const { initialIngestCompleted } = useActiveCompany<true>();
  const { isModalVisible: isUpgradeModalVisible } = useMonetization();
  const visibleFormula = formulas.find((x) => x.value === burnFormula) as Formula;
  const lastMonth = useMemo(
    () => startOfMonth(today(timeZone)).subtract({ months: 1 }),
    [timeZone]
  );
  const cardHeader = showThreeMonthAverage
    ? "3 month average"
    : monthFormatter.customFormat(lastMonth, "MMM ''yy");

  const hasActiveConnections = useMemo(
    () => integrationConnections.some((v) => v.status === IntegrationConnectionStatus.Ok),
    [integrationConnections]
  );

  const showGraph = !!company?.features.metricCardsCore;

  const monthlyBankBurnSubset = monthlyBankBurn
    ? showThreeMonthAverage
      ? monthlyBankBurn.slice(-4) // 3 complete months + current month
      : monthlyBankBurn
    : [];

  const cashBalanceSubset = charts?.cashBalance
    ? showThreeMonthAverage
      ? charts?.cashBalance.slice(-4) // 3 complete months + current month
      : charts?.cashBalance
    : [];

  let percentageIncrease = undefined;
  if (isPosthogFeatureFlagEnabled(FeatureFlag.DashboardShowPercentageChanged)) {
    if (burnFormula === BurnFormula.TotalBurn) {
      percentageIncrease = chartDataPointPercentageDiff(cashBalanceSubset, "net-burn");
    } else if (burnFormula === BurnFormula.BankBurn) {
      percentageIncrease = metricOnDataPercentageDiff(monthlyBankBurnSubset);
    }
  }

  return (
    <MetricCard
      cardId="burn"
      loading={!initialIngestCompleted ? false : loading}
      toggleBlur={toggleBlur}
      blur={blur}
      blurEnabled={true}
      shouldHandleBlur={false}
      header={`${visibleFormula.label} - ${cardHeader}`}
      expandButtonOnTop={true}
      expandable={!isUpgradeModalVisible}
      expandedContent={
        <BurnContent
          burnFormula={burnFormula}
          setBurnFormula={setBurnFormula}
          showThreeMonthAverage={showThreeMonthAverage}
          setShowThreeMonthAverage={setShowThreeMonthAverage}
          setCustomBurn={setCustomBurn}
          customBurnValue={customBurnValue}
          formulas={formulas}
        />
      }
      hasGraph={showGraph}
      isFeatureGated={isGraphFeatureGated}
    >
      <MetricTileDateGate>
        <DisplayMoneyColumn>
          <DisplayMoneySubrow>
            {!initialIngestCompleted ? (
              <Calculating />
            ) : displayBurn ? (
              <Blurrable active={blur}>
                {formatMoney(displayBurn, { truncateValue: true })}
              </Blurrable>
            ) : null}
            {displayBurn && ingesting && (
              <Tooltip content="Looks like were are still ingesting data from one or more accounts.">
                <Box css={{ display: "flex", alignItems: "center" }}>
                  <Exclamation css={{ opacity: 0.5 }} />
                </Box>
              </Tooltip>
            )}
            {percentageIncrease !== undefined && <DeltaIndicator value={percentageIncrease} />}
            {visibleFormula.isEstimated && hasActiveConnections && (
              <EstimatedTag
                modalTitle="Estimated Net Burn"
                modalBody={
                  <Stack css={{ gap: vars.space["$2"] }}>
                    <Text>Your Net Burn is currently an estimated value.</Text>
                    <Text>
                      Navigate to the transactions page to see what you can do to increase the
                      accuracy of this metric.
                    </Text>
                  </Stack>
                }
              />
            )}
          </DisplayMoneySubrow>
        </DisplayMoneyColumn>

        {showGraph && (
          <>
            <BurnTileGraphContainer gateBlur={isGraphFeatureGated}>
              {ingesting || loading ? (
                <EmptyGraph variant={EmptyGraphVariant.BARS_THICK} />
              ) : burnFormula === BurnFormula.TotalBurn ? (
                <TotalBurnBarGraph data={cashBalanceSubset} />
              ) : burnFormula === BurnFormula.BankBurn ? (
                <BankBurnBarGraph data={monthlyBankBurnSubset} />
              ) : (
                <EmptyGraph text="No graph to display" variant={EmptyGraphVariant.BARS_THICK} />
              )}
            </BurnTileGraphContainer>
            {isGraphFeatureGated && (
              <UpgradeButton
                title="Upgrade to view"
                variant="gold-outline"
                css={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: "50%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            )}
          </>
        )}
      </MetricTileDateGate>
    </MetricCard>
  );
};

export default Burn;

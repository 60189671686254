/* eslint-disable local-rules/no-base64-in-tsx */
import React from "react";
import Icon, { IconProps } from "../Icon";

const DEFAULT_SIZE = 48;

export default function GoogleSheet({
  viewBox = "0 0 48 48",
  width = DEFAULT_SIZE,
  height = DEFAULT_SIZE,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox}>
      <defs>
        <path
          id="gsheet_a"
          d="M29.583 0H4.438A4.45 4.45 0 0 0 0 4.438v56.208a4.45 4.45 0 0 0 4.438 4.437h38.458a4.45 4.45 0 0 0 4.437-4.437V17.75Z"
        />
      </defs>
      <defs>
        <use xlinkHref="#gsheet_a" id="gsheet_c" />
        <use xlinkHref="#gsheet_a" id="gsheet_e" />
        <use xlinkHref="#gsheet_a" id="gsheet_h" />
        <use xlinkHref="#gsheet_a" id="gsheet_j" />
        <use xlinkHref="#gsheet_a" id="gsheet_l" />
        <use xlinkHref="#gsheet_a" id="gsheet_n" />
        <radialGradient
          id="gsheet_p"
          cx={1.758}
          cy={1.508}
          r={89.498}
          fx={1.758}
          fy={1.508}
          gradientTransform="scale(.8528 1.1726)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0%" stopColor="#FFF" stopOpacity={0.1} />
          <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
        </radialGradient>
        <linearGradient
          id="gsheet_f"
          x1={39.104}
          x2={39.104}
          y1={17.866}
          y2={32.906}
          gradientTransform="scale(1.00011 .99989)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0%" stopColor="#263238" stopOpacity={0.2} />
          <stop offset="100%" stopColor="#263238" stopOpacity={0.02} />
        </linearGradient>
      </defs>
      <g transform="matrix(.81007 0 0 .7295 4.828 .261)">
        <mask id="gsheet_b" fill="#fff">
          <use xlinkHref="#gsheet_a" />
        </mask>
        <path
          fill="#0f9d58"
          d="M29.583 0H4.438A4.45 4.45 0 0 0 0 4.438v56.208a4.45 4.45 0 0 0 4.438 4.437h38.458a4.45 4.45 0 0 0 4.437-4.437V17.75L36.98 10.354Z"
          mask="url(#gsheet_b)"
        />
      </g>
      <g transform="matrix(.81007 0 0 .7295 4.828 .261)">
        <mask id="gsheet_d" fill="#fff">
          <use xlinkHref="#gsheet_c" />
        </mask>
        <path
          fill="#f1f1f1"
          d="M11.833 31.802V53.25H35.5V31.802Zm10.354 18.49h-7.395v-3.698h7.396Zm0-5.917h-7.395v-3.698h7.396Zm0-5.917h-7.395V34.76h7.396Zm10.355 11.834h-7.396v-3.698h7.396zm0-5.917h-7.396v-3.698h7.396zm0-5.917h-7.396V34.76h7.396z"
          mask="url(#gsheet_d)"
        />
      </g>
      <g transform="matrix(.81007 0 0 .7295 4.828 .261)">
        <mask id="gsheet_g" fill="#fff">
          <use xlinkHref="#gsheet_e" />
        </mask>
        <path fill="url(#gsheet_f)" d="M47.333 32.9V17.75l-16.452-1.298z" mask="url(#gsheet_g)" />
      </g>
      <g transform="matrix(.81007 0 0 .7295 4.828 .261)">
        <mask id="gsheet_i" fill="#fff">
          <use xlinkHref="#gsheet_h" />
        </mask>
        <g mask="url(#gsheet_i)">
          <path fill="#87ceac" d="M29.583 0v13.313a4.436 4.436 0 0 0 4.438 4.437h13.312Z" />
        </g>
      </g>
      <g fill="#fff" transform="matrix(.81007 0 0 .7295 4.828 .261)">
        <mask id="gsheet_k">
          <use xlinkHref="#gsheet_j" />
        </mask>
        <path
          fillOpacity={0.2}
          d="M4.438 0A4.45 4.45 0 0 0 0 4.438v.37A4.45 4.45 0 0 1 4.438.37h25.145V0Z"
          mask="url(#gsheet_k)"
        />
      </g>
      <g transform="matrix(.81007 0 0 .7295 4.828 .261)">
        <mask id="gsheet_m" fill="#fff">
          <use xlinkHref="#gsheet_l" />
        </mask>
        <path
          fill="#263238"
          fillOpacity={0.2}
          d="M42.896 64.714H4.437A4.45 4.45 0 0 1 0 60.276v.37a4.45 4.45 0 0 0 4.438 4.437h38.458a4.45 4.45 0 0 0 4.437-4.437v-.37a4.45 4.45 0 0 1-4.437 4.438z"
          mask="url(#gsheet_m)"
        />
      </g>
      <g transform="matrix(.81007 0 0 .7295 4.828 .261)">
        <mask id="gsheet_o" fill="#fff">
          <use xlinkHref="#gsheet_n" />
        </mask>
        <path
          fill="#263238"
          fillOpacity={0.1}
          d="M34.02 17.75a4.436 4.436 0 0 1-4.437-4.438v.37a4.436 4.436 0 0 0 4.438 4.438h13.312v-.37Z"
          mask="url(#gsheet_o)"
        />
      </g>
      <path
        fill="url(#gsheet_p)"
        d="M29.583 0H4.438A4.45 4.45 0 0 0 0 4.438v56.208a4.45 4.45 0 0 0 4.438 4.437h38.458a4.45 4.45 0 0 0 4.437-4.437V17.75Z"
        transform="matrix(.81007 0 0 .7295 4.828 .261)"
      />
    </Icon>
  );
}

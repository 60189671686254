import React from "react";
import { Dialog } from "@puzzle/ui";
import { Close as CloseIcon } from "@puzzle/icons";

import { IconButton, S } from "ve";
import { zIndex } from "@puzzle/utils";
import { usePlansPricingModal } from "./usePlansPricingModal";
import { PlanSelectStep } from "./PlanSelectStep";
import { ReviewStep } from "./ReviewStep";
import { OnboardingCall } from "../OnboardingCall";
import { useRouter } from "next/router";

const MODAL_BACKGROUND =
  "radial-gradient(59.63% 59.63% at 50% 0%, rgba(106, 75, 194, 0.72) 0%, rgba(23, 22, 36, 0) 100%), #000211;";

export const PlansPricingModal = () => {
  const router = useRouter();
  const {
    selectedPlanId,
    isUpgradeModalOpen,
    hideUpgradeModal,
    isOnboardingCallVisible,
    hideOnboardingCallModal,
  } = usePlansPricingModal();

  return (
    <>
      <OnboardingCall
        planName={router?.query?.plan as string}
        open={isOnboardingCallVisible}
        onClose={hideOnboardingCallModal}
      />
      <Dialog
        data-testid="upgrade-modal"
        size="fullScreen"
        open={isUpgradeModalOpen}
        onOpenChange={hideUpgradeModal}
        style={{ zIndex: zIndex("modal") }}
      >
        <Dialog.Body
          css={{
            background: MODAL_BACKGROUND,
            height: "100%",
            overflowY: "auto",
            maxHeight: "100vh",
          }}
        >
          <Dialog.Close asChild>
            <IconButton
              css={{
                position: "absolute",
                right: S.$2,
                top: S.$2,
                width: S.$4,
                height: S.$4,
                background: "transparent",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Dialog.Close>
          {selectedPlanId ? <ReviewStep /> : <PlanSelectStep />}
        </Dialog.Body>
      </Dialog>
    </>
  );
};

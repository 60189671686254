import React from "react";
import { AccountFragment } from "graphql/types";
import { Tooltip } from "@puzzle/ui";
import { formatAccountName } from "@puzzle/utils";
import InstitutionLogo from "components/integrations/bank/InstitutionLogo";
import {
  logoStyle,
  logoWithDescriptionContainer,
  sourceDescription,
} from "./AccountWithInstitutionLogoCell.css";
import compact from "lodash/compact";

export const ACCOUNT_WITH_INSTITUTION_LOT_TEST_IDS = {
  ACCOUNT_LOGO_CELL_CONTAINER: "account_logo_cell_container",
  ACCOUNT_DESCRIPTION: "source-description",
};

export const MAX_MASK_CHARACTERS_LENGTH = 4;

const formatAccountDescription = (
  account: Pick<AccountFragment, "name" | "mask" | "financialInstitution">
): string => {
  let mask = account.mask;

  if (mask && mask.length > MAX_MASK_CHARACTERS_LENGTH) {
    mask = mask.slice(-MAX_MASK_CHARACTERS_LENGTH);
  }

  return compact([account.financialInstitution.name, mask]).join(" - ");
};

export type AccountWithInstitutionLogoCellProps = {
  account: Pick<AccountFragment, "name" | "mask" | "financialInstitution">;
};

export const AccountWithInstitutionLogoCell = ({
  account,
}: AccountWithInstitutionLogoCellProps) => {
  return (
    <div data-testid={ACCOUNT_WITH_INSTITUTION_LOT_TEST_IDS.ACCOUNT_LOGO_CELL_CONTAINER}>
      <Tooltip content={formatAccountName(account)} side="right">
        <div className={logoWithDescriptionContainer}>
          <div className={logoStyle}>
            <InstitutionLogo institution={account.financialInstitution} circular={true} />
          </div>
          <span
            data-testid={ACCOUNT_WITH_INSTITUTION_LOT_TEST_IDS.ACCOUNT_DESCRIPTION}
            className={sourceDescription}
          >
            {formatAccountDescription(account)}
          </span>
        </div>
      </Tooltip>
    </div>
  );
};

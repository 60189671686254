import React, { useEffect, useMemo, useState } from "react";
import { endOfMonth, startOfMonth, today } from "@internationalized/date";

import { pluralize } from "@puzzle/utils";
import {
  Dialog,
  Text,
  Stack,
  Button,
  StatusIcon,
  DateRangePickerCalendar,
  RangePresets,
  RangePresetLists,
  RangeValue,
} from "@puzzle/ui";
import { CategorizeStepResults } from "./CategorizeStep";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import Analytics from "lib/analytics";
import { Box, S } from "ve";

const CategorizedMessage = ({ results }: { results: CategorizeStepResults }) => {
  const dateFormatter = useCompanyDateFormatter({ month: "long", year: "numeric" });
  const skipped = results.totalCategorized === 0 && results.totalReviewed > 0;

  if (!results.range) {
    throw new Error("CategorizedMessage should not be rendered with unbounded ranges.");
  }

  const header = useMemo(() => {
    if (results.totalReviewed === results.totalCategorized) {
      return (
        <Box css={{ display: "flex", alignItems: "center", gap: S["1"] }}>
          <StatusIcon status="success" size={20} /> {dateFormatter.format(results.range![0])}{" "}
          complete!
        </Box>
      );
    } else {
      if (skipped) {
        return "No transactions were categorized.";
      }
      const categorizedString =
        results.totalReviewed === results.totalCategorized
          ? `${pluralize(results.totalCategorized, "transaction")}`
          : `${results.totalCategorized} of ${results.totalReviewed} transactions`;
      return `${categorizedString} for ${dateFormatter.formatRange(
        results.range![0],
        results.range![1]
      )} categorized!`;
    }
  }, [results.totalReviewed, results.totalCategorized, results.range, dateFormatter, skipped]);

  const description = useMemo(() => {
    if (skipped) {
      return null;
    }

    return "However, you still have uncategorized impactful transactions from other months.";
  }, [skipped]);

  return (
    <Stack gap="1">
      <Text variant="headingS" color="gray50">
        {header}
      </Text>

      {description && (
        <Text variant="bodyS" color="gray400">
          {description}
        </Text>
      )}
    </Stack>
  );
};

const DateRangeStep = ({
  previousRange,
  previousResults,
  onContinue,
  onGoToDashboard,
  onComplete,
}: {
  onContinue: (range: RangeValue) => void;
  onGoToDashboard: () => void;
  previousResults?: CategorizeStepResults;
  previousRange?: RangeValue;
  onComplete?: () => void;
}) => {
  useEffect(() => {
    Analytics.categorizerDateRangeStepViewed();
  }, []);

  const dateFormatter = useCompanyDateFormatter({
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const { company, isWithinLockedPeriod, lockedPeriodDate, timeZone } = useActiveCompany<true>();
  // last full month is default selection
  const presets = RangePresetLists.TopTransactions;
  const [preset, setPreset] = useState(() =>
    previousRange
      ? undefined
      : presets.find((p) => p.key === RangePresets.LastFullMonth.key) || presets[0]
  );
  const minDate = lockedPeriodDate;

  const [range, setRange] = useState<RangeValue>(() => {
    // TODO Listen, this isn't smart about checking neighboring months yet.
    // It'd be a better use of time to try making an aggregate monthly query, or make a new resolver for this.
    // Attempting with network requests will look silly.
    if (previousRange) {
      const start = startOfMonth(previousRange[0]).subtract({ months: 1 });
      const end = endOfMonth(start);
      return [start, end];
    }

    return (preset || presets[0]).range!();
  });

  const inLockedPeriod = useMemo(
    () => isWithinLockedPeriod(range[0]),
    [range, isWithinLockedPeriod]
  );

  if (previousResults && !previousResults.range) {
    throw new Error("DateRangeStep should not be rendered with unbounded ranges.");
  }

  return (
    <>
      <Dialog.Body css={{ paddingRight: "$1h" }}>
        <Stack gap="3">
          {previousResults && <CategorizedMessage results={previousResults} />}

          <div>
            <Text variant="bodyS" color="gray400" css={{ marginBottom: "$1" }}>
              Select a month range
            </Text>
            <DateRangePickerCalendar
              presets={RangePresetLists.TopTransactions}
              preset={preset}
              onPresetChange={setPreset}
              onChange={setRange}
              view="month"
              value={range}
              maxDate={today(timeZone)}
              minDate={minDate}
              dark={false}
            />
          </div>
          {minDate && (
            <Text variant="bodyS" color="gray400">
              You cannot add transactions or adjust ending balances on or before{" "}
              {dateFormatter.format(lockedPeriodDate)} as periods before this date are locked.
            </Text>
          )}
        </Stack>
      </Dialog.Body>
      <Dialog.Footer divider>
        <Dialog.Actions>
          {previousResults &&
            previousResults.totalCategorized > 0 &&
            previousResults.totalReviewed > 0 && (
              <Button variant="secondary" onClick={onGoToDashboard}>
                Go to dashboard
              </Button>
            )}
          <Button
            disabled={inLockedPeriod}
            onClick={() => {
              Analytics.categorizerDateRangeSelected({
                startDate: range?.[0].toString(),
                endDate: range?.[1].toString(),
              });
              onContinue(range);
            }}
          >
            Continue
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </>
  );
};

export default DateRangeStep;

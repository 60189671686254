import { PuzzleSubscriptionFragment } from "components/companies/graphql.generated";
import { Money } from "graphql/types";

export const getShowUpgradeModal = (
  activePlan: PuzzleSubscriptionFragment,
  billedPlan: PuzzleSubscriptionFragment | null | undefined,
  hasAvailableUpgrade: boolean
) => {
  if (!hasAvailableUpgrade) {
    return false;
  }
  const isOnFreeTrial = activePlan.plan.isTrial;

  if (!isOnFreeTrial) {
    return true;
  }

  if (isOnFreeTrial && (!billedPlan || billedPlan?.plan?.isFree)) {
    return true;
  }

  return false;
};

export const getPlanPrice = (
  subscription: PuzzleSubscriptionFragment
): Money | undefined | null => {
  if (subscription.plan.isTrial || subscription.plan.isFree) {
    return { amount: "0", currency: "USD" };
  }

  if (subscription.price) {
    return subscription.price;
  }

  return null;
};

export const formatDaysLeftingOnFreeTrial = (daysLeftOnFreeTrial: number) => {
  return `${daysLeftOnFreeTrial} day${daysLeftOnFreeTrial > 1 ? "s" : ""} left`;
};

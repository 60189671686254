import { useMemo } from "react";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useMetricsAccountsQuery } from "./graphql.generated";

export const useAccountsForMetrics = () => {
  const { company } = useActiveCompany<true>();
  const { data, loading } = useMetricsAccountsQuery({
    variables: { filter: { companyId: company.id } },
  });

  const accounts = useMemo(() => data?.accounts ?? [], [data]);
  return {
    loading,
    accounts,
  };
};

import React from "react";
import { Text, Stack } from "@puzzle/ui";
import Link from "components/common/Link";
import {
  DisconnectionErrorCode,
  IntegrationConnectionFragment,
  IntegrationConnectionStatus,
} from "graphql/types";
import { getDisplayNameForIntegrationType } from "../integrations/setup/utils";

type DisconnectedErrorMessageProps = {
  integrationConnections: IntegrationConnectionFragment[];
  onClickReconnect?: () => void;
}

const getPlaidErrorMessage = (errorCode: DisconnectionErrorCode) => {
  // These are copied from Plaid's docs: https://plaid.com/docs/errors/
  // If we ever start using these error codes for non-Plaid integrations, we may have to change the wording a bit
  switch (errorCode) {
    case DisconnectionErrorCode.MfaNotSupported:
      return "The multi-factor security features enabled on this account are not currently supported for this financial institution. We apologize for the inconvenience.";
    case DisconnectionErrorCode.InvalidMfa:
      return "The provided MFA responses were not correct. Please try again.";
    case DisconnectionErrorCode.ItemLocked:
      return "The given account has been locked by the financial institution. Please visit your financial institution's website to unlock your account.";
    case DisconnectionErrorCode.ItemNotSupported:
      return "The given account is not currently supported for this financial institution. We apologize for the inconvenience.";
  }
};

const reconnectTextOrLink = (text: string, onClick?: () => void) => {
  if (onClick) {
    return (
      <Link onClick={() => onClick()} css={{ textDecoration: "underline" }}>
        {text}
      </Link>
    );
  }
  return <>{text}</>;
};

const DisconnectionErrorMessage = ({
  integrationConnections,
  onClickReconnect,
}: DisconnectedErrorMessageProps) => {
  const disconnectedIntegrations = integrationConnections.filter(
    (ic) => ic.status === IntegrationConnectionStatus.Disconnected
  );

  if (disconnectedIntegrations.length === 0) {
    return null;
  }

  if (disconnectedIntegrations.length > 1) {
    return (
      <Text>
        At least 1 of your integrations has been disconnected. Additional details can be found on
        the {reconnectTextOrLink("Integrations page", onClickReconnect)}.
      </Text>
    );
  }

  const integration = disconnectedIntegrations[0];
  const integrationType = getDisplayNameForIntegrationType(integration.type);

  switch (integration.errorCode) {
    // Plaid specific errors
    case DisconnectionErrorCode.MfaNotSupported:
    case DisconnectionErrorCode.InvalidMfa:
    case DisconnectionErrorCode.ItemLocked:
    case DisconnectionErrorCode.ItemNotSupported:
      return (
        <Stack gap="1">
          <Text>We received the following error from {integrationType}:</Text>
          <Text>
            &quot;
            {getPlaidErrorMessage(integration.errorCode)}&quot;.
          </Text>
        </Stack>
      );

    case DisconnectionErrorCode.InsufficientPermissions:
      return (
        <Stack gap="1">
          <Text>
            {integrationType} has been disconnected because the person who connected does not have
            all the required permissions.
          </Text>
          <Text>
            Please have someone with admin permissions in {integrationType}{" "}
            {reconnectTextOrLink("reconnect", onClickReconnect)} to ensure your financial data is
            accurate.
          </Text>
        </Stack>
      );

    case DisconnectionErrorCode.NoAccounts:
      return (
        <Stack gap="1">
          <Text>
            Your credentials are correct, but we couldn't find any valid accounts from{" "}
            {integrationType}.
          </Text>
          <Text>
            Please log into your financial institution to verify everything is correct then{" "}
            {reconnectTextOrLink("reconnect", onClickReconnect)} this integration.
          </Text>
        </Stack>
      );

    default:
      return (
        <Stack gap="1">
          <Text>{integrationType} has been disconnected.</Text>
          <Text>
            Please {reconnectTextOrLink("reconnect", onClickReconnect)} to ensure your financial
            data is accurate.
          </Text>
        </Stack>
      );
  }
};

export default DisconnectionErrorMessage;

import { useEffect, useRef } from "react";
import { useToasts, styled } from "@puzzle/ui";
import { IntegrationConnectionFragment, IntegrationType } from "graphql/types";
import { usePendingConnections } from "../shared/PendingConnectionsProvider";
import useAppRouter from "lib/useAppRouter";

const Content = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});

type IntegrationLoadingProps = {
  loading: boolean;
  integrationType: IntegrationType;
  error: boolean;
  connection?: IntegrationConnectionFragment | null;
  connectIntegration: () => void;
  subtitle?: string;
  /** @deprecated unused..? */
  title: string;
}

const IntegrationLoading = ({
  integrationType,
  loading,
  connection,
  connectIntegration,
  error,
}: IntegrationLoadingProps) => {
  const { router } = useAppRouter();
  const hide = router?.query?.hide ?? false;
  const { toast } = useToasts();
  const { removePendingConnection, addPendingConnection } = usePendingConnections();
  const attemptedRef = useRef(false);

  const shouldConnect = !loading;

  useEffect(() => {
    if (connection) {
      if (integrationType !== IntegrationType.Plaid) {
        removePendingConnection(integrationType);
      }
    }
  }, [connection, integrationType, removePendingConnection]);

  useEffect(() => {
    // if there is an error, remove the connection from the list of pending connections
    // so that the connected button changes to a connect button
    if (error) {
      removePendingConnection(integrationType);
    }
  }, [error, integrationType, removePendingConnection]);

  useEffect(() => {
    const connect = async () => {
      if (shouldConnect && !attemptedRef.current) {
        // since plaid uses financial institutions, connections are determined by
        // the existence of a financial institution, so it is treated differently
        if (integrationType !== IntegrationType.Plaid) {
          addPendingConnection(integrationType);
        }

        attemptedRef.current = true;
        await connectIntegration();
      }
    };

    connect();
  }, [addPendingConnection, connectIntegration, integrationType, shouldConnect]);

  useEffect(() => {
    if (!hide && error) {
      toast({
        message: `Something went wrong while trying to connect to ${integrationType}, please try again!`,
        status: "error",
      });
    }
  }, [connection, error, hide, integrationType, toast]);

  return null;

  // this will be replaced with an error modal in next pr
  // return (
  //   <Modal open={false} style={{ width: 350 }}>
  //     <Content>
  //       <Loader />
  //       <Headline>{title}</Headline>
  //     </Content>
  //   </Modal>
  // );
};

export default IntegrationLoading;

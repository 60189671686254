import React from "react";

import { Tag } from "@puzzle/ui";

export const AlphaTag = ({ ...props }) => {
  return (
    <Tag variant="gradient" {...props} style={{ boxSizing: "border-box", overflow: "visible" }}>
      Alpha
    </Tag>
  );
};

import React, { useState } from "react";
import { useRippling } from "./useRippling";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import Logo from "./Logo";
import { IntegrationDisplayComponent } from "../shared/types";
import Analytics from "lib/analytics";
import { useRouter } from "next/router";
import { ConnectRipplingModal } from "./ConnectRipplingModal";
import { Alert, Tooltip } from "@puzzle/ui";

type RipplingListItemProps = {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}
export const TITLE = "Rippling";
export const SUBTITLE = "Link employment costs for your financial statements and taxes.";
const PAID_ADD_ON_TOOLTIP =
  "Rippling is a paid add-on. Rippling charges $2 per employee per month for API access";
const RipplingListItem = ({ DisplayComponent, companyId }: RipplingListItemProps) => {
  const { connection, loading } = useRippling({ companyId });
  const [showModal, setShowModal] = useState(false);
  const router = useRouter();
  const isOnboarding = router.pathname.includes("intro");

  const onClickConnectWrapper = () => {
    setShowModal(true);
    Analytics.ripplingIntegrationSelected({ source: isOnboarding ? "Onboarding" : "Integrations" });
  };

  return (
    <>
      <DisplayComponent
        integrationType={IntegrationType.Rippling}
        connectIntegration={onClickConnectWrapper}
        title={TITLE}
        logo={
          <Tooltip content={PAID_ADD_ON_TOOLTIP}>
            <div style={{ position: "relative", width: 48, height: 48 }}>
              <Logo isPaidAddOn={true} />
            </div>
          </Tooltip>
        }
        connection={connection}
        loading={loading}
      />
      <ConnectRipplingModal
        open={showModal}
        onOpenChange={() => {
          setShowModal(false);
        }}
        showSteps={!isOnboarding}
        forceShowWarning={isOnboarding}
        explainCosts={!isOnboarding}
      />
      {connection && isOnboarding && (
        <Alert>Upgrade to a paid plan after onboarding to unlock your Rippling integration.</Alert>
      )}
    </>
  );
};

export default RipplingListItem;

import React from "react";

import { Arrow } from "@puzzle/icons";
import { noop } from "lodash";

import { Button, S, Text, Box } from "ve";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { AnalyticsTriggeredFrom } from "../PostOnboardModal/analytics";
import { WhatsYourPosition } from "../PostOnboardModal/Panes";

import { rootStyle } from "./WhatPosition.css";

export const WhatsPosition = ({ onContinue }: { onContinue: () => void }) => {
  const { membership } = useActiveCompany<true>();

  const hasPosition = !!membership?.position;

  return (
    <div className={rootStyle}>
      <Box css={{ display: "flex", flexDirection: "column" }}>
        <Text variant="headingM" weight="extrabold" color="gray100">
          What is your position?
        </Text>
        <Text variant="bodyXS" color="gray200">
          Help us customize your Puzzle experience
        </Text>
      </Box>
      <WhatsYourPosition onNext={noop} location={AnalyticsTriggeredFrom.PostOnboarding} />
      {hasPosition && (
        <Button
          onClick={onContinue}
          css={{ alignSelf: "flex-end", marginTop: "auto", marginBottom: S.$2, marginRight: S.$2 }}
          suffixElement={<Arrow size={16} color="$black" />}
        >
          Continue
        </Button>
      )}
    </div>
  );
};

import React, { useMemo } from "react";
import { IntercomProvider } from "react-use-intercom";
import NextApp from "next/app";
import Head from "next/head";
import Script from "next/script";
import { RequestError, UserProvider } from "@auth0/nextjs-auth0";

import ThemeProvider from "components/common/theme/ThemeProvider";
import { ExtendedNextApp } from "components/common/types";
import InactivityTimer from "components/common/InactivityTimer";
import AppProviders from "components/layout/AppProviders";

import config from "lib/config";
import { withApollo } from "apollo/withApollo";
import { Route } from "lib/routes";
import { ErrorBoundary } from "lib/errors";
import { usePageLoadTrace } from "lib/instrumentation/usePageLoadTrace";
import { startTracing } from "lib/instrumentation/otelInstrumentation";
import Analytics from "lib/analytics";
import "lib/instrumentation/dataDogRUM";

if (typeof window !== "undefined" && process.env.TRACE_ENABLED) {
  startTracing();
}

const WithAuth = ({ children }: React.PropsWithChildren<unknown>) => {
  const userFetcher = async (url: string) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        window.location.href = "/login";
        return undefined; // Unauthorized
      }
      throw new RequestError(response.status);
    } catch {
      throw new RequestError(0); // Network error
    }
  };

  return (
    <UserProvider profileUrl={Route.me} fetcher={userFetcher} loginUrl={Route.login}>
      <InactivityTimer />
      {children}
    </UserProvider>
  );
};

const App: ExtendedNextApp = ({ Component, pageProps }) => {
  usePageLoadTrace();
  const content = useMemo(() => {
    const getLayout = Component.getLayout || ((page) => page);

    const result = <main>{getLayout(<Component {...pageProps} />)}</main>;
    return Component.disableTheme ? result : <ThemeProvider>{result}</ThemeProvider>;
  }, [Component, pageProps]);

  const Noop = ({ children }: React.PropsWithChildren<unknown>) => <>{children}</>;
  const MainWrapper = Component.disableAuthAndDataProviders ? Noop : WithAuth;

  return (
    <ErrorBoundary>
      <IntercomProvider
        appId={config.INTERCOM_APP_ID!}
        onShow={() => Analytics.intercomOpened()}
        shouldInitialize={!Component.disableIntercom}
        initializeDelay={5000}
      >
        <MainWrapper>
          <Head>
            {/** https://err.sh/next.js/no-document-viewport-meta */}
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            <title>Puzzle</title>

            <script>
              {config.GTM_ID
                ? `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${config.GTM_ENV_PARAMS}';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${config.GTM_ID}');`
                : `${config.GTM_ID}`}
            </script>

            <script>
              {`
                window.dataLayer = window.dataLayer || { push: function() {}};
                function gtag() { dataLayer.push(arguments); }
                gtag('js', new Date());
                `}
            </script>
          </Head>

          <script>
            {config.GTM_ID
              ? `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${config.GTM_ID}${config.GTM_ENV_PARAMS}"
              height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
              : ""}
          </script>

          {/* Sometimes react-plaid-link doesn't load the script: https://github.com/plaid/react-plaid-link/issues/86 */}
          <Script
            src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"
            strategy="beforeInteractive"
          />

          <AppProviders
            disableDataProviders={
              !!Component.disableDataProviders || !!Component.disableAuthAndDataProviders
            }
          >
            {content}
          </AppProviders>
        </MainWrapper>
      </IntercomProvider>
    </ErrorBoundary>
  );
};

// Next.js seems to ignore the getInitialProps from withApollo.
// This no-op intentionally disables static page generation.
App.getInitialProps = async (appContext) => {
  const appProps = await NextApp.getInitialProps(appContext);
  return { ...appProps };
};

export default withApollo({ ssr: false })(App);

import React, { useCallback, useMemo, useState } from "react";
import { Button, ButtonGroup, Dialog, Text, useToasts, Stack, ScrollArea } from "@puzzle/ui";
import { useSetEpochsForAccountsMutation } from "graphql/types";
import { reportError } from "lib/errors";
import useAppRouter from "lib/useAppRouter";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { EpochInput } from "./ingestionDateUtils";
import { S } from "ve";
import Link from "components/common/Link";
import { colors } from "ve/theme/colors";
import links from "lib/links";
import { SelectIngestDateModalProps } from "./IngestDateModal";
import { SelectStartForSingleAccount } from "./SelectStartForSingleAccount";
import { SelectEndForSingleAccount } from "./SelectEndForSingleAccount";
import { AccountHeader } from "./AccountHeader";

const SMALL_MODAL_BODY_HEIGHT = "670";

export const SelectStartDateModal = ({
  open,
  accounts,
  isReconnect,
  onOpenChange,
  accountsLoading,
  connectionId,
  onDatesSet,
  successToastMessageOverride,
  ...props
}: SelectIngestDateModalProps) => {
  const [setEpochsForAccountsMutation, { loading }] = useSetEpochsForAccountsMutation();
  const { goToTransactions } = useAppRouter();
  const { toast } = useToasts();
  const isOptionRequired = isPosthogFeatureFlagEnabled(
    FeatureFlag.ReqIngestOprionOnReconnectIntegration
  );
  const isCutoverV2Enabled = isPosthogFeatureFlagEnabled(FeatureFlag.CutoverDateV2);

  const [accountEpochs, setAccountEpochs] = useState<EpochInput[]>([]);

  const onSetIngestionDate = useCallback(
    (epochInput: EpochInput) => {
      if (!accountEpochs.find((a) => a.accountId === epochInput.accountId)) {
        return setAccountEpochs([...accountEpochs, epochInput]);
      }
      const newSet = accountEpochs.map((epoch) => {
        if (epoch.accountId === epochInput.accountId) {
          return { ...epochInput };
        }
        return epoch;
      });
      setAccountEpochs(newSet);
    },
    [accountEpochs, setAccountEpochs]
  );

  const hasAllAccountsSetCuttoffBefore = useMemo(() => {
    return accountEpochs.every((accountEpoch) => !!accountEpoch.cutoffBefore);
  }, [accountEpochs]);

  const onConfirm = useCallback(async () => {
    if (isOptionRequired && isReconnect && !hasAllAccountsSetCuttoffBefore) {
      return;
    }

    return setEpochsForAccountsMutation({
      variables: {
        input: {
          connectionId: connectionId ?? "",
          epochs: accountEpochs.map((epoch) => {
            return {
              accountId: epoch.accountId,
              cutoffBefore: epoch.cutoffBefore?.toString(),
              cutoffAtOrAfter: epoch.cutoffAtOrAfter?.toString(),
              shouldUpdateHistory: epoch.shouldUpdateHistory,
            };
          }),
        },
      },
      onCompleted() {
        toast({
          message:
            successToastMessageOverride ??
            `Ingestion start and end date has been set! We will start syncing your accounts now. Data ingestion may take a couple minutes.`,
          onAction: () => {
            goToTransactions();
          },
          actionText: "You will need to refresh to see the results.",
          status: "success",
        });
        onOpenChange?.(false);
        setAccountEpochs([]);
        onDatesSet?.();
      },
      onError(error) {
        toast({
          message: `Failed to set ingestion start and end date.`,
          status: "error",
        });
        reportError(error);
      },
    });
  }, [
    isOptionRequired,
    isReconnect,
    hasAllAccountsSetCuttoffBefore,
    setEpochsForAccountsMutation,
    connectionId,
    accountEpochs,
    toast,
    successToastMessageOverride,
    onOpenChange,
    onDatesSet,
    goToTransactions,
  ]);

  return (
    <Dialog size="medium" {...props} open={open}>
      <Dialog.Title
        onClose={() => onOpenChange?.(false)}
        showClose={!(isOptionRequired && isReconnect)}
        css={{ padding: isOptionRequired && isReconnect ? "$2h $1h $2 $3" : undefined }}
      >
        {isCutoverV2Enabled ? (
          <>Set ingestion start date and ingestion end date {!isReconnect && "(optional)"}</>
        ) : (
          <>Set ingestion start date {!isReconnect && "(optional)"}</>
        )}
      </Dialog.Title>
      <Dialog.Body
        css={{
          textVariant: "$bodyS",
          height: `${SMALL_MODAL_BODY_HEIGHT}px`,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack direction="vertical" gap="0" css={{ marginBottom: S["$2"] }}>
          {isCutoverV2Enabled ? (
            <Text>Puzzle will ingest data from this account based on the chosen dates. </Text>
          ) : (
            <Text>
              Set ingestion start date for the following account(s). Puzzle will start ingesting
              data from the associated account beginning on the chosen date.{" "}
            </Text>
          )}
          <Link
            href={links.education.startIngestionDate}
            underline
            external
            target="_blank"
            css={{ color: colors.white50 }}
          >
            Learn more about ingestion start and end date
          </Link>
        </Stack>
        <ScrollArea css={{ flexGrow: "1" }}>
          <ScrollArea.Viewport>
            <Stack direction="vertical" gap="1">
              {accounts.map((account) => {
                const currentEpoch = accountEpochs.find((a) => a.accountId === account.id);
                return (
                  <>
                    <AccountHeader account={account} />
                    <SelectStartForSingleAccount
                      key={account.id}
                      account={account}
                      currentEpoch={currentEpoch}
                      onSetIngestionDate={onSetIngestionDate}
                      isReconnect={isReconnect}
                    />
                    <SelectEndForSingleAccount
                      key={account.id}
                      account={account}
                      currentEpoch={currentEpoch}
                      onSetIngestionDate={onSetIngestionDate}
                      isReconnect={isReconnect}
                      shouldUpdateHistory={currentEpoch?.shouldUpdateHistory}
                    />
                  </>
                );
              })}
            </Stack>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar orientation="vertical">
            <ScrollArea.Thumb variant="shadowed" />
          </ScrollArea.Scrollbar>
        </ScrollArea>
      </Dialog.Body>
      <Dialog.Footer>
        <ButtonGroup>
          <Button
            variant="primary"
            onClick={onConfirm}
            loading={loading || accounts.length === 0}
            disabled={isOptionRequired && isReconnect && !hasAllAccountsSetCuttoffBefore}
          >
            Confirm
          </Button>
        </ButtonGroup>
      </Dialog.Footer>
    </Dialog>
  );
};

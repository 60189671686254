import React, { useState } from "react";
import { useActiveCompany, PricingFeatures } from "components/companies/ActiveCompanyProvider";
import { DataConsentModal } from "components/common/DataConsentModal";
import useMonetization from "components/monetization/useMonetization";
import { useAI } from "components/AI/useAI";
import { Sparkle3, Crown } from "@puzzle/icons";

import { Button, IconButton, color } from "ve";

export const BaseAskAIButton = ({ onClick, text }: { text?: string; onClick: () => void }) => {
  const { consentToAI, enableAutonomousAccounting } = useAI();
  const [consentModalOpen, setConsentModalOpen] = useState(false);
  const { company, pricePlanFeatureEnabled } = useActiveCompany<true>();
  const { showUpgradeModal } = useMonetization();
  const [consentSent, setConsentSent] = useState(false);

  const isFeatureGated = !pricePlanFeatureEnabled.has(PricingFeatures.puzzle_ai);

  const featureDisabled = !company.features.assistedCategorizationEnabled || !company.consentsToAI;

  const ButtonComponent = text ? Button : IconButton;

  const handleDataConsentConfirmed = async () => {
    setConsentSent(true);
    await consentToAI();
    await enableAutonomousAccounting();
    setConsentSent(false);
    setConsentModalOpen(false);
    onClick?.();
  };

  return (
    <>
      <DataConsentModal
        open={consentModalOpen}
        onOpenChange={setConsentModalOpen}
        onConfirm={handleDataConsentConfirmed}
        consentLoading={consentSent}
      />
      <ButtonComponent
        onClick={() => {
          if (isFeatureGated) {
            showUpgradeModal();
          } else {
            if (featureDisabled) {
              setConsentModalOpen(true);
            } else {
              onClick();
            }
          }
        }}
        // @ts-ignore These sizes are valid in their respective components
        size={text ? "mini" : "medium"}
        variant="secondaryAlt"
        css={{ padding: text ? 10 : "auto" }}
      >
        {text ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 6,
            }}
          >
            <Sparkle3 color={color.white80} size={14} />
            {text}
            {isFeatureGated && <Crown />}
          </div>
        ) : (
          <Sparkle3 color={color.white80} size={12} />
        )}
      </ButtonComponent>
    </>
  );
};

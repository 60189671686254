import { compact, uniqBy } from "lodash";
import { MutationHookOptions } from "@apollo/client";
import { CalendarDateString } from "@puzzle/utils";

import Analytics from "lib/analytics";

import {
  AccountType,
  CategorizationRule,
  CategorizationRuleType,
  DetailConfirmedState,
  UserCategorizationRule,
  UserCategorizationRulesInput,
  UserCategorizationRulesPaginator,
  CategorizationRuleApplicationType,
} from "graphql/types";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

import {
  useCreateRuleMutation,
  useDeactivateCategorizationRuleMutation,
  RulesPageUserRuleFragment,
  RulesPageUserRuleFragmentDoc,
  CreateRuleMutation,
  CreateRuleMutationVariables,
  DeactivateCategorizationRuleMutation,
  DeactivateCategorizationRuleMutationVariables,
} from "./graphql.generated";

export enum RuleOption {
  Retroactive = "Retroactive",
  GoingForward = "GoingForward",
  OnlyThisTransaction = "OnlyThisTransaction",
}

export type CreateRuleInput = {
  replaceRuleId?: string;
  vendorId?: string | null;
  ledgerCoaKey?: string;
  effectiveAt?: CalendarDateString | null;
  matchEntireString: boolean;
  minAmountInclusive?: number | null;
  maxAmountInclusive?: number | null;
  amountQualifier?: string;
  rule: string;
  accountTypes: AccountType[];
  appliesTo?: CategorizationRuleApplicationType[];
};

export const isUserCategorizationRule = (
  rule?: Pick<CategorizationRule, "type"> | null
): rule is UserCategorizationRule => {
  if (rule && rule.type === CategorizationRuleType.User) {
    return true;
  }
  return false;
};

export const useDeactivateRule = ({
  id,
  ...options
}: { id?: string } & MutationHookOptions<
  DeactivateCategorizationRuleMutation,
  DeactivateCategorizationRuleMutationVariables
> = {}) => {
  const { company } = useActiveCompany<true>();
  return useDeactivateCategorizationRuleMutation({
    ...options,

    variables: id ? { input: { id } } : undefined,

    update(cache, variables, ...args) {
      const { data } = variables;
      const rule = data?.deactivateCategorizationRule.matchRule;

      if (!rule) {
        return;
      }

      // NOTE This may get complicated once we add sorting.
      // Either do a best guess or return details.INVALIDATE/refetch
      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          // @ts-expect-error expects Reference type
          userCategorizationRules(
            value: UserCategorizationRulesPaginator,
            { fieldName, storeFieldName, toReference, readField }
          ) {
            const keyArgs: UserCategorizationRulesInput = JSON.parse(
              storeFieldName.replace(`${fieldName}:`, "")
            ).input;
            const { filter, companyId } = keyArgs;
            if (companyId !== company.id) {
              return value;
            }

            if (filter?.isActive) {
              return {
                ...value,
                // Remove the deactivated rule
                rules: uniqBy(
                  value.rules.filter((ref) => readField("id", ref) !== rule.id),
                  "__ref"
                ),
              };
            } else {
              return {
                ...value,
                // Append the deactivated rule
                rules: uniqBy([...value.rules, toReference(rule)], "__ref"),
              };
            }
          },
        },
      });

      options.update?.(cache, variables, ...args);
    },

    onCompleted(data, ...args) {
      const rule = data?.deactivateCategorizationRule.matchRule;
      Analytics.ruleDisabled({
        ruleId: rule!.id,
      });

      options.onCompleted?.(data, ...args);
    },
  });
};

export const useCreateRule = ({
  affectedTransaction,
  location,
  ...options // the form values
}: MutationHookOptions<CreateRuleMutation, CreateRuleMutationVariables> & {
  affectedTransaction?: {
    detail: {
      id: string;
      rule?: { id: string } | null;
    };
  };
  location?: string;
} = {}) => {
  const { company } = useActiveCompany<true>();

  // Rules asynchronously categorize, so we manually assign the new rule to the fragment, assuming it will eventually match.
  // We need a better long-term strategy. This doesn't work if you ever refetch the transaction or refresh the page.

  return useCreateRuleMutation({
    ...options, // the form values

    update(cache, variables, updateOptions) {
      const { input } = updateOptions.variables || {};
      const { data, errors } = variables;
      const rule = data?.createUserCategorizationRule.matchRule;

      if (!rule || !input) {
        return;
      }

      const updateTransactionRule = (rollback = false) => {
        if (!affectedTransaction) {
          return;
        }

        const originalRule = affectedTransaction.detail.rule;
        const finalRule = rollback ? originalRule : rule;

        cache.modify({
          id: cache.identify(affectedTransaction.detail),
          fields: {
            rule: (value, { toReference }) => (finalRule ? toReference(finalRule) : undefined),
            confirmedState: (value) => (rollback ? value : DetailConfirmedState.UserRuleAssigned),
            vendor: (value) => (rollback ? value : rule?.vendor),
            category: (value) => (rollback ? value : rule?.category),
          },
        });
      };

      // Deactivate the original rule
      let originalRule: RulesPageUserRuleFragment | null;
      if (input.replaceRuleId) {
        const options = {
          id: `UserCategorizationRule:${input.replaceRuleId}`,
          fragmentName: "rulesPageUserRule",
          fragment: RulesPageUserRuleFragmentDoc,
        };
        originalRule = cache.readFragment<RulesPageUserRuleFragment>(options);

        if (originalRule) {
          cache.writeFragment<RulesPageUserRuleFragment>({
            ...options,
            data: {
              ...originalRule,
              isActive: false,
            },
          });
        }
      }

      // NOTE This may get complicated once we add sorting.
      // Either do a best guess or return details.INVALIDATE/refetch
      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          // @ts-expect-error expects Reference type
          userCategorizationRules(
            value: UserCategorizationRulesPaginator,
            { fieldName, storeFieldName, readField, toReference }
          ) {
            const keyArgs: UserCategorizationRulesInput = JSON.parse(
              storeFieldName.replace(`${fieldName}:`, "")
            ).input;
            const { filter, companyId } = keyArgs;
            if (companyId !== company.id) {
              return value;
            }

            if (filter?.isActive) {
              return {
                ...value,
                rules: uniqBy(
                  [
                    // Remove the replaced rule now that it's disabled
                    ...value.rules.filter((r) => readField("id", r) !== input.replaceRuleId),
                    toReference(rule),
                  ],
                  "__ref"
                ),
              };
            } else {
              return {
                ...value,
                rules: uniqBy(
                  compact([...value.rules, originalRule && toReference(originalRule)]),
                  "__ref"
                ),
              };
            }
          },
        },
      });

      // Is it ever not a UserCategorizationRule? Should we handle it somehow?
      if (!isUserCategorizationRule(rule) || errors) {
        // Rollback the optimistic update
        updateTransactionRule(true);
        console.error("Create rule should have returned a user categorization rule");
      }

      updateTransactionRule();

      options.update?.(cache, variables, updateOptions);
    },

    onCompleted(data, ...args) {
      const rule = data?.createUserCategorizationRule.matchRule;
      Analytics.ruleSaved({
        ruleId: rule?.id,
        ruleType: rule?.appliesTo?.[0],
        location,
      });

      options.onCompleted?.(data, ...args);
    },
  });
};

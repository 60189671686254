import React, { useMemo } from "react";
import { Alert, Checkbox, Tooltip } from "@puzzle/ui";
import { S, Text, Stack, Button } from "ve";

import { YearlyDiscountBadge } from "./Badges";
import { AIChecklist, Sparkle } from "@puzzle/icons";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { PuzzleAddon, PuzzlePriceBillingInterval } from "graphql/types";
import { calcPriceRounded } from "./utils";
import { usePlansPricingModal } from "./usePlansPricingModal";
import {
  addOnContainerRecipe,
  bulletItemsContainerStyle,
  bulletItemStyle,
} from "./plansPricingModal.css";
import { formatMoney } from "@puzzle/utils";
import { circledIconStyle } from "../PricingModal/AddOnBox/addonStyles.css";

const ADDO_ON_CHECKBOX_SIZE = 14;
const SPARKLE_ICON_SIZE = 24;
const AI_ICON_SIZE = 44;

const getAddOnCheckBoxDataTestId = (name: string) => `add-on-${name}-checkbox`;
const getAddOnContainerDataTestId = (name: string) => `add-on-${name}-box`;
const getAddOnNameDataTestId = (name: string) => `add-on-${name}-name`;

const getIcon = (addOnName: string | undefined) => {
  if (addOnName === "addon1") {
    return <Sparkle size={SPARKLE_ICON_SIZE} />;
  }
  return <AIChecklist size={AI_ICON_SIZE} />;
};

type AddOnBoxProps = PuzzleAddon & {
  CtaComponent?: React.ReactNode;
  showPrices?: boolean;
  disabled?: boolean;
  tooltipMessage?: string;
  showCTAButton?: boolean;
};

export const AddOnBox = ({
  CtaComponent,
  showPrices = true,
  disabled = false,
  tooltipMessage = "",
  showCTAButton = true,
  ...props
}: AddOnBoxProps) => {
  const { id, name, displayName, monthlyPrice, annualPrice, contentHeading, contentBullets } =
    props;
  const { company } = useActiveCompany<true>();
  const puzzleSubscriptionState = company.puzzleSubscriptionState;
  const { toggleAddOn, preSelectedAddOnIds, selectedCycle } = usePlansPricingModal();
  const currentAddOns = useMemo(() => {
    return puzzleSubscriptionState?.billedPlan?.addons.map((addOn) => addOn.id) ?? [];
  }, [puzzleSubscriptionState?.billedPlan?.addons]);
  const selected = currentAddOns.includes(id) || preSelectedAddOnIds.includes(id);
  const formattedPrice = formatMoney({
    currency: "USD",
    amount: calcPriceRounded(selectedCycle, props, true),
  });

  const cta = CtaComponent ?? (
    <Tooltip content={tooltipMessage}>
      <div style={{ marginLeft: "auto" }}>
        <Checkbox
          data-testid={getAddOnCheckBoxDataTestId(name)}
          size={ADDO_ON_CHECKBOX_SIZE}
          checked={disabled ? false : selected}
          disabled={disabled || currentAddOns.includes(id)}
          interactive={false}
        />
      </div>
    </Tooltip>
  );

  return (
    <Button
      disabled={disabled}
      data-testid={getAddOnContainerDataTestId(name)}
      className={addOnContainerRecipe({
        state: disabled ? "disabled" : selected && showCTAButton ? "active" : "default",
      })}
      onClick={!CtaComponent ? () => toggleAddOn(id) : undefined}
    >
      <Stack gap={S.$2}>
        <Stack gap={S.$2} direction="horizontal" css={{ justifyContent: "flex-start" }}>
          <div className={circledIconStyle}>{getIcon(name)}</div>
          <Stack gap={S.$1} css={{ justifyContent: "space-between" }}>
            <Text variant="headingM" color="gray200" data-testid={getAddOnNameDataTestId(name)}>
              {displayName}
            </Text>
            {showPrices && (
              <Stack
                gap={S.$1}
                direction="horizontal"
                css={{
                  alignItems: "center",
                }}
              >
                <Text variant="headingM" color="gray200">
                  {formattedPrice}
                </Text>
                <Text variant="headingM" color="gray400">
                  / month
                </Text>
                {selectedCycle === PuzzlePriceBillingInterval.Year && !!annualPrice && (
                  <YearlyDiscountBadge />
                )}
              </Stack>
            )}
          </Stack>

          {showCTAButton && cta}
        </Stack>
        <Stack gap={S.$1} css={{ justifyContent: "flex-start" }}>
          <Text variant="headingM" color="gray400">
            {contentHeading}
          </Text>
          <ul className={bulletItemsContainerStyle}>
            <Text variant="bodyM" color="gray400">
              {contentBullets.map((bullet, index) => (
                <li className={bulletItemStyle} key={index}>
                  {bullet}
                </li>
              ))}
            </Text>
          </ul>
        </Stack>
        {!!monthlyPrice && !annualPrice && (
          <Alert>{displayName} add-on is only available on a monthly plan.</Alert>
        )}
      </Stack>
    </Button>
  );
};

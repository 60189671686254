import React, { useMemo, useEffect } from "react";
import { styled, IconButton, Tabs } from "@puzzle/ui";
import { Settings } from "@puzzle/icons";
import useAppRouter from "lib/useAppRouter";
import Analytics from "lib/analytics";

import { useInboxContext, useInboxStore } from "./InboxContext";
import UpdatesList from "./UpdatesList";
import TaskList from "./TaskList";
import { DetailPane } from "./DetailPane";

const Root = styled("div", {
  height: "100vh",
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "minmax(344px, 1fr) minmax(0, 600px)",
});

const List = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  // TODO use custom scrollbar
  overflowY: "auto",
});

const Detail = styled("div", {
  height: "100%",

  background: "#29293B",
});

const FeedRoot = styled("div", {
  display: "flex",
  flex: 1,

  overflow: "auto",
  padding: "0 $2 $2 $2",

  a: { color: "$gray300" },
});

const CountBadge = styled("span", {
  background: "#F8F8FA14",
  borderRadius: "6px",
  marginLeft: "10px",
  padding: "2px $0h 2px $0h",
  textVariant: "$bodyXS",
  lineHeight: "14px",
});

const TABS = [
  { title: "My tasks", href: "/inbox/tasks", value: "tasks" },
  { title: "Updates", href: "/inbox/updates", value: "updates" },
  { title: "Archived", href: "/inbox/archived", value: "archived" },
] as const;

const FeedMap = {
  updates: <UpdatesList />,
  tasks: <TaskList />,
  archived: <TaskList archived />,
};

export const Inbox = ({ children }: React.PropsWithChildren<unknown>) => {
  const { goToNotificationSettings, router } = useAppRouter();
  const path = router.asPath;

  // This is hack to avoid user to hit /inbox, and making actions on their tasks
  // When user make actions in /inbox there is a weird bug that fails to send
  // Knock requests.
  const inboxRoute = useMemo(() => TABS.find((t) => path.startsWith(t.href)), [path]);
  if (!inboxRoute) {
    router.replace(TABS[0].href);
  }

  const activeTab = inboxRoute || TABS[0];

  const { setActiveItem } = useInboxContext();

  useEffect(() => {
    setActiveItem(undefined);
  }, [activeTab, setActiveItem]);

  useEffect(() => {
    Analytics.inboxViewed({ tab: activeTab.value });
  }, [activeTab.value]);

  const { unseen_count: tasksCount } = useInboxStore("tasks", (state) => state.metadata);
  const { unseen_count: updatesCount } = useInboxStore("updates", (state) => state.metadata);
  const counts = useMemo(
    () => ({
      tasks: tasksCount,
      updates: updatesCount,
      archived: 0,
    }),
    [tasksCount, updatesCount]
  );

  const tabsWithCounts = useMemo(
    () =>
      TABS.map((tab) => {
        const count = counts[tab.value];

        return {
          ...tab,
          title: (
            <>
              {tab.title} {count > 0 && <CountBadge>{count}</CountBadge>}
            </>
          ),
        };
      }),
    [counts]
  );

  return (
    <Root>
      <List>
        <Tabs
          css={{ paddingLeft: "$2" }}
          items={tabsWithCounts}
          value={activeTab.value}
          actions={
            <IconButton
              onClick={() => {
                Analytics.notificationSettingsClicked({
                  location: "inbox",
                });
                goToNotificationSettings();
              }}
            >
              <Settings />
            </IconButton>
          }
        />

        <FeedRoot>{FeedMap[activeTab.value]}</FeedRoot>
      </List>

      <Detail>
        <DetailPane />
      </Detail>
    </Root>
  );
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { usePrevious, usePreviousDistinct, useSet } from "react-use";
import { keyBy } from "lodash";
import { parseDate } from "@internationalized/date";

import { Box, Button as ButtonVE, S } from "ve";
import { styled, Dialog, Text, Button, RangeValue, Stack } from "@puzzle/ui";
import { formatMoney, formatAccountNameWithInstitution } from "@puzzle/utils";
import { ChevronLeft, ChevronRight, CaretDown } from "@puzzle/icons";

import Loader from "components/common/Loader";
import Slider from "./Slider";

import { BasicTransactionFragment } from "../../graphql.generated";
import { CategoryFragment } from "graphql/types";
import useTransactionsNeedingCategorizationReview from "components/transactions/useSignificantTransactions";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import useSingleTransaction, {
  UpdateCategoryMetricsLocations,
  UpdateCategoryMetricsView,
} from "../../hooks/useSingleTransaction";
import useCategories from "components/common/hooks/useCategories";
import CategorySearch from "./CategorySearch";
import { useChartOfAccounts } from "components/dashboard/Accounting/shared/useChartOfAccounts";
import Analytics from "lib/analytics";
import { AccountCell } from "components/transactions/Cells";

const Actions = styled("div", {});

// NOTE: Relies on dummy nodes to keep the grid
const Paginator = styled("div", {
  marginTop: "$2",
  display: "grid",
  gridTemplateColumns: "1fr 100px 1fr",
  alignItems: "center",
  gap: "$2",
  justifyContent: "space-between",

  textStyle: "$bodyS",
  color: "$white",

  [`${Button}`]: {
    whiteSpace: "nowrap",
  },

  "& > *:first-child": {
    justifySelf: "start",
  },

  "& > span": {
    textAlign: "center",
  },

  "& > *:last-child": {
    justifySelf: "end",
  },
});

const SummaryContainer = styled(Stack, {
  marginBottom: "$2",
  padding: "$2 $3",
  backgroundColor: "$blackA20",
  borderRadius: "$2",
});

const Details = styled("div", {
  display: "flex",
  justifyContent: "space-between",

  defaultVariants: {
    variant: "primary",
  },

  variants: {
    variant: {
      primary: {
        textStyle: "$bodyM",
        color: "$gray50",
      },
      secondary: {
        textStyle: "$bodyS",
        color: "$gray200",
      },
    },
  },
});

const IconContainer = styled("div", {
  display: "flex",
  gap: "$1",
  alignItems: "center",
  maxWidth: "80%",
});

export const checkCoaKeyForExpenseAndRevenue = (coaKey: string): string | undefined => {
  if (["transaction_income", "other_expenses"].includes(coaKey)) {
    return coaKey!.split("_")[1];
  }
  return undefined;
};

const TransactionSummary = ({ transaction }: { transaction: BasicTransactionFragment }) => {
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "medium" });

  return (
    <SummaryContainer gap="1" direction="horizontal">
      <Stack gap="1" css={{ flex: 1 }}>
        <Details>
          <span>{transaction.descriptor}</span>

          <span>
            {formatMoney({ currency: "USD", amount: transaction.amount }, { truncateValue: true })}
          </span>
        </Details>

        <Details variant="secondary">
          <IconContainer>
            <AccountCell account={transaction.account} />
            <span>{formatAccountNameWithInstitution(transaction.account)}</span>
          </IconContainer>
          <span>{dateFormatter.format(parseDate(transaction.date))}</span>
        </Details>
      </Stack>
    </SummaryContainer>
  );
};

export type CategorizeStepResults = {
  totalCategorized: number;
  totalReviewed: number;
  range?: RangeValue;
};

// TODO keep these in mind:
// Accounts payable step
// Capitalized step

// TODO There's a ton in here because the layout changes the footer when we search for a category.
// It was harder to split up than I realized.
const CategorizeStep = ({
  range,
  onContinue,
  pageSize = 10,
  minAmount,
  basic,
  metricsLocation,
}: {
  range?: RangeValue;
  onContinue: (results: CategorizeStepResults) => void;
  pageSize?: number;
  minAmount?: number;
  basic?: boolean;
  metricsLocation?: UpdateCategoryMetricsLocations;
}) => {
  useEffect(() => {
    Analytics.categorizerRoundStarted({
      startDate: range?.[0].toString(),
      endDate: range?.[1].toString(),
      location: metricsLocation,
    });
  }, [range, metricsLocation]);

  const { uncategorizedPermaKeys } = useCategories();
  const { categories: coaData } = useChartOfAccounts();
  const categoryTypes = useMemo(() => {
    const types = coaData?.map((account) => {
      return { id: account.displayId, type: account.type };
    });
    return keyBy(types, "id");
  }, [coaData]);
  const { company } = useActiveCompany<true>();
  const { transactionsByPage, fetchNextPage, loading, hasMore, nextPageCount, totalItems } =
    useTransactionsNeedingCategorizationReview(company.id, {
      pageSize,
      startDate: range?.[0].toString(),
      endDate: range?.[1].toString(),
    });

  const transactions = transactionsByPage?.[transactionsByPage?.length - 1] || [];

  // Keeps track of how many transactions were categorized across all pages
  const [categorizedSet, categorizedSetActions] = useSet(new Set<string>([]));
  const totalCategorized = categorizedSet.size;
  const totalReviewed = useMemo(
    () => transactionsByPage?.reduce((result, page) => result + page.length, 0) || 0,
    [transactionsByPage]
  );

  const maxIndex = Math.min(transactions.length - 1, pageSize - 1);
  const [index, setIndex] = useState(0);
  const lastIndex = usePreviousDistinct(index);

  const totalViewed = totalReviewed - pageSize + index + 1;

  const currentTransaction = transactions.length > 0 ? transactions[index] : undefined;
  const { updateCategory } = useSingleTransaction({ id: currentTransaction?.id });
  const [isSearching, setIsSearching] = useState(false);
  const [isLeavingSearch, setIsLeavingSearch] = useState(false);
  const [subset, setSubset] = useState<string | undefined>(undefined);

  const searchingDirection = useMemo(() => {
    return isLeavingSearch ? "backward" : "forward";
  }, [isLeavingSearch]);

  useEffect(() => {
    if (isLeavingSearch) {
      setIsLeavingSearch(false);
    }
  }, [isLeavingSearch]);

  const onPrevious = useMemo(() => {
    if (index > 0) {
      return () => setIndex((i) => Math.max(i - 1, 0));
    }

    return undefined;
  }, [index]);

  const fireRoundFinished = useCallback(
    (closed = false) => {
      Analytics.categorizerRoundFinished({
        startDate: range?.[0].toString(),
        endDate: range?.[1].toString(),
        transactionsCategorized: totalCategorized,
        transactionsLoaded: totalReviewed,
        transactionsViewed: totalViewed,
        closed,
        location: metricsLocation,
      });
    },
    [range, totalCategorized, totalReviewed, totalViewed, metricsLocation]
  );

  const didFinish = useRef(false);
  const finish = useCallback(() => {
    didFinish.current = true;

    fireRoundFinished();

    onContinue({
      totalCategorized,
      totalReviewed,
      range,
    });
  }, [fireRoundFinished, onContinue, range, totalCategorized, totalReviewed]);

  useEffect(() => {
    return () => {
      if (!didFinish.current) {
        fireRoundFinished(true);
      }
    };
  }, [fireRoundFinished]);

  const onNext = useMemo(() => {
    if (index < maxIndex) {
      return () => setIndex((i) => Math.min(i + 1, maxIndex));
    }
    return undefined;
  }, [index, maxIndex]);

  const wasLoading = usePrevious(loading);
  useEffect(() => {
    if (wasLoading && !loading && transactions.length === 0) {
      finish();
    }
  }, [finish, loading, transactions.length, wasLoading]);

  const onSelect = useCallback(
    async (category: CategoryFragment) => {
      if (!currentTransaction) {
        return;
      }

      const categorized = !uncategorizedPermaKeys!.includes(category.permaKey);
      if (categorized) {
        categorizedSetActions.add(currentTransaction.id);
      } else {
        categorizedSetActions.remove(currentTransaction?.id);
      }

      const checkSubset = checkCoaKeyForExpenseAndRevenue(category.coaKey!);

      if (!isSearching && checkSubset) {
        setIsSearching(true);
        setSubset(checkSubset);
        return;
      }

      updateCategory({
        category,
        metrics: {
          location: metricsLocation,
          component: UpdateCategoryMetricsView.TopTransactionsModal,
        },
      });
      // Give enough time to show it's active now
      setTimeout(() => {
        setIsSearching(false);
        setSubset(undefined);
        onNext?.();
      }, 100);
    },
    [
      categorizedSetActions,
      currentTransaction,
      isSearching,
      onNext,
      uncategorizedPermaKeys,
      updateCategory,
      metricsLocation,
    ]
  );

  const loadMore = useCallback(() => {
    Analytics.categorizerLoadMoreClicked({ location: metricsLocation });
    fetchNextPage();
    setIndex(0);
  }, [fetchNextPage, metricsLocation]);

  if (loading || !currentTransaction) {
    return (
      <Dialog.Body>
        <Loader css={{ margin: "$10 0" }} size={32} />
      </Dialog.Body>
    );
  }

  return (
    <>
      <Dialog.Body css={{ paddingTop: basic ? 0 : undefined }}>
        <Box css={{ marginBottom: S["4"] }}>
          Reviewing transactions increases the accuracy of your financials and helps align our
          categorization engine to your business and reporting preferences.
        </Box>
        <Slider direction={lastIndex && lastIndex > index ? "backward" : "forward"}>
          <Box key={currentTransaction.id}>
            <TransactionSummary transaction={currentTransaction} />

            <Slider direction={searchingDirection}>
              {isSearching ? (
                <Stack gap="0">
                  <div>
                    <Button
                      variant="minimal"
                      size="compact"
                      onClick={() => {
                        setIsLeavingSearch(true);
                        setIsSearching(false);
                      }}
                      prefix={<ChevronLeft color="currentColor" />}
                    >
                      Back
                    </Button>
                  </div>
                  <CategorySearch
                    key="search"
                    onSelect={onSelect}
                    categoryTypes={categoryTypes}
                    subset={subset}
                  />
                </Stack>
              ) : (
                <Actions key="shortcuts">
                  <Stack gap="2">
                    <Text type="bodyS" color="gray400">
                      {currentTransaction.detail.category.name !== "No Category"
                        ? "This transaction is categorized as follows. Confirm, or select a different category."
                        : "Select a category. You can change the category anytime."}
                    </Text>
                    <span>
                      <ButtonVE
                        fullWidth={true}
                        fullWidthContent={true}
                        variant="secondary"
                        onClick={() => {
                          setIsSearching(true);
                          setSubset(undefined);
                        }}
                        suffixElement={<CaretDown />}
                      >
                        {currentTransaction.detail.category.name}
                      </ButtonVE>
                    </span>
                  </Stack>
                  <Paginator>
                    {onPrevious ? (
                      <Button
                        variant="secondary"
                        size="compact"
                        prefix={<ChevronLeft color="currentColor" />}
                        onClick={onPrevious}
                      >
                        Previous
                      </Button>
                    ) : (
                      <span />
                    )}

                    <span>
                      {index + 1} of {maxIndex + 1}
                    </span>

                    {onNext ? (
                      <Stack gap="1" direction="horizontal">
                        <Button
                          variant="secondary"
                          size="compact"
                          suffix={<ChevronRight color="currentColor" />}
                          onClick={onNext}
                        >
                          Skip
                        </Button>
                        {currentTransaction.detail.category.name !== "No Category" ? (
                          <Button
                            variant="primary"
                            size="compact"
                            suffix={<ChevronRight color="currentColor" />}
                            onClick={() => onSelect(currentTransaction.detail.category)}
                          >
                            Confirm
                          </Button>
                        ) : null}
                      </Stack>
                    ) : (
                      <span />
                    )}
                  </Paginator>
                </Actions>
              )}
            </Slider>
          </Box>
        </Slider>
      </Dialog.Body>

      {hasMore && index === maxIndex ? (
        <Slider direction={searchingDirection}>
          <Dialog.Footer
            divider
            align={isSearching ? "left" : "right"}
            key={isSearching ? "searching" : "shortcuts"}
          >
            <Dialog.Actions>
              <Button variant="primary" onClick={loadMore}>
                Load {nextPageCount} more
              </Button>
            </Dialog.Actions>
          </Dialog.Footer>
        </Slider>
      ) : null}
    </>
  );
};

export default CategorizeStep;

import { CalendarDate, parseDate } from "@internationalized/date";
import { styled } from "@puzzle/ui";
import { CategoryFragment, ReportingClassType } from "graphql/types";
import { TransactionPageAccountFragment, BasicTransactionFragment } from "./graphql.generated";

export type TransactionRowData = {
  date: CalendarDate;
  availableOn: CalendarDate | null;
  account: TransactionPageAccountFragment;
  category: CategoryFragment;
  detailId: string;
  amount: string;
  transaction: BasicTransactionFragment;
  descriptor: string;
  transactionId: string;
  id: string;
  classSegments: {
    id: string;
    name: string;
    description?: string | null;
    reportingClass: { id: string; name: string; type: ReportingClassType };
  }[];
  subRows?: SplitRowData[];
};

export type SplitRowData = TransactionRowData & { splitIndex: number };

export const StatusRoot = styled("div", {
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: "$1",
  "& > *": { flexShrink: "0" },
});

export const transactionToRowData = (
  transactions?: BasicTransactionFragment[]
): TransactionRowData[] => {
  // console.log("OLD TRANSFORM: transactionToRowData -> ", transactions); // turn on to see how often this runs
  return (
    transactions?.map((t) => ({
      isSplit: false,
      date: parseDate(t.date),
      availableOn: t.availableOn ? parseDate(t.availableOn) : null,
      // TODO worth de-duping..?
      // 10 months later... YES! :)
      transaction: t, // At this point, we are stuffing the whole transaction (and everything we might not need) into the row data
      transactionId: t.id,
      detailId: t.id,
      account: t.account,
      amount: t.amount,
      descriptor: t.descriptor,
      category: t.detail.category,
      id: t.id,
      classSegments: t.detail.classSegments,
      subRows: t.splits.map((s, i) => ({
        id: s.id,
        splitIndex: i,
        transaction: t, // Again, stuffing the whole transaction (and everything we might not need) into the row data
        category: t.detail.category,
        classSegments: s.classSegments,
        transactionId: t.id,
        detailId: s.id,
        account: t.account,
        date: parseDate(t.date),
        availableOn: t.availableOn ? parseDate(t.availableOn) : null,
        descriptor: s.descriptor,
        amount: s.amount,
        status: t.detail.confirmedState,
      })) as SplitRowData[],
    })) ?? []
  );
};

/*

  transactionToTableRow is a new transform function that returns only what is needed for the table row.
  We are using it for testing of performance improvements presently.
  Soon, we should replace transactionToRowData with transactionToTableRow
  to avoid sending duplicated and unnecessary data to the table rows.

*/

export const transactionToTableRow = (transactions?: BasicTransactionFragment[]): any => {
  console.log("NEW TRANSFORM: transactionToTableRow -> ", transactions);
  const tableRows =
    transactions?.map((t) => ({
      transaction: {
        date: t.date, // duplicate data from date field below (which is parsed, but this one is not)
        linkedBills: t.linkedBills,
        splits: t.splits,
        amount: t.amount,
        links: t.links,
        eventViews: t.eventViews,
        id: t.id,

        detail: {
          category: {
            __typename: t.detail.category.__typename,
          },
          confirmedState: t.detail.confirmedState,
          classSegments: t.detail.classSegments,
          transactionId: t.detail.transactionId,
        },
      },
      date: parseDate(t.date),
      amount: t.amount,
      account: t.account,
      transactionId: t.id,
      id: t.id,
    })) ?? [];
  console.log({ tableRows });
  return tableRows;
};

export enum TransactionColId {
  "date" = "date",
  "account" = "account",
  "vendor_customer" = "vendor_customer",
  "descriptor" = "descriptor",
  "category" = "category",
  "department" = "department",
  "location" = "location",
  "classes" = "classes",
  "classifications" = "classifications",
  "status" = "status",
  "amount" = "amount",
}

export type ColumnVisibility = Record<TransactionColId, boolean>;

export const defaultColumnVisibility: ColumnVisibility = {
  date: true,
  account: false, // Source
  vendor_customer: true,
  descriptor: true, // Description
  category: true,
  department: false,
  location: false,
  classes: false,
  classifications: false,
  status: true,
  amount: true,
};

export const defaultColumnOrder = [
  "mrt-row-select", // the select checkbox
  "date",
  "vendor_customer",
  "amount",
  "descriptor",
  "category",
  "status",

  "account",
  "department",
  "location",
  "classes",
  "classifications",
];

export const TransactionColHeaderMapping = {
  date: "Date",
  account: "Source",
  vendor_customer: "Vendor/Customer",
  descriptor: "Description",
  category: "Category",
  department: "Department",
  location: "Location",
  classes: "Classes",
  classifications: "Classifications",
  status: "Status",
  amount: "Amount",
};

export const HighPriorityCoaKeys = [
  "transfer_account",
  "processor_transfers",
  "preferred_stock",
  "venture_safe_note",
  "equity",
  "miscellaneous_income",
  "miscellaneous_expenses",
  "other_revenue",
  "other_expenses",
  "income",
  "uncategorized_expenses",
  "uncategorized_reimbursements",
  "no_category",
];

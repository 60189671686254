import React from "react";
import useDeel from "./useDeel";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import { DeelLogo } from ".";
import { IntegrationDisplayComponent } from "../shared";

type DeelListItemProps = {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}
export const TITLE = "Deel";

const DeelListItem = ({ DisplayComponent, companyId }: DeelListItemProps) => {
  const { onClickConnect, connection, loading } = useDeel({ companyId });

  return (
    <DisplayComponent
      integrationType={IntegrationType.Deel}
      connectIntegration={onClickConnect}
      title={TITLE}
      logo={<DeelLogo />}
      connection={connection}
      loading={loading}
      isBeta={true}
    />
  );
};

export default DeelListItem;

import React from "react";

import { FilterList } from "@puzzle/icons";
import { Text } from "@puzzle/ui";
import { CategoryFragment } from "graphql/types";

import { useTransactionsPage } from "../TransactionsProvider";
import { CategoriesFilter } from "components/common/CategoriesFilter";

export const CategoriesColumnHeader = () => {
  const { selectedCategories, setFilter } = useTransactionsPage();

  return (
    <CategoriesFilter
      trigger={
        <div>
          <FilterList height={14} width={16} style={{ marginTop: 11 }} />
          {selectedCategories.length > 0 && (
            <Text css={{ fontSize: "10px" }}>{selectedCategories.length}</Text>
          )}
        </div>
      }
      onCategoriesChange={(values: CategoryFragment[]) => {
        setFilter({
          ledgerCoaKeys: values.map((c) => c.permaKey),
        });
      }}
      selectedCategories={selectedCategories}
      footer
    />
  );
};

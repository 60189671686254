import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DialogProps, RangeValue } from "@puzzle/ui";
import { Dialog } from "@puzzle/ui";
import IntroductionStep from "./IntroductionStep";
import DateRangeStep from "./DateRangeStep";
import CategorizeStep from "./CategorizeStep";
import { CategorizeStepResults } from "./CategorizeStep";
import CompleteStep from "./CompleteStep";
import useAppRouter from "lib/useAppRouter";
import useTransactionsNeedingCategorizationReview from "components/transactions/useSignificantTransactions";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { UpdateCategoryMetricsLocations } from "../hooks/useSingleTransaction";
import { endOfMonth } from "@internationalized/date";

export enum TopTransactionsStep {
  Introduction = "Introduction",
  SelectRange = "SelectRange",
  Categorize = "Categorize",
  Complete = "Complete",
}

type TopTransactionsModalProps = {
  /**
   * This callback runs after the categorizing step.
   * If undefined, the user is asked to select another date range (or they see the completion step).
   */
  onCategorizingComplete?: () => void;
  /**
   * The initial step for this flow.
   * - Introduction requires selecting a date range
   * - Categorize defaults to all-time transactions, but can be overridden with initialRange.
   */
  initialStep?: `${TopTransactionsStep.Introduction | TopTransactionsStep.Categorize}`;
  initialRange?: RangeValue;
  basic?: boolean;
  metricsLocation?: UpdateCategoryMetricsLocations;
};

export const TopTransactionsModalContent = ({
  open,
  setOpen,
  onCategorizingComplete,
  initialStep = TopTransactionsStep.Introduction,
  initialRange,
  basic,
  metricsLocation,
}: TopTransactionsModalProps & { open: boolean; setOpen: (open: boolean) => void }) => {
  const { goHome } = useAppRouter();
  const [step, setStep] = useState<`${TopTransactionsStep}`>(
    initialStep ?? TopTransactionsStep.Introduction
  );
  const [range, setRange] = useState<RangeValue | undefined>(initialRange);
  const { company } = useActiveCompany();
  const [categorizedResults, setCategorizedResults] = useState<CategorizeStepResults>();

  // Are there any significant transactions in any period?
  // TODO Need a month-wide overview using new CategorizationStats.
  const { transactions, loading, refetch } = useTransactionsNeedingCategorizationReview(
    company!.id,
    {
      prefetch: false,
      pageSize: 10,
      startDate: range ? range[0].toString() : undefined,
      endDate: range ? range[1].toString() : undefined,
    }
  );

  // Do an initial check. Should the button even be visible if everything's categorized?
  useEffect(() => {
    if (
      range &&
      !loading &&
      transactions.length === 0 &&
      step === TopTransactionsStep.Introduction
    ) {
      setStep(TopTransactionsStep.Complete);
    }
  }, [loading, step, range, transactions.length]);

  useEffect(() => {
    if (open) {
      return;
    }

    // Reset
    setTimeout(() => {
      setStep(TopTransactionsStep.Introduction);
      setRange(undefined);
      setCategorizedResults(undefined);
    }, 500);
  }, [open, range]);

  const goToDashboard = useCallback(() => {
    setOpen(false);
    // Wait for animation?
    setTimeout(() => {
      // TODO add querystrings to homepage to support date range
      goHome();
    }, 100);
  }, [goHome, setOpen]);

  const stepComponents = useMemo(
    () => ({
      [TopTransactionsStep.Introduction]: (
        <IntroductionStep onContinue={() => setStep(TopTransactionsStep.SelectRange)} />
      ),

      [TopTransactionsStep.SelectRange]: (
        <DateRangeStep
          previousRange={range}
          previousResults={categorizedResults}
          onGoToDashboard={goToDashboard}
          onComplete={() => setStep(TopTransactionsStep.Complete)}
          onContinue={(range) => {
            setOpen(true);
            setRange([range[0], endOfMonth(range[1])]);
            setStep(TopTransactionsStep.Categorize);
          }}
        />
      ),

      [TopTransactionsStep.Categorize]: (
        <CategorizeStep
          basic={basic}
          range={range}
          // TODO CategorizeStep should use promise to show loading state
          onContinue={async (results) => {
            if (onCategorizingComplete) {
              onCategorizingComplete();
            } else {
              const result = await refetch();
              setStep(
                result.length === 0 ? TopTransactionsStep.Complete : TopTransactionsStep.SelectRange
              );
              setCategorizedResults(results);
            }
          }}
          metricsLocation={metricsLocation}
        />
      ),

      [TopTransactionsStep.Complete]: <CompleteStep onContinue={goToDashboard} />,
    }),
    [
      basic,
      categorizedResults,
      goToDashboard,
      onCategorizingComplete,
      range,
      refetch,
      setOpen,
      metricsLocation,
    ]
  );

  return <>{stepComponents[step]}</>;
};

const TopTransactionsModal = ({
  defaultOpen = false,
  open: externalOpen,
  onOpenChange,
  basic,
  ...props
}: DialogProps & TopTransactionsModalProps) => {
  const [_open, _setOpen] = useState(defaultOpen);
  const open = externalOpen ?? _open;
  const setOpen = onOpenChange ?? _setOpen;

  // TODO Bwahhh, title is going to change for these steps:
  // - Which account is being paid?
  // - Should this be capitalized?
  // Maybe make a layout component with a default title...
  // Also, how sophisticated should the animation be...
  return (
    <Dialog open={open} onOpenChange={setOpen} {...props} size="small">
      <Dialog.Title basic={basic} showClose>
        Review transactions for accuracy
      </Dialog.Title>
      <TopTransactionsModalContent open={open} setOpen={setOpen} {...props} />
    </Dialog>
  );
};

export default TopTransactionsModal;

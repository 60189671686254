import { CalendarDate } from "@internationalized/date";
import React, { useMemo } from "react";
import { Stack, Text } from "ve";
import {
  CutoverSegmentType,
  getCutoverScenario,
  getCutoverScenarioData,
  getCutoverScenarioDescription,
} from "../ingestionDateUtils";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { IngestionChart } from "./IngestionChart";
import { IngestionChartLegend } from "./IngestionChartLegend";
import { ingestStartContainerExplainerStyle } from "./ingestionChart.css";

type IngestionStartExplainerProps = {
  ledgerStartDate: CalendarDate;
  ingestStartDate: CalendarDate;
  removeTransactions?: boolean;
};
export const IngestionStartExplainer = ({
  ledgerStartDate,
  ingestStartDate,
  removeTransactions = false,
}: IngestionStartExplainerProps) => {
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "short" });

  const ledgerStartDateString = dateFormatter.format(ledgerStartDate);
  const ingestStartDateString = dateFormatter.format(ingestStartDate);
  const scenario = getCutoverScenario(ledgerStartDate, ingestStartDate, removeTransactions);

  const data = getCutoverScenarioData(scenario, ledgerStartDateString, ingestStartDateString);

  const description = getCutoverScenarioDescription(
    scenario,
    ledgerStartDateString,
    ingestStartDateString
  );

  const labels = [
    `Puzzle ledger start date\n${ledgerStartDateString}`,
    `Ingestion start date\n${ingestStartDateString}`,
  ];

  const sortedLabels = ledgerStartDate.compare(ingestStartDate) < 0 ? labels : labels.reverse();

  const legendValues = useMemo(() => {
    const legendValues = new Set<CutoverSegmentType>();
    data.forEach((dataArray) => {
      dataArray.forEach((item) => {
        if (!item) return;
        legendValues.add(item.type);
      });
    });

    return Array.from(legendValues);
  }, [data]);

  return (
    <Stack className={ingestStartContainerExplainerStyle}>
      <Text variant="bodyS">{description}</Text>
      <IngestionChartLegend legendValues={legendValues}></IngestionChartLegend>
      <IngestionChart data={data} labels={sortedLabels}></IngestionChart>
    </Stack>
  );
};

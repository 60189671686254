import React, { useMemo, useRef, useEffect } from "react";
import { Button, S, color } from "ve";
import { Select } from "@puzzle/ui";
import { zIndex } from "@puzzle/utils";
import { ChevronRight, ChevronLeft, ChevronDoubleLeft } from "@puzzle/icons";

type PaginationToolbarProps = {
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  loading: boolean;
  totals: {
    count: number;
  };
  setPagination: (pagination: { pageIndex: number; pageSize: number }) => void;
  setStoragePageSize: (pageSize: number) => void;
  setFilter: (filter: { pageSize: number }) => void;
}

const BottomToolbar = ({
  pagination,
  loading,
  totals,
  setPagination,
  setStoragePageSize,
  setFilter,
}: PaginationToolbarProps) => {
  const selectPageSizeRef = useRef<HTMLDivElement>(null);

  const pageSizeOptions = useMemo(
    () => [
      { label: "25 per page", value: "25" },
      { label: "50 per page", value: "50" },
      { label: "100 per page", value: "100" },
    ],
    []
  );

  useEffect(() => {
    if (!loading) {
      // Remove the focus from the page size select once the data is loaded.
      // This allows the user to use the keyboard to navigate the table.
      selectPageSizeRef.current?.blur();
    }
  }, [loading]);

  useEffect(() => {
    setStoragePageSize(pagination.pageSize);
    // Call setFilter here to update the queryVariables with the new page size
    setFilter({
      pageSize: pagination.pageSize,
    });
  }, [pagination.pageSize]);

  const { pageIndex, pageSize } = pagination;
  const total = totals.count;
  const pageStartNum = pageIndex * pageSize + 1;
  const pageEndNum = Math.min((pageIndex + 1) * pageSize, total);
  const isPrevDisabled = loading || pageIndex === 0;
  const isNextDisabled = loading || pageEndNum >= total;

  // These constants are needed so that the pagination controls
  // don't move around when the label or select input text changes
  const LABEL_WIDTH = "200px";
  const SELECT_INPUT_WIDTH = "130px";

  const getPageSizeLabel = () => {
    if (loading) {
      return "Loading transactions";
    }
    if (pageEndNum === 1) {
      return "1 transaction";
    }
    if (total <= pageSize) {
      return `${total} transactions`; // handles 0 transactions too
    }
    return `${pageStartNum}-${pageEndNum} of ${total} transactions`;
  };

  return (
    <div
      style={{
        display: "inline-flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        float: "right",
        gap: S.$0h,
        height: S.$8,
      }}
    >
      <div
        style={{
          width: LABEL_WIDTH,
          display: "flex",
          justifyContent: "flex-end", // float right to stop movement of buttons when text changes
        }}
      >
        <label style={{ marginBottom: "1px", marginRight: S.$0h }}>{getPageSizeLabel()}</label>
      </div>
      <Select
        ref={selectPageSizeRef}
        value={String(pageSize)}
        size="mini"
        css={{ width: SELECT_INPUT_WIDTH }}
        onSelectionChange={(value: string) => {
          setPagination({ ...pagination, pageSize: parseInt(value) });
        }}
        variant={"fill"}
        menuCss={{
          zIndex: zIndex("menu"),
        }}
        options={pageSizeOptions}
      />

      <Button
        variant="secondaryAlpha"
        size="compactSquare"
        shape="buttonMoreSquare"
        disabled={isPrevDisabled}
        onClick={() => {
          if (pageIndex === 0) return;
          setPagination({ ...pagination, pageIndex: 0 });
        }}
      >
        <span className="sr-only">Go to first page</span>
        <ChevronDoubleLeft
          width={21}
          height={21}
          fill={isPrevDisabled ? color.white30 : color.white}
        />
      </Button>

      <Button
        variant="secondaryAlpha"
        size="compactSquare"
        shape="buttonMoreSquare"
        disabled={isPrevDisabled}
        onClick={() => {
          if (pageIndex === 0) return;
          setPagination({ ...pagination, pageIndex: pageIndex - 1 });
        }}
      >
        <span className="sr-only">Go to previous page</span>
        <ChevronLeft
          style={{ display: "flex", alignItems: "center" }}
          width={14}
          height={14}
          fill={isPrevDisabled ? color.white30 : color.white}
        />
      </Button>

      <Button
        variant="secondaryAlpha"
        size="compactSquare"
        shape="buttonMoreSquare"
        disabled={isNextDisabled}
        onClick={() => {
          if (pageEndNum >= total) return;
          setPagination({ ...pagination, pageIndex: pageIndex + 1 });
        }}
      >
        <span className="sr-only">Go to next page</span>
        <ChevronRight
          style={{ display: "flex", alignItems: "center" }}
          width={14}
          height={14}
          fill={isNextDisabled ? color.white30 : color.white}
        />
      </Button>
    </div>
  );
};

export default BottomToolbar;

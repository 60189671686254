import React, { useMemo } from "react";
import { formatMoney } from "@puzzle/utils";
import { useTransactionsPage } from "./TransactionsProvider";
import { S } from "ve";

export const Totals = () => {
  const { totals } = useTransactionsPage();
  return useMemo(() => {
    if (totals.count > 0 && typeof totals.sum === "number") {
      return (
        <div>
          Total {formatMoney({ currency: "USD", amount: totals.sum })}
          <span style={{ margin: `0 ${S.$1}` }}>|</span>
        </div>
      );
    }
  }, [totals]);
};

/* eslint-disable react/display-name */
import React, { useCallback, useMemo } from "react";
import { compact, groupBy, keyBy, keys, pickBy } from "lodash";
import { formatAccountName, formatCardName, zIndex } from "@puzzle/utils";
import { AutocompleteMenu, Menu, Toolbar } from "@puzzle/ui";
import { FilterList } from "@puzzle/icons";
import {
  AccountFragment,
  CardFragment,
  CategoryFragment,
  RecurrencePeriod,
  VendorFragment,
} from "graphql/types";
import { VendorSearch } from "components/transactions/vendors";
import { FilterState, FilterStatus, useTransactionsPage } from "../TransactionsProvider";
import { recurrenceOptions, RecurrencePeriodItem } from "../UpdateField/RecurrenceSelect";
import { CategoriesFilter } from "components/common/CategoriesFilter";
import { AmountMenu } from "components/common/AmountMenu";
import { ClassificationsFilter } from "./ClassificationsFilter";
import { ClassFilterOption } from "./useClassificationsFilter";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

const unknownRecurrenceItem = { key: undefined, label: "Unknown" };

const FilterStatusLedgerAccountKeyMap: Record<
  Exclude<FilterStatus, "uncategorized" | "unfinalized" | "finalized">,
  string
> = {
  NotLinkedBankTransfers: "transfer_account",
  NotLinkedCCPayments: "credit_card_payment",
  NotLinkedProcessorTransfers: "processor_transfers",
};

type ExtendedFilterProps = {
  hasAICategorizationFeatureEnabled: boolean | undefined;
}

const ExtendedFilter = ({ hasAICategorizationFeatureEnabled }: ExtendedFilterProps) => {
  const {
    cards,
    categories,
    cardsById,
    institutions: financialInstitutions,
    filter,
    setFilter,
    selectedCategories,
    selectedVendors,
    selectedClassFilters,
    classificationOptions,
    filterCount,
  } = useTransactionsPage();
  const { cardIds, accountIds, status, posted, minAmount, maxAmount, recurrences, ledgerCoaKeys } =
    filter;

  const onCategoriesChange = useCallback(
    (values: CategoryFragment[]) => {
      setFilter({
        ledgerCoaKeys: values.map((c) => c.permaKey),
      });
    },
    [setFilter]
  );

  const onVendorsChange = useCallback(
    (values: VendorFragment[]) => {
      setFilter({
        vendorIds: values.map((v) => v.id),
      });
    },
    [setFilter]
  );

  const onClassificationsChange = (values: ClassFilterOption[]) => {
    setFilter({
      classesFilter: {
        withSomeOfClassSegmentIds: values.filter((v) => !v.isExclusionClass).map((v) => v.id),
        withNoneOfClassIds: values.filter((v) => v.isExclusionClass).map((v) => v.parentId),
      },
    });
  };

  const recurrenceByKey = useMemo(() => keyBy(recurrenceOptions, "key"), []);

  const selectedRecurrences = useMemo(() => {
    return compact(
      recurrences?.map((val) => {
        if (val === undefined) {
          return unknownRecurrenceItem;
        }
        return recurrenceByKey[val];
      })
    );
  }, [recurrences, recurrenceByKey]);

  const onRecurrenceChange = useCallback(
    (values: RecurrencePeriodItem[]) => {
      setFilter({ recurrences: values.map((val) => val.key! as RecurrencePeriod) });
    },
    [setFilter]
  );

  const selectedCards = useMemo(() => {
    return compact(cardIds.map((id) => cardsById[id]));
  }, [cardIds, cardsById]);

  const onCardsChange = useCallback(
    (values: CardFragment[]) => {
      setFilter({
        cardIds: values.map((c) => c.id),
      });
    },
    [setFilter]
  );

  // MUI autocomplete needs to handle grouping.
  // Put the institution in the account object to allow this.
  const accountOptions = useMemo(() => {
    return (
      financialInstitutions?.flatMap((institution) =>
        institution.accounts.map((account) => ({
          ...account,
          institution,
        }))
      ) || []
    );
  }, [financialInstitutions]);
  const accountOptionsById = useMemo(() => keyBy(accountOptions, "id"), [accountOptions]);

  const selectedAccounts = useMemo(
    () => accountIds.map((id) => accountOptionsById[id]),
    [accountIds, accountOptionsById]
  );

  const onAccountsChange = useCallback(
    (values: AccountFragment[]) => {
      setFilter({
        accountIds: values.map((a) => a.id),
      });
    },
    [setFilter]
  );

  const filteredOptions = useMemo(
    () => categories.filter((category) => !category.deprecated),
    [categories]
  );
  const duplicateCategories = useMemo(
    () => keys(pickBy(groupBy(categories, "coaDisplayId"), (c) => c.length > 1)),
    [categories]
  );

  // This is for the radio buttons at the bottom
  const specialFilterValue = posted ? "posted" : undefined;

  const hasActiveFilters = useMemo(() => {
    return (
      selectedCategories.length + selectedCards.length + selectedAccounts.length > 0 ||
      Boolean(
        specialFilterValue || status || filter.minAmount !== null || filter.maxAmount !== null
      )
    );
  }, [
    filter.maxAmount,
    filter.minAmount,
    selectedAccounts.length,
    selectedCards.length,
    selectedCategories.length,
    specialFilterValue,
    status,
  ]);

  const menuTrigger = useMemo(() => {
    return (
      <Toolbar.Button active={hasActiveFilters} css={{ gap: "$1" }}>
        <FilterList width={14} height={12} />
        Filters{filterCount > 0 ? ` (${filterCount})` : ""}
      </Toolbar.Button>
    );
  }, [hasActiveFilters, filterCount]);

  return (
    <Menu
      trigger={menuTrigger}
      modal={false}
      label="Filter by"
      style={{ zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("menu") : "auto" }}
    >
      <Menu.Group>
        <Menu.CheckboxItem
          checked={filter.status === FilterStatus.NotCategorized}
          onCheckedChange={(checked) => {
            setFilter({
              status: checked ? FilterStatus.NotCategorized : null,
            });
          }}
        >
          Not categorized
        </Menu.CheckboxItem>

        {hasAICategorizationFeatureEnabled && (
          <Menu.CheckboxItem
            checked={filter.categorizedByAI === true}
            onCheckedChange={(checked) => {
              setFilter({
                categorizedByAI: checked ? true : null,
              });
            }}
          >
            Categorized by AI
          </Menu.CheckboxItem>
        )}

        <Menu.CheckboxItem
          checked={filter.assignedToMe === true}
          onCheckedChange={(checked) => {
            setFilter({
              assignedToMe: checked ? true : null,
            });
          }}
        >
          Assigned to me
        </Menu.CheckboxItem>
        
        <Menu.CheckboxItem
          checked={!!filter.assignedToOthers}
          onCheckedChange={(checked) => {
            setFilter({
              assignedToOthers: checked ? true : null,
            });
          }}
        >
          Assigned to others
        </Menu.CheckboxItem>

        <Menu.CheckboxItem
          checked={posted === true}
          onCheckedChange={() => {
            setFilter({
              posted: posted ? null : true,
            });
          }}
        >
          Date pending
        </Menu.CheckboxItem>
      </Menu.Group>

      <Menu.Separator />

      <Menu.Group>
        {useMemo(
          () => (
            <AutocompleteMenu<typeof accountOptions[number], true, false, false>
              getOptionLabel={(option) => formatAccountName(option)}
              getOptionKey={(option) => option.id}
              groupBy={(o) => o.institution.name}
              options={accountOptions}
              value={selectedAccounts}
              onChange={(e, newValue) => {
                onAccountsChange(newValue);
              }}
              subMenuTrigger="Source"
              multiple
              css={{
                zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("subMenu") : "auto",
              }}
            />
          ),
          [accountOptions, onAccountsChange, selectedAccounts]
        )}
        {useMemo(
          () => (
            <CategoriesFilter
              onCategoriesChange={onCategoriesChange}
              selectedCategories={selectedCategories}
              subMenuTrigger="Category"
              trigger={<></>}
              footer
            />
          ),
          [onCategoriesChange, selectedCategories]
        )}
        <ClassificationsFilter
          onClassificationsChange={onClassificationsChange}
          selectedClassFilters={selectedClassFilters}
          classificationOptions={classificationOptions}
        />
        {useMemo(
          () => (
            <VendorSearch
              value={selectedVendors}
              onSelect={(vendors) => {
                onVendorsChange(vendors);
              }}
              subMenuTrigger="Vendor"
              label="Pick vendors"
            />
          ),
          [onVendorsChange, selectedVendors]
        )}
        {useMemo(
          () => (
            <AutocompleteMenu<RecurrencePeriodItem, true, false, false>
              getOptionLabel={(o) => o.label}
              getOptionKey={(o) => o.key ?? o.label}
              options={recurrenceOptions}
              value={selectedRecurrences}
              onChange={(e, newValue) => {
                onRecurrenceChange(newValue);
              }}
              subMenuTrigger="Recurrence"
              label="Pick recurrences"
              multiple
              emptyOption={unknownRecurrenceItem}
              isOptionEqualToValue={(a, b) => a.label === b.label}
              css={{
                zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("subMenu") : "auto",
              }}
            />
          ),
          [onRecurrenceChange, selectedRecurrences]
        )}
        {useMemo(
          () => (
            <Menu
              style={{
                zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("subMenu") : "auto",
              }}
              subMenuTrigger="Status"
            >
              <Menu.Group>
                <Menu.RadioGroup
                  onValueChange={(newStatus: string) => {
                    const existingStatusEnabled = status === newStatus;
                    let filterUpdate: Partial<FilterState> = {
                      status: existingStatusEnabled ? null : (newStatus as FilterStatus),
                      // NOTE: isLinked being true doesn't work as expected.
                      // It only works for false/null
                      isLinked: null,
                    };

                    if (
                      newStatus === FilterStatus.NotLinkedBankTransfers ||
                      newStatus === FilterStatus.NotLinkedCCPayments ||
                      newStatus === FilterStatus.NotLinkedProcessorTransfers
                    ) {
                      filterUpdate = {
                        ...filterUpdate,
                        // NOTE: isLinked being true doesn't work as expected.
                        // It only works for false/null
                        isLinked: existingStatusEnabled ? null : false,
                        ledgerCoaKeys: existingStatusEnabled
                          ? ledgerCoaKeys.filter(
                              (acctKey) => acctKey !== FilterStatusLedgerAccountKeyMap[newStatus]
                            )
                          : [
                              ...ledgerCoaKeys.filter(
                                (acctKey) =>
                                  !Object.values(FilterStatusLedgerAccountKeyMap).includes(acctKey)
                              ),
                              FilterStatusLedgerAccountKeyMap[newStatus],
                            ],
                      };
                    }

                    setFilter(filterUpdate);
                  }}
                  value={status ?? undefined}
                >
                  <Menu.RadioItem value={FilterStatus.NotCategorized}>
                    Not categorized
                  </Menu.RadioItem>
                  <Menu.RadioItem value={FilterStatus.NotFinalized}>Not finalized</Menu.RadioItem>
                  <Menu.RadioItem value={FilterStatus.Finalized}>Finalized</Menu.RadioItem>
                  <Menu.RadioItem value={FilterStatus.NotLinkedBankTransfers}>
                    Unlinked Bank Transfers
                  </Menu.RadioItem>
                  <Menu.RadioItem value={FilterStatus.NotLinkedCCPayments}>
                    Unlinked Credit Card Payments
                  </Menu.RadioItem>
                  {categories.findIndex(
                    (category) =>
                      category.permaKey ===
                      FilterStatusLedgerAccountKeyMap[FilterStatus.NotLinkedProcessorTransfers]
                  ) > 0 ? (
                    <Menu.RadioItem value={FilterStatus.NotLinkedProcessorTransfers}>
                      Unlinked Payment Processor Transfers
                    </Menu.RadioItem>
                  ) : null}
                </Menu.RadioGroup>
              </Menu.Group>
            </Menu>
          ),
          [setFilter, status, ledgerCoaKeys, categories]
        )}
        {useMemo(
          () =>
            cards.length > 0 && (
              <AutocompleteMenu<CardFragment, true, false, false>
                getOptionLabel={formatCardName}
                getOptionKey={(o) => o.id}
                options={cards}
                value={selectedCards}
                onChange={(e, newValue) => {
                  onCardsChange(newValue);
                }}
                subMenuTrigger="Cardholder"
                label="Pick cardholders"
                multiple
                css={{
                  zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("subMenu") : "auto",
                }}
              />
            ),
          [cards, onCardsChange, selectedCards]
        )}
        {useMemo(
          () => (
            <Menu
              style={{
                zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("subMenu") : "auto",
              }}
              subMenuTrigger="Date"
            >
              <Menu.Group>
                <Menu.CheckboxItem checked disabled>
                  Transaction date
                </Menu.CheckboxItem>
                <Menu.CheckboxItem
                  checked={filter.showAccrualDate ?? false}
                  onCheckedChange={(showAccrualDate) =>
                    setFilter({ showAccrualDate: !!showAccrualDate })
                  }
                >
                  Accrual date
                </Menu.CheckboxItem>
                <Menu.CheckboxItem
                  checked={filter.showAvailableDate ?? false}
                  onCheckedChange={(showAvailableDate) =>
                    setFilter({ showAvailableDate: !!showAvailableDate })
                  }
                >
                  Available date (Stripe only)
                </Menu.CheckboxItem>
              </Menu.Group>
            </Menu>
          ),
          [filter.showAccrualDate, filter.showAvailableDate, setFilter]
        )}
        {useMemo(
          () => (
            <Menu
              style={{
                zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("subMenu") : "auto",
              }}
              subMenuTrigger="Documentation"
            >
              <Menu.Group>
                <Menu.RadioGroup
                  onValueChange={(value) => {
                    setFilter({
                      hasDocumentation:
                        value === filter.hasDocumentation?.toString() ? null : value === "true",
                    });
                  }}
                  value={
                    typeof filter.hasDocumentation === "boolean"
                      ? filter.hasDocumentation.toString()
                      : undefined
                  }
                >
                  <Menu.RadioItem value="true">Has documentation</Menu.RadioItem>
                  <Menu.RadioItem value="false">No documentation</Menu.RadioItem>
                </Menu.RadioGroup>
              </Menu.Group>
            </Menu>
          ),
          [filter.hasDocumentation, setFilter]
        )}
        {useMemo(
          () => (
            <AmountMenu
              onChange={setFilter}
              value={{
                minAmount: minAmount,
                maxAmount: maxAmount,
              }}
            />
          ),
          [maxAmount, minAmount, setFilter]
        )}
        {useMemo(
          () => (
            <Menu
              style={{
                zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("subMenu") : "auto",
              }}
              subMenuTrigger="Input method"
            >
              <Menu.Group>
                <Menu.RadioGroup
                  onValueChange={(value) => {
                    setFilter({
                      isManual: value === filter.isManual?.toString() ? null : value === "true",
                    });
                  }}
                  value={
                    typeof filter.isManual === "boolean" ? filter.isManual.toString() : undefined
                  }
                >
                  <Menu.RadioItem value="false">Automatic transactions</Menu.RadioItem>
                  <Menu.RadioItem value="true">Manual transactions</Menu.RadioItem>
                </Menu.RadioGroup>
              </Menu.Group>
            </Menu>
          ),
          [filter.isManual, setFilter]
        )}
        {useMemo(
          () => (
            <Menu
              style={{
                zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("subMenu") : "auto",
              }}
              subMenuTrigger="Bill payment"
            >
              <Menu.Group>
                <Menu.RadioGroup
                  onValueChange={(value) => {
                    setFilter({
                      isBillPayment:
                        value === filter.isBillPayment?.toString() ? null : value === "true",
                    });
                  }}
                  value={
                    typeof filter.isBillPayment === "boolean"
                      ? filter.isBillPayment.toString()
                      : undefined
                  }
                >
                  <Menu.RadioItem value="true">Bill payment</Menu.RadioItem>
                  <Menu.RadioItem value="false">Not a bill payment</Menu.RadioItem>
                </Menu.RadioGroup>
              </Menu.Group>
            </Menu>
          ),
          [filter.isBillPayment, setFilter]
        )}
      </Menu.Group>
    </Menu>
  );
};

export default ExtendedFilter;

import { Big } from "big.js";
import { Money } from "./money";

type FormatMoneyOptions = {
  locale?: string;
  usingCostNotation?: boolean; // applies parens () around negative numbers, defaults to true
  currencySymbol?: boolean;
  truncateValue?: boolean;
};

const defaultOptions: Required<FormatMoneyOptions> = {
  locale: "en-US",
  usingCostNotation: true,
  currencySymbol: true,
  truncateValue: false,
};

const createMoneyFormatter = (
  money: Pick<Money, "currency">,
  options: Required<FormatMoneyOptions>
) => {
  const { locale, usingCostNotation, truncateValue } = options;

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: money.currency ?? "USD",
    currencySign: usingCostNotation ? "accounting" : "standard",
    minimumFractionDigits: truncateValue ? 0 : undefined,
    maximumFractionDigits: truncateValue ? 0 : undefined,
  });
};

export const formatMoney = (money: Money, options: FormatMoneyOptions = defaultOptions) => {
  const mergedOptions = { ...defaultOptions, ...options };
  const formatter = createMoneyFormatter(money, mergedOptions);
  const amountAsBig = Big(money.amount);
  const amount = amountAsBig.eq(-0) ? Big(0).toNumber() : amountAsBig.toNumber();

  if (!mergedOptions.currencySymbol) {
    return removeCurrencySymbol(formatter.formatToParts(amount));
  }

  return formatter.format(amount);
};

// Exported from emails/src/templates/utils/formatters.ts
export const formatDollar = (amount: number, options?: FormatMoneyOptions) =>
  formatMoney({ amount, currency: "USD" }, { truncateValue: true, ...options });

const removeCurrencySymbol = (parts: Intl.NumberFormatPart[]) => {
  return parts
    .filter((part) => part.type !== "currency")
    .map((part) => part.value)
    .join("")
    .trim();
};

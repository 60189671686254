import React, { useCallback, useEffect, useMemo } from "react";
import {
  styled,
  Button,
  Dialog,
  Autocomplete,
  ControlGroup,
  CurrencyInput,
  Input,
  Text,
  useToasts,
} from "@puzzle/ui";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toCalendarDate, parseDate } from "@internationalized/date";
import * as yup from "yup";
import Big from "big.js";

import FormGroupTable, { Item } from "./FormGroupTable";
import ModifyBalance from "./ModifyBalance";
import {
  AccountBalancePeriod,
  AccountType,
  CategoryFragment,
  LedgerAccountType,
  AccountFragment,
  TransactionSortOrder,
} from "graphql/types";
import { useToggle } from "react-use";
import { useUpsertInitialUserLedgerAccountBalanceMutation } from "../graphql.generated";
import { useReconciliationCategories } from "../useReconciliationCategories";
import { formatAccountNameWithInstitution, formatMoney, parseAbsolute } from "@puzzle/utils";
import { ExternalInline } from "@puzzle/icons";
import Link from "components/common/Link";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { useIntercom } from "react-use-intercom";
import Analytics from "lib/analytics";
import { ApolloQueryResult } from "@apollo/client";
import { reportError } from "lib/errors";
import { useFirstTransactionQuery } from "components/companies/graphql.generated";
import { Box, S, color, vars } from "ve";

type BalanceTransaction = {
  descriptor: string;
  ledgerCoaKey: string;
  amount?: string;
};

export type FormValues = {
  transactions: BalanceTransaction[];
  amount: string;
  // this is for credit cards only,
  // designs require user to manually confirm/enter the amount
  confirmedAmount: string;
};

const schema = yup
  .object()
  .shape({
    transactions: yup.array().of(
      yup.object({
        descriptor: yup.string().required(),
        ledgerCoaKey: yup.string().required(),
        amount: yup.string().required().notOneOf(["0"]),
      })
    ),
    // TODO Since total is used for display and validation, you should prefer using watch/setValue
    amount: yup
      .string()
      .required()
      .test(
        "total",
        "The remaining balance should equal $0, please modify activity items.",
        (amount, { parent }) => {
          if (parent.account.type === AccountType.Credit) {
            return true;
          }
          const transactions = parent.transactions as Partial<BalanceTransaction>[];
          const amounts = transactions?.map((t) => t.amount || "0") || [];
          return amounts.reduce((sum, amount) => sum.add(amount), Big(0)).eq(amount || "0");
        }
      ),
    confirmedAmount: yup.string(),
  })
  .required();

const Balance = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",

  padding: "$1 $1 $1 $2",
  marginBottom: "$2",

  backgroundColor: "$gray800",
  borderRadius: "$1",
});

const BalanceDetails = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const BalanceActions = styled("div", {
  alignSelf: "flex-end",
  lineHeight: "14px", // specific alignment
});

const BalanceColumnRoot = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$0h",
  alignItems: "center",

  "&:last-child": {
    textAlign: "right",
  },
});

const BalanceColumn = ({
  primary,
  secondary,
  actions,
  isNegative,
  ...props
}: {
  primary: string;
  secondary?: string;
  actions?: React.ReactNode;
  isNegative?: boolean;
}) => {
  return (
    <BalanceColumnRoot {...props}>
      <BalanceDetails>
        {secondary && (
          <Text type="bodyXS" color="$gray400">
            {secondary}
          </Text>
        )}
        <Text type="bodyM" color={isNegative ? "red500" : "white"}>
          {primary}
        </Text>
      </BalanceDetails>

      {actions && <BalanceActions>{actions}</BalanceActions>}
    </BalanceColumnRoot>
  );
};

const Negative = styled("span", {
  color: "$red500",
  fontSize: "$bodyS",
});

type Split = {
  descriptor: string;
  amount?: string;
  ledgerCoaKey: string;
};

const createBalanceTransaction = ({
  amount,
  ledgerCoaKey,
}: {
  amount?: string;
  ledgerCoaKey: string;
}): Split => ({
  descriptor: "Opening Balance",
  amount: amount,
  ledgerCoaKey,
});

const OpeningBalanceModal = ({
  onSuccess,
  open: _open,
  onOpenChange: _onOpenChange,
  account,
  initialAmount: _initialAmount,
  refetch,
  ledgerAccountId,
  companyId,
  ...props
}: React.ComponentPropsWithoutRef<typeof Dialog> & {
  onSuccess?: () => void;
  refetch?: () => Promise<ApolloQueryResult<any>>;
  account: AccountFragment;
  ledgerAccountId: string;
  companyId: string;
  initialAmount?: string;
}) => {
  const intercom = useIntercom();
  const { initialAccountBalance } = account;
  const { toast } = useToasts();
  const initialAmount = _initialAmount || initialAccountBalance?.balance;
  const [internalOpen, toggle] = useToggle(false);
  const open = _open ?? internalOpen;
  const onOpenChange = _onOpenChange ?? toggle;
  const { balanceCategories } = useReconciliationCategories();
  const { company } = useActiveCompany();
  const [upsertInitialUserLedgerAccountBalance] =
    useUpsertInitialUserLedgerAccountBalanceMutation();

  const { data: transactionData } = useFirstTransactionQuery({
    skip: !company,
    variables: {
      companyId: company?.id ?? "",
      filterBy: {
        accountIds: [account.id],
      },
      page: {
        after: "0",
        count: 1,
      },
      sortBy: TransactionSortOrder.DateAsc,
    },
  });
  const firstTransaction = transactionData?.company?.transactions.nodes[0];

  const { timeZone } = useActiveCompany<true>();

  const initialDate = firstTransaction?.date
    ? parseDate(firstTransaction?.date)
    : parseAbsolute(initialAccountBalance.date, timeZone);

  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const formattedInitialDate = initialDate ? dateFormatter.format(initialDate) : undefined;

  const categoriesForAccountType = useMemo(() => {
    return balanceCategories.filter(
      (balanceCategory) =>
        !balanceCategory.deprecated &&
        (!balanceCategory.accountType ||
          [
            LedgerAccountType.Asset,
            LedgerAccountType.Liability,
            LedgerAccountType.Equity,
            LedgerAccountType.ContraAsset,
            LedgerAccountType.ContraLiability,
            LedgerAccountType.ContraEquity,
          ].includes(balanceCategory.accountType))
    );
  }, [balanceCategories]);

  const defaultCategory = useMemo(() => {
    return categoriesForAccountType.length > 0 ? categoriesForAccountType[0] : null;
  }, [categoriesForAccountType]);

  const defaultValues = useMemo(() => {
    const amount = initialAmount;
    const confirmedAmount = initialAmount;
    const splits = initialAccountBalance.splits;
    const transactions: Split[] = splits
      ? splits.map((s) => ({
          amount: s.amount,
          descriptor: s.description,
          ledgerCoaKey: s.category.permaKey,
        }))
      : !defaultCategory || Big(amount).eq(0)
      ? []
      : [
          createBalanceTransaction({
            ledgerCoaKey: defaultCategory.permaKey,
            amount,
          }),
        ];

    return { amount, transactions, confirmedAmount, account };
  }, [initialAmount, initialAccountBalance, defaultCategory, account]);

  const { formState, register, control, handleSubmit, reset, setValue, watch, trigger } =
    useForm<FormValues>({
      defaultValues,
      resolver: yupResolver(schema),
      mode: "onChange",
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "transactions",
  });

  const amount = watch("amount");
  const confirmedAmount = watch("confirmedAmount");
  const transactionsTotal = watch("transactions").reduce(
    (acc, field) => acc.add(field.amount || 0),
    Big(0)
  );
  const remainingBalance = Big(amount).minus(transactionsTotal);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    const payload = {
      period: AccountBalancePeriod.Beginning,
      amount: account.type === AccountType.Credit ? data.confirmedAmount : data.amount,
      splits:
        account.type === AccountType.Credit
          ? []
          : data.transactions.map((t) => ({
              // TODO this should be removed from GraphQL
              timestamp: toCalendarDate(initialDate).toString(),
              description: t.descriptor,
              amount: t.amount ?? "0",
              ledgerCoaKey: t.ledgerCoaKey,
            })),
    };
    return await upsertInitialUserLedgerAccountBalance({
      variables: {
        input: {
          ledgerAccountId,
          companyId,
          amount: payload.amount,
          balanceDay: toCalendarDate(initialDate).toString(),
          splits: payload.splits,
        },
      },
      onCompleted(data) {
        refetch?.();
        onOpenChange(false);
        toast({ message: "Opening balance updated" });
        Analytics.bankRecBalanceUpdated({
          id: ledgerAccountId,
          balancePeriod: AccountBalancePeriod.Beginning,
          accountId: ledgerAccountId,
          totalSplits: payload.splits.length,
        });
      },
      onError(error) {
        toast({ message: "Error updating opening balance", status: "error" });
        reportError(error);
      },
    });
  });

  const appendEmptyTxn = useCallback(
    (amount?: string) => {
      if (!defaultCategory) return;

      append(createBalanceTransaction({ amount, ledgerCoaKey: defaultCategory.permaKey }), {
        focusName: `transactions.${fields.length}.date`,
      });
    },
    [defaultCategory, append, fields.length]
  );

  const splitsTable = useMemo(() => {
    return (
      <FormGroupTable
        hideDate
        onAdd={() => {
          if (!defaultCategory) {
            return;
          }

          appendEmptyTxn();
        }}
        addText="Add activity"
        hideHeader={fields.length === 0}
        remainingBalance={remainingBalance}
        message="Once you confirm, your opening balance will be allocated to the categories displayed below. You can modify these as needed."
      >
        {fields.map((field, index) => (
          <Item key={field.id} onRemove={() => remove(index)}>
            <ControlGroup>
              <Input
                autoComplete="off"
                placeholder="Add description"
                autoFocus={false}
                {...register(`transactions.${index}.descriptor`, { required: true })}
              />

              <Controller
                control={control}
                name={`transactions.${index}.ledgerCoaKey`}
                render={({ field }) => {
                  const selectedCategory = categoriesForAccountType.find(
                    (c) => c.permaKey === field.value
                  );

                  return (
                    <Autocomplete<CategoryFragment, false, true, false>
                      ref={field.ref}
                      options={categoriesForAccountType}
                      value={selectedCategory}
                      getOptionLabel={(option) => option.name}
                      getOptionKey={(option) => option.permaKey}
                      disableClearable
                      isOptionEqualToValue={(option, value) => option.permaKey === value.permaKey}
                      placeholder="Select a category"
                      onBlur={field.onBlur}
                      onChange={(e, value: CategoryFragment) => {
                        field.onChange({
                          target: { name: field.name, value: value.permaKey },
                        });
                      }}
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name={`transactions.${index}.amount`}
                render={({ field }) => {
                  return (
                    <CurrencyInput
                      placeholder="$0"
                      style={{ textAlign: "right" }}
                      value={field.value}
                      truncateWhenInactive={false}
                      onValueChange={({ value }) => {
                        field.onChange({
                          target: { value, name: field.name },
                        });

                        // Needed to trigger error message
                        // https://github.com/react-hook-form/resolvers/issues/120
                        trigger("amount");
                      }}
                      onBlur={field.onBlur}
                      ref={field.ref}
                    />
                  );
                }}
              />
            </ControlGroup>
          </Item>
        ))}
      </FormGroupTable>
    );
  }, [
    appendEmptyTxn,
    categoriesForAccountType,
    control,
    defaultCategory,
    fields,
    register,
    remainingBalance,
    remove,
    trigger,
  ]);

  const bodyTextLines = useMemo(
    () => [
      `The earliest transaction we have for this ${formatAccountNameWithInstitution(account)}
      account is:`,
      `
      ${[
        firstTransaction?.date,
        firstTransaction?.detail.descriptor,
        firstTransaction?.detail.vendor?.name,
        firstTransaction?.amount
          ? formatMoney(
              { amount: firstTransaction?.amount, currency: "USD" },
              { truncateValue: true }
            )
          : undefined,
      ]
        .filter(Boolean)
        .join(" | ")}`,
      `What was the balance before that transaction?`,
    ],
    [firstTransaction, account]
  );

  return (
    <Dialog {...props} open={open} onOpenChange={onOpenChange}>
      <Dialog.Title>Review opening balance</Dialog.Title>

      <Dialog.Body css={{ textVariant: "$bodyS" }}>
        {account.type === AccountType.Credit ? (
          <>
            {bodyTextLines.map((text, i) => (
              <Box key={i} css={{ paddingBottom: S["2"] }}>
                {text}
              </Box>
            ))}
            <Balance>
              <BalanceColumn primary="Calculated balance" />
              <BalanceColumn
                secondary={`On ${formattedInitialDate}`}
                primary={formatMoney({ currency: "USD", amount })}
              />
            </Balance>
            <Box css={{ color: color.gray200, paddingBottom: S["0h"] }}>
              Account balance on {formattedInitialDate}
            </Box>
            <div>
              <CurrencyInput
                size="small"
                textAlign="left"
                truncateWhenInactive={false}
                placeholder={formatMoney({ currency: "USD", amount })}
                value={confirmedAmount}
                onValueChange={({ value }) => {
                  setValue("confirmedAmount", value, { shouldValidate: true });
                  if (value) {
                    setValue("amount", value, { shouldValidate: true });
                  }
                }}
              />
            </div>
          </>
        ) : (
          <>
            <Box
              css={{
                paddingBottom: S["2"],
                fontSize: vars.fontSizes.body,
              }}
            >
              {bodyTextLines.map((text, i) => (
                <Box key={i} css={{ paddingBottom: S["2"] }}>
                  {text}
                </Box>
              ))}

              <Link
                href="https://puzzlefin.notion.site/Opening-Bank-Balance-fe6f2338479a4b758fb7145be82ee37f"
                target="_blank"
                color="green600"
                css={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: 2,
                  lineHeight: "24px",
                  marginLeft: "$1",
                }}
              >
                Learn more <ExternalInline />
              </Link>
            </Box>

            <Balance>
              <BalanceColumn
                primary="Opening balance"
                secondary={formatAccountNameWithInstitution(account)}
              />

              <BalanceColumn
                secondary={`On ${formattedInitialDate}`}
                primary={formatMoney({ currency: "USD", amount })}
                isNegative={Big(amount).lt(0)}
                actions={
                  <>
                    <ModifyBalance
                      account={account}
                      initialAmount={initialAmount}
                      period={AccountBalancePeriod.Beginning}
                      onSave={({ correctAmount }) => {
                        setValue("amount", correctAmount, { shouldValidate: true });
                        if (!Big(correctAmount).eq(0)) {
                          appendEmptyTxn(Big(correctAmount).minus(Big(amount)).toString());
                        } else {
                          remove(0);
                        }
                      }}
                    />
                  </>
                }
              />
            </Balance>
            {splitsTable}
          </>
        )}
      </Dialog.Body>
      <Dialog.Footer css={{ justifyContent: "space-between" }}>
        <Button
          variant="minimal"
          onClick={() => {
            Analytics.feedbackButtonClicked({ location: "OpeningBalanceModal" });
            intercom.showNewMessage();
          }}
        >
          Help
        </Button>
        <Dialog.Actions>
          {formState.errors.amount && !formState.isValid && (
            <Negative>{formState.errors.amount.message}</Negative>
          )}
          <Dialog.Close asChild>
            <Button variant="secondary">Cancel</Button>
          </Dialog.Close>
          <Button
            variant="primary"
            onClick={onSubmit}
            disabled={
              !formState.isValid ||
              formState.isSubmitting ||
              (account.type === AccountType.Credit && !confirmedAmount)
            }
          >
            Confirm
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog>
  );
};

export default OpeningBalanceModal;

import React from "react";
import { PackagingPlanV2 } from "./PlanBox";
import { Text } from "ve";
import { IS_PROD } from "lib/config";

export const PuzzleBillingPlansV2: PackagingPlanV2[] = [
  {
    name: "Free",
    description: "For recently incorporated companies to get started",
    annualPrice: "0",
    stripeAnnualId: "",
    monthlyPrice: "0",
    stripeMonthlyId: "",
    expenses: "Annual expenses <$25k",
    seats: "1 seat",
    isFree: true,
    featuresHeader: <Text>Includes:</Text>,
    features: [
      "Intuitive onboarding",
      "Financial reports (view)",
      "Auto-categorization",
      "Tax alerts & reminders",
    ],
  },
  {
    name: "Basic",
    description: "Automated accounting for small companies",
    annualPrice: "255",
    stripeAnnualId: IS_PROD ? "price_1QX7IgLLbpHgYASjhHM30RGz" : "price_1QXCDvCEKUfSo6K0Kr8qOTIR",
    monthlyPrice: "25",
    stripeMonthlyId: IS_PROD ? "price_1QX7HHLLbpHgYASj06VkdfGb" : "price_1QXCDKCEKUfSo6K0JiDGDYZk",
    expenses: "Annual expenses <$25k",
    seats: "Unlimited seats",
    isFree: false,
    featuresHeader: (
      <span>
        Everything from{" "}
        <Text color="white" weight="heavy">
          Free
        </Text>
        , plus:
      </span>
    ),
    features: [
      "Financial reports (export)",
      "General ledger (export)",
      "Cash activity report",
      "Revenue recognition",
      "One-click tax package",
    ],
  },
  {
    name: "Starter",
    description: "Accounting, automation, and metrics in a single solution",
    annualPrice: "510",
    stripeAnnualId: IS_PROD ? "price_1QX7MfLLbpHgYASjw3elAwC6" : "price_1QXCFgCEKUfSo6K0ymyZuvJQ",
    monthlyPrice: "50",
    stripeMonthlyId: IS_PROD ? "price_1QX7MBLLbpHgYASjBhpcv4BR" : "price_1QXCFGCEKUfSo6K0X7kMDCYk",
    expenses: "Annual expenses <$100k",
    seats: "Unlimited seats",
    isFree: false,
    isMostPopular: false,
    isSignatureOffer: false,
    featuresHeader: (
      <span>
        Everything from{" "}
        <Text color="white" weight="heavy">
          Basic
        </Text>
        , plus:
      </span>
    ),
    features: [
      "Real-time insights",
      "Cash, spend, and revenue explorer",
      "Spotlight variance analysis",
      "Software-driven accrual policies",
    ],
  },
  {
    name: "Pro",
    description: "The highest standard of accuracy and accounting automation",
    header: "SIGNATURE OFFERING",
    annualPrice: "1020",
    stripeAnnualId: IS_PROD ? "price_1QX7OILLbpHgYASjkXCru8bv" : "price_1QXCH2CEKUfSo6K0w9bfgy7v",
    monthlyPrice: "100",
    stripeMonthlyId: IS_PROD ? "price_1QX7NdLLbpHgYASjJKBGXyOq" : "price_1QXCGjCEKUfSo6K0boYoj5S4",
    expenses: "Annual expenses <$1M",
    seats: "Unlimited seats",
    isFree: false,
    isSignatureOffer: true,
    featuresHeader: (
      <span>
        Everything from{" "}
        <Text color="white" weight="heavy">
          Starter
        </Text>
        , plus:
      </span>
    ),
    features: [
      "Account setup review",
      "Automated month-end accuracy review",
      "Classes & departments",
      "Live support",
      "Early access to AI Labs"
    ],
  },
  {
    name: "Advanced",
    description: "A scalable solution for growing companies",
    annualPrice: "3060",
    stripeAnnualId: IS_PROD ? "price_1QX7QELLbpHgYASj7kxnCJm3" : "price_1QXCIJCEKUfSo6K0jP7g08CA",
    monthlyPrice: "300",
    stripeMonthlyId: IS_PROD ? "price_1QX7PULLbpHgYASjw2auwbl4" : "price_1QXCHqCEKUfSo6K0FnLGC768",
    expenses: "Unlimited annual expenses",
    seats: "Unlimited seats",
    isFree: false,
    isCustom: true,
    customPlanDemoCallUrl: "https://meetings.hubspot.com/luke-frye/puzzle-demo",
    featuresHeader: (
      <span>
        Everything from{" "}
        <Text color="white" weight="heavy">
          Pro
        </Text>
        , plus:
      </span>
    ),
    features: [
      "Concierge onboarding",
      "Custom reports",
      "Custom permissions",
      "AI accuracy review",
      "AI insights",
      "Audit log"
    ],
  },
];

import React, { useCallback, useEffect } from "react";
import { Dialog, useToasts } from "@puzzle/ui";
import useMonetization from "../useMonetization";
import { Close as CloseIcon } from "@puzzle/icons";

import { PlanSelectStep } from "./PlanSelectStep";
import { IconButton, S } from "ve";
import { PuzzleBillingPlansV2 } from "./PuzzleBillingPlansV2";
import { queryTypes, useQueryStates } from "next-usequerystate";
import { zIndex } from "@puzzle/utils";

const MODAL_BACKGROUND =
  "radial-gradient(59.63% 59.63% at 50% 0%, rgba(106, 75, 194, 0.72) 0%, rgba(23, 22, 36, 0) 100%), #000211;";

export const PricingPackageV2Modal = () => {
  const { toast } = useToasts();
  const {
    isModalVisible,
    showUpgradeModalQuery,
    showUpgradeModal,
    hideUpgradeModal,
    setShowUpgradeModalQuery,
    setBookkeepingEnabled,
    setSelectedPlan,
    setSelectedCycle,
  } = useMonetization();

  const [queryState, setQueryState] = useQueryStates({
    plan: queryTypes.stringEnum(PuzzleBillingPlansV2.map((plan) => plan.name)),
    paymentSuccess: queryTypes.boolean.withDefault(false),
  });

  useEffect(() => {
    if (!isModalVisible && showUpgradeModalQuery) {
      showUpgradeModal();
    }
  }, [isModalVisible, showUpgradeModal, showUpgradeModalQuery]);

  const cleanUpUrl = useCallback(async () => {
    await setQueryState({ plan: null, paymentSuccess: null }, { shallow: true });
    toast({
      duration: 10000,
      message:
        "Thanks for subscribing! Enjoy premium features until February 3 as our gift to you.",
      title: "Subscription updated",
    });
  }, [setQueryState, toast]);

  // This is a useEffect to dispatch the toast message when the user is redirected back
  // from the stripe payment page to the puzzle app.
  // Using these parameters, we detect if the user has made the payment and then
  // display the message.
  useEffect(() => {
    if (queryState.plan && !!queryState.paymentSuccess) {
      cleanUpUrl();
    }
  }, [cleanUpUrl, queryState.paymentSuccess, queryState.plan]);

  // The idea behind this is to clear the query params and avoid
  // the url to be confusing mantaining state it shouldn't
  // Await is needed when running multiple query cleans
  // Shallow true is to avoid running NextJS SSR/SSG? functions
  const handleOpenChange = useCallback(
    async (open: boolean) => {
      if (!open) {
        await setShowUpgradeModalQuery(null, { shallow: true }); // needs to happen first so modal can close properly
        hideUpgradeModal();
        await setBookkeepingEnabled(null, { shallow: true });
        await setSelectedPlan(null, { shallow: true });
        await setSelectedCycle(null, { shallow: true });
      }
    },
    [
      hideUpgradeModal,
      setShowUpgradeModalQuery,
      setBookkeepingEnabled,
      setSelectedPlan,
      setSelectedCycle,
    ]
  );
  return (
    <Dialog
      data-testid="upgrade-modal"
      size="fullScreen"
      open={isModalVisible}
      onOpenChange={handleOpenChange}
      style={{ zIndex: zIndex("modal") }}
    >
      <Dialog.Body
        css={{
          background: MODAL_BACKGROUND,
          height: "100%",
          overflowY: "auto",
          maxHeight: "100vh",
        }}
      >
        <Dialog.Close asChild>
          <IconButton
            css={{
              position: "absolute",
              right: S.$2,
              top: S.$2,
              width: S.$4,
              height: S.$4,
              background: "transparent",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Dialog.Close>
        <PlanSelectStep onClose={() => handleOpenChange(false)} />
      </Dialog.Body>
    </Dialog>
  );
};

import React from "react";
import { zIndex } from "@puzzle/utils";
import { Dialog } from "@puzzle/ui";
import { Button, Stack } from "ve";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

export type RemoveInput = { id?: string; groupId?: string };

export const InvoicePaymentWarningModal = ({
  open,
  onOpenChange,
  handleRemove,
  removeOptions,
  isRemoving,
  ...props
}: React.ComponentPropsWithoutRef<typeof Dialog> & {
  handleRemove: (id?: string, groupId?: string) => void;
  removeOptions: RemoveInput;
  isRemoving: boolean;
}) => {
  return (
    <Dialog
      size="xsmall"
      open={open}
      onOpenChange={onOpenChange}
      {...props}
      style={{ zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("subMenu") : "auto" }}
    >
      <Dialog.Title>Confirm payment removal</Dialog.Title>
      <Dialog.Body css={{ textVariant: "$bodyS" }}>
        <Stack>
          Removing this payment will result in the following changes:
          <Stack direction="horizontal">
            <div>&#8226;</div>The transaction will no longer be posted to accounts receivable or
            related accounts
          </Stack>
          <Stack direction="horizontal">
            <div>&#8226;</div>The transaction will no longer be associated with any invoice or
            customer balances
          </Stack>
        </Stack>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.Actions>
          <Button variant="secondary" onClick={() => onOpenChange?.(false)} disabled={isRemoving}>
            Cancel
          </Button>
          <Button
            variant="negative"
            loading={isRemoving}
            onClick={() => {
              handleRemove(removeOptions.id, removeOptions.groupId);
              onOpenChange?.(false);
            }}
          >
            Remove
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog>
  );
};

import { useMemo } from "react";
import { keyBy, orderBy, partition } from "lodash";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import {
  useGetChartOfAccountsQuery,
  useAllCategoriesQuery,
} from "./chartOfAccounts.graphql.generated";
import { makeVar } from "@apollo/client";

const fetched = makeVar(false);

export const useChartOfAccounts = () => {
  const { company } = useActiveCompany<true>();

  const { data, loading } = useGetChartOfAccountsQuery({
    variables: {
      id: company.id,
    },
    onCompleted: () => {
      fetched(true);
    },
    fetchPolicy: fetched() ? "cache-first" : "cache-and-network",
  });

  const { data: ledgerCategoryData, loading: ledgerCategoryLoading } = useAllCategoriesQuery({
    variables: {
      companyId: company.id,
    },
  });

  const allCategoriesWithNoCategory = useMemo(
    () => ledgerCategoryData?.categories ?? [],
    [ledgerCategoryData]
  );

  const allCategories = useMemo(
    () => allCategoriesWithNoCategory.filter((a) => a.coaKey !== "no_category"),
    [allCategoriesWithNoCategory]
  );

  const nonHistoricalCategories = useMemo(
    () => allCategories.filter((c) => c.ruleType !== "historical_category_match"),
    [allCategories]
  );

  const allCategoriesByPermaKey = useMemo(() => keyBy(allCategories, "coaKey"), [allCategories]);
  const chartOfAccounts = useMemo(() => data?.company?.chartOfAccounts ?? [], [data]);
  const chartOfAccountsById = useMemo(() => keyBy(chartOfAccounts, "id"), [chartOfAccounts]);
  const accountsByHidden = useMemo(() => partition(chartOfAccounts, "hidden"), [chartOfAccounts]);

  const categories = useMemo(
    () =>
      orderBy(
        // Filter out completely deprecated options.
        // categoriesByPermaKey will contain old references for lookup.
        chartOfAccounts?.filter((x) => x.coaKeys.length > 0),
        "displayId"
      ) || [],
    [chartOfAccounts]
  );

  const categoriesByPermaKey = useMemo(
    () =>
      keyBy(
        chartOfAccounts?.flatMap((category) =>
          [...category.coaKeys, ...category.deprecatedCoaKeys].map((coaKey) => ({
            ...category,
            coaKey,
          }))
        ),
        "coaKey"
      ),
    [chartOfAccounts]
  );

  const categoriesByDisplayId = useMemo(() => keyBy(categories, "displayId"), [categories]);

  return {
    data,
    loading,
    categories,
    categoriesByPermaKey,
    categoriesByDisplayId,
    chartOfAccounts,
    chartOfAccountsById,
    inactiveAccounts: accountsByHidden[0],
    activeAccounts: accountsByHidden[1],
    allCategories,
    nonHistoricalCategories,
    allCategoriesByPermaKey,
    ledgerCategoryLoading,
    allCategoriesWithNoCategory,
  };
};

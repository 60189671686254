import React, { useEffect, useState } from "react";
import { noop } from "lodash";

import { DateInput, Menu, Select, Tooltip, Text, RadioGroup } from "@puzzle/ui";
import { CalendarDateString, DateValue, parseDate, toCalendarDate } from "@puzzle/utils";

import { AccountingRecognitionCalculation, AccountingRecognitionTiming } from "graphql/types";
import { Box, S } from "ve";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";

export type PeriodMethod = "endDate" | "duration";
export const PeriodMethodValues: PeriodMethod[] = ["endDate", "duration"];

type ValueProps = {
  startDate?: DateValue;
  endDate?: DateValue;
  duration?: string;
  periodMethod?: PeriodMethod;
};

type Props = {
  method?: AccountingRecognitionTiming;
  calculation?: AccountingRecognitionCalculation;
  values?: ValueProps;
  placeholder?: string;
  onPointInTimeDateChange: (d: DateValue) => void;
  onStartDateChange: (d: DateValue) => void;
  onEndDateChange: (d: DateValue) => void;
  onPeriodMethodChange?: (p: PeriodMethod) => void;
  onDurationChange?: (d: string) => void;
  onOpenChange?: () => void;
};

const DAILY_CALCULATIONS = [
  AccountingRecognitionCalculation.Days360,
  AccountingRecognitionCalculation.DaysActual,
];

const STRAIGHT_LINE_OR_DAILY_CALCULATIONS = [
  ...DAILY_CALCULATIONS,
  AccountingRecognitionCalculation.StraightLineByPeriod,
];

const durationOptions = Array(99)
  .fill(0)
  .map((_, i) => {
    const duration = (i + 1).toString();
    return {
      value: duration,
      label: duration,
    };
  });

export function formatDateRangeForPayload({
  method,
  calculation,
  startDate,
  endDate,
  pointInTimeDate,
  disabled,
}: {
  method?: AccountingRecognitionTiming;
  calculation?: AccountingRecognitionCalculation;
  startDate?: CalendarDateString;
  endDate?: CalendarDateString;
  pointInTimeDate?: CalendarDateString;
  disabled?: boolean;
}) {
  if (calculation === AccountingRecognitionCalculation.Manual) {
    return undefined;
  }

  if (method === AccountingRecognitionTiming.PointInTime) {
    return [pointInTimeDate, pointInTimeDate];
  }

  if (calculation && STRAIGHT_LINE_OR_DAILY_CALCULATIONS.includes(calculation)) {
    return [startDate, endDate];
  }

  return undefined;
}

export function RecognitionPeriodSelector(props: Props) {
  const [pointInTimeDate, setPointInTimeDate] = useState<string>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [periodMethod, setPeriodMethod] = useState<PeriodMethod>("endDate");
  const [duration, setDuration] = useState<string>();
  const dateFormatter = useCompanyDateFormatter({
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });

  useEffect(() => {
    if (props.values?.startDate) {
      setPointInTimeDate(toCalendarDate(props.values.startDate).toString());
    }

    setStartDate(
      props.values?.startDate ? toCalendarDate(props.values?.startDate).toString() : undefined
    );

    setEndDate(
      props.values?.endDate ? toCalendarDate(props.values?.endDate).toString() : undefined
    );

    setDuration(props.values?.duration);
    setPeriodMethod(props.values?.periodMethod || "endDate");
  }, [
    props.values?.startDate,
    props.values?.endDate,
    props.values?.duration,
    props.values?.periodMethod,
  ]);

  // Point in time
  if (props.method === AccountingRecognitionTiming.PointInTime) {
    return (
      <Box onClick={(e) => e.stopPropagation()}>
        <DateInput
          onClick={(e) => e.stopPropagation()}
          size="small"
          aria-label="datepicker-point-in-time"
          placeholder={props.placeholder ?? "Pick a date"}
          value={pointInTimeDate ? parseDate(pointInTimeDate) : undefined}
          onChange={(value) => {
            if (value) {
              props.onPointInTimeDateChange(value);
              setPointInTimeDate(toCalendarDate(value).toString());
              props.onOpenChange?.();
            }
          }}
        />
      </Box>
    );
  }

  // Not enough info to deicde on what to show
  if (!props.method || !props.calculation) {
    return (
      <Tooltip content="Please select a policy before specifying the recognition period">
        <Select
          options={[]}
          size="small"
          onSelectionChange={noop}
          placeholder={props.placeholder}
          css={{ whiteSpace: "nowrap" }}
        />
      </Tooltip>
    );
  }

  // Manual
  if (props.calculation === AccountingRecognitionCalculation.Manual) {
    return <Text>Manually defined</Text>;
  }

  // Straight-line or Daily
  if (props.calculation && STRAIGHT_LINE_OR_DAILY_CALCULATIONS.includes(props.calculation)) {
    return (
      <Menu
        dark
        onClick={(e) => e.preventDefault()}
        onOpenChange={props.onOpenChange}
        trigger={
          <Select
            options={[]}
            size="small"
            css={{ whiteSpace: "nowrap" }}
            onSelectionChange={noop}
            value={
              startDate && endDate
                ? dateFormatter.formatRange(parseDate(startDate), parseDate(endDate))
                : undefined
            }
            placeholder={props.placeholder}
          />
        }
      >
        <Box
          css={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: S["1h"],
            padding: S["1h"],
            alignItems: "center",
          }}
        >
          <Text size="body">Start date</Text>

          <Box onClick={(e) => e.stopPropagation()}>
            <DateInput
              maxDate={endDate ? parseDate(endDate) : undefined}
              size="mini"
              placeholder="Pick a date"
              aria-label="Period selector start date"
              value={startDate ? parseDate(startDate) : undefined}
              onClick={(e) => e.stopPropagation()}
              onChange={(value) => {
                if (value) {
                  props.onStartDateChange(value);
                  setStartDate(toCalendarDate(value).toString());
                }
              }}
            />
          </Box>

          {DAILY_CALCULATIONS.includes(props.calculation) && (
            <>
              <Text size="body">Period method</Text>
              <RadioGroup
                direction="horizontal"
                value={periodMethod}
                onValueChange={(method) => {
                  props.onPeriodMethodChange?.(method as PeriodMethod);
                  setPeriodMethod(method as PeriodMethod);
                }}
                onClick={(e) => e.stopPropagation()}
                options={[
                  {
                    label: "End date",
                    value: "endDate",
                  },
                  {
                    label: "Duration",
                    value: "duration",
                  },
                ]}
              />
            </>
          )}
          <Text size="body">
            {periodMethod === "duration" ||
            props.calculation === AccountingRecognitionCalculation.StraightLineByPeriod
              ? "Duration month(s)"
              : "End date"}
          </Text>

          {periodMethod === "endDate" &&
            props.calculation !== AccountingRecognitionCalculation.StraightLineByPeriod && (
              <Box onClick={(e) => e.stopPropagation()}>
                <DateInput
                  size="mini"
                  minDate={startDate ? parseDate(startDate) : undefined}
                  placeholder="Pick a date"
                  aria-label="Period selector end date"
                  value={endDate ? parseDate(endDate) : undefined}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(value) => {
                    if (value) {
                      props.onEndDateChange(value);
                      setEndDate(toCalendarDate(value).toString());
                    }
                  }}
                />
              </Box>
            )}

          {(periodMethod === "duration" ||
            props.calculation === AccountingRecognitionCalculation.StraightLineByPeriod) && (
            <Box onClick={(e) => e.stopPropagation()}>
              <Select
                options={durationOptions}
                value={duration}
                size="small"
                onSelectionChange={(duration) => {
                  setDuration(duration);
                  props.onDurationChange?.(duration);
                  const durationEndDate =
                    startDate && duration
                      ? parseDate(startDate)
                          .add({ months: parseInt(duration) })
                          .add({ days: -1 })
                      : undefined;

                  if (durationEndDate) {
                    setEndDate(toCalendarDate(durationEndDate).toString());
                    props.onEndDateChange(durationEndDate);
                  }
                }}
              />
            </Box>
          )}
        </Box>
      </Menu>
    );
  }

  // Should never get here
  return null;
}

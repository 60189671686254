import React from "react";
import useMercury from "./useMercuryIntegration";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import MercuryLogo from "./MercuryLogo";
import { IntegrationDisplayComponent } from "../shared/types";

type MercuryListItemProps = {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}
export const TITLE = "Mercury";
export const SUBTITLE = "Credit Card";

const MercuryListItem = ({ DisplayComponent, companyId }: MercuryListItemProps) => {
  const { onClickConnect, connection, loading } = useMercury({ companyId });
  return (
    <DisplayComponent
      integrationType={IntegrationType.Mercury}
      connectIntegration={onClickConnect}
      title={TITLE}
      // subtitle={SUBTITLE}
      connection={connection}
      logo={<MercuryLogo />}
      loading={loading}
    />
  );
};

export default MercuryListItem;

import React, { useCallback, useMemo, useState } from "react";
import { endOfMonth, today } from "@internationalized/date";
import { last } from "lodash";

import { styled, GroupBy, IconButton, StatusIcon, StatusRing } from "@puzzle/ui";
import { LockTransaction } from "@puzzle/icons";
import { parseCalendarMonth } from "@puzzle/utils";

import Analytics from "lib/analytics";
import { DynamicReportType, IntervalMetadataFragment } from "graphql/types";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { useReportContext } from "components/reports/ReportContext";

import ChecklistStatusCard from "./ChecklistStatusCard";
import { PzReportLabel } from "./styledComponents";
// import { AccountChecklistItemFragment } from "components/dashboard/Checklist/types";

const Clickable = styled("div", {
  cursor: "pointer",
  lineHeight: 0,
  outline: "none",
  "@media print": { display: "none" },
});

const Root = styled("div", {
  width: "min-content",
  marginLeft: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
  gap: "$1",
  "@media print": {
    fontSize: "$bodyL",
  },
});

const LabelWithIndicator = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$1",
  justifyContent: "flex-end",
  height: "20px",
});

const InProgressLabel = styled("div", {
  textStyle: "$bodyXS",
  color: "$gray400",
  fontWeight: "$heavy",
  "@media print": {
    fontSize: "$bodyL",
  },
});

const Label = styled("div", {
  // Always match the height of the potential icon
  textStyle: "$bodyXS",
  height: "20px",
  display: "flex",
  alignItems: "center",

  "@media print": {
    fontSize: "$bodyL",
  },
});

const IconContainer = styled(IconButton, {
  transform: "scale(1.4)",
  marginBottom: "1px",
  variants: {
    hoverColor: {
      green: { color: "$green800", "&:hover, &:focus": { color: "$green600" } },
      purple: { color: "$mauve100", "&:hover, &:focus": { color: "$purple700" } },
    },
  },
});

const TimePeriodHeader = ({
  metrics,
  label,
  reportType,
  isInProgress = false,
}: {
  metrics?: IntervalMetadataFragment;
  label: string;
  isInProgress?: boolean;
  reportType: DynamicReportType;
}) => {
  const { timeZone, isWithinLockedPeriod } = useActiveCompany<true>();
  const {
    options: { groupBy },
  } = useReportContext();

  const dateFormatter = useCompanyDateFormatter({
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });

  if (metrics?.interval.__typename === "LedgerDateRangeInterval") {
    throw new Error(`TimePeriodHeader needs to be implemented for LedgerDateRangeInterval`);
  }
  const interval = metrics?.interval;
  const popoverLabel = useMemo(() => {
    if (metrics && interval) {
      // Make month and total more explicit than the column header
      if (groupBy === GroupBy.Month) {
        return metrics.interval.key;
      } else if (groupBy === GroupBy.Total && interval.periods.length > 0) {
        return dateFormatter.formatRange(
          parseCalendarMonth(interval.periods[0]),
          endOfMonth(parseCalendarMonth(last(interval.periods)!))
        );
      }
    }

    return label;
  }, [metrics, label, groupBy, dateFormatter, interval]);

  const isSingleMonth = useMemo(() => {
    if (metrics && interval) {
      const from = parseCalendarMonth(interval?.periods[0]);
      const to = parseCalendarMonth(last(interval?.periods)!);
      return from.toString() === to.toString();
    }
    return false;
  }, [metrics, interval]);

  const [open, _setOpen] = useState(false);
  const iconActive = open;

  const isComplete =
    metrics?.unfinalized.percentComplete === 100 && metrics?.uncategorized.percentComplete === 100;
  // TODO turn this off until we can make this more efficient
  // (
  //   metrics.checklist.items.filter(
  //     (i) => i.__typename === "AccountChecklistItem"
  //   ) as AccountChecklistItemFragment[]
  // ).every((i) => !!i.dataAnomalyReview.context.reconciledThrough);

  const isLocked = useMemo(
    () =>
      Boolean(
        interval?.periods[0] && isWithinLockedPeriod(parseCalendarMonth(interval.periods[0]))
      ),
    [isWithinLockedPeriod, interval?.periods]
  );

  const isSpotlight = useMemo(() => label.includes("Change") || label.includes("%"), [label]);

  const setOpen = useCallback(
    (open: boolean) => {
      _setOpen(open);

      if (open) {
        Analytics.dashboardReportCategorizationStatusViewed({
          columnName: label,
          reportType,
          categorizedPercent: metrics?.uncategorized.percentComplete ?? 0,
          finalizedPercent: metrics?.unfinalized.percentComplete ?? 0,
          isComplete,
          isInProgress,
          isLocked,
        });
      }
    },
    [
      isComplete,
      isInProgress,
      isLocked,
      label,
      metrics?.uncategorized.percentComplete,
      metrics?.unfinalized.percentComplete,
      reportType,
    ]
  );

  return (
    <Root>
      {isInProgress &&
        // yuck lol
        !isSpotlight && <InProgressLabel>In Progress</InProgressLabel>}

      <LabelWithIndicator>
        {!isSpotlight && metrics && (
          <ChecklistStatusCard
            open={open}
            onOpenChange={setOpen}
            isComplete={isComplete}
            isInProgress={isInProgress}
            metrics={metrics}
            label={popoverLabel}
            isLocked={isLocked}
            isSingleMonth={isSingleMonth}
            trigger={
              <Clickable tabIndex={0}>
                <>
                  {isComplete ? (
                    isLocked ? (
                      <IconContainer hoverColor="green">
                        <LockTransaction />
                      </IconContainer>
                    ) : (
                      <StatusIcon
                        css={{ display: "block" }}
                        size={24}
                        status={
                          interval
                            ? isInProgress ||
                              endOfMonth(parseCalendarMonth(interval?.periods[0])).compare(
                                today(timeZone)
                              ) > 0
                              ? "completeAndInProgress"
                              : "complete"
                            : "unchecked"
                        }
                        active={iconActive}
                        activeOnHover
                      />
                    )
                  ) : isLocked ? (
                    <IconContainer hoverColor="purple">
                      <LockTransaction />
                    </IconContainer>
                  ) : (
                    <StatusRing
                      values={[
                        metrics.unfinalized.percentComplete,
                        metrics.uncategorized.percentComplete,
                      ]}
                      colors={["finalized", "categorized"]}
                      active={iconActive}
                      activeOnHover
                    />
                  )}
                </>
              </Clickable>
            }
          />
        )}

        <PzReportLabel>{label}</PzReportLabel>
      </LabelWithIndicator>
    </Root>
  );
};

export default TimePeriodHeader;

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { ActorType, TransactionMemoType } from "graphql/types";
import { omit, uniqueId } from "lodash";
import {
  AiPromptFragment,
  useAskAboutMyBusinessMutation,
  useAskAboutTransactionMutation,
  useProvideAdditionalContextForTransactionMutation,
} from "./graphql.generated";
import {
  FullTransactionFragment,
  GetFullTransactionDocument,
  TransactionPageActivity_TransactionAiComment_Fragment,
} from "components/dashboard/Transactions/graphql.generated";
import { useCallback } from "react";
import useSelf from "components/users/useSelf";
import useAssignments from "components/dashboard/Transactions/hooks/useAssignments";

export const AI_NAME = "Generated with PuzzleAI";
export const LOADING_PLACEHOLDER_MESSAGE = "[LOADING]";

// Creates an optimistic update object that will show that gpt is
// loading in the activity section
export const createGPTLoadingPlaceholderActivity =
  (): TransactionPageActivity_TransactionAiComment_Fragment => ({
    __typename: "TransactionAIComment",
    actor: {
      type: ActorType.AiActor,
      name: "Puzzlebot AI",
    },
    id: "mock id" + uniqueId(),
    type: TransactionMemoType.AiComment,
    createdAt: new Date().toISOString(),
    createdByUser: { name: AI_NAME },
    message: LOADING_PLACEHOLDER_MESSAGE,
  });

export const useAskAI = () => {
  const { company } = useActiveCompany<true>();
  const { self } = useSelf();
  const { assignUser } = useAssignments();
  const [requestInformation, askAboutTransactionResult] = useAskAboutTransactionMutation();
  const [provideContext] = useProvideAdditionalContextForTransactionMutation();
  const [_askAboutMyBusiness, askAboutMyBusinessResult] = useAskAboutMyBusinessMutation();

  // the response will show up as activity in the activity section
  const triggerLastMileWorkflow = (
    transaction: FullTransactionFragment,
    { onError }: { onError: () => void }
  ) => {
    return assignUser({ targetUser: self!, transaction, request: "category review" }).catch(
      onError
    );
  };

  const askAboutTransaction = (
    transaction: FullTransactionFragment,
    { onError }: { onError: () => void }
  ) => {
    return requestInformation({
      onError,
      variables: {
        input: {
          transactionId: transaction.id,
          companyId: company.id,
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        requestTransactionInformationFromAI: {
          transaction: {
            ...omit(transaction, "activity"),
            activity: {
              __typename: "TransactionActivityDetails",
              currentAssignment: transaction.activity.currentAssignment,
              activity: [...transaction.activity.activity, createGPTLoadingPlaceholderActivity()],
            },
          },
        },
      },
    });
  };

  // the response will show up as activity in the activity section
  const provideAdditionalContextForTransaction = useCallback(
    (transaction: FullTransactionFragment, context: string) => {
      if (!transaction.activity.currentAssignment) {
        throw new Error("No assignment cant provide context");
      }
      return provideContext({
        variables: {
          input: {
            context,
            transactionId: transaction.id,
            assignmentId: transaction.activity.currentAssignment.id,
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          provideAdditionalContextForTransaction: {
            transaction: {
              ...omit(transaction, "activity"),
              activity: {
                __typename: "TransactionActivityDetails",
                currentAssignment: transaction.activity.currentAssignment,
                activity: [
                  ...transaction.activity.activity,
                  {
                    __typename: "TransactionMessage",
                    actor: {
                      type: ActorType.UserActor,
                      name: self?.name ?? self?.email ?? "",
                    },
                    id: "mock id" + uniqueId(),
                    type: TransactionMemoType.Message,
                    createdAt: new Date().toISOString(),
                    createdByUser: { ...self },
                    text: context,
                  },
                  createGPTLoadingPlaceholderActivity(),
                ],
              },
            },
          },
        },
        refetchQueries: [{ query: GetFullTransactionDocument, variables: { id: transaction.id } }],
      });
    },
    [provideContext, self]
  );

  const askAboutMyBusiness = (prompt: AiPromptFragment) => {
    _askAboutMyBusiness({
      variables: { input: { promptName: prompt.name, companyId: company.id } },
    });
  };

  return {
    triggerLastMileWorkflow: triggerLastMileWorkflow,
    askAboutMyBusiness,
    askAboutTransaction,
    askAboutTransactionResult,
    askAboutMyBusinessResult,
    provideAdditionalContextForTransaction,
  };
};

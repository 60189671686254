import Big from "big.js";
import { PuzzlePlanFragment } from "components/companies/graphql.generated";
import { Money, PuzzleAddon, PuzzlePriceBillingInterval } from "graphql/types";
import Analytics from "lib/analytics";

import config from "lib/config";
import { parseCookies } from "nookies";
import { Cookies } from "lib/cookies";
import { reportError } from "lib/errors";
import { NEW_SUBSCRIPTION_EVENT_DESTINATION } from "./constants/events";

export const getSubscriptionCycleLabel = (cycle: PuzzlePriceBillingInterval) => {
  return cycle === PuzzlePriceBillingInterval.Month ? "/ month" : "/ year";
};

export const getUpgradeButtonLabel = (
  plan: PuzzlePlanFragment,
  currentPlan: PuzzlePlanFragment | undefined | null
) => {
  if (plan.id === currentPlan?.id) {
    return "Current plan";
  }
  if ((currentPlan?.tier ?? 0) < plan.tier) {
    return plan.isCustom ? "Book a demo" : "Upgrade";
  }
  return `Switch to ${plan.displayName}`;
};

// It calls the calcPrice price, and rounds the result
export const calcPriceRounded = (
  cycle: PuzzlePriceBillingInterval,
  itemToSubscribe: PuzzlePlanFragment | PuzzleAddon,
  estimateMonthly?: boolean
) => {
  return Math.round(
    calcPrice(
      cycle,
      itemToSubscribe.monthlyPrice,
      itemToSubscribe.annualPrice,
      estimateMonthly
    ).toNumber()
  );
};

// Calculates the total for a subscription
// Including the plan price (it must be rounded)
// Including the addOn price
export const calcSubtotal = (
  selectedAddOns: PuzzleAddon[],
  cycle: PuzzlePriceBillingInterval,
  plan?: PuzzlePlanFragment,
  estimateMonthly?: boolean
) => {
  const planPrice = new Big(plan ? calcPriceRounded(cycle, plan, estimateMonthly) : 0);
  const addOnsPrice = calcAddOnPrice(selectedAddOns, cycle, estimateMonthly);
  const total = planPrice.add(addOnsPrice);
  return (estimateMonthly ? total.round(0) : total).toNumber();
};

// Calculates the value for a plan or add-on subscriptions
// Checks if the cycle is annual or monthly and calculates it.
export const calcPrice = (
  cycle: PuzzlePriceBillingInterval,
  monthlyPrice?: Money | null,
  annualPrice?: Money | null,
  estimateMonthly?: boolean
) => {
  if (cycle === PuzzlePriceBillingInterval.Month && monthlyPrice) {
    return new Big(monthlyPrice.amount);
  }

  if (cycle === PuzzlePriceBillingInterval.Year && annualPrice) {
    const planPriceYear = new Big(annualPrice.amount);
    return estimateMonthly ? planPriceYear.div(12) : planPriceYear;
  }
  return new Big(0);
};

// Iterates over an array of add-ons and returns the total.
export const calcAddOnPrice = (
  selectedAddons: PuzzleAddon[],
  cycle: PuzzlePriceBillingInterval,
  estimateMonthly?: boolean
) => {
  return selectedAddons.reduce(
    (acc, addOn) =>
      acc.add(calcPrice(cycle, addOn.monthlyPrice, addOn.annualPrice, estimateMonthly)),
    new Big(0)
  );
};

export const sendUpgradePlanAnalyticsEvent = (
  companyId: string,
  userId: string | undefined,
  planId: string,
  isFree: boolean
) => {
  Analytics.paidTrialUpgradePlanSelected({
    companyId,
    userId: userId ?? undefined,
    currentPlan: planId,
    previousPlan: "",
    upgradedToPremium: !isFree,
    downgradedToFree: isFree,
  });
};

export const joinWithCommasAnd = (array: string[]) => {
  if (array.length === 0) return "";
  if (array.length === 1) return array[0];
  if (array.length === 2) return `${array[0]} and ${array[1]}`;
  const joinedExceptLast = array.slice(0, array.length - 1).join(", ");
  const lastItem = array[array.length - 1];
  return `${joinedExceptLast} and ${lastItem}`;
};

export const createStripeCheckoutSessionProps = (
  companyId: string,
  plan: PuzzlePlanFragment,
  addOns: PuzzleAddon[],
  bookkeeping: boolean,
  subscriptionCycle: PuzzlePriceBillingInterval
) => {
  const planPriceId =
    subscriptionCycle === PuzzlePriceBillingInterval.Month
      ? plan.monthlyStripePriceId
      : plan.annualStripePriceId;
  if (!planPriceId) {
    throw Error(
      `Selected plan ("${plan.name}") doesn't have a price defined for the selected cycle.`
    );
  }

  let successUrl = `${config.ROOT_URL}?paymentSuccess=true&plan=${plan.displayName}&selectedPlanStripeId=${planPriceId}`;
  let cancelUrl = `${config.ROOT_URL}?paymentSuccess=false&plan=${plan.displayName}`;
  if (addOns.length > 0) {
    const addOnsParams = addOns.map((addOn) => `&addon=${addOn.displayName}`);
    successUrl += addOnsParams;
    cancelUrl += addOnsParams;
  }
  // Getting bookkeeping = true on after a payment cancelation
  // will also run the inbox workflow for additional services
  // so we just enable it for the success flow
  if (bookkeeping) {
    successUrl += `&bookkeeping=true`;
  }

  const addonPriceIds = addOns.map((addOn) => {
    const addOnPriceId =
      subscriptionCycle === PuzzlePriceBillingInterval.Month
        ? addOn.monthlyStripePriceId
        : addOn.annualStripePriceId;
    if (!addOnPriceId) {
      throw Error(
        `Selected addOn ("${addOn.name}") doesn't have a price defined for the selected cycle.`
      );
    }
    return addOnPriceId;
  });

  const stripePriceIds = [planPriceId, ...addonPriceIds];
  const cookies = parseCookies();
  const facebookClickId: string | undefined = cookies[Cookies.FacebookClickId];
  const facebookPixelId: string | undefined = cookies[Cookies.FacebookPixelId];

  return {
    companyId,
    successUrl,
    cancelUrl,
    stripePriceIds,
    facebookPixelId,
    facebookClickId,
  };
};

export const getStripeIds = (addOns: PuzzleAddon[], cycle: PuzzlePriceBillingInterval) => {
  return (
    addOns.reduce<string[]>((result, addon) => {
      const stripeId =
        cycle === PuzzlePriceBillingInterval.Year
          ? addon.annualStripePriceId
          : addon.monthlyStripePriceId;
      if (stripeId) {
        result.push(stripeId);
      }
      return result;
    }, []) ?? []
  );
};

export const getAddonsArrayFromQueryParam = (addOns: string | string[] | undefined) => {
  if (!addOns) {
    return [];
  }

  if (Array.isArray(addOns)) {
    return addOns;
  }
  return [addOns];
};

export const sendNewSubscriptionEvent = (stripeId: string | string[]) => {
  try {
    gtag("event", "conversion", {
      send_to: NEW_SUBSCRIPTION_EVENT_DESTINATION,
      value: stripeId,
      currency: "USD",
      event_callback: () => undefined,
    });
  } catch (error: any) {
    reportError(error);
  }
};

import React from "react";
import { rootStyle, messageContainer, iconContainerRecipe } from "./PreparingYourFinancials.css";
import { Text } from "@puzzle/ui";
import Link from "components/common/Link";
import { Arrow, Check, Envelope } from "@puzzle/icons";
import { ProgressBar, ProgressBox } from "./ProgressBox";
import { usePostOnboardModalStore } from "./postOnboardModalStore";
import { Button, S } from "ve";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import Analytics from "lib/analytics";

const completeMsg =
  "Your company financial data has been fully ingested into Puzzle and your company is ready.";
const inProgressMsg =
  "We’ll send you an email once the data ingestion is complete and your financials are ready.";

export const PrepareYourFinancials = ({ onContinue }: { onContinue: () => void }) => {
  const { initialIngestCompleted } = useActiveCompany<true>();
  const { isPostOnboardModalProgressShown, setIsPostOnboardModalProgressShown } =
    usePostOnboardModalStore();
  const isCompleted = initialIngestCompleted || !isPostOnboardModalProgressShown;

  const handleComplete = () => {
    setIsPostOnboardModalProgressShown(false);
  };

  return (
    <div className={rootStyle}>
      <Text variant="headingM" color="white">
        Preparing Your Financials
      </Text>
      {isCompleted ? <ProgressBar progress={100} /> : <ProgressBox onComplete={handleComplete} />}
      <div className={messageContainer}>
        <div className={iconContainerRecipe({ withBackground: isCompleted })}>
          {isCompleted ? <Check /> : <Envelope />}
        </div>
        <Text>{isCompleted ? completeMsg : inProgressMsg}</Text>
      </div>
      <Text>
        If you have any questions, you can{" "}
        <Link color="greenalpha" onClick={() => Analytics.chatWithAnExpertClicked()}>
          chat with our team.
        </Link>
      </Text>
      <Button
        onClick={onContinue}
        css={{ alignSelf: "flex-end", marginTop: "auto", marginBottom: S.$1 }}
        suffixElement={<Arrow size={16} color="$black" />}
      >
        Continue
      </Button>
    </div>
  );
};

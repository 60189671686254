import React from "react";
import { FinancialInstitutionFragment } from "graphql/types";
import { BankLogo } from "../bank";
import { IntegrationLogo } from "components/integrations";

import { BrandIcon, BRAND_BACKGROUND_COLORS } from "@puzzle/icons";
import institutionLogoResolver from "./InstitutionLogoResolver";

export type InstitutionLogoProps = {
  institution: Pick<FinancialInstitutionFragment, "name">;
  circular?: boolean;
};

const InstitutionLogo = ({ institution, circular }: InstitutionLogoProps) => {
  const institutionName = institution.name;

  const brandName = institutionLogoResolver(institutionName);

  if (brandName) {
    return (
      <IntegrationLogo circular={circular} color={BRAND_BACKGROUND_COLORS[brandName]}>
        <BrandIcon name={brandName} />
      </IntegrationLogo>
    );
  }

  return <BankLogo circular={circular} />;
};

export default React.memo(InstitutionLogo);

import React, { useMemo, useCallback, useRef } from "react";
import { ApolloCache } from "@apollo/client";

import { compact, uniqBy } from "lodash";
import Big from "big.js";
import { usePathname } from "next/navigation";

import { parseDate } from "@internationalized/date";

import { useToasts, DateInput } from "@puzzle/ui";
import { Add, AddUser, CaretDown, CaretRight, Rule2, Split, Upload } from "@puzzle/icons";
import { toCalendarDate } from "@puzzle/utils";

import Analytics from "lib/analytics";
import { IS_DEV, IS_LOCAL_DEVELOPMENT } from "lib/config";
import { Route } from "lib/routes";
import { isEditorRole } from "lib/roles";
import usePersistedBoolQueryParam from "lib/usePersistedQueryParam";

import { Collapse, Text, Button, IconButton, Loader, Stack, S, color } from "ve";

import useFile from "components/common/files/useFile";
import { RevenueRecognitionSchedule } from "components/dashboard/Invoices/ScheduleDrawer/RevenueRecognitionSchedule";
import { NewAssetFromTransactionDrawer } from "components/dashboard/Accounting/FixedAssetsV2/NewAssetFromTransactionDrawer";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { VendorSelect } from "components/transactions/vendors";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import ClassificationsSubsection from "./ClassificationsSubsection";
import { MatchBillsSubsection } from "./MatchBillsSubsection";
import { DocumentationSection } from "../../../common/DocumentationSection";
import { getInstitutionForDocument, updateTransactionDocumentCache } from "./utils";
import { LinkedTransactions } from "./LinkedTransactions";

import {
  AssociatedEntity,
  ClassAwareType,
  IntegrationType,
  LedgerView,
  LedgerEventStatus,
  FileFragment,
} from "graphql/types";
import { FullTransactionFragment, TransactionDocumentFragment } from "../graphql.generated";
import { CategoryFragment } from "graphql/fragments/category.generated";
import useSingleTransaction, {
  UpdateCategoryMetricsLocations,
  UpdateCategoryMetricsView,
  useExtraTransactionState,
  useUpdateCategory,
  useUpdateAccrualDate,
} from "../hooks/useSingleTransaction";
import useSearchOptions from "../hooks/useSearchOptions";
import RecurrenceSelect from "../UpdateField/RecurrenceSelect";
import CustomerSelect from "../UpdateField/CustomerSelect";
import ProductSelect from "../UpdateField/ProductSelect";

import { CategorySubsection } from "./CategorySubsection";
import { useDetailPaneContext } from "./DetailPaneContext";
import { AskAIAboutTransactionButton } from "../AI/AskAIAboutTransactionButton"; // AI Lookup button
import { TriggerLastMileButton } from "../AI/TriggerLastMileButton"; // AI Categorizer button

import { AssignmentsSection } from "./AssignmentsSection";
import { AssignmentMenu } from "./AssignmentMenu";
import { JournalsWithStatus } from "./JournalsWithStatus";
import { FixedAssetCard, PrepaidCard } from "./Cards";
import { InvoicePaymentSubsection } from "./InvoicePaymentSubsection";

import {
  rootStyle,
  contentStyle,
  buttonRow,
  buttonRowPacked,
  journalsRow,
  subSection,
  simpleRow,
  numberBadge,
} from "./accountingSection.css";

function UploadedBy({
  document,
  transaction,
}: {
  document: TransactionDocumentFragment;
  transaction: FullTransactionFragment;
}) {
  if (document.uploadActorType === "SYSTEM_ACTOR") {
    return <span>via {getInstitutionForDocument(document, transaction)}</span>;
  }
  return null;
}

const CARET_SIZE = 10;

export const AccountingSection = React.memo(function AccountingSection({
  transaction,
  isRevenueTransaction,
  hasEditableRule,
  showRuleModal,
}: {
  transaction: FullTransactionFragment;
  isRevenueTransaction: boolean;
  hasEditableRule: boolean;
  showRuleModal: () => void;
}) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { toast } = useToasts();
  const pathname = usePathname();
  const [accountingSectionOpen, setAccountingSectionOpen] = usePersistedBoolQueryParam(
    "pz:transactions-pane-accounting-open",
    "accounting",
    true
  );

  const [journalSubsectionCollapsed, setJournalSubsectionCollapsed] = usePersistedBoolQueryParam(
    "pz:transactions-pane-journals-collapsed",
    "je",
    false
  );

  const [invoiceSubsectionCollapsed, setInvoiceSubsectionCollapsed] = usePersistedBoolQueryParam(
    "pz:transactions-pane-invoice-collapse",
    "invoice",
    false
  );

  const [linkedTransactionsSubsectionCollapsed, setLinkedTransactionsSubsectionCollapsed] =
    usePersistedBoolQueryParam("pz:transactions-pane-linked-transactions-collapse", "lt", false);

  const {
    // FIXME: useExtraTransactionState
    canEdit,
    canEditSplits,

    // FIXME: Individually load these mutation hooks.
    // They create state which will re-render the whole sidebar.
    updateVendor,
    updateRecurrence,
    updateCustomer,
    updateProduct,
    refetch,
  } = useSingleTransaction({ id: transaction?.id });
  const { canBeBillPayment, canBeInvoicePayment, canRecategorizeSplit } =
    useExtraTransactionState(transaction);

  const { categories, categoriesByPermaKey } = useSearchOptions();
  const {
    close,
    isEditingSplit,
    canEdit: canEditDetailPane,
    toggleEditingSplit,
    isScheduleDrawerOpen,
    setIsScheduleDrawerOpen,
    isNewAssetDrawerOpen,
    setIsNewAssetDrawerOpen,
    setPreviewFile,
  } = useDetailPaneContext<true>();
  const updateAccrualDate = useUpdateAccrualDate(transaction);
  const { lockedPeriodDate, membershipRole, company } = useActiveCompany<true>();
  const isEditor = isEditorRole(membershipRole);

  const updateCategory = useUpdateCategory(transaction);

  const onCategoryChange = (c: CategoryFragment) => {
    if (canEdit && c)
      updateCategory({
        category: c,
        metrics: {
          location: UpdateCategoryMetricsLocations.TransactionsDrawer,
          component: UpdateCategoryMetricsView.CategoryModal,
        },
      });
  };

  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const showAccrualDate = useMemo(
    () =>
      !isEditingSplit &&
      transaction.splits.length === 0 &&
      (canEditDetailPane || transaction.detail.accrualDate) &&
      !transaction.linkedBills.length,
    [isEditingSplit, transaction, canEditDetailPane]
  );

  const handleFileError = (msg = "There was an error uploading your file") => {
    toast({ message: msg, status: "error" });
  };

  const { dropAreaProps, isDropping, isUploading, onFiles } = useFile({
    entityId: transaction.id,
    entityType: AssociatedEntity.Transaction,
    onError: handleFileError,
    onUploadComplete: () => {
      Analytics.transactionDocumentationAdded({
        transactionId: transaction.id,
      });
      refetch?.();
    },
  });
  const openFilePicker = useCallback(() => hiddenFileInput.current?.click(), []);

  const journals = useMemo(() => {
    let eventAmount: string | null = null;
    return compact(
      uniqBy(
        transaction?.events
          .filter((event) => {
            eventAmount = event.amount;
            return event.entries && event.entries.length > 0;
          })
          .flatMap(({ entries }) => entries)
          .filter((entry) => entry?.amount === eventAmount),
        "accountId"
      )
    );
  }, [transaction?.events]);

  const cashStatus = transaction.eventViews.cash.status; // todo: use this!
  const accrualStatus = transaction.eventViews.accrual.status;
  const { fixedAsset, prepaid, schedule } = transaction.detail;

  const showNewFixeedAssetButton =
    Big(transaction.amount).lt(0) && !fixedAsset?.id && pathname !== Route.fixedAssets && isEditor;
  const showFixedAsset = fixedAsset?.id && pathname !== Route.fixedAssets;
  const accrualDate = transaction.detail.accrualDate && parseDate(transaction.detail.accrualDate);
  const showPayments = company?.features.arPaymentsEnabled || IS_LOCAL_DEVELOPMENT;
  const hasLinkedTransactions = useMemo(
    () => Boolean(transaction && transaction.links.length),
    [transaction]
  );

  const scrollToBottomOfActivity = () => {
    // use setTimeout out so that by the time we scroll down,
    // the "AI response loading" placeholder activity element is already
    // there
    setTimeout(() => {
      document.getElementById("activity-footer")?.scrollIntoView({
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <div className={rootStyle}>
      <div className={simpleRow}>
        <Text variant="headingS" color="white" weight="heavy">
          Accounting
        </Text>

        <IconButton
          size="medium"
          onClick={() => {
            setAccountingSectionOpen(!accountingSectionOpen);
          }}
        >
          <CaretDown
            width={CARET_SIZE}
            height={CARET_SIZE}
            rotate={accountingSectionOpen ? 0 : -90}
            color={color.white70}
          />
        </IconButton>
      </div>
      <Collapse open={accountingSectionOpen} style={{ width: "100%" }}>
        <div className={contentStyle}>
          <div className={subSection}>
            <div className={buttonRow}>
              <Text variant="bodyS" color="white" weight="heavy">
                Category
              </Text>
              <div className={buttonRowPacked}>
                {isEditor && (
                  <TriggerLastMileButton
                    key="trigger-last-mile-button"
                    onClick={scrollToBottomOfActivity}
                    transaction={transaction}
                    includeText={false}
                  />
                )}
                {canEditSplits && (
                  <Button
                    variant="secondaryAlt"
                    size="mini"
                    prefixElement={<Split />}
                    onClick={toggleEditingSplit}
                  >
                    {transaction.splits.length > 0 ? "Edit split" : "Split"}
                  </Button>
                )}

                <AssignmentMenu
                  request="category review"
                  transaction={transaction}
                  trigger={
                    <Button variant="secondaryAlt" size="mini" prefixElement={<AddUser />}>
                      Request review
                    </Button>
                  }
                />
                {isEditor && (
                  <Button
                    variant="secondaryAlt"
                    size="mini"
                    prefixElement={<Rule2 />}
                    onClick={showRuleModal}
                  >
                    {hasEditableRule ? "Edit rule" : "Create rule"}
                  </Button>
                )}
              </div>
            </div>
            <CategorySubsection
              transaction={transaction}
              categories={categories}
              canEdit={!!company && canEditSplits}
              canRecategorizeSplit={canRecategorizeSplit}
            />

            <AssignmentsSection
              transaction={transaction}
              assignmentTypeFilter={(type: string) => type === "category review"}
            />
          </div>
          {isRevenueTransaction ? (
            <>
              <div className={subSection}>
                <Text variant="bodyS" color="white" weight="heavy">
                  Customer
                </Text>
                <CustomerSelect
                  key="customer-select"
                  value={transaction.detail.customer}
                  onSelect={updateCustomer}
                  onCreateCustomer={updateCustomer}
                  canEdit={canEdit || !transaction.payment}
                />
              </div>

              <div className={subSection}>
                <Text variant="bodyS" color="white" weight="heavy">
                  Product
                </Text>
                <ProductSelect
                  key="product-select"
                  value={transaction.detail.product}
                  onSelect={updateProduct}
                  onCreateProduct={updateProduct}
                  canEdit={canEdit}
                />
              </div>
            </>
          ) : (
            <div className={subSection}>
              <div className={buttonRow}>
                <Text variant="bodyS" color="white" weight="heavy">
                  Vendor
                </Text>
                {isEditor && (
                  <AskAIAboutTransactionButton
                    key="ask-ai-about-transaction-button"
                    onClick={scrollToBottomOfActivity}
                    transaction={transaction}
                    includeText={false}
                  />
                )}
              </div>

              <VendorSelect
                key="vendor-select"
                value={transaction.detail.vendor}
                onSelect={updateVendor}
                onCreate={updateVendor}
                canEdit={canEditSplits}
                fullWidth={true}
                //suggestAutoGenerateRuleContent  TODO: do we need this on the new version? Seems not?
              />
            </div>
          )}

          <div className={subSection}>
            <div className={buttonRow}>
              <Text variant="bodyS" color="white" weight="heavy">
                Receipts
              </Text>
              <Button
                variant="secondaryAlt"
                size="mini"
                prefixElement={
                  isUploading ? <Loader /> : <Upload size={14} color={color.white80} />
                }
                onClick={openFilePicker}
                disabled={isUploading}
              >
                <Text variant="bodyS" color="white80">
                  Upload
                </Text>
              </Button>
              <AssignmentMenu
                request="documentation"
                transaction={transaction}
                trigger={
                  <Button variant="secondaryAlt" size="mini" prefixElement={<AddUser size={14} />}>
                    <Text variant="bodyS" color="white80">
                      Assign
                    </Text>
                  </Button>
                }
              />
            </div>

            <DocumentationSection
              setPreviewFile={(file) => setPreviewFile(file as FileFragment)}
              associatedEntity={AssociatedEntity.Transaction}
              documents={transaction.documentation}
              uploadedBy={(document) => (
                <UploadedBy
                  document={document as TransactionDocumentFragment}
                  transaction={transaction}
                />
              )}
              refetch={(cache, fileId) => {
                updateTransactionDocumentCache(
                  cache as ApolloCache<FullTransactionFragment>,
                  fileId,
                  transaction
                );
                // Refetching to update the activity...
                // TODO Maybe we can predict the new activity item?
                refetch();
              }}
              emptyMessage=""
            />

            <AssignmentsSection
              transaction={transaction}
              assignmentTypeFilter={(type: string) => type !== "category review"}
            />
          </div>

          {showAccrualDate && (
            <div className={accrualDate ? subSection : simpleRow}>
              <Text variant="bodyS" color="white" weight="heavy">
                Accrual date
              </Text>
              {canEdit ? (
                <DateInput
                  iconTrigger={true}
                  value={accrualDate}
                  onChange={(value) => value && updateAccrualDate(toCalendarDate(value))}
                  minDate={
                    lockedPeriodDate && parseDate(lockedPeriodDate.toString()).add({ days: 1 })
                  }
                  maxDate={parseDate(transaction.date)}
                  inputReadOnly
                  css={{
                    width: "fit-content",
                    height: 32,
                  }}
                  key="accrualDate"
                  onClear={() => updateAccrualDate(null)}
                />
              ) : (
                <div>
                  {transaction.detail.accrualDate &&
                    dateFormatter.format(parseDate(transaction.detail.accrualDate))}
                </div>
              )}
            </div>
          )}
          {isEditingSplit || transaction.splits.length > 0 ? null : (
            <ClassificationsSubsection
              hideAddButton={transaction.integrationType === IntegrationType.Stripe && !IS_DEV}
              entity={{
                id: transaction.detail.id,
                name: transaction.detail.descriptor,
                type: ClassAwareType.TransactionDetail,
                classSegments: transaction.detail.classSegments,
              }}
            />
          )}
          <div className={subSection}>
            <Text variant="bodyS" color="white" weight="heavy">
              Recurrence
            </Text>
            <RecurrenceSelect
              key="recurrence-select"
              value={transaction.detail.recurrence}
              onSelect={updateRecurrence}
              canEdit={canEdit}
              fullWidth={true}
            />
          </div>
          {canBeBillPayment && (
            <MatchBillsSubsection
              transaction={transaction}
              categories={categories}
              canEdit={canEdit}
              onCategoryChange={onCategoryChange}
              categoriesByPermaKey={categoriesByPermaKey}
            />
          )}
          {canBeInvoicePayment && showPayments && (
            <InvoicePaymentSubsection
              transaction={transaction}
              invoiceSubsectionCollapsed={invoiceSubsectionCollapsed}
              setInvoiceSubsectionCollapsed={setInvoiceSubsectionCollapsed}
              companyId={company.id}
              onComplete={refetch}
            />
          )}
          {showNewFixeedAssetButton && (
            <div className={subSection}>
              <div className={simpleRow}>
                <Text variant="bodyS" color="white" weight="heavy">
                  Fixed asset
                </Text>
                <div className={numberBadge} style={{ cursor: "pointer" }}>
                  <Add
                    color="rgba(255, 255, 255, 0.8)"
                    size={12}
                    onClick={() => setIsNewAssetDrawerOpen(true)}
                  />
                </div>
              </div>
            </div>
          )}
          {showFixedAsset && (
            <div className={subSection}>
              <div className={simpleRow}>
                <Text variant="bodyS" color="white" weight="heavy">
                  Fixed asset
                </Text>
              </div>

              {fixedAsset && (
                <FixedAssetCard
                  description={fixedAsset?.description || "Fixed asset"}
                  amount={fixedAsset?.originalValue || undefined}
                  status={fixedAsset?.status || undefined}
                />
              )}
            </div>
          )}
          {prepaid?.id && (
            <div className={subSection}>
              <Text variant="bodyS" color="white" weight="heavy">
                Prepaid
              </Text>

              <PrepaidCard
                description={prepaid.description || "Prepaid"}
                amount={prepaid.total?.amount}
                status={prepaid.status}
              />
            </div>
          )}
          <NewAssetFromTransactionDrawer
            open={isNewAssetDrawerOpen}
            onOpenChange={(open) => {
              if (!open) setIsNewAssetDrawerOpen(false);
            }}
            transaction={transaction}
            onComplete={refetch}
          />
          {accrualStatus !== LedgerEventStatus.NotApplicable && isEditor && (
            <div className={subSection}>
              <Stack direction="horizontal" gap={S.$1} css={{ alignItems: "center" }}>
                <Text variant="bodyS" color="white" weight="heavy">
                  Journal entries created
                </Text>
                <div className={numberBadge}>
                  <Text variant="bodyXS" color="gray300">
                    {journals?.length}
                  </Text>
                </div>
                <IconButton
                  size="medium"
                  onClick={() => {
                    setJournalSubsectionCollapsed(!journalSubsectionCollapsed);
                  }}
                  css={{ marginLeft: "-4px" }}
                  data-testid="expand_journals"
                >
                  <CaretDown
                    width={CARET_SIZE}
                    height={CARET_SIZE}
                    rotate={journalSubsectionCollapsed ? 0 : -90}
                    color={color.white70}
                  />
                </IconButton>
              </Stack>
              <Collapse open={journalSubsectionCollapsed} style={{ width: "100%" }}>
                <div className={journalsRow} key="journals">
                  <JournalsWithStatus
                    view={LedgerView.Cash}
                    title="Cash"
                    journals={journals}
                    key="journals_cash"
                  />
                  <JournalsWithStatus
                    view={LedgerView.Accrual}
                    title="Accrual"
                    journals={journals}
                    key="journals_cash"
                  />
                </div>
              </Collapse>
            </div>
          )}
          {hasLinkedTransactions && (
            <div className={subSection}>
              <Stack direction="horizontal" gap={S.$1} css={{ alignItems: "center" }}>
                <Text variant="bodyS" color="white" weight="heavy">
                  Linked Transactions
                </Text>
                <Button
                  variant="minimal"
                  size="mini"
                  onClick={() => {
                    setLinkedTransactionsSubsectionCollapsed(
                      !linkedTransactionsSubsectionCollapsed
                    );
                  }}
                  css={{ marginLeft: "-4px" }}
                  data-testid="expand_linked_transactions"
                >
                  <CaretDown
                    width={CARET_SIZE}
                    height={CARET_SIZE}
                    rotate={linkedTransactionsSubsectionCollapsed ? 0 : -90}
                    color={color.white70}
                  />
                </Button>
              </Stack>
              <Collapse open={linkedTransactionsSubsectionCollapsed} style={{ width: "100%" }}>
                <LinkedTransactions transaction={transaction} refetch={refetch} />
              </Collapse>
            </div>
          )}
          {schedule && (
            <div className={subSection}>
              <Stack direction="horizontal" gap={S.$1} css={{ alignItems: "center" }}>
                <Text variant="bodyS" color="white" weight="heavy">
                  Associated revenue schedule
                </Text>
                <Button
                  variant="minimal"
                  size="mini"
                  onClick={() => {
                    setIsScheduleDrawerOpen(true);
                  }}
                >
                  <CaretRight width={CARET_SIZE} height={CARET_SIZE} />
                </Button>
              </Stack>
              <RevenueRecognitionSchedule
                scheduleId={schedule?.id}
                open={isScheduleDrawerOpen}
                onOpenChange={setIsScheduleDrawerOpen}
              />
            </div>
          )}
        </div>
      </Collapse>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={(e) => e.target.files && onFiles(e.target.files)}
        style={{ display: "none" }}
      />
    </div>
  );
});

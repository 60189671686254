import React, { useState } from "react";
import { Dialog } from "@puzzle/ui";
import Wizard from "components/common/Wizard";
import { Success } from "./Success";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

enum Step {
  Success = "success",
}

export const SelectIngestionDateModal = ({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: () => void;
}) => {
  const [currentStep, setCurrentStep] = useState<string>(Step.Success);
  const { refetchUserCompanies } = useActiveCompany<true>();

  return (
    <Dialog open={open} size="small" onOpenChange={onOpenChange}>
      <Wizard
        initialStep={currentStep}
        steps={[{ label: "Success", name: Step.Success, component: Success }]}
        onChangeStep={async (step) => {
          setCurrentStep(step.name);
        }}
        onComplete={async () => {
          onOpenChange();
          refetchUserCompanies();
          window.location.reload();
        }}
        render={({ activeStep }) => {
          const Step = activeStep.component;
          return <Step />;
        }}
      />
    </Dialog>
  );
};

import React, { useState } from "react";
import { styled, Dialog, Button, ButtonGroup, CheckboxField, Alert } from "@puzzle/ui";
import { useWizardContext } from "components/common/Wizard";
import { manualAccountFormValues, manualAccountIdVar } from "./ConnectManualAccountFormStep";
import { useCreateManualAccountMutation } from "graphql/mutations/createManualAccount.generated";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { CreateManualAccountError } from "graphql/types";
import { ManualAccountTypesMap } from "../types";
import { GetTransactionSearchOptionsDocument } from "../../../dashboard/Transactions/graphql.generated";
import { formatMoney } from "@puzzle/utils";

const PreviewContainer = styled("div", {
  marginBottom: "$2",
  padding: "$2",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "$rhino600",
  borderRadius: "$1",
  fontSize: "$bodyS",
  color: "$gray100",
});

const PreviewItem = styled("div", {
  marginBottom: "$2",
});

const PreviewLabel = styled("label", {
  display: "block",
  marginBottom: "$0h",
  fontWeight: "$bold",
  color: "$gray500",
});

export const ConnectManualAccountPreviewStep = () => {
  const { goToPreviousStep, goToNextStep } = useWizardContext();
  const [checked, setChecked] = useState(false);
  const { institutionName, name, mask, balance, type } = manualAccountFormValues();
  const display = `${institutionName}・${name} - ${mask}`;
  const { company } = useActiveCompany<true>();
  const [showError, setShowError] = useState(false);
  const [createManualAccount, { loading }] = useCreateManualAccountMutation({
    refetchQueries: [GetTransactionSearchOptionsDocument],
  });
  const manualAccountType = ManualAccountTypesMap[type!];

  return (
    <>
      <Dialog.Title onBack={goToPreviousStep}>
        Add a bank or credit card account manually
      </Dialog.Title>

      <Dialog.Body>
        {showError && (
          <Alert
            kind="error"
            css={{ marginBottom: "$2" }}
          >{`The account ${display} already exists.`}</Alert>
        )}
        <PreviewContainer>
          <PreviewItem>
            <PreviewLabel>Source display</PreviewLabel>
            {display}
          </PreviewItem>
          <PreviewItem>
            <PreviewLabel>Account type</PreviewLabel>
            {manualAccountType?.type}
          </PreviewItem>
          <PreviewItem>
            <PreviewLabel>Current account balance</PreviewLabel>
            {formatMoney(balance || { currency: "USD", amount: 0 })}
          </PreviewItem>
        </PreviewContainer>
        <CheckboxField
          checked={checked}
          onCheckedChange={setChecked}
          label="I have confirmed that all information is correct and understand the account name, type, and number cannot be modified once created."
        />
      </Dialog.Body>
      <Dialog.Footer>
        <ButtonGroup>
          <Dialog.Close asChild>
            <Button variant="secondary">Cancel</Button>
          </Dialog.Close>
          <Button
            loading={loading}
            variant="primary"
            disabled={!checked}
            onClick={async () => {
              try {
                const { data } = await createManualAccount({
                  variables: {
                    input: {
                      companyId: company.id,
                      institutionName: institutionName!,
                      type: manualAccountType!.type,
                      subType: manualAccountType!.subType,
                      name: name!,
                      mask: mask!,
                      balance: balance!.amount.toString(),
                    },
                  },
                });
                if (data?.createManualAccount.error === CreateManualAccountError.DuplicateAccount) {
                  setShowError(true);
                  return;
                }
                manualAccountIdVar(data?.createManualAccount.account?.id);
                goToNextStep();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            Save
          </Button>
        </ButtonGroup>
      </Dialog.Footer>
    </>
  );
};

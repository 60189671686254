import React from "react";
import { Button, Dialog, Stack, DialogProps, styled } from "@puzzle/ui";
import Link from "./Link";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { zIndex } from "@puzzle/utils";

const ShareList = styled("ul", {
  padding: "0 0 0 5px",
  margin: 0,

  "& li": {
    listStylePosition: "inside",
  },
});
const DIALOG_BODY_STYLE = {
  textVariant: "$body",
  color: "$gray300",
  a: {
    color: "$green700",
    textDecoration: "none",
  },
};

export const DataConsentModal = ({
  onConfirm,
  onOpenChange,
  consentLoading,
  ...props
}: DialogProps & {
  onConfirm: () => void;
  consentLoading?: boolean;
}) => {
  const handleModalClose = () => {
    onOpenChange?.(false);
  };

  return (
    <Dialog
      size="xsmall"
      {...props}
      style={{ zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("modal") : "auto" }}
    >
      <Dialog.Title css={{ textVariant: "$heading3" }} onClose={handleModalClose}>
        Data sharing authorization
      </Dialog.Title>

      <Dialog.Body css={DIALOG_BODY_STYLE}>
        <Stack gap="3">
          <div>
            You are authorizing Puzzle Financial Inc. to share certain information with OpenAI.
          </div>

          <div>
            This will allow you to:
            <ShareList>
              <li>Share individual transaction data</li>
              <li>Share the chart of accounts</li>
              <li>Share your business model (generically)</li>
              <li>Share financial statements</li>
            </ShareList>
          </div>

          <div>
            By clicking Allow, you allow Puzzle to use your information in accordance to OpenAI’s{" "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://openai.com/policies/terms-of-use"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link target="_blank" rel="noopener noreferrer" href="https://openai.com/policies">
              Privacy Policies
            </Link>
            .
          </div>
        </Stack>
      </Dialog.Body>

      <Dialog.Footer align="right">
        <Dialog.Actions>
          <Dialog.Close asChild>
            <Button variant="secondary" onClick={handleModalClose}>
              Cancel
            </Button>
          </Dialog.Close>
          <Button variant="primary" onClick={onConfirm} loading={consentLoading}>
            Allow
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog>
  );
};

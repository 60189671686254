import { useEffect, useState } from "react";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useUpsertMessageSettingsMutation } from "components/users/graphql.generated";
import useSelf from "components/users/useSelf";
import { MembershipRole } from "graphql/types";
import { useBilling } from "components/settings/Billing/useBilling";

const SETTING_KEY = "trial-expired-modal-seen";

export function useTrialExpiredModal() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { exceeding } = useBilling();
  const { messageSettings } = useSelf();
  const [upsertMessageSettings] = useUpsertMessageSettingsMutation();

  const { membership, isOnFreeTrial, isOnPaidPlan, membershipRole } = useActiveCompany<true>();
  const isAdmin = membershipRole === MembershipRole.Admin;
  const isOwner = membership?.isOwner || false;
  const freeTrialExpired = !isOnFreeTrial && !isOnPaidPlan;
  const hasSeen = !!messageSettings[SETTING_KEY];

  const markAsSeen = () => {
    upsertMessageSettings({
      variables: {
        input: {
          key: SETTING_KEY,
          value: true,
        },
      },
    });
  };

  useEffect(() => {
    if ((isOwner || isAdmin) && !hasSeen && freeTrialExpired) {
      setIsModalVisible(true);
    }
  }, [isOwner, hasSeen, freeTrialExpired, isAdmin]);

  const handleOpenChange = (open: boolean) => {
    if (open) {
      return;
    }
    markAsSeen();
    setIsModalVisible(false);
  };

  return {
    exceeding,
    isModalVisible,
    handleOpenChange,
  };
}

import { useCallback } from "react";
import useAppRouter, { isPayrollRoute } from "./useAppRouter";
import { RequiredPermissions, Requires, loadingFeatureFlagsVar } from "./VerifyRouteAccessProvider";
import { Route } from "./routes";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useReactiveVar } from "@apollo/client";
import useSelf from "components/users/useSelf";
import config, { IS_DEV } from "./config";
import { MembershipRole } from "graphql/types";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "./analytics";

const useRouteAccess = () => {
  const { self, loading: _loadingSelf, isPuzzlenaut } = useSelf();
  const loadingFeatureFlags = useReactiveVar(loadingFeatureFlagsVar);
  const loadingSelf = _loadingSelf || loadingFeatureFlags;
  const {
    company,
    membershipRole,
    loading: loadingCompany,
    completedOnboarding,
  } = useActiveCompany();
  const {
    isTempAuthRoute,
    isUnknownRoute,
    isIntegrationRoute,
    isInboxRoute,
    isTransactionRoute,
    isAccountingRoute,
    isVendorRoute,
    isProductRoute,
    isCustomersRoute,
    isHistoricalBooksRoute,
  } = useAppRouter();

  const hasAccess = useCallback(
    (path: Route) => {
      if (isTempAuthRoute(path)) {
        return true;
      }

      if (isUnknownRoute(path)) {
        return false;
      }

      let permissions = RequiredPermissions[path];
      if (isIntegrationRoute(path)) {
        permissions = RequiredPermissions[Route.integrations];
      } else if (isTransactionRoute(path)) {
        permissions = RequiredPermissions[Route.transactions];
      } else if (isInboxRoute(path)) {
        permissions = RequiredPermissions[Route.inbox];
      } else if (isAccountingRoute(path)) {
        permissions = RequiredPermissions[Route.accounting];
      } else if (isVendorRoute(path)) {
        permissions = RequiredPermissions[Route.vendors];
      } else if (isCustomersRoute(path)) {
        permissions = RequiredPermissions[Route.customers];
      } else if (isProductRoute(path)) {
        permissions = RequiredPermissions[Route.products];
      } else if (isPayrollRoute(path)) {
        permissions = RequiredPermissions[Route.payroll];
      } else if (isHistoricalBooksRoute(path)) {
        permissions = RequiredPermissions[Route.historicalBooks];
      }

      if (permissions.length === 0) {
        return true;
      }

      if (loadingSelf || loadingCompany) {
        return false;
      }

      const forbidden = permissions.some((permission) => {
        if (permission === Requires.Company) {
          if (!company || !self) {
            return permission;
          }
        }

        if (permission === Requires.PermanentUser) {
          if (!self) {
            return permission;
          }
        }

        if (permission === Requires.Puzzle) {
          if (!isPuzzlenaut) {
            return permission;
          }
        }

        if (permission === Requires.CompletedOnboarding) {
          if (!completedOnboarding) {
            return permission;
          }
        }

        if (permission === Requires.CompanyAdmin) {
          return membershipRole !== "Admin" ? permission : false;
        }

        if (permission === Requires.Notifications) {
          if (!config.NOTIFICATIONS_ENABLED) {
            return permission;
          }
        }

        if (permission === Requires.IntegrationsPageVisibility) {
          if (company?.features.hideIntegrationsPage) {
            return permission;
          }
        }

        if (permission === Requires.UsersPageVisibility) {
          if (company?.features.hideUsersPage) {
            return permission;
          }
        }

        if (permission === Requires.FirmOwner) {
          // RED-1131: soon this will require the firm owner api from
          // the GW, for now we just want to make sure only
          // Puzzle users will see it
          // initially it will be visible for both Decimal owners
          // and Puzzle users

          if (!(isPuzzlenaut || self?.firmMembership?.isOwner)) {
            return permission;
          }
        }

        if (permission === Requires.NonFirmOwner) {
          // RED-1216: GW returns that everyone in dev is an owner rn because no firm data model
          // exists, this flag just overrides this check for E2E testing purposes
          // when the data model is in place we can remove this and add some testing that
          // makes sense
          const skipCheck =
            IS_DEV && isPosthogFeatureFlagEnabled(FeatureFlag.DevOnlyFlagOverrideNonFirmOwnerCheck);
          if (!skipCheck && self?.firmMembership?.isOwner) {
            return permission;
          }
        }

        if (permission === Requires.NonClientViewer) {
          if (membershipRole === MembershipRole.ClientViewer) {
            return permission;
          }
        }
      });

      return !forbidden;
    },
    [
      loadingSelf,
      loadingCompany,
      company,
      self,
      isPuzzlenaut,
      completedOnboarding,
      membershipRole,
      isAccountingRoute,
      isIntegrationRoute,
      isTransactionRoute,
      isHistoricalBooksRoute,
      isVendorRoute,
      isInboxRoute,
      isUnknownRoute,
      isTempAuthRoute,
      isCustomersRoute,
      isProductRoute,
    ]
  );

  return {
    hasAccess,
  };
};

export default useRouteAccess;

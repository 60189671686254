import React, { useState } from "react";
import { useRippling } from "./useRippling";
import { IntegrationLoading } from "../setup";
import { IntegrationConnectionCondition, IntegrationType } from "graphql/types";
import { usePollIntegration } from "../shared/usePollIntegration";
import useAppRouter from "../../../lib/useAppRouter";
import { destroyRequestedIntegrationCookie } from "../../../lib/cookies";
import { SelectIngestDateModal } from "../setup/modals/IngestionDateModal/IngestDateModal";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

type ConnectRipplingPageProps = {
  code: string;
  companyId: string;
  redirectUri?: string;
  isOnboarding: boolean;
};

const ConnectRipplingPage = ({
  code,
  companyId,
  redirectUri,
  isOnboarding,
}: ConnectRipplingPageProps) => {
  const { router } = useAppRouter();
  const { company, isOnPaidPlan, isOnFreeTrial } = useActiveCompany();
  const hide = router?.query?.hide ?? false;

  const { connection, connectRippling, connectionError, loading } = useRippling({ companyId });
  const [isReconnect, setIsReconnect] = useState(false);
  const { financialInstitutions, accounts, startPolling } = usePollIntegration(
    IntegrationType.Rippling,
    !isOnboarding
  );
  const [openSelectDate, onOpenSelectDateChange] = useState(false);

  const onDatesSet = () => {
    destroyRequestedIntegrationCookie();

    if (hide && redirectUri) {
      window.location.assign(redirectUri);
    }
  };

  const connect = () => {
    if (!code) {
      return;
    }
    if (connection?.id) {
      setIsReconnect(true);
    }

    // TODO: GRO-2045: update to check they have rippling_add_on_selected
    // If they are on a premium free trial, we want them to be able to sync Rippling data
    const condition =
      isOnPaidPlan || isOnFreeTrial
        ? IntegrationConnectionCondition.WaitingForUserEpoch
        : IntegrationConnectionCondition.WaitingForAddOnPayment;

    connectRippling({
      companyId,
      code,
      condition,
    }).then((result) => {
      if (!result || !result?.data || result.errors) {
        throw new Error("Error connecting rippling");
      }
      if (financialInstitutions.length === 0) {
        startPolling(1000);
      }

      onOpenSelectDateChange(true);
    });
  };

  return (
    <>
      <IntegrationLoading
        integrationType={IntegrationType.Rippling}
        title="Connecting to Rippling"
        connectIntegration={connect}
        connection={connection}
        loading={loading}
        error={!!connectionError}
      />
      {!isOnboarding && (
        <SelectIngestDateModal
          open={openSelectDate}
          accounts={accounts}
          onOpenChange={onOpenSelectDateChange}
          isReconnect={isReconnect}
          connectionId={connection?.id}
          accountsLoading={accounts.length === 0 ?? false}
          onDatesSet={onDatesSet}
        />
      )}
    </>
  );
};

export default ConnectRipplingPage;

import React, { ReactElement } from "react";
import { File2, Delete, Download2 } from "@puzzle/icons";
import { IconButton, Text, color } from "ve";

import {
  rootStyle,
  mainTextContainer,
  buttonsContainerStyle,
  textNowrapStyle,
  metadataContainerStyle,
} from "./baseRow.css";

const getHumanReadableFileSize = (sizeInBytes: number) => {
  if (sizeInBytes < 1000) {
    return `${sizeInBytes} bytes`;
  } else if (sizeInBytes < 1000 * 1000) {
    return `${(sizeInBytes / 1000).toFixed(2)} KB`;
  } else if (sizeInBytes < 1000 * 1000 * 1000) {
    return `${(sizeInBytes / 1000 / 1000).toFixed(2)} MB`;
  }
};

type BaseRowProps = {
  downloadUrl?: string;
  setPreviewFile: () => void;
  uploadedBy?: ReactElement | null;
  onDelete: React.MouseEventHandler;
  fileName: string;
  size: number;
}

export function BaseRow({
  size,
  fileName,
  setPreviewFile,
  uploadedBy,
  downloadUrl,
  onDelete,
}: BaseRowProps) {
  const metadata = getHumanReadableFileSize(size);

  return (
    <div className={rootStyle} onClick={setPreviewFile} role="button" tabIndex={0}>
      <File2 size={16} color={color.white80} />

      <div className={mainTextContainer}>
        <Text variant="headingS" color="white" className={textNowrapStyle}>
          {fileName}
        </Text>

        {metadata && (
          <div className={metadataContainerStyle}>
            {metadata}
            {uploadedBy?.props.children ? ` · ` : ""}
            {uploadedBy}
          </div>
        )}
      </div>

      <div className={buttonsContainerStyle}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (downloadUrl) {
              window.open(downloadUrl);
            }
          }}
        >
          <Download2 color={color.white80} size={16} />
        </IconButton>
        <IconButton onClick={onDelete}>
          <Delete color={color.white80} size={16} />
        </IconButton>
      </div>
    </div>
  );
}

import { FetchResult } from "@apollo/client";
import { DateValue, parseAbsolute } from "@puzzle/utils";
import { Button, useToasts, Text, Alert, AlertProps } from "@puzzle/ui";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import {
  DisconnectIntegrationMutation,
  IntegrationConnectionCondition,
  IntegrationConnectionStatus,
  IntegrationConnectionWithAccountStatsFragment,
  IntegrationType,
  RemoveAccountMutation,
  LedgerHistory,
} from "graphql/types";
import Analytics from "lib/analytics";
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";
import DisconnectIntegrationConfirmationModal from "../shared/DisconnectIntegrationConfirmationModal";
import {
  Footer,
  Activity,
  getIntegrationStatusTag,
  Logo,
  Header,
  HeaderGroup,
  TextGroup,
  Label,
  DetailText,
} from "./shared";
import { ConnectionStatusMessage } from "./ConnectionStatusMessage";
import { AutomatedAccrualRevenueSection } from "./AutomatedAccrualRevenueSection";
import { useFinancialInstitutions } from "../shared";
import Link from "../../common/Link";
import links from "lib/links";
import { Exclamation, External, Warning } from "@puzzle/icons";
import IntegrationAccountList from "./IntegrationAccountList";
import { hasIndeterminateConnection } from "../ListItem/ListItemUtils";
import { isIntegrationConnectionMissingAccounts } from "../setup/utils";
import { Box, S, Stack } from "ve";
import { WarningButtonStyles } from "./detailPaneStyles.css";
import { SelectIngestDateModal } from "../setup/modals/IngestionDateModal/IngestDateModal";
import { shouldEnableCutoverV2 } from "../setup/modals/IngestionDateModal/ingestionDateUtils";
import useSelf from "components/users/useSelf";

const ContentWrapper = ({ children }: { children: React.ReactNode }) => (
  <Box css={{ width: "100%", paddingBottom: S["3"] }}>{children}</Box>
);

const AlertMessage = ({ children, ...props }: PropsWithChildren<AlertProps>) => (
  <Alert
    kind="minimal"
    icon={<Exclamation />}
    css={{
      textVariant: "$bodyS",
      marginBottom: "$2",
      padding: "$1",
    }}
    {...props}
  >
    {children}
  </Alert>
);

const getQuickbooksDetailText = (ledgerHistory?: Pick<LedgerHistory, "id" | "finishedAt">) => {
  if (ledgerHistory) {
    if (ledgerHistory.finishedAt) {
      return "Transfer complete";
    }
    return "Transfer in progress";
  }
  return "Transfer not started";
};

const IntegrationDetails = ({
  activity: _activity,
  balance,
  logo,
  title,
  subtitle,
  connection,
  onDisconnect,
  onRemove,
  onClose,
  onReconnect,
  lastImportedAt,
  financialInstitutionId,
  requiresExternalSetup,
  ...props
}: {
  logo: React.ReactElement;
  title: string;
  subtitle?: string;
  connection?: IntegrationConnectionWithAccountStatsFragment | null;
  amount?: number;
  balance?: string;
  onDisconnect?: () => Promise<FetchResult<DisconnectIntegrationMutation> | undefined>;
  onRemove?: () => Promise<FetchResult<RemoveAccountMutation> | undefined>;
  onClose?: () => void;
  activity?: string[];
  onReconnect?: () => void;
  lastImportedAt?: DateValue;
  financialInstitutionId?: string;
  requiresExternalSetup?: boolean;
}) => {
  const { toast } = useToasts();
  const { timeZone, company } = useActiveCompany<true>();
  const { accounts: allAccounts, accountsLoading } = useFinancialInstitutions();
  const [openSelectDate, onOpenSelectDateChange] = useState(false);
  const { isPuzzlenaut } = useSelf();

  const isCutoverV2Enabled = shouldEnableCutoverV2(company?.coaType, isPuzzlenaut);

  const { condition, status, type } = connection || {};

  // We treat all financial institutions with the same name as the same institution
  // So we have to filter the accounts based on the financial institution name
  const financialInstituionName = allAccounts.find(
    (a) => a.financialInstitution?.id === financialInstitutionId
  )?.financialInstitution?.name;

  const associatedAccounts = useMemo(
    () =>
      allAccounts
        .filter((a) => a.financialInstitution?.name === financialInstituionName)
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        }),
    [allAccounts, financialInstituionName]
  );

  const connectedAccounts = useMemo(
    () => associatedAccounts.filter((a) => !a.manuallyAdded),
    [associatedAccounts]
  );

  const previousConnection = usePrevious(connection);
  useEffect(() => {
    if (!connection || connection.id === previousConnection?.id) {
      return;
    }

    Analytics.integrationViewed({
      connectionId: connection.id,
      connectionStatus: connection.status,
      integrationType: connection.type,
      institutionName: subtitle,
    });
  }, [connection, previousConnection?.id, subtitle]);

  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const disconnect = useCallback(async () => {
    if (!onDisconnect || !connection) {
      return;
    }

    await onDisconnect();
    toast({ message: "Integration successfully disconnected.", status: "success" });
    Analytics.integrationDisconnected({
      connectionId: connection.id,
      integrationType: connection.type,
      institutionName: subtitle,
    });
    setIsDisconnecting(false);
    onClose?.();
  }, [onDisconnect, connection, toast, subtitle, onClose]);

  const dateTimeFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  });

  const activity = useMemo<string[]>(() => {
    if (_activity) {
      return _activity;
    }

    if (connection) {
      return [
        `Integration initially created ${dateTimeFormatter.format(new Date(connection.createdAt))}`,
      ];
    }

    return [];
  }, [_activity, connection, dateTimeFormatter]);

  const lastSyncAt = useMemo(() => {
    const date = connection?.lastSyncedAt
      ? parseAbsolute(connection?.lastSyncedAt, timeZone)
      : undefined;
    return date ? dateTimeFormatter.format(date) : "-";
  }, [connection?.lastSyncedAt, timeZone, dateTimeFormatter]);

  const shouldShowSyncWarning = hasIndeterminateConnection(connection, timeZone);

  const shouldShowNoAccountsWarning =
    connection && isIntegrationConnectionMissingAccounts(connection);

  const shouldShowStartIngestionWarning =
    isCutoverV2Enabled &&
    condition === IntegrationConnectionCondition.WaitingForUserEpoch &&
    status === IntegrationConnectionStatus.Ok;

  return (
    <>
      {shouldShowStartIngestionWarning && (
        <Alert kind="warning" icon={<Warning />} css={{ marginBottom: S["3"], marginTop: S["2"] }}>
          <Stack direction="horizontal" css={{ alignItems: "center" }}>
            <Text style={{ paddingLeft: S["1"] }}>
              Please set ingestion start date for this integration to continue syncing data.
            </Text>
            <Button
              size="small"
              variant="secondary"
              onClick={() => onOpenSelectDateChange(true)}
              className={WarningButtonStyles}
            >
              Set ingestion start date
            </Button>
          </Stack>
        </Alert>
      )}
      <Header>
        <HeaderGroup css={{ justifyContent: "space-between", width: "100%" }}>
          <HeaderGroup css={{ gap: "$2" }}>
            <Logo>{logo}</Logo>
            <TextGroup>
              <Text variant="headingM">{title}</Text>
              <Text variant="bodyS">{subtitle} </Text>
              {lastSyncAt && (
                <Text variant="bodyXS" color="gray500">
                  Last synced {lastSyncAt}
                </Text>
              )}
            </TextGroup>
          </HeaderGroup>
          {getIntegrationStatusTag(status, false)}
        </HeaderGroup>
      </Header>

      {connection && type && status === IntegrationConnectionStatus.Disconnected && (
        <ContentWrapper>
          <AlertMessage>
            <ConnectionStatusMessage connection={connection} />
          </AlertMessage>
          <Button
            variant="outline"
            shape="pill"
            onClick={onReconnect}
            suffix={requiresExternalSetup ? <External /> : <></>}
          >
            Reconnect {requiresExternalSetup ? `via ${title}` : ""}
          </Button>
        </ContentWrapper>
      )}

      {connection?.type && shouldShowSyncWarning && (
        <ContentWrapper>
          <AlertMessage kind="warning">
            <Text>
              We have not received any data from {connection.type} for more than 30 days. Please
              check your connection via the {connection.type} portal.
            </Text>
          </AlertMessage>
        </ContentWrapper>
      )}

      {connection?.type && shouldShowNoAccountsWarning && (
        <ContentWrapper>
          <AlertMessage kind="warning">
            <Text>
              We have successfully connected to {connection.type}, but we have not received any
              accounts yet. Please check your connection via the {connection.type} portal.
            </Text>
          </AlertMessage>
        </ContentWrapper>
      )}

      {/* TODO: CORE-4346 remove when cutoverV2 is released */}
      {!isCutoverV2Enabled &&
        condition === IntegrationConnectionCondition.WaitingForUserEpoch &&
        status === IntegrationConnectionStatus.Ok && (
          <ContentWrapper>
            <AlertMessage>
              <Text variant="bodyS">
                Please{" "}
                <Link underline onClick={() => onOpenSelectDateChange(true)}>
                  set ingestion date ranges
                </Link>{" "}
                for this integration to continue syncing data.
              </Text>
            </AlertMessage>
          </ContentWrapper>
        )}

      {connection?.type === IntegrationType.Stripe && company.features.accrualEventsEnabled && (
        <AutomatedAccrualRevenueSection />
      )}

      {associatedAccounts.length > 0 && (
        <ContentWrapper>
          <TextGroup>
            <IntegrationAccountList accounts={associatedAccounts} />
          </TextGroup>
        </ContentWrapper>
      )}

      {connection?.type === IntegrationType.QuickBooks && (
        <TextGroup>
          <Label>Historical Financials</Label>
          {company.startIngestionDate ? (
            <DetailText>
              {getQuickbooksDetailText(company.mostRecentPublishedLedgerHistory ?? undefined)}
            </DetailText>
          ) : (
            <Link
              href={links.historicalFinancialMigration}
              css={{ color: "$green600" }}
              external
              target="_blank"
            >
              Request conversion
            </Link>
          )}
        </TextGroup>
      )}

      <Footer>
        {activity && activity.length > 0 && (
          <Activity>
            {activity.map((item) => (
              <div key={item}>{item}</div>
            ))}
          </Activity>
        )}

        {onDisconnect && connection?.status !== IntegrationConnectionStatus.Disconnected && (
          <>
            <div>
              Disconnecting this integration will pause the syncing of data from this source for all
              associated accounts. The historical data in Puzzle from this integration will not
              change.
            </div>

            <DisconnectIntegrationConfirmationModal
              open={isDisconnecting}
              onOpenChange={setIsDisconnecting}
              onConfirm={disconnect}
            />
            <Button
              size="compact"
              variant="secondary"
              color="negative"
              onClick={() => setIsDisconnecting(true)}
              css={{ marginBottom: "$3" }}
            >
              Disconnect
            </Button>
          </>
        )}
      </Footer>

      <SelectIngestDateModal
        open={openSelectDate}
        accounts={connectedAccounts}
        onOpenChange={onOpenSelectDateChange}
        isReconnect={true}
        connectionId={connection?.id ?? ""}
        accountsLoading={accountsLoading}
      />
    </>
  );
};
export default IntegrationDetails;

import React from "react";
import { Text, Stack } from "@puzzle/ui";
import { formatAccountName } from "@puzzle/utils";
import InstitutionLogo from "components/integrations/bank/InstitutionLogo";
import { IntegrationAccountFragment } from "./ingestionDateUtils";

const LOGO_SIZE = "24px";
type AccountHeaderProps = {
  account: IntegrationAccountFragment;
};

export const AccountHeader = ({ account }: AccountHeaderProps) => {
  return (
    <Stack direction="horizontal" gap="1">
      {account.connection && (
        <div style={{ width: LOGO_SIZE, height: LOGO_SIZE }}>
          <InstitutionLogo institution={{ name: account.connection?.type }} circular />
        </div>
      )}
      <Text variant="bodyM" color="gray200" weight="heavy">
        {formatAccountName(account)}
      </Text>
    </Stack>
  );
};

export default AccountHeader;

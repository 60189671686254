import React, { useMemo, useState } from "react";
import { ScrollArea, Dialog, Tooltip } from "@puzzle/ui";
import { Stack, Button, S } from "ve";
import { Text } from "ve/Text";
import { colors } from "ve/theme/colors";
import { IngestRangeForAccount } from "./IngestRangeForAccount";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import Link from "components/common/Link";
import links from "lib/links";
import { SelectIngestDateModalProps } from "./IngestDateModal";
import { SelectStartDateModal } from "./AccountIngestDateModal";
import { IntegrationAccountFragment } from "./ingestionDateUtils";
import { useToggle } from "react-use";
import { IntegrationConnectionCondition } from "graphql/types";
import dynamic from "next/dynamic";

const DynamicConfirmIngestionModal = dynamic(() => import("./ConfirmIngestionDateModal"), {
  ssr: false,
});
import { AccountHeader } from "./AccountHeader";

const SMALL_MODAL_BODY_HEIGHT = "600";
const MEDIUM_MODAL_BODY_HEIGHT = "700";
const MAX_ACCOUNTS_FOR_SMALL_MODAL = 3;

export const MultiAccountIngestDateModal = ({
  open,
  onOpenChange,
  accounts,
  connectionId,
  isReconnect,
  successToastMessageOverride,
  onDatesSet,
  ...props
}: SelectIngestDateModalProps) => {
  const [openSelectDateModal, onOpenSelectDateModal] = useToggle(false);
  const [openConfirmDateModal, onOpenConfirmDateModal] = useToggle(false);
  const [activeAccount, setActiveAccount] = useState<IntegrationAccountFragment | undefined>();
  const onEditAccountRange = (account: IntegrationAccountFragment) => {
    setActiveAccount(account);
    onOpenSelectDateModal(true);
  };

  const isOptionRequired = isPosthogFeatureFlagEnabled(
    FeatureFlag.ReqIngestOprionOnReconnectIntegration
  );

  const hasAllAccountsSetCutoffBefore = useMemo(() => {
    return accounts.every((account) => !!account.epoch?.cutoffBefore);
  }, [accounts]);

  const handleClose = () => {
    if (accountsWaitingForEpoch.length > 0) {
      onOpenConfirmDateModal(true);
    } else {
      onOpenChange?.(false);
    }
  };

  const accountsWaitingForEpoch = accounts.filter(
    (account) =>
      account.connection?.condition === IntegrationConnectionCondition.WaitingForUserEpoch
  );

  const modalBodyHeight =
    accounts.length >= MAX_ACCOUNTS_FOR_SMALL_MODAL
      ? MEDIUM_MODAL_BODY_HEIGHT
      : SMALL_MODAL_BODY_HEIGHT;
  const shouldDisableClose = isOptionRequired && isReconnect && !hasAllAccountsSetCutoffBefore;

  return (
    <>
      <Dialog size="medium" {...props} open={open}>
        <Dialog.Title
          onClose={handleClose}
          showClose={!(isOptionRequired && isReconnect)}
          css={{ padding: isOptionRequired && isReconnect ? "$2h $1h $2 $3" : undefined }}
        >
          Set ingestion start and end date {!isReconnect && "(optional)"}
        </Dialog.Title>
        <Dialog.Body
          css={{
            textVariant: "$bodyS",
            height: `${modalBodyHeight}px`,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack direction="vertical" gap="2" css={{ marginBottom: S["$2"] }}>
            <Text>
              Set ingestion start date and ingestion end date for the following account(s). Puzzle
              will ingest data from the associated account based on the chosen dates.{" "}
            </Text>
            <Link
              href={links.education.startIngestionDate}
              underline
              external
              target="_blank"
              css={{ color: colors.white50 }}
            >
              Learn more about ingestion start date and ingestion end date
            </Link>
          </Stack>
          <ScrollArea css={{ flexGrow: "1" }}>
            <ScrollArea.Viewport>
              <Stack direction="vertical" gap="4">
                {accounts.map((account) => (
                  <Stack key={account.id}>
                    <AccountHeader account={account} />
                    <IngestRangeForAccount account={account} onEditRange={onEditAccountRange} />
                  </Stack>
                ))}
              </Stack>
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar orientation="vertical">
              <ScrollArea.Thumb variant="shadowed" />
            </ScrollArea.Scrollbar>
          </ScrollArea>
        </Dialog.Body>
        <Dialog.Footer>
          <Tooltip
            content={
              shouldDisableClose && "Please set ingestion start date for all reconnected accounts"
            }
          >
            <Button variant="secondary" onClick={handleClose} disabled={shouldDisableClose}>
              Close
            </Button>
          </Tooltip>
        </Dialog.Footer>
      </Dialog>
      {activeAccount && (
        <SelectStartDateModal
          open={openSelectDateModal}
          accounts={[activeAccount]}
          onOpenChange={onOpenSelectDateModal}
          isReconnect={isReconnect}
          connectionId={connectionId}
          successToastMessageOverride={successToastMessageOverride}
        />
      )}
      {openConfirmDateModal && (
        <DynamicConfirmIngestionModal
          open={openConfirmDateModal}
          accounts={accountsWaitingForEpoch}
          onOpenChange={onOpenConfirmDateModal}
          onConfirm={() => {
            onOpenChange?.(false);
          }}
        />
      )}
    </>
  );
};

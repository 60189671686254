import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  CheckboxField,
  Tooltip,
  Help,
  DateInput,
  RadioGroup,
  OptionCard,
  Tag,
  Switch,
} from "@puzzle/ui";
import { Text, Stack, color } from "ve";
import { TransactionSortOrder } from "graphql/types";
import { parseDate, toCalendarDate, today } from "@internationalized/date";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import useTransactions from "components/dashboard/Transactions/hooks/useTransactions";
import { CalendarDateString } from "@puzzle/utils";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import {
  ingestionStartOptionStyle,
  sectionHeaderStyle,
  singleAccountStartStyle,
  startOptionStyle,
} from "./ingestionDateModal.css";
import compact from "lodash/compact";
import {
  EpochInput,
  getIngestStartDateLabel,
  IngestionStartOption,
  IngestStartTooltips,
  shouldEnableCutoverV2,
} from "./ingestionDateUtils";
import { IngestionStartExplainer } from "./IngestionChart/IngestionStartExplainer";
import { CustomDateRadioItem } from "./CustomDateRadioItem";
import useSelf from "components/users/useSelf";

type AccountSummary = {
  id: string;
  name: string;
  mask?: string | null;
  financialInstitution: {
    name: string;
  };
  epoch?: {
    cutoffBefore?: CalendarDateString | null;
    cutoffAtOrAfter?: CalendarDateString | null;
  } | null;
};

type SelectStartForSingleAccountProps = {
  account: AccountSummary;
  onSetIngestionDate: (epoch: EpochInput) => void;
  currentEpoch?: EpochInput;
  isReconnect?: boolean;
};

export const SelectStartForSingleAccount = ({
  account,
  onSetIngestionDate,
  currentEpoch,
  isReconnect,
}: SelectStartForSingleAccountProps) => {
  const { timeZone, company, lockedPeriodDate, isWithinLockedPeriod } = useActiveCompany<true>();
  const ledgerStartDateString = company.startIngestionDate;
  const [shouldUpdateHistory, setShouldUpdateHistory] = useState(false);
  const isOptionRequired = isPosthogFeatureFlagEnabled(
    FeatureFlag.ReqIngestOprionOnReconnectIntegration
  );
  const initialEpoch = account.epoch;
  const { isPuzzlenaut } = useSelf();
  const isCutoverV2Enabled = shouldEnableCutoverV2(company?.coaType, isPuzzlenaut);
  const minDate = useMemo(
    () => (company.startIngestionDate ? parseDate(company.startIngestionDate) : undefined),
    [company.startIngestionDate]
  );
  const [selectStartValue, setSelectStartValue] = useState<IngestionStartOption>();

  const [customStartDate, setCustomStartDate] = useState(
    initialEpoch?.cutoffBefore ? parseDate(initialEpoch.cutoffBefore) : minDate ?? today(timeZone)
  );

  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const monthYearFormatter = useCompanyDateFormatter({
    month: "short",
    year: "numeric",
  });

  const { loading: lastTransactionsLoading, transactions: lastTransactions } = useTransactions({
    companyId: company.id,
    sortBy: TransactionSortOrder.DateDesc,
    filterBy: {
      accountIds: [account.id],
    },
    page: { count: 1, after: null },
  });

  const lastTransactionDate = lastTransactions?.length ? parseDate(lastTransactions[0].date) : null;

  const hasUnrestrictedOption =
    (!account.epoch?.cutoffBefore && !isOptionRequired) ||
    (!account.epoch?.cutoffBefore && isOptionRequired && !isReconnect);

  const getIngestStartValue = useCallback(
    (option: IngestionStartOption): EpochInput => {
      const baseValues = {
        accountId: account.id,
        shouldUpdateHistory,
        cutoffAtOrAfter: currentEpoch?.cutoffAtOrAfter ?? null,
      };
      switch (option) {
        case IngestionStartOption.LAST_TRANSACTION:
          return {
            cutoffBefore: lastTransactionDate,
            ...baseValues,
          };
        case IngestionStartOption.ALL_TIME:
          return {
            cutoffBefore: null,
            ...baseValues,
          };
        case IngestionStartOption.LEDGER_START:
          return {
            cutoffBefore: ledgerStartDateString ? parseDate(ledgerStartDateString) : null,
            ...baseValues,
          };
        default:
          return {
            cutoffBefore: customStartDate,
            ...baseValues,
          };
      }
    },
    [
      account.id,
      currentEpoch?.cutoffAtOrAfter,
      customStartDate,
      lastTransactionDate,
      ledgerStartDateString,
      shouldUpdateHistory,
    ]
  );

  const startOptions: { label: ReactNode; value: IngestionStartOption }[] = useMemo(() => {
    return compact([
      ledgerStartDateString && {
        value: IngestionStartOption.LEDGER_START,
        label: (
          <Stack direction="horizontal" className={startOptionStyle}>
            <Text color="white">{dateFormatter.format(parseDate(ledgerStartDateString))}</Text>
            <Text>{getIngestStartDateLabel(IngestionStartOption.LEDGER_START)}</Text>
            <Help content={IngestStartTooltips[IngestionStartOption.LEDGER_START]}></Help>
          </Stack>
        ),
      },
      lastTransactionDate && {
        value: IngestionStartOption.LAST_TRANSACTION,
        label: (
          <Stack direction="horizontal" className={startOptionStyle}>
            <Text color="white">{dateFormatter.format(lastTransactionDate)}</Text>
            <Text>{getIngestStartDateLabel(IngestionStartOption.LAST_TRANSACTION)}</Text>
            <Help content={IngestStartTooltips[IngestionStartOption.LAST_TRANSACTION]}></Help>
          </Stack>
        ),
      },
      hasUnrestrictedOption && {
        value: IngestionStartOption.ALL_TIME,
        label: (
          <Stack direction="horizontal" className={startOptionStyle}>
            <Text color="white">{getIngestStartDateLabel(IngestionStartOption.ALL_TIME)}</Text>
            <Help content={IngestStartTooltips[IngestionStartOption.ALL_TIME]}></Help>
          </Stack>
        ),
      },
    ]);
  }, [dateFormatter, hasUnrestrictedOption, lastTransactionDate, ledgerStartDateString]);

  const ledgerStartDate = ledgerStartDateString ? parseDate(ledgerStartDateString) : null;
  const ingestionStartInitialOption = useMemo(() => {
    const epochStartDate = initialEpoch?.cutoffBefore;

    // If there is an epoch date, then try to match it against one of the existing dates
    if (epochStartDate) {
      if (ledgerStartDateString === epochStartDate) {
        return IngestionStartOption.LEDGER_START;
      }
      if (lastTransactionDate?.toString() === epochStartDate) {
        return IngestionStartOption.LAST_TRANSACTION;
      }

      return IngestionStartOption.CUSTOM;
    }
    // if there is an epoch value but the cutoffBefore is null then allTime is selected
    else if (account.epoch && hasUnrestrictedOption) {
      return IngestionStartOption.ALL_TIME;
    }

    // if no value set, then default to first option
    return startOptions[0].value;
  }, [
    account.epoch,
    hasUnrestrictedOption,
    initialEpoch?.cutoffBefore,
    lastTransactionDate,
    ledgerStartDateString,
    startOptions,
  ]);

  useEffect(() => {
    if (!lastTransactionsLoading && !currentEpoch) {
      setSelectStartValue(ingestionStartInitialOption);
    }
  }, [
    lastTransactionsLoading,
    onSetIngestionDate,
    currentEpoch,
    ledgerStartDateString,
    lastTransactionDate,
    hasUnrestrictedOption,
    getIngestStartValue,
    account.epoch,
    startOptions,
    customStartDate,
    ingestionStartInitialOption,
  ]);

  const shouldShowIngestStartChart = () => {
    if (!currentEpoch) return false;

    return (
      selectStartValue === IngestionStartOption.CUSTOM &&
      ledgerStartDate &&
      ledgerStartDate.compare(customStartDate) !== 0
    );
  };

  return (
    <>
      {isCutoverV2Enabled && (
        <Stack>
          <Text className={sectionHeaderStyle} variant="bodyXXS">
            Ingestion Start Date
          </Text>
          <Stack direction="vertical" key={account.id}>
            <Stack className={singleAccountStartStyle}>
              <RadioGroup
                options={startOptions}
                value={selectStartValue}
                onValueChange={(value: IngestionStartOption) => {
                  setSelectStartValue(value);
                  onSetIngestionDate(getIngestStartValue(value));
                }}
              >
                <CustomDateRadioItem
                  value={customStartDate}
                  onChange={(value) => {
                    if (value) {
                      const dateValue = toCalendarDate(value);
                      setCustomStartDate(dateValue);
                      onSetIngestionDate({
                        cutoffBefore: dateValue,
                        cutoffAtOrAfter: currentEpoch?.cutoffAtOrAfter ?? null,
                        accountId: account.id,
                        shouldUpdateHistory,
                      });
                    }
                  }}
                />
              </RadioGroup>
              {selectStartValue === IngestionStartOption.CUSTOM &&
                isWithinLockedPeriod(customStartDate) && (
                  <Text color={"warning"}>
                    Caution: The date is within the locked period:{" "}
                    {lockedPeriodDate ? monthYearFormatter.format(lockedPeriodDate) : ""} and
                    earlier
                  </Text>
                )}

              <div style={{ width: "100%", height: "1px", backgroundColor: color.white05 }} />
              <Stack direction="horizontal" className={startOptionStyle}>
                <Text>Remove transactions posted before the ingestion start date</Text>
                <Help
                  content={
                    "Turning on this toggle will remove all transactions associated with this account that were posted before the ingestion start date in both the historical ledger and Puzzle’s general ledger, whether manually created or ingested."
                  }
                />
                <Switch
                  checked={shouldUpdateHistory}
                  onCheckedChange={() => setShouldUpdateHistory(!shouldUpdateHistory)}
                  disabled={selectStartValue === IngestionStartOption.ALL_TIME}
                  css={{ marginLeft: "auto" }}
                />
              </Stack>
            </Stack>
            {ledgerStartDate && shouldShowIngestStartChart() && (
              <IngestionStartExplainer
                ledgerStartDate={ledgerStartDate}
                ingestStartDate={customStartDate}
                removeTransactions={shouldUpdateHistory}
              />
            )}
          </Stack>
        </Stack>
      )}
      {!isCutoverV2Enabled && (
        <Stack direction="vertical" key={account.id} gap="1">
          {lastTransactionDate ? (
            <OptionCard
              onClick={() => {
                setSelectStartValue(IngestionStartOption.LAST_TRANSACTION);
                onSetIngestionDate({
                  cutoffBefore: lastTransactionDate,
                  cutoffAtOrAfter: currentEpoch?.cutoffAtOrAfter ?? null,
                  accountId: account.id,
                  shouldUpdateHistory,
                });
              }}
              checked={selectStartValue === IngestionStartOption.LAST_TRANSACTION}
              css={{ alignItems: "flex-start" }}
            >
              <div className={ingestionStartOptionStyle}>
                <Stack
                  direction="horizontal"
                  css={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <Text color="gray100">Last known transaction from this account is on:</Text>
                  <Tag css={{ height: 16 }} variant="filledPill">
                    Recommended
                  </Tag>
                </Stack>
                <Text variant="body">
                  {dateFormatter.format(lastTransactionDate)} Ingest data from this date
                </Text>
              </div>
            </OptionCard>
          ) : null}

          {hasUnrestrictedOption && (
            <OptionCard
              onClick={() => {
                setSelectStartValue(IngestionStartOption.ALL_TIME);
                onSetIngestionDate({
                  cutoffBefore: null,
                  cutoffAtOrAfter: currentEpoch?.cutoffAtOrAfter ?? null,
                  accountId: account.id,
                  shouldUpdateHistory: false,
                });
              }}
              checked={selectStartValue === IngestionStartOption.ALL_TIME}
              css={{ alignItems: "flex-start" }}
            >
              <div className={ingestionStartOptionStyle}>
                <Stack direction="horizontal" css={{ alignItems: "center" }}>
                  <Text color="gray100">Ingest data for all dates for this account</Text>
                  {!lastTransactionDate && (
                    <Tag css={{ height: 16 }} variant="filledPill">
                      Recommended
                    </Tag>
                  )}
                </Stack>
              </div>
            </OptionCard>
          )}

          <OptionCard
            onClick={() => {
              if (selectStartValue !== IngestionStartOption.CUSTOM) {
                setSelectStartValue(IngestionStartOption.CUSTOM);
                onSetIngestionDate({
                  cutoffBefore: customStartDate,
                  cutoffAtOrAfter: currentEpoch?.cutoffAtOrAfter ?? null,
                  accountId: account.id,
                  shouldUpdateHistory,
                });
              }
            }}
            checked={selectStartValue === IngestionStartOption.CUSTOM}
            css={{ alignItems: "flex-start" }}
          >
            <div className={ingestionStartOptionStyle}>
              <Text color="gray100">Restricted: Do not ingest any data prior to</Text>
              <DateInput
                size="small"
                css={{ height: 36, width: 152 }}
                value={customStartDate}
                minDate={
                  account.epoch?.cutoffBefore
                    ? parseDate(account.epoch.cutoffBefore)
                    : minDate ?? undefined
                }
                onChange={(value) => {
                  if (value) {
                    const dateValue = toCalendarDate(value);
                    setCustomStartDate(dateValue);
                    setSelectStartValue(IngestionStartOption.CUSTOM);
                    onSetIngestionDate({
                      cutoffBefore: dateValue,
                      cutoffAtOrAfter: currentEpoch?.cutoffAtOrAfter ?? null,
                      accountId: account.id,
                      shouldUpdateHistory,
                    });
                  }
                }}
                footer={
                  minDate &&
                  `You previously elected to start ingesting data across all external accounts on ${company.startIngestionDate}. You cannot select a date for this account prior to that cutoff date.`
                }
              />
            </div>
          </OptionCard>

          <div className={ingestionStartOptionStyle}>
            <CheckboxField
              label={
                <Text>
                  Update historical data{" "}
                  <Tooltip
                    content={
                      "By opting to update historical data, any data associated with this account within the restricted range will be removed. This includes both ingested data and manually created data."
                    }
                  >
                    <span>
                      <Help />
                    </span>
                  </Tooltip>
                </Text>
              }
              checked={shouldUpdateHistory}
              onCheckedChange={(checked) => setShouldUpdateHistory(checked)}
              disabled={selectStartValue === IngestionStartOption.ALL_TIME}
            />
          </div>
        </Stack>
      )}
    </>
  );
};

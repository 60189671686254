import React from "react";
import { TodaysCash } from "../Metrics";
import useCompanyFinancialSummary from "components/reports/useCompanyFinancialSummary";
import { useAccountsForMetrics } from "components/reports/useAccountsForMetrics";
import { useActiveCompany, PricingFeatures } from "components/companies/ActiveCompanyProvider";
import { Charts } from "graphql/types";
import useCompanyArr from "../Revenue/useCompanyARR";
import { SpendGraphTile } from "../Metrics/SpendGraphTile";
import { RevenueGraphTile } from "../Metrics/RevenueGraphTile";
import { Cards } from "./StatCardsStyle";

export const StatCardsMinimal = ({
  charts,
  chartsLoading,
}: {
  charts?: Charts;
  chartsLoading: boolean;
}) => {
  const { initialIngestCompleted, pricingPlanFeaturesLoading, pricePlanFeatureEnabled, company } =
    useActiveCompany<true>();

  // Top row graphs
  const isFirstRowGraphFeatureGated = // feature gate if both are true:
    !pricingPlanFeaturesLoading && // it is not loading (because we display a loading state)
    !pricePlanFeatureEnabled.has(PricingFeatures.metric_cards_core); // metric_cards_core is NOT enabled
  // Second row cards
  const isSecondRowFeatureGated = // feature gate if both are true:
    !pricingPlanFeaturesLoading && // it is not loading (because we display a loading state)
    !pricePlanFeatureEnabled.has(PricingFeatures.metric_cards_additional); // metric_cards_additional is NOT enabled

  const { loading, dailyTotalCash, ...metrics } = useCompanyFinancialSummary();
  const { accounts } = useAccountsForMetrics();

  // Call this to preload the ARR for when the user goes to the Revenue Explorer page. Loading ARR
  // is slow, so Product wants us to preload and cache it. Don't bother polling though.
  useCompanyArr({ skipPolling: true });

  const showGraph = !!company?.features.metricCardsCore;

  return (
    <>
      <Cards graph={showGraph ? "true" : "false"} columnCount={"three"}>
        <TodaysCash
          loading={loading || chartsLoading}
          totalCash={metrics?.totalCash}
          dailyTotalCash={dailyTotalCash?.metrics || []}
          accounts={accounts}
          key="cash"
          isGraphFeatureGated={isFirstRowGraphFeatureGated}
        />

        <SpendGraphTile
          data={charts?.spending}
          loading={loading || chartsLoading}
          isFeatureGated={isSecondRowFeatureGated}
          initialIngestOngoing={!initialIngestCompleted}
        />
        <RevenueGraphTile
          title="Revenue"
          data={charts?.revenue}
          chartDatePointDataKey="revenue"
          loading={loading || chartsLoading}
          isFeatureGated={isSecondRowFeatureGated}
          initialIngestOngoing={!initialIngestCompleted}
        />
      </Cards>
    </>
  );
};

import React, { useEffect } from "react";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useProgress } from "components/dashboard/Dashboard/PostOnboardModal/ProgressBar";

import { Text } from "ve";

import { rootStyle, progressBarContainer, progressBar } from "./ProgressBox.css";

export const ProgressBox = ({ onComplete }: { onComplete: () => void }) => {
  const [progress, stopProgress] = useProgress();
  const { initialIngestCompleted } = useActiveCompany<true>();
  let effectiveProgress = progress;
  if (effectiveProgress > 0.95 && !initialIngestCompleted) {
    // Hold at 0.95 until initial ingest is complete so that we don't hit 100% while it's going
    effectiveProgress = 0.95;
  }

  useEffect(() => {
    if (effectiveProgress === 1) {
      onComplete();
    }
  }, [effectiveProgress]);

  // Stop the progress timer on unmount
  useEffect(() => {
    return () => {
      stopProgress();
    };
  }, []);

  return <ProgressBar progress={effectiveProgress * 100} />;
};

export function ProgressBar({ progress }: { progress: number }) {
  return (
    <div className={rootStyle}>
      <div className={progressBarContainer}>
        <div
          className={progressBar}
          style={{
            width: `${progress}%`,
          }}
        />
      </div>
      <div>
        <Text variant="bodyXS" color="gray400">
          {progress === 100
            ? "Data ingestion complete"
            : "Ingesting financial data from your connected accounts"}
        </Text>
      </div>
    </div>
  );
}

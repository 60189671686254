import React from "react";
import { Box, Stack, Text } from "ve";
import { Tooltip } from "@puzzle/ui";
import { BarDataOrNull } from "../ingestionDateUtils";
import {
  chartContainerStyle,
  chartLabelStyle,
  rowContainerStyle,
  separatorStyle,
  barsContainerStyle,
} from "./ingestionChart.css";

const barHeight = 10;
const barGap = 2;
const chartToLabelGap = 8;
const separatorExtraHeight = 4;
const chartPaddingTop = 4;
const heightOfLabel = 30;

type IngestionStartChartProps = {
  data: BarDataOrNull[][];
  subdivisionCount?: number;
  labels: string[];
};

export const IngestionChart = ({
  data,
  labels,
  subdivisionCount = 3,
}: IngestionStartChartProps) => {
  const subDivisionPercent = ((1 / subdivisionCount) * 100).toFixed(2);

  const drawChartSegments = () => {
    return (
      <div
        className={barsContainerStyle}
        style={{
          gap: barGap,
          paddingTop: `${chartPaddingTop}px`,
        }}
      >
        {data.map((row, index) => {
          return (
            <div key={index} className={rowContainerStyle}>
              {row.map((segment, segmentIndex) => {
                if (!segment) return <span style={{ width: `${subDivisionPercent}%` }} />;

                return (
                  <Tooltip key={segmentIndex} content={segment.tooltip}>
                    <span
                      style={{
                        ...(segment.styles ?? {}),
                        width: `${subDivisionPercent}%`,
                        height: `${barHeight}px`,
                        top: `${barHeight * index}px`,
                      }}
                    />
                  </Tooltip>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const heightOfSeparator =
    data.length * (barHeight + barGap) + separatorExtraHeight + chartPaddingTop;

  // Container height needs to be set because of the absolute positioning of the separators and labels
  const heightOfContainer = heightOfSeparator + heightOfLabel + chartToLabelGap + chartPaddingTop;

  return (
    <Box
      className={chartContainerStyle}
      style={{
        height: heightOfContainer,
      }}
    >
      {/* Separators */}
      {labels.map((_, index) => {
        return (
          <span
            key={index}
            className={separatorStyle}
            style={{
              left: `${parseFloat(subDivisionPercent) * (index + 1)}%`,
              height: heightOfSeparator,
            }}
          />
        );
      })}
      <Stack style={{ width: "100%", gap: chartToLabelGap }}>
        {drawChartSegments()}

        <div style={{ position: "relative", width: "100%" }}>
          {labels.map((value, index) => {
            return (
              <span
                key={index}
                className={chartLabelStyle}
                style={{
                  left: `${parseFloat(subDivisionPercent) * (index + 1)}%`,
                  maxWidth: `${subDivisionPercent}%`,
                }}
              >
                <Text variant="bodyXXS">{value}</Text>
              </span>
            );
          })}
        </div>
      </Stack>
    </Box>
  );
};

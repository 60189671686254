export const space: Record<string, string> = {
  "0": "0px",
  "0h": "4px",
  "1": "8px",
  "1h": "12px",
  "2": "16px",
  "2h": "20px",
  "3": "24px",
  "3h": "28px",
  "4": "32px",
  "4h": "36px",
  "5": "40px",
  "5h": "44px",
  "6": "48px",
  "6h": "52px",
  "7": "56px",
  "7h": "60px",
  "8": "64px",
  "8h": "68px",
  "9": "72px",
  "9h": "76px",
  "10": "80px",
  "10h": "84px",
  "11": "88px",
  "11h": "92px",
  "12": "96px",
  "12h": "100px",
  "13": "104px",
  "13h": "108px",
  "14": "112px",
  "14h": "116px",
  "15": "120px",
  "15h": "124px",
  "16": "128px",
  "16h": "132px",
  "17": "136px",
  "17h": "140px",
  "18": "144px",
  "18h": "148px",
  "19": "152px",
  "19h": "156px",
  "20": "160px",
  "20h": "164px",
  "21": "168px",
  "21h": "172px",
  "22": "176px",
  "22h": "180px",
  "23": "184px",
  "23h": "188px",
  "24": "192px",
  "24h": "196px",
  "25": "200px",
  "25h": "204px",
  "26": "208px",
  "26h": "212px",
  "27": "216px",
  "27h": "220px",
  "28": "224px",
  "28h": "228px",
  "29": "232px",
  "29h": "236px",
  "30": "240px",
  "30h": "244px",
  "31": "248px",
  "31h": "252px",
  "32": "256px",
  "32h": "260px",
  "33": "264px",
  "33h": "268px",
  "34": "272px",
  "34h": "276px",
};

// This allows for both with and without $ prefix to make it easier to migrate existing code
export const spaceS: Record<string, string> = Object.keys(space).reduce(
  (acc: Record<string, string>, key) => {
    acc["$" + key] = space[key];
    return acc;
  },
  {}
);

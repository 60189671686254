import {
  CompanyType,
  RevenueModel,
  UserPosition,
  CompanyOrgType,
  LedgerView,
  GoldDataModule,
  ProductServiceType,
  MonthlyExpenses,
  NumberOfEmployees,
  PayrollProviderType,
} from "graphql/types";
import { ActiveCompanyFragment } from "./graphql.generated";
import { capitalize, isDst } from "@puzzle/utils";
import { Positions } from "components/dashboard/Dashboard/PostOnboardModal/analytics";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { useActiveCompany } from "./ActiveCompanyProvider";

export const newCompanyTypes: Record<CompanyType, string> = {
  [CompanyType.Advertising]: "Advertising",
  [CompanyType.AgricultureAndFarming]: "Agriculture & farming",
  [CompanyType.Construction]: "Construction",
  [CompanyType.Ecommerce]: "Ecommerce",
  [CompanyType.Fintech]: "Fintech",
  [CompanyType.FintechCrypto]: "Fintech - Crypto",
  [CompanyType.Healthcare]: "Healthcare",
  [CompanyType.HomeServices]: "Home Services",
  [CompanyType.Legal]: "Legal",
  [CompanyType.Manufacturing]: "Manufacturing",
  [CompanyType.MediaAndEntertainment]: "Media & entertainment",
  [CompanyType.Services]: "Professional services",
  [CompanyType.Retail]: "Retail",
  [CompanyType.RealEstate]: "Real Estate",
  [CompanyType.Restaurant]: "Restaurant",
  [CompanyType.Saas]: "SaaS",
  [CompanyType.Transportation]: "Transportation",
  [CompanyType.WholesaleTrade]: "Wholesale trade",
  [CompanyType.VirtualGoods]: "Virtual goods",
  [CompanyType.Other]: "Other",
  [CompanyType.NotSure]: "Not sure",
} as Record<CompanyType, string>;

export const companyTypes: Record<CompanyType, string> = {
  [CompanyType.Advertising]: "Advertising",
  [CompanyType.Saas]: "B2B/SaaS",
  [CompanyType.Consumer]: "Consumer goods",
  [CompanyType.Ecommerce]: "E-Commerce",
  [CompanyType.Enterprise]: "Enterprise",
  [CompanyType.Fintech]: "Fintech",
  [CompanyType.FintechCrypto]: "Fintech - Crypto",
  [CompanyType.Marketplace]: "Marketplace",
  [CompanyType.Services]: "Professional Services",
  [CompanyType.VirtualGoods]: "Virtual goods",
  [CompanyType.Other]: "Other",
  [CompanyType.NotSure]: "Not sure",
} as Record<CompanyType, string>;

export const newCompanyOrgTypes: Record<CompanyOrgType, string> = {
  [CompanyOrgType.CCorporation]: "C Corporation",
  [CompanyOrgType.Llc]: "LLC",
  [CompanyOrgType.Partnership]: "Partnership",
  [CompanyOrgType.SCorporation]: "S Corporation",
  [CompanyOrgType.SoleProprietorship]: "Sole Proprietorship",
  [CompanyOrgType.ProfessionalServicesCorp]: "Professional Services Corp",
  [CompanyOrgType.Nonprofit]: "Nonprofit",
};

export const companyOrgTypes: Record<CompanyOrgType, string> = {
  [CompanyOrgType.CCorporation]: "C-Corporation",
  [CompanyOrgType.Llc]: "Limited Liability Corporation (LLC)",
  [CompanyOrgType.Partnership]: "Partnership (including LLP)",
  [CompanyOrgType.SCorporation]: "S-Corporation",
  [CompanyOrgType.SoleProprietorship]: "Sole Proprietorship",
} as Record<CompanyOrgType, string>;

export const newRevenueModels: Partial<Record<RevenueModel, string>> = {
  [RevenueModel.Subscription]: "Subscription",
  [RevenueModel.AddOns]: "Add ons",
  [RevenueModel.TransactionBasedPhysical]: "Transaction-based - physical goods",
  [RevenueModel.TransactionBasedOther]: "Transaction-based - other",
  [RevenueModel.UsageBased]: "Usage-based",
  [RevenueModel.ServiceBased]: "Service-based",
  [RevenueModel.Contracts]: "Contracts",
  [RevenueModel.HourlyBilling]: "Hourly billing",
  [RevenueModel.Marketplace]: "Marketplace",
  [RevenueModel.Commission]: "Commission",
};

export const revenueModels: Partial<Record<RevenueModel, string>> = {
  [RevenueModel.PreRevenue]: "We do not generate revenue yet",
  [RevenueModel.Subscription]: "Subscription",
  [RevenueModel.TransactionBased]: "Transaction-based",
  [RevenueModel.UsageBased]: "Usage-based",
  [RevenueModel.ServiceBased]: "Service-based",
};

export const roles: Record<UserPosition, string> = {
  [UserPosition.Founder]: "Founder",
  [UserPosition.CompanyEmployee]: "Employee",
  [UserPosition.OutsourcedAccountantOrCfo]: "Accounting or bookkeeping professional",
};

// Mapping for analytics
export const roleToPosition: Record<UserPosition, Positions> = {
  [UserPosition.Founder]: Positions.founderCEO,
  [UserPosition.CompanyEmployee]: Positions.financeAccounting,
  [UserPosition.OutsourcedAccountantOrCfo]: Positions.outsourcedProfessional,
};

export const goldDataModules: Record<GoldDataModule, string> = {
  [GoldDataModule.DemoV3]: "Demo V3",
  [GoldDataModule.UhvStripe]: "Utra High Volume Stripe",
};

export const productServiceType: Record<ProductServiceType, string> = {
  [ProductServiceType.Inventory]: "Inventory",
  [ProductServiceType.NonInventory]: "Non-Inventory",
  [ProductServiceType.Service]: "Service",
  [ProductServiceType.Unknown]: "Unknown",
};

const isDST = isDst();
const utcOffset = (x: number, checkDST = true) => `(UTC-0${checkDST && isDST ? x - 1 : x}:00)`;

export const timeZones = {
  "US/Hawaii": `${utcOffset(10, false)} US/Hawaii`,
  "US/Alaska": `${utcOffset(8, false)} US/Alaska`,
  "US/Pacific": `${utcOffset(8)} US/Pacific`,
  "US/Mountain": `${utcOffset(7)} US/Mountain`,
  "US/Central": `${utcOffset(6)} US/Central`,
  "US/Eastern": `${utcOffset(5)} US/Eastern`,
};

// https://github.com/eggert/tz/blob/main/backward
// Example: America/Chicago => US/Central
export const normalizeTimeZone = (tz: string) => {
  if (!tz) return "US/Pacific";
  tz = tz.toLowerCase();
  switch (tz) {
    case "pacific/honolulu":
      return "US/Hawaii";
    case "america/anchorage":
      return "US/Alaska";
    case "america/los_angeles":
      return "US/Pacific";
    case "america/denver":
      return "US/Mountain";
    case "america/chicago":
      return "US/Central";
    case "america/new_york":
      return "US/Eastern";
    default:
      return "US/Pacific";
  }
};

export const helpText = {
  companyName: "This is the name we'll use throughout the app and in any communications.",
  companyType:
    "You will not be able to modify this later, but you can add custom accounts to your default COA.",
  companyOrgType:
    "You will not be able to modify this later, but you can add custom accounts to your default COA.",
  revenueModel: "You are able to modify this at any time.",
  role: "Knowing your role helps us customize your experience.",
  timeZone:
    "We use this to generate accurate financial reports and align time zones across connected data sources.",
  historicalBooks:
    "If you have finalized historical financials that you want to use as your permanent accounting records, you will have the option to start in Puzzle after those financials and bring the historical financials into Puzzle.",
  reconciliationsBasis:
    "Puzzle maintains a cash and accrual basis general ledger. You can choose which ledger to reconcile and switch between the two ledgers. For bank and credit card accounts, the ledgers will typically align.",
  referralCode:
    "Enter your investor referral code, investor name, or the email of your investor to access your upgrade",
};

export const recordToOptions = (object: Record<string, string>) =>
  Object.entries(object).map(([value, label]) => ({ value, label }));

export const getViewOptions = (company?: ActiveCompanyFragment, labelSuffix?: string) => {
  const viewToLabel = (view: string | null) =>
    view ? `${capitalize(view)}${labelSuffix ? labelSuffix : ""}` : "";

  // If the company has reporting views enabled, show those custom views
  if (company?.views && company.views.length > 0) {
    return company.views.map((view) => ({
      value: view ?? "",
      label: viewToLabel(view),
    }));
  }

  // Otherwise just show cash with accrual view disabled
  return [LedgerView.Cash, LedgerView.Accrual].map((view) => {
    const disabled = view === LedgerView.Accrual;
    return {
      value: view,
      label: `${viewToLabel(view)}${disabled ? " (coming soon)" : ""}`,
      disabled,
    };
  });
};

// This is not the best solution, but it is the only way to get the reference for the correct view option
export const useDefaultViewPreferences = (availableViews?: string[]) => {
  const { company } = useActiveCompany<true>();
  if (!isPosthogFeatureFlagEnabled(FeatureFlag.CashAcrrualReportDefaultFilter)) {
    return LedgerView.Cash;
  }
  const companyViewPreference = company.features.viewPreference;
  const companyViews = availableViews ?? getViewOptions(company).map((option) => option.value);
  return companyViews?.find((view) => view === companyViewPreference) ?? LedgerView.Cash;
};

export const monthlyExpenses: Record<MonthlyExpenses, string> = {
  [MonthlyExpenses.LessThan10k]: "Less than $10k/mo",
  [MonthlyExpenses.Between10kAnd50k]: "$10k to $50k/mo",
  [MonthlyExpenses.Between50kAnd100k]: "$50k to $100k/mo",
  [MonthlyExpenses.Between100kAnd250k]: "$100k to $250k/mo",
  [MonthlyExpenses.Between250kAnd500k]: "$250k to $500k/mo",
  [MonthlyExpenses.Between500kAnd1m]: "$500k to $1M/mo",
  [MonthlyExpenses.GreaterThan1m]: "Greater than $1M",
};

export const numberOfEmployees: Record<NumberOfEmployees, string> = {
  [NumberOfEmployees.Between1And10]: "1 to 10",
  [NumberOfEmployees.Between10And50]: "11 to 50",
  [NumberOfEmployees.Between50And100]: "51 to 100",
  [NumberOfEmployees.GreaterThan100]: "101+",
};

export const payrollProviders: Record<PayrollProviderType, string> = {
  [PayrollProviderType.Gusto]: "Gusto",
  [PayrollProviderType.Rippling]: "Rippling",
  [PayrollProviderType.Adp]: "ADP",
  [PayrollProviderType.Deel]: "Deel",
  [PayrollProviderType.Remote]: "Remote",
  [PayrollProviderType.Justworks]: "Justworks",
  [PayrollProviderType.TriNet]: "TriNet",
  [PayrollProviderType.Wagepoint]: "Wagepoint",
  [PayrollProviderType.Paychex]: "Paychex",
  [PayrollProviderType.Papaya]: "Papaya",
  [PayrollProviderType.Plane]: "Plane",
  [PayrollProviderType.Zenefits]: "Zenefits",
  [PayrollProviderType.Wave]: "Wave",
  [PayrollProviderType.Square]: "Square",
  [PayrollProviderType.SurePayroll]: "SurePayroll",
  [PayrollProviderType.Other]: "Other",
};

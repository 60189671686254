import React from "react";
import { getTrialInfoDate } from "./utils";
import { ColorfulCrown } from "@puzzle/icons";
import { Stack, vars, Button, Text } from "ve";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { freeTrialExpiringStyle } from "./trailInfoPanel.css";
import useMonetization from "components/monetization/useMonetization";
import { formatDaysLeftingOnFreeTrial } from "components/settings/Billing/BillingSubscription/utils";

const PREMIUM_FREE_TRIAL_DAYS = 14;

export function TrialInfoPanel() {
  const { company, timeZone } = useActiveCompany<true>();
  const { showUpgradeModal } = useMonetization();
  const daysLeftOnFreeTrial = getTrialInfoDate(company.puzzleSubscriptionState, timeZone);
  if (!daysLeftOnFreeTrial) {
    return null;
  }
  return (
    <div className={freeTrialExpiringStyle}>
      <Stack direction="horizontal" css={{ alignItems: "center" }} gap={vars.space["1"]}>
        <ColorfulCrown />
        <Text>
          {/*
            For Puzzle companies, we are setting the Premium Free Trial end date to 10 years
            after the company's creation date. So, we are limiting it to 14 days to be displayed.
          */}
          {formatDaysLeftingOnFreeTrial(Math.min(daysLeftOnFreeTrial, PREMIUM_FREE_TRIAL_DAYS))} on
          your free trial
        </Text>
      </Stack>
      <Button fullWidth variant="gold" onClick={() => showUpgradeModal()}>
        View plans
      </Button>
    </div>
  );
}

import React from "react";
import { styled } from "./stitches";
import { UnstyledButton } from "./Button";
import { Close } from "@puzzle/icons";

const Root = styled("div", {
  unstyled: true,

  display: "flex",
  flexDirection: "row",
  gap: "$0h",
  width: "fit-content",
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: "$bold",
  alignItems: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
  cursor: "pointer",

  "button&": {
    cursor: "pointer",

    "&:focus, &:hover": {
      boxShadow: "0 0 0 1px #3C3B44",
    },
  },

  svg: {
    width: 8,
    height: 8,
    color: "$gray500",
  },

  defaultVariants: {
    variant: "tag",
    // TBD if this is the best default
    wrap: false,
  },

  variants: {
    variant: {
      tag: {
        padding: "$0h $1",
        border: "1px solid #3C3B44",
        boxSizing: "border-box",
        borderRadius: "24px",
        color: "$gray300",
        backgroundColor: "rgba(33, 31, 53, 0.4)",
      },

      pill: {
        padding: "$1",
        background: "#191829",
        border: "1px solid #302F4A",
        boxSizing: "border-box",
        borderRadius: "80px",
        width: "fit-content",
        lineHeight: 1,
        color: "$gray200",
        letterSpacing: "0.2px",
      },

      noBorderPill: {
        padding: "$1",
        borderRadius: "80px",
        width: "fit-content",
        lineHeight: 1,
        color: "$gray200",
        letterSpacing: "0.2px",
      },

      // TODO I can't tell if pill is deprecated, so here's a second one...
      filledPill: {
        padding: "$0h $1",
        background: "#121718",
        borderRadius: "36px",
        color: "$gray200",
        letterSpacing: "0.2px",
      },

      outlined: {
        padding: "$0h $1",
        background: "rgba(33, 31, 53, 0.4)",
        border: "1px solid $gray700",
        borderRadius: "24px",
        color: "$gray300",
      },

      neutralDarkOutlined: {
        padding: "$0h $1",
        background: "$mauve800",
        borderRadius: "29px",
        border: "1px solid $mauve600",
      },

      j2_outlined: {
        height: "40px",
        borderRadius: "$1",
        padding: "6px 8px 6px 12px",
        border: "1px solid rgba(255, 255, 255, 0.10)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },

      // wtf i'm guessing
      button: {
        background: "transparent",
        border: "1px solid $gray600",
        borderRadius: "$1",
        padding: "$1",

        [`${UnstyledButton}`]: {
          marginLeft: "$0h",

          "&:hover": {
            color: "$white !important",
          },
        },
      },

      warningPill: {
        padding: "$0h $1",
        background: "$yellow400",
        borderRadius: "36px",
        color: "$black",
        letterSpacing: "0.2px",
      },

      neutralSquare: {
        borderRadius: "$1",
        backgroundColor: "$rhino700",
        padding: "$0h $1",
        cursor: "default",
      },

      gradient: {
        cursor: "default",
        position: "relative",
        padding: "$0h 12px",
        boxSizing: "border-box",

        color: "$gray300",
        background: "$mauve800",
        backgroundClip: "padding-box",
        border: "solid 1px transparent",
        borderRadius: "29px",

        "&:before": {
          content: "",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
          margin: "-1px",
          borderRadius: "inherit",
          background: "linear-gradient(135deg, #AB7DE3 0%,#FFFFFF 50%, #ADFFE2 100%)",
          // TODO: Use purple600 and green300 above
        },
      },
    },

    color: {
      vivid: {
        color: "$greenalpha",
        borderColor: "$greenalpha",
      },
      active: {
        color: "$gray600",
        background: "$white",
        borderColor: "$white",
      },
      coolMint: {
        color: "#00A056",
        backgroundColor: "#C9E4C9",
      },
      warning: {
        color: "$red1000",
        backgroundColor: "$red100",
      },
      inactive: {
        color: "$gray200",
        backgroundColor: "$rhino600",
      },
      coolBlue: {
        color: "#A5E6FF",
        background: "$rhino700",
      },
      warningOutline: {
        color: "$red300",
        borderColor: "$red500",
      },
      white05: {
        color: "rgba(255, 255, 255, 0.70)",
        backgroundColor: "rgba(255, 255, 255, 0.05)",
      },
    },

    wrap: {
      true: {},
      false: {
        whiteSpace: "nowrap",
      },
    },

    size: {
      small: { lineHeight: "0.5rem" },
      medium: {
        padding: "$1 $1h",
        lineHeight: 1,
        svg: { width: 10, height: 10 },
      },
    },
  },

  compoundVariants: [
    {
      variant: "neutralSquare",
      color: "warning",
      css: {
        backgroundColor: "$rhino700",
        color: "$red200",
      },
    },
    {
      variant: "neutralSquare",
      color: "coolMint",
      css: {
        backgroundColor: "$rhino700",
        color: "#C9E4C9",
      },
    },
    {
      variant: "neutralSquare",
      color: undefined,
      css: {
        backgroundColor: "$rhino700",
        color: "$gray300",
      },
    },
  ],
});

// eslint-disable-next-line react/display-name
export const Tag = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof Root> & {
    maxWidth?: number;
    onRemove?: () => void;
  }
>(({ children, maxWidth, css, ...props }, ref) => {
  // Doesn't make sense to have a nested button
  // Only allow one
  let onClick, onRemove;
  if ("onClick" in props) {
    onClick = props.onClick;
  } else if ("onRemove" in props) {
    const { onRemove: _onRemove, ...finalProps } = props;
    onRemove = _onRemove;
    props = { ...finalProps };
  }

  return (
    <Root
      {...props}
      onClick={onRemove ? onRemove : onClick}
      role={onClick ? "button" : undefined}
      tabIndex={onClick && !onRemove ? 0 : undefined}
      css={
        typeof maxWidth !== "undefined"
          ? {
              ...css,
              "& > *": {
                maxWidth,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }
          : css
      }
      ref={ref}
    >
      {children}

      {onRemove && (
        <UnstyledButton onClick={onRemove} css={{ lineHeight: 0 }}>
          <Close fill="currentColor" />
        </UnstyledButton>
      )}
    </Root>
  );
});

Tag.toString = Root.toString;

export default Tag;

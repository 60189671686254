import React from "react";
import { DateInput, RadioItem, RadioGroupItem, RadioIndicator } from "@puzzle/ui";
import { Text } from "ve";
import { DateValue } from "@internationalized/date";

import { IngestionStartOption } from "./ingestionDateUtils";

type CustomDateRadioItemProps = {
  value: DateValue | null;
  onChange: ((value: DateValue | null) => void) | undefined;
};

export const CustomDateRadioItem = ({ value, onChange }: CustomDateRadioItemProps) => {
  const radioItemRef = React.useRef<HTMLButtonElement>(null);

  return (
    <RadioGroupItem
      key={IngestionStartOption.CUSTOM}
      // Default onClick was causing dateInput popover to close because focus shifted to radioItem
      // this ensures onClick for radioItem occurs first and only once
      onClick={(e) => {
        if (radioItemRef.current) {
          radioItemRef.current.click();
          e.preventDefault();
        }
      }}
    >
      <RadioItem value={IngestionStartOption.CUSTOM} ref={radioItemRef}>
        <RadioIndicator />
      </RadioItem>
      <Text color="white">Custom: </Text>
      <DateInput size="small" css={{ width: "max-content" }} value={value} onChange={onChange} />
    </RadioGroupItem>
  );
};

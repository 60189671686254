import Big, { BigSource } from "big.js";

export type Money = {
  currency?: string;
  amount: BigSource;
};

export const addMoney = (...moneys: Money[]): Money => {
  const currency = moneys[0]?.currency ?? "USD";
  let total = Big(0);

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < moneys.length; i++) {
    const money = moneys[i];

    if (money.currency !== currency) {
      throw new Error("addMoney cannot add multiple currencies");
    }

    total = total.add(money.amount);
  }

  return {
    currency,
    amount: total,
  };
};

export const subtractMoney = (a: Money, b: Money): Money => {
  if (a.currency !== b.currency) {
    throw new Error("subtractMoney cannot subtract different currencies");
  }

  return {
    currency: a.currency ?? "USD",
    amount: Big(a.amount).sub(b.amount),
  };
};

export const sumAmounts = (amounts: string[], currency = "USD") =>
  Number(addMoney(...amounts.map((amount) => ({ amount, currency }))).amount);

import { create } from "zustand";

type UseAddOnConfirmationDialogProps = {
  isOpen: boolean;
  showAddOnConfirmationDialog: () => void;
  hideAddOnConfirmationDialog: () => void;
};

export const useAddOnConfirmationDialog = create<UseAddOnConfirmationDialogProps>((set) => ({
  isOpen: false,
  showAddOnConfirmationDialog: () => set({ isOpen: true }),
  hideAddOnConfirmationDialog: () => set({ isOpen: false }),
}));

import { useEffect, useState } from "react";

type Props<T> = {
  items: T[];
  chunkSize?: number;
  interval?: number;
};

export function useChunkRenderer<T>({ items, chunkSize = 5, interval = 250 }: Props<T>) {
  const [visibleItems, setVisibleItems] = useState<T[]>([]);
  const [currentChunk, setCurrentChunk] = useState(0);

  useEffect(() => {
    if (items.length === 0) {
      setVisibleItems([]); // Explicitly handle empty items
      return; // Skip setting an interval
    }

    const loadNextChunk = () => {
      setCurrentChunk((prevChunk) =>
        (prevChunk + 1) * chunkSize <= items.length ? prevChunk + 1 : prevChunk
      );
    };

    const intervalId = setInterval(loadNextChunk, interval);
    console.log("Interval created:", intervalId);

    return () => {
      console.log("Clearing interval:", intervalId);
      clearInterval(intervalId);
    };
  }, [items.length, chunkSize, interval]);

  useEffect(() => {
    const nextChunk = items.slice(0, (currentChunk + 1) * chunkSize);
    setVisibleItems(nextChunk);
  }, [currentChunk, items, chunkSize]);

  return visibleItems;
}

import React from "react";
import PrivateLayout from "./PrivateLayout";
import { Box, S } from "ve";
import { EducationMenuProps } from "../topbar/EducationMenu";

const FlexTableLayout = ({
  children,
  title,
  documentTitle,
  header,
  containerCss,
  layoutCss,
  education,
}: React.PropsWithChildren<{
  title?: string;
  documentTitle?: string;
  header?: React.ReactNode;
  containerCss?: React.CSSProperties;
  layoutCss?: React.CSSProperties;
  education?: EducationMenuProps;
}>) => {
  return (
    <PrivateLayout minimal documentTitle={documentTitle} title={title} education={education}>
      <Box
        css={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: `${S["3"]} ${S["4"]} 0 ${S["5"]}`,
          ...layoutCss,
        }}
      >
        <Box css={{ display: "flex", height: "100%", overflow: "hidden" }}>
          <Box css={{ display: "flex", flex: 1, flexDirection: "column", ...containerCss }}>
            {header && header}
            {children}
          </Box>
        </Box>
      </Box>
    </PrivateLayout>
  );
};

export default FlexTableLayout;

import React from "react";
import Icon, { IconProps } from "./Icon";

export default function ColorfulCrown({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} fill="none" viewBox={viewBox}>
      <rect width="24" height="24" rx="9" fill="black" fillOpacity="0.2" />
      <g clipPath="url(#clip0_317_14257)">
        <path
          d="M8.15625 15.625L6.875 9.375L9.875 11.125L12 7.875L14.125 11.125L17.125 9.375L15.8438 15.625H8.15625Z"
          stroke="url(#paint0_linear_317_14257)"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_317_14257"
          x1="7.6224"
          y1="8.60156"
          x2="14.2348"
          y2="16.9306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB7DE3" />
          <stop offset="1" stopColor="#F7DB60" />
        </linearGradient>
        <clipPath id="clip0_317_14257">
          <rect width="12" height="12" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </Icon>
  );
}

import React from "react";
import useMeow from "./useMeow";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import MeowLogo from "./MeowLogo";
import { IntegrationDisplayComponent } from "../shared/types";

type MeowListItemProps = {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}
export const TITLE = "Meow";
export const SUBTITLE = "Bank";

const MeowListItem = ({ DisplayComponent, companyId }: MeowListItemProps) => {
  const { onClickConnect, connection, loading } = useMeow({
    companyId,
  });

  return (
    <DisplayComponent
      integrationType={IntegrationType.Meow}
      connectIntegration={onClickConnect}
      title={TITLE}
      connection={connection}
      logo={<MeowLogo />}
      loading={loading}
    />
  );
};

export default MeowListItem;

import React, { useCallback, useEffect, useState } from "react";
import { RadioGroup, Option } from "@puzzle/ui";
import { Text, Stack } from "ve";
import { type CalendarDate, parseDate, toCalendarDate, today } from "@internationalized/date";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { CalendarDateString } from "@puzzle/utils";
import {
  sectionHeaderStyle,
  singleAccountStartStyle,
  startOptionStyle,
} from "./ingestionDateModal.css";
import { EpochInput, IngestionStartOption, shouldEnableCutoverV2 } from "./ingestionDateUtils";
import { CustomDateRadioItem } from "./CustomDateRadioItem";
import useSelf from "components/users/useSelf";

type AccountSummary = {
  id: string;
  name: string;
  mask?: string | null;
  financialInstitution: {
    name: string;
  };
  epoch?: {
    cutoffBefore?: CalendarDateString | null;
    cutoffAtOrAfter?: CalendarDateString | null;
  } | null;
};

type SelectEndForSingleAccountProps = {
  account: AccountSummary;
  onSetIngestionDate: (epoch: EpochInput) => void;
  currentEpoch?: EpochInput;
  isReconnect?: boolean;
  shouldUpdateHistory?: boolean;
};

export const SelectEndForSingleAccount = ({
  account,
  onSetIngestionDate,
  currentEpoch,
  shouldUpdateHistory = false,
}: SelectEndForSingleAccountProps) => {
  const { timeZone, company } = useActiveCompany<true>();
  const initialEpoch = account.epoch;
  const ledgerStartDateString = company.startIngestionDate;
  const { isPuzzlenaut } = useSelf();
  const isCutoverV2Enabled = shouldEnableCutoverV2(company?.coaType, isPuzzlenaut);

  const [selectEndValue, setSelectEndValue] = useState<IngestionStartOption>(
    currentEpoch?.cutoffAtOrAfter ? IngestionStartOption.CUSTOM : IngestionStartOption.ALL_TIME
  );

  const [customEndDate, setCustomEndDate] = useState<CalendarDate>(
    initialEpoch?.cutoffAtOrAfter ? parseDate(initialEpoch.cutoffAtOrAfter) : today(timeZone)
  );

  const getIngestEndValue = useCallback(
    (selectionType: IngestionStartOption) => {
      const currentCutoffBefore = () => {
        if (currentEpoch?.cutoffBefore) return currentEpoch.cutoffBefore;
        if (initialEpoch?.cutoffBefore) return parseDate(initialEpoch.cutoffBefore);
        return null;
      };
      const baseValues = {
        accountId: account.id,
        shouldUpdateHistory,
        cutoffBefore: currentCutoffBefore(),
      };
      if (selectionType === IngestionStartOption.CUSTOM) {
        return {
          cutoffAtOrAfter: customEndDate,
          ...baseValues,
        };
      }
      return {
        cutoffAtOrAfter: null,
        ...baseValues,
      };
    },
    [
      account.id,
      currentEpoch?.cutoffBefore,
      customEndDate,
      initialEpoch?.cutoffBefore,
      shouldUpdateHistory,
    ]
  );

  const endOptions: Option[] = [
    {
      value: IngestionStartOption.ALL_TIME,
      label: (
        <Stack direction="horizontal" className={startOptionStyle}>
          <Text color="white">Up to date</Text>
        </Stack>
      ),
    },
  ];

  const ingestionEndInitialOption = initialEpoch?.cutoffAtOrAfter
    ? IngestionStartOption.CUSTOM
    : IngestionStartOption.ALL_TIME;

  useEffect(() => {
    if (!currentEpoch) {
      setSelectEndValue(ingestionEndInitialOption);
    }
  }, [
    onSetIngestionDate,
    currentEpoch,
    ledgerStartDateString,
    account.epoch,
    ingestionEndInitialOption,
    getIngestEndValue,
  ]);

  return (
    <>
      {isCutoverV2Enabled && (
        <Stack>
          <Stack>
            <Text className={sectionHeaderStyle} variant="bodyXXS">
              Ingestion End Date
            </Text>
            <Stack className={singleAccountStartStyle}>
              <RadioGroup
                options={endOptions}
                value={selectEndValue}
                onValueChange={(value: IngestionStartOption) => {
                  setSelectEndValue(value);
                  onSetIngestionDate(getIngestEndValue(value));
                }}
              >
                <CustomDateRadioItem
                  value={customEndDate}
                  onChange={(value) => {
                    if (value) {
                      const dateValue = toCalendarDate(value);
                      setCustomEndDate(dateValue);
                      onSetIngestionDate({
                        cutoffAtOrAfter: dateValue,
                        cutoffBefore: currentEpoch?.cutoffBefore ?? null,
                        accountId: account.id,
                        shouldUpdateHistory,
                      });
                    }
                  }}
                />
              </RadioGroup>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

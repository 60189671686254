import React from "react";

import { Button } from "ve";

import { ArrowLeft, Arrow } from "@puzzle/icons";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { BookkeeperPlan } from "graphql/types";

import { PersistedHowMaintainBooks } from "components/dashboard/Dashboard/PostOnboardModal/Panes";
import { AnalyticsTriggeredFrom } from "components/dashboard/Dashboard/PostOnboardModal/analytics";

import { rootStyle, buttonsRowStyle } from "./BookkeepPlan.css";

export const BookkeepPlan = ({ onContinue }: { onContinue: () => void }) => {
  const { company, updateCompany } = useActiveCompany<true>();
  const hasPlan = !!company?.bookkeeperPlan && company.bookkeeperPlan !== BookkeeperPlan.Unknown;

  const handleClear = async () => {
    await updateCompany({ bookkeeperPlan: BookkeeperPlan.Unknown });
  };
  const handleSave = (value?: string) => {
    if (value === BookkeeperPlan.DoItMyself) {
      onContinue();
    }
  };

  return (
    <div className={rootStyle}>
      <PersistedHowMaintainBooks
        location={AnalyticsTriggeredFrom.PostOnboarding}
        showSubtext={true}
        onNext={handleSave}
        showTitleTooltip={false}
        showCompletionText={hasPlan}
      />
      {hasPlan && (
        <div className={buttonsRowStyle}>
          <Button
            onClick={handleClear}
            variant="secondary"
            css={{ paddingLeft: "0" }}
            prefixElement={<ArrowLeft css={{ marginLeft: "4px" }} />}
          >
            Back
          </Button>
          <Button onClick={onContinue} suffixElement={<Arrow size={16} color="$black" />}>
            Continue
          </Button>
        </div>
      )}
    </div>
  );
};

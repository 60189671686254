import { useGetStatementBalancesQuery } from "../../graphql.generated";
import { useQueryState } from "next-usequerystate";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { today, parseDate, startOfMonth, endOfMonth, CalendarDate } from "@puzzle/utils";
import { LedgerAccountWithReconciliationFragment, useBalancesQuery } from "../graphql.generated";
import { useMemo } from "react";

export const useSuggestedEndingBalance = ({
  ledgerAccount,
}: {
  ledgerAccount?: LedgerAccountWithReconciliationFragment;
}) => {
  const { company, timeZone } = useActiveCompany<true>();
  const [endingDate] = useQueryState("ending-date");
  const accountId = ledgerAccount?.info.financialInstitutionAccount?.id;
  const reconciledThrough =
    ledgerAccount?.ledgerReconciliationSummary?.lastReconciled?.statementDate;

  const defaultEndingDate = endingDate
    ? parseDate(endingDate)
    : endOfMonth(today(timeZone).subtract({ months: 1 }));
  let suggestedEndDate: CalendarDate | undefined = defaultEndingDate;
  if (reconciledThrough) {
    // we only want to suggest an end date if it's not already reconciled through that date
    suggestedEndDate =
      parseDate(reconciledThrough).compare(defaultEndingDate) < 0 ? defaultEndingDate : undefined;
  }

  const { data: statementBalancesData, loading: statementLoading } = useGetStatementBalancesQuery({
    skip: !accountId || !suggestedEndDate,
    variables: {
      input: {
        companyId: company.id,
        accountId: accountId!,
        dateRange: {
          fromInclusive: suggestedEndDate ? startOfMonth(suggestedEndDate).toString() : "",
          toInclusive: suggestedEndDate ? suggestedEndDate.toString() : "",
        },
      },
    },
  });

  const { data: balancesData, loading: balancesLoading } = useBalancesQuery({
    skip: !accountId || !suggestedEndDate,
    variables: {
      input: {
        companyId: company.id,
        accountId,
        dates: suggestedEndDate ? [suggestedEndDate.toString()] : [],
      },
    },
  });

  const suggestedBalance = useMemo(() => {
    return statementBalancesData?.statementBalances?.endingBalance
      ? statementBalancesData?.statementBalances?.endingBalance
      : balancesData?.balances.balancesByDate[0]?.balances[0].balance;
  }, [balancesData, statementBalancesData]);

  return { suggestedBalance, suggestedEndDate, loading: statementLoading || balancesLoading };
};

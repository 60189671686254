import { create } from "zustand";

type BulkRecategorizeStore = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  toggle: () => void;
}

export const useBulkRecategorizeStore = create<BulkRecategorizeStore>((set) => ({
  isOpen: false,
  setIsOpen: (val: boolean) => set({ isOpen: val }),
  toggle: () => set((state) => ({ isOpen: !state.isOpen })),
}));

import React from "react";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { PuzzlePlanModalVersion } from "graphql/types";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { PlansPricingModal } from "./PlansPricingModal/PlansPricingModal";
import { PricingModal } from "./PricingModal";
import { PricingPackageV2Modal } from "./PricingPackageV2Modal/PricingPackageV2Modal";

export const MonetizationModal = () => {
  const { company } = useActiveCompany();
  const isOldPlan = company?.puzzlePlanModalVersion === PuzzlePlanModalVersion.V1;

  if (isPosthogFeatureFlagEnabled(FeatureFlag.PlansPricingModal)) {
    return <PlansPricingModal />;
  }

  // Old modal for billing plan prices for old companies or if FF is disabled
  if (isOldPlan || !isPosthogFeatureFlagEnabled(FeatureFlag.PricingPackageV2)) {
    return <PricingModal />;
  }

  return <PricingPackageV2Modal />;
};

import React from "react";

import ToggleGroup from "components/common/ToggleGroup";
import { PuzzlePriceBillingInterval } from "graphql/types";
import { usePlansPricingModal } from "./usePlansPricingModal";

const SubscriptionCycleSwitchComponent = ({
  cycle,
  setCycle,
}: {
  cycle: PuzzlePriceBillingInterval;
  setCycle: (cycle: PuzzlePriceBillingInterval) => void;
}) => {
  return (
    <ToggleGroup.Root
      type="single"
      onValueChange={(value) => {
        if (!value) return;

        setCycle(value as PuzzlePriceBillingInterval);
      }}
      value={cycle}
      css={{ width: "300px" }}
    >
      <ToggleGroup.Item
        key={PuzzlePriceBillingInterval.Year}
        value={PuzzlePriceBillingInterval.Year}
        title="Yearly"
      >
        Yearly • Save 15%
      </ToggleGroup.Item>
      <ToggleGroup.Item
        key={PuzzlePriceBillingInterval.Month}
        value={PuzzlePriceBillingInterval.Month}
        title="Monthly"
      >
        Monthly
      </ToggleGroup.Item>
    </ToggleGroup.Root>
  );
};

export const SubscriptionCycleSwitch = () => {
  const { selectedCycle, setSelectedCycle } = usePlansPricingModal();

  return (
    <SubscriptionCycleSwitchComponent
      cycle={selectedCycle || PuzzlePriceBillingInterval.Year}
      setCycle={setSelectedCycle}
    />
  );
};

import React from "react";
import { S, Stack, Text } from "ve";
import { CutoverSegmentType, CutoverSegmentTypeToLegend } from "../ingestionDateUtils";

type IngestionChartLegendProps = {
  legendValues: CutoverSegmentType[];
}

export const IngestionChartLegend = ({ legendValues }: IngestionChartLegendProps) => {
  return (
    <Stack direction="horizontal">
      <Text variant="bodyXXS">Data Source: </Text>
      {legendValues.map((value) => {
        const legendItem = CutoverSegmentTypeToLegend[value];
        return (
          <Stack key={value} direction="horizontal" gap={S["$0h"]} css={{ alignItems: "center" }}>
            <div
              style={{ ...legendItem.styles, width: "12px", height: "8px", borderRadius: "200px" }}
            ></div>
            <Text variant="bodyXXS">{legendItem.label}</Text>
          </Stack>
        );
      })}
    </Stack>
  );
};

import React, { ComponentProps } from "react";
import dynamic from "next/dynamic";

export enum BrandName {
  Amazon = "amazon",
  AmericanExpress = "american-express",
  AngelList = "angellist",
  BankOfAmerica = "bank-of-america",
  BillDotCom = "bill-dot-com",
  Bluevine = "bluevine",
  Brex = "brex",
  Carta = "carta",
  Central = "central",
  Chase = "chase",
  Citi = "citi",
  Daydream = "daydream",
  Deel = "deel",
  ElanCreditCard = "elan-credit-card",
  Every = "every",
  Expensify = "expensify",
  FirstNationalBankOfOmaha = "first-national-bank-of-omaha",
  FirstRepublic = "first-republic",
  Google = "google",
  Gusto = "gusto",
  Justworks = "justworks",
  Ltse = "ltse",
  Meow = "meow",
  Mercury = "mercury",
  Novo = "novo",
  Paypal = "paypal",
  PNC = "pnc",
  Plaid = "plaid",
  Quickbooks = "quickbooks",
  Ramp = "ramp",
  Rho = "rho",
  Relay = "relay",
  Rippling = "rippling",
  Runaway = "runaway",
  Salesforce = "salesforce",
  Stripe = "stripe",
  SVB = "svb",
  Trinet = "trinet",
  UsBank = "us-bank",
  WellsFargo = "wells-fargo",
  Wise = "wise",
}

export const BRAND_BACKGROUND_COLORS: Record<BrandName, string> = {
  [BrandName.Amazon]: "#FFFFFF",
  [BrandName.AmericanExpress]: "#016FD0",
  [BrandName.AngelList]: "#FFFFFF",
  [BrandName.BankOfAmerica]: "#FFFFFF",
  [BrandName.BillDotCom]: "#FF5A0A",
  [BrandName.Bluevine]: "#FFFFFF",
  [BrandName.Brex]: "#171717",
  [BrandName.Carta]: "#E3EEF3",
  [BrandName.Central]: "#1A0DAB",
  [BrandName.Chase]: "#FFFFFF",
  [BrandName.Citi]: "#FFFFFF",
  [BrandName.Daydream]: "#FFFFFF",
  [BrandName.Deel]: "#FFFFFF",
  [BrandName.ElanCreditCard]: "#FFFFFF",
  [BrandName.Every]: "#161B2C",
  [BrandName.Expensify]: "#0B1B34",
  [BrandName.FirstNationalBankOfOmaha]: "#FFFFFF",
  [BrandName.FirstRepublic]: "#ffffff",
  [BrandName.Google]: "#FFFFFF",
  [BrandName.Gusto]: "#F45D48",
  [BrandName.Justworks]: "#1e98ff",
  [BrandName.Ltse]: "#110837",
  [BrandName.Meow]: "#0C1D37",
  [BrandName.Mercury]: "#5466F9",
  [BrandName.Novo]: "#FFFFFF",
  [BrandName.Paypal]: "#FFFFFF",
  [BrandName.PNC]: "#FFFFFF",
  [BrandName.Plaid]: "#111111",
  [BrandName.Quickbooks]: "#2CA01C",
  [BrandName.Ramp]: "#E4F222",
  [BrandName.Rho]: "#151716",
  [BrandName.Relay]: "#004822",
  [BrandName.Rippling]: "#FCB315",
  [BrandName.Runaway]: "#F9A600",
  [BrandName.Salesforce]: "#00A1E0",
  [BrandName.Stripe]: "#635BFF",
  [BrandName.SVB]: "#FFFFFF",
  [BrandName.Trinet]: "#0B0134",
  [BrandName.UsBank]: "#FFFFFF",
  [BrandName.WellsFargo]: "#D71E28",
  [BrandName.Wise]: "#9EE771",
};

const SVG_IMPORT_FUNCTION = (icon: BrandName) => () => import(`./svgs/${icon}.svg?svgr`);

export type BrandIconProps = {
  name: BrandName;
} & ComponentProps<"svg">;

// TODO: Add a 'shape' prop defaulting to circular
// if 'square', we'll use the 'width' and 'height' of 48px
const BrandIcon = ({ name, ...props }: BrandIconProps) => {
  const Svg = dynamic(SVG_IMPORT_FUNCTION(name));

  return <Svg data-testid={name} {...props} />;
};

export default BrandIcon;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createStore, StoreApi, useStore } from "zustand";

import useKnockClient from "./useKnockClient";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import config from "lib/config";
import { InboxFeed, KnockWorkflow } from "components/dashboard/Inbox/shared";
import { Feed, FeedItem, FeedStoreState, NetworkStatus } from "@knocklabs/client";
import { useRouter } from "next/router";
import { isInboxRoute } from "lib/useAppRouter";
import Analytics from "lib/analytics";

type InboxContextValue = {
  feeds: {
    tasks: Feed | null;
    updates: Feed | null;
    archived: Feed | null;
  };
  feedClient?: Feed | null;
  feed?: InboxFeed;

  // These are only used in the full Inbox view
  activeItem?: FeedItem;
  setActiveItem: React.Dispatch<React.SetStateAction<FeedItem | undefined>>;
  handleArchivalClick: ({
    event,
    item,
    analyticsLocation,
  }: {
    event: React.MouseEvent;
    analyticsLocation?: string;
    item?: FeedItem;
  }) => Promise<void>;
};

const InboxContext = React.createContext<InboxContextValue | undefined>(undefined);

const useFeed = ({
  feedId,
  pageSize,
  companyId,
  source,
  archived,
}: {
  feedId: string;
  pageSize?: number;
  companyId?: string;
  source?: `${KnockWorkflow}`;
  archived?: "include" | "exclude" | "only";
}) => {
  // feeds on knock client must be unique by feed id
  // must create additional knock clients for archival feed
  // otherwise the tasks feed will get overriden my the
  // archival tasks feed
  const knockClient = useKnockClient(archived);

  const feed = useMemo<Feed | null>(
    () =>
      knockClient?.isAuthenticated && companyId
        ? knockClient?.feeds.initialize(feedId, {
            tenant: companyId,
            source,
            archived,
            page_size: pageSize,
            __experimentalCrossBrowserUpdates: true,
          })
        : null,
    [archived, companyId, feedId, knockClient?.isAuthenticated, pageSize, source]
  );

  useEffect(() => {
    if (!feed) {
      return;
    }

    feed.listenForUpdates();
    feed.fetch();
    return () => feed.teardown();
  }, [feed]);

  return feed;
};

export default useFeed;

export const InboxProvider = ({ children }: { children: React.ReactNode }) => {
  const { company } = useActiveCompany<true>();
  const [activeItem, setActiveItem] = useState<FeedItem>();

  const tasks = useFeed({
    feedId: config.KNOCK_TASKS_FEED_ID!,
    companyId: company?.id,
  });

  const updates = useFeed({
    feedId: config.KNOCK_UPDATES_FEED_ID!,
    companyId: company?.id,
  });

  // TODO we can maybe load this in isolation since it doesn't add to the count..
  const archived = useFeed({
    feedId: config.KNOCK_TASKS_FEED_ID!,
    companyId: company?.id,
    archived: "only",
  });

  const router = useRouter();
  const feed = isInboxRoute(router.pathname)
    ? router.query
      ? ((router.query.slug as string[] | undefined)?.[0] as InboxFeed | undefined)
      : "tasks"
    : undefined;

  const feeds = useMemo(() => {
    return {
      tasks,
      updates,
      archived,
    };
  }, [tasks, updates, archived]);

  const feedClient = feed ? feeds[feed] : undefined;

  const handleArchivalClick = useCallback(
    async ({
      event,
      analyticsLocation,
      item = activeItem,
    }: {
      event: React.MouseEvent;
      analyticsLocation?: string;
      item?: FeedItem;
    }) => {
      event.stopPropagation();
      if (!item) return;

      if (item.archived_at) {
        await feedClient?.markAsUnarchived(item);
        Analytics.inboxItemStatusChanged({
          id: item.id,
          action: "unarchive",
          location: analyticsLocation,
        });
      } else {
        await feedClient?.markAsArchived(item);
        Analytics.inboxItemStatusChanged({
          id: item.id,
          action: "archive",
          location: analyticsLocation,
        });
        const { metadata, setMetadata } = feedClient?.getState() || {};
        if (metadata && setMetadata && !item.seen_at) {
          setMetadata({ ...metadata, unseen_count: metadata?.unseen_count - 1 });
        }
      }

      setActiveItem(undefined);

      if (feed === "archived") {
        feeds.tasks?.fetch();
      } else {
        feeds.archived?.fetch();
      }
    },
    [activeItem, feed, feeds, feedClient]
  );

  const value = useMemo<InboxContextValue>(() => {
    return {
      activeItem,
      setActiveItem,
      handleArchivalClick,
      feedClient,
      feed,
      feeds,
    };
  }, [activeItem, feed, feeds, feedClient, handleArchivalClick]);

  return <InboxContext.Provider value={value}>{children}</InboxContext.Provider>;
};

export const useInboxContext = () => {
  const context = React.useContext(InboxContext);

  if (!context) {
    throw new Error("useInboxContext must be used in NotificationContext");
  }

  return context;
};

type Selector<T> = (state: FeedStoreState) => T;
type EqualityFn<T> = (objA: T, objB: T) => boolean;

const fallbackStore = createStore<FeedStoreState>(() => {
  return {
    networkStatus: NetworkStatus.loading,
    items: [],
    loading: true,
    metadata: {
      unread_count: 0,
      total_count: 0,
      unseen_count: 0,
    },
    pageInfo: {
      after: null,
      before: null,
      page_size: 0,
    },
    setMetadata: () => ({}),
    setItemAttrs: () => ({}),
    setResult: () => ({}),
    setLoading: () => ({}),
    setNetworkStatus: () => ({}),
    resetStore: () => ({}),
  };
});
export function useFeedStore<T>(
  feed: Feed | null,
  selector: Selector<T>,
  equalityFn?: EqualityFn<T>
) {
  return useStore<StoreApi<FeedStoreState>, T>(feed?.store || fallbackStore, selector, equalityFn);
}

export function useInboxStore<T>(
  feed: keyof InboxContextValue["feeds"],
  selector: Selector<T>,
  equalityFn?: EqualityFn<T>
) {
  const context = useInboxContext();
  const { feeds } = context;

  return useFeedStore<T>(feeds[feed], selector, equalityFn);
}

import Link from "components/common/Link";
import { useWizardContext } from "components/common/Wizard";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import {
  ConnectModalTitle,
  ConnectModalBody,
  ConnectModalContainer,
  ConnectModalFooter,
  ConnectModalSecurityMessage,
} from "components/intro/components/ConnectModal";
import React from "react";
import { IntegrationType } from "graphql/types";
import { BasicListItem } from "../../ListItem";
import { SetupMercuryListItem } from "../../mercury";
import { SetupBrexListItem } from "../../brex";
import { SetupRampListItem } from "../../ramp";
import { SetupMeowListItem } from "../../meow";
import { AddBankListItem } from "../../bank";
import { PlaidLogo } from "../../plaid";
import SetupPushIntegrationListItem from "../../pushIntegrations/SetupPushIntegrationListItem";

export const ConnectIntegrationStep = () => {
  const { goToNextStep } = useWizardContext();
  const { company } = useActiveCompany<true>();

  return (
    <>
      <ConnectModalTitle>Add bank or credit card accounts</ConnectModalTitle>

      <ConnectModalBody>
        <ConnectModalSecurityMessage />

        <ConnectModalContainer>
          <SetupMercuryListItem companyId={company.id} DisplayComponent={BasicListItem} />
          <SetupBrexListItem companyId={company.id} DisplayComponent={BasicListItem} />
          <SetupRampListItem companyId={company.id} DisplayComponent={BasicListItem} />
          <SetupMeowListItem companyId={company.id} DisplayComponent={BasicListItem} />
          <AddBankListItem
            key={IntegrationType.Plaid}
            DisplayComponent={BasicListItem}
            companyId={company.id}
            title="Other banks and credit cards"
            subtitle="via secure connection with Plaid"
            logo={<PlaidLogo />}
          />
          {company.features.centralEnabled && (
            <SetupPushIntegrationListItem
              companyId={company.id}
              integrationType={IntegrationType.Central}
              DisplayComponent={BasicListItem}
            />
          )}
        </ConnectModalContainer>
      </ConnectModalBody>

      <ConnectModalFooter>
        <Link underline color="greenalpha" onClick={goToNextStep}>
          Add an account
        </Link>{" "}
        that has no supported integration.
      </ConnectModalFooter>
    </>
  );
};

import React, { useCallback, useMemo } from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import Link from "next/link";

import { Stack, Text, styled, Field, Select, DateInput, CurrencyInput } from "@puzzle/ui";
import { formatMoney, parseDate, toCalendarDate } from "@puzzle/utils";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { Route } from "lib/routes";
import useCategories from "components/common/hooks/useCategories";

import { ContractRevenueScheduleFragment } from "../graphql.generated";
import { ScheduleFormValues, FormValues, LineField } from "../InvoiceForm/types";

import { SchedulePreviewTable } from "./SchedulePreviewTable";
import { InputBox, InputGroup } from "../shared";

import { Box, color, IconButton, Loader, S, vars } from "ve";
import DescriptionList from "components/common/DescriptionList";
import { compact, startCase } from "lodash";
import {
  AccountingRecognitionCalculation,
  AccountingRecognitionTiming,
  RevenueRecognitionConfigurationConfig,
} from "graphql/types";
import { Add, Delete } from "@puzzle/icons";
import { useRemainingToRecognize } from "./hooks/useRemainingToRecognize";
import { AccountingConfigurationFragment } from "components/dashboard/Accounting/Configurations/graphql.generated";
import { useConfigurationsQuery } from "components/dashboard/Accounting/Configurations/hooks/useConfigurationsQuery";
import { RecognitionPeriodSelector } from "components/dashboard/Accounting/RevenueLines/RecognitionPeriodSelector";

const durationOptions = Array(99)
  .fill(0)
  .map((_, i) => {
    const duration = (i + 1).toString();
    return {
      value: duration,
      label: duration,
    };
  });

type Props = {
  existingSchedule?: ContractRevenueScheduleFragment;
  parentForm?: UseFormReturn<FormValues>;
  scheduleForm: UseFormReturn<ScheduleFormValues>;
  readOnly: boolean;
  lineField?: LineField;
  open?: boolean;
  lineIndex?: number;
  amount: string;
  configurations?: AccountingConfigurationFragment[];
  configurationsLoading?: boolean;
  configuration?: RevenueRecognitionConfigurationConfig;
};

const StyledStack = styled(Stack, {
  gap: "$3",
  padding: "$3",
  flex: 1,
});

export const ScheduleDrawerBody = ({
  existingSchedule,
  parentForm,
  scheduleForm,
  readOnly,
  open,
  lineField,
  lineIndex = 0,
  amount,
  configurations,
  configurationsLoading,
  configuration,
}: Props) => {
  const serviceDuration = scheduleForm?.watch("serviceDuration");
  const startDate = scheduleForm.watch("startDate");
  const endDate = scheduleForm.watch("endDate");
  const periodMethod = scheduleForm.watch("periodMethod");
  const accountingConfigurationId = scheduleForm.watch("accountingConfigurationId");
  const { company, lockedPeriodDate, timeZone } = useActiveCompany<true>();
  const { categoriesByPermaKey } = useCategories();
  const firstBookableDate = lockedPeriodDate ? lockedPeriodDate.add({ days: 1 }) : undefined;
  const parentCustomer = parentForm?.watch("customer.name");
  const parentLineProduct = lineField && parentForm?.watch(`${lineField}.${lineIndex}.product`);
  const parentLineCategory = lineField && parentForm?.watch(`${lineField}.${lineIndex}.category`);

  const dateFormatter = useCompanyDateFormatter({
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });

  const { getConfigurationById } = useConfigurationsQuery();

  const configurationsField = useMemo(() => {
    if (configurationsLoading) return <Loader />;
    const configurationName = getConfigurationById(accountingConfigurationId)?.name;

    return (
      <DescriptionList
        direction="horizontal"
        termWidth="175px"
        items={[
          [
            "Policy",
            !configurations || configurations.length <= 0 ? (
              <Text>
                You do not have any{" "}
                <Link
                  color="blue400"
                  href={Route.accountingConfigurations}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  policies
                </Link>
                {" configured yet."}
              </Text>
            ) : readOnly ? (
              <Text>{configurationName}</Text>
            ) : (
              <Controller
                control={scheduleForm.control}
                name="accountingConfigurationId"
                key="accountingConfigurationIdField"
                render={({ field }) => {
                  return (
                    <Select
                      options={configurations?.map((policy) => ({
                        value: policy.id,
                        label: policy.name,
                      }))}
                      value={field.value}
                      size="small"
                      onSelectionChange={(policyId) => {
                        field.onChange({
                          target: {
                            value: policyId,
                            name: field.name,
                          },
                        });
                        scheduleForm.trigger();
                      }}
                    />
                  );
                }}
              />
            ),
          ],
        ]}
      />
    );
  }, [
    configurationsLoading,
    configurations,
    readOnly,
    scheduleForm,
    getConfigurationById,
    accountingConfigurationId,
  ]);

  const manualEntriesFieldArray = useFieldArray({
    control: scheduleForm.control,
    name: "manualEntries",
  });
  const manualEntries = manualEntriesFieldArray.fields;
  const { remove, append } = manualEntriesFieldArray;
  const entries = scheduleForm.watch("manualEntries");
  const remainingToRecognize = useRemainingToRecognize({ amount, entries });

  const addManualEntry = useCallback(() => {
    append({
      amount: "",
      effectiveDate: "",
    });
  }, [append]);

  const isOverTime = configuration?.method === AccountingRecognitionTiming.OverTime;

  const lineCoaKey = existingSchedule?.invoiceLine?.coaKey;
  const discountLineCoaKey =
    !!existingSchedule?.contractLine.invoiceDiscountLines?.length &&
    existingSchedule?.contractLine.invoiceDiscountLines[0].ledgerCategory?.coaKey;
  const coaKey = lineCoaKey || discountLineCoaKey;
  const category = coaKey
    ? categoriesByPermaKey?.[coaKey].displayName ?? "-"
    : parentLineCategory?.name ?? "-";
  const showConfigurationsSection = configurations && configurations.length > 0;

  return (
    <>
      <StyledStack>
        <DescriptionList
          direction="horizontal"
          termWidth="175px"
          items={[
            ["Total amount", formatMoney({ currency: "USD", amount })],
            [
              "Customer",
              existingSchedule?.contractLine.contract.customer?.name ?? parentCustomer ?? "-",
            ],
            [
              "Product / Service",
              parentLineProduct?.name ?? existingSchedule?.contractLine.description ?? "-",
            ],
            ["Category", category],
          ]}
        />
        {showConfigurationsSection && (
          <Box
            css={{
              borderTop: `1px solid ${color.mauve600}`,
              borderBottom: `1px solid ${color.mauve600}`,
              padding: `${S["3"]} ${S["0"]}`,
            }}
          >
            <Text variant="bodyS" style={{ marginBottom: S["2h"], display: "inline-block" }}>
              Revenue recognition policy
            </Text>
            {configurationsField}
            <InputGroup>
              <InputBox>
                {accountingConfigurationId && (
                  <>
                    <Box
                      css={{
                        borderTop: `1px solid ${color.mauve600}`,
                        padding: `${S["3"]} ${S["0"]}`,
                        marginTop: S["2"],
                      }}
                    >
                      <Text
                        variant="bodyS"
                        style={{ marginBottom: S["2h"], display: "inline-block" }}
                      >
                        Recognition method
                      </Text>
                      <DescriptionList
                        direction="horizontal"
                        termWidth="175px"
                        items={compact([
                          configuration?.method && [
                            "Recognition method",
                            startCase(configuration.method),
                          ],
                          isOverTime &&
                            configuration?.calculation && [
                              "Calculation method",
                              startCase(configuration.calculation),
                            ],
                          isOverTime && ["Interval", "Monthly"],
                          isOverTime &&
                            configuration?.firstPeriodAllocation && [
                              "First period allocation",
                              startCase(configuration.firstPeriodAllocation),
                            ],
                        ])}
                      />
                    </Box>
                  </>
                )}
                {configuration?.calculation === AccountingRecognitionCalculation.Manual && (
                  <Box
                    css={{
                      marginTop: S["2"],
                      padding: S["2"],
                      borderRadius: vars.radii[2],
                      border: `1px solid ${color.mauve600}`,
                    }}
                  >
                    {manualEntries.map((entry, index) => (
                      <Stack
                        key={entry.id}
                        direction="horizontal"
                        css={{ gap: S["2"], marginBottom: S["2"], alignItems: "center" }}
                      >
                        <Controller
                          control={scheduleForm.control}
                          name={`manualEntries.${index}.effectiveDate`}
                          render={({ field }) => {
                            return (
                              <DateInput
                                size="compact"
                                placeholder="Pick a date"
                                value={field.value ? parseDate(field.value) : undefined}
                                onChange={(value) => {
                                  field.onChange({
                                    target: {
                                      value: value && toCalendarDate(value).toString(),
                                      name: field.name,
                                    },
                                  });
                                }}
                              />
                            );
                          }}
                        />
                        <Controller
                          control={scheduleForm.control}
                          name={`manualEntries.${index}.amount`}
                          render={({ field }) => {
                            return (
                              <CurrencyInput
                                size="compact"
                                placeholder="$0.00"
                                value={field.value}
                                onValueChange={({ value }) => {
                                  field.onChange({
                                    target: {
                                      value: value,
                                      name: field.name,
                                    },
                                  });
                                }}
                              />
                            );
                          }}
                        />
                        <IconButton onClick={() => remove(index)}>
                          <Delete />
                        </IconButton>
                      </Stack>
                    ))}
                    <Stack direction="horizontal" css={{ justifyContent: "space-between" }}>
                      <IconButton onClick={addManualEntry}>
                        <Add />
                      </IconButton>
                      <Text>
                        Remaining:{" "}
                        {formatMoney({
                          amount: remainingToRecognize,
                          currency: "USD",
                        })}
                      </Text>
                    </Stack>
                  </Box>
                )}
              </InputBox>
            </InputGroup>
          </Box>
        )}
        {showConfigurationsSection ? (
          configuration?.calculation !== AccountingRecognitionCalculation.Manual &&
          !readOnly && (
            <DescriptionList
              direction="horizontal"
              termWidth="175px"
              items={[
                [
                  "Recognition period",
                  <RecognitionPeriodSelector
                    key="periodSelectorField"
                    method={configuration?.method}
                    calculation={configuration?.calculation ?? undefined}
                    values={{
                      startDate: startDate && parseDate(startDate),
                      endDate: endDate && parseDate(endDate),
                      periodMethod: periodMethod,
                      duration: serviceDuration,
                    }}
                    onEndDateChange={(date) => {
                      scheduleForm.setValue("endDate", date.toString());
                    }}
                    onStartDateChange={(date) => {
                      scheduleForm.setValue("startDate", date.toString());
                    }}
                    onPointInTimeDateChange={(date) => {
                      scheduleForm.setValue("startDate", date.toString());
                      scheduleForm.setValue("endDate", date.toString());
                      scheduleForm.setValue("serviceDuration", "1");
                      scheduleForm.setValue("periodMethod", "endDate");
                    }}
                    onPeriodMethodChange={(periodMethod) => {
                      scheduleForm.setValue("periodMethod", periodMethod);
                    }}
                    onDurationChange={(duration) => {
                      scheduleForm.setValue("serviceDuration", duration);
                    }}
                    onOpenChange={() => {
                      scheduleForm.trigger();
                    }}
                  />,
                ],
              ]}
            />
          )
        ) : (
          <InputGroup>
            <InputBox>
              <Field label="Start date">
                <Controller
                  control={scheduleForm.control}
                  name="startDate"
                  render={({ field }) => {
                    if (readOnly)
                      return (
                        <Text>
                          {existingSchedule?.startDay
                            ? dateFormatter.format(parseDate(existingSchedule.startDay))
                            : ""}
                        </Text>
                      );

                    return (
                      <DateInput
                        size="small"
                        placeholder="Pick a date"
                        value={field.value ? parseDate(field.value) : undefined}
                        onChange={(value) => {
                          field.onChange({
                            target: {
                              value: value && toCalendarDate(value).toString(),
                              name: field.name,
                            },
                          });
                        }}
                        minDate={firstBookableDate}
                        footer={
                          firstBookableDate && (
                            <div>
                              Due to{""}
                              <Link href={Route.lockedPeriod}>locked periods</Link>, you cannot set
                              a start date before{""}
                              {dateFormatter.format(firstBookableDate)}
                            </div>
                          )
                        }
                      />
                    );
                  }}
                />
              </Field>
            </InputBox>
            <InputBox>
              <Field label={readOnly ? "End date" : "Duration month(s)"}>
                <Controller
                  control={scheduleForm.control}
                  name="serviceDuration"
                  render={({ field }) => {
                    if (readOnly) {
                      return (
                        <Text>
                          {existingSchedule?.endDay
                            ? dateFormatter.format(parseDate(existingSchedule.endDay))
                            : ""}
                        </Text>
                      );
                    }

                    return (
                      <Select
                        options={durationOptions}
                        value={field.value}
                        size="small"
                        onSelectionChange={(duration) => {
                          field.onChange(duration);
                        }}
                      />
                    );
                  }}
                />
              </Field>
            </InputBox>
          </InputGroup>
        )}
      </StyledStack>
      {configuration?.calculation !== AccountingRecognitionCalculation.Manual && (
        <StyledStack css={{ overflow: "hidden", flex: "100%", paddingTop: 0 }}>
          {(scheduleForm.formState.isValid || readOnly || existingSchedule) && (
            <SchedulePreviewTable
              readOnly={readOnly}
              scheduleForm={scheduleForm}
              amount={amount}
              existingSchedule={existingSchedule}
              open={open}
              configuration={configuration}
              isSimulation={!existingSchedule}
            />
          )}
        </StyledStack>
      )}
    </>
  );
};

import React from "react";
import {
  ConnectModal,
  ConnectModalBody,
  ConnectModalContainer,
  ConnectModalProps,
  ConnectModalSecurityMessage,
  ConnectModalTitle,
} from "components/intro/components/ConnectModal";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { SetupBillDotComListItem } from "../../billdotcom";
import { BasicListItem } from "../../ListItem";

export const ConnectAccountsPayableModal = ({ onOpenChange, ...rest }: ConnectModalProps) => {
  const { company } = useActiveCompany<true>();

  return (
    <ConnectModal
      {...rest}
      onOpenChange={(open: boolean) => {
        onOpenChange?.(open);
      }}
    >
      <ConnectModalTitle>Add accounts payable/accounts receivable integration</ConnectModalTitle>

      <ConnectModalBody>
        <ConnectModalSecurityMessage />
        <ConnectModalContainer>
          <SetupBillDotComListItem
            companyId={company.id}
            DisplayComponent={BasicListItem}
            onConnect={() => {
              onOpenChange?.(false);
            }}
          />
        </ConnectModalContainer>
      </ConnectModalBody>
    </ConnectModal>
  );
};

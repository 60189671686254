import React from "react";

import { buttonStyles } from "./unstyledButtonStyles.css";

type UnstyledButtonProps = {
  children?: React.ReactNode;
  css?: React.CSSProperties;
} & React.HTMLProps<HTMLButtonElement>

export const UnstyledButton = ({ css, children, ...props }: UnstyledButtonProps) => {
  return (
    <button className={buttonStyles} style={css} {...props} type="button">
      {children}
    </button>
  );
};

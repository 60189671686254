import { formatMoney } from "@puzzle/utils";

import React, { useState } from "react";

import { S, Separator, Text, color, Box, vars, Button, Stack } from "ve";
import { PuzzlePriceBillingInterval } from "graphql/types";
import { calcPriceRounded, calcSubtotal } from "./utils";
import { usePlansPricingModal } from "./usePlansPricingModal";
import { checkoutSummaryStyle } from "./plansPricingModal.css";
import { useCheckoutSummary } from "./useCheckoutSummary";

const BACKGROUND_GRADIENT =
  "linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #1A192A";

const getSummaryLineItemDataTestId = (id: string) => `summary-${id}-line`;

const SummaryLineItem = ({
  dataTestId,
  title,
  price,
}: {
  dataTestId?: string;
  title?: string | React.ReactNode;
  price: string | null;
}) => {
  return (
    <Box
      data-testid={dataTestId}
      css={{
        color: color.gray400,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Text variant="bodyM" color="gray400">
        {title}
      </Text>
      <Text variant="bodyM" color="gray400">
        {price} / mo
      </Text>
    </Box>
  );
};

export const CheckoutSummary = ({
  isPlanCurrentSubscription,
}: {
  isPlanCurrentSubscription: boolean;
}) => {
  const { onCheckout } = useCheckoutSummary();
  const [isOnCheckout, setIsOnCheckout] = useState(false);
  const { preSelectedAddOns, selectedCycle, selectedPlan } = usePlansPricingModal();
  const monthlyEstimate = calcSubtotal(
    preSelectedAddOns ?? [],
    selectedCycle,
    // If PlansPricingModal is open and isPlanCurrentSubscription is true it means the user is add add-on
    // Plan price should not be considered in the calc
    isPlanCurrentSubscription ? undefined : selectedPlan,
    true
  );
  const subtotal = selectedPlan
    ? calcSubtotal(
        preSelectedAddOns ?? [],
        selectedCycle,
        // If PlansPricingModal is open and isPlanCurrentSubscription is true it means the user is add add-on
        // Plan price should not be considered in the calc
        isPlanCurrentSubscription ? undefined : selectedPlan
      )
    : null;
  const showDiscountBadge =
    selectedCycle === PuzzlePriceBillingInterval.Year && !selectedPlan?.isFree;

  const handleOnCheckout = async () => {
    setIsOnCheckout(true);
    await onCheckout();
    setIsOnCheckout(false);
  };

  return (
    <Stack
      gap={S.$2h}
      className={checkoutSummaryStyle}
      css={{
        background: BACKGROUND_GRADIENT,
      }}
    >
      <Text color="gray200" variant="heading3">
        Summary
      </Text>
      {!isPlanCurrentSubscription && selectedPlan && (
        <SummaryLineItem
          dataTestId={getSummaryLineItemDataTestId(selectedPlan?.name)}
          title={`${selectedPlan?.displayName} plan`}
          price={formatMoney({
            currency: "USD",
            amount: calcPriceRounded(selectedCycle, selectedPlan, true),
          })}
        />
      )}

      {(preSelectedAddOns ?? []).length > 0 && (
        <Text variant="bodyXS" color="gray400">
          ADD-ONS:
        </Text>
      )}

      {(preSelectedAddOns ?? []).map((addon) => {
        const estimatedPrice = calcPriceRounded(selectedCycle, addon, true);
        return (
          <SummaryLineItem
            dataTestId={`summary-${addon.name}-line`}
            title={<>&nbsp; &#x2022; {addon.displayName}</>}
            price={formatMoney({ currency: "USD", amount: estimatedPrice })}
            key={addon.id}
          />
        );
      })}

      <Separator />
      {selectedCycle === PuzzlePriceBillingInterval.Year && (
        <SummaryLineItem
          dataTestId="monthly-estimate"
          title="Monthly estimate"
          price={formatMoney(
            {
              currency: "USD",
              amount: monthlyEstimate,
            },
            { truncateValue: false }
          )}
        />
      )}
      <Box
        css={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text variant="headingM" color="gray50">
          Total due
        </Text>
        <Text variant="headingM" color="gray50">
          {subtotal && formatMoney({ currency: "USD", amount: subtotal }, { truncateValue: false })}
          {selectedCycle === PuzzlePriceBillingInterval.Year ? " / year" : " / mo"}
        </Text>
      </Box>

      {showDiscountBadge && (
        <Box
          css={{
            padding: `${S["1h"]} ${S["2"]}`,
            borderRadius: vars.radii[2],
            backgroundColor: color.greenBackground,
          }}
        >
          <Text variant="bodyM" color="green600">
            You are saving 15% with yearly subscriptions
          </Text>
        </Box>
      )}

      <Button variant="primary" fullWidth onClick={handleOnCheckout} loading={isOnCheckout}>
        Continue
      </Button>
    </Stack>
  );
};

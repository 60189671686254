import React, { useEffect, useMemo } from "react";
import { styled, Input, CurrencyInput, Button, IconButton, DateInput, Tooltip } from "@puzzle/ui";
import { formatMoney } from "@puzzle/utils";
import { FullTransactionFragment } from "../graphql.generated";
import UpdateCategoryMenu from "../UpdateField/UpdateCategoryMenu";
import { Add, Clear, RemoveCircle } from "@puzzle/icons";
import { NumberFormatValues } from "react-number-format";
import SelectCategoryInput from "components/transactions/SelectCategoryInput";
import useEditSplits, { SplitInput } from "../hooks/useEditSplits";
import { CategoryFragment, ClassAwareType, IntegrationType } from "graphql/types";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { CalendarDate, parseDate, toCalendarDate } from "@internationalized/date";
import { useDetailPaneContext } from "./DetailPaneContext";
import {
  UpdateCategoryMetricsLocations,
  UpdateCategoryMetricsView,
} from "../hooks/useSingleTransaction";
import { useEffectOnFirstRender } from "components/common/reactHooks";
import ClassificationsSection from "components/common/Classifications/ClassificationsSection";
import { IS_DEV } from "lib/config";
import { Box, S, Text } from "ve";

import { CategorizedBy } from "./CategorizedBy";

const Editor = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$3",
  width: "100%",
});

const Actions = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$1",
});

const ListActions = styled("div", {
  display: "flex",
  flexDirection: "horizontal",
  justifyContent: "space-between",
});

const Amount = styled("span");

const Remainder = styled("div", {
  marginTop: "$1",
  marginLeft: "auto",
  color: "gray400",

  [`${Amount}`]: {
    color: "$red500",
  },
});

const SplitsRowContainer = styled("div", {
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const SplitRow = styled("div", {
  position: "relative",
  width: "100%",
  display: "flex",
  gap: "$1",
  alignItems: "center",
});

const Container = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  gap: "10px",
  flexDirection: "column",
});

type SplitsSectionProps = {
  transaction: FullTransactionFragment;
  categories: CategoryFragment[];
  canEdit: boolean;
  canRecategorizeSplit: (split: SplitInput) => boolean;
};

export const CategorySubsection = ({
  transaction,
  categories,
  canEdit: _canEdit,
  canRecategorizeSplit,
}: SplitsSectionProps) => {
  const { isWithinLockedPeriod } = useActiveCompany<true>();
  const {
    splitRemainder,
    addSplit,
    splits,
    removeSplit,
    persistSplits: _persistSplits,
    updateSplitAmount,
    updateSplitCategory,
    updateSplitDescriptor,
    updateSplitAccrualDate,
    canSave,
    resetSplits,
    deleteSplits: _deleteSplits,
    startSplit,
  } = useEditSplits(transaction);
  const { isEditingSplit, toggleEditingSplit, splitsOpenByDefault } = useDetailPaneContext();
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "long" });

  const showLockWarning = useMemo(
    () =>
      transaction &&
      isWithinLockedPeriod(parseDate(transaction.date)) &&
      !transaction.detail.postedAt,
    [isWithinLockedPeriod, transaction]
  );

  const canEdit = _canEdit && !showLockWarning;

  useEffect(() => {
    if (!isEditingSplit) {
      resetSplits();
    } else {
      if (splits.length === 0) {
        startSplit();
      }
    }
  }, [isEditingSplit, resetSplits]);

  const deleteSplits = () => {
    _deleteSplits();
    toggleEditingSplit();
  };
  const persistSplits = (splits: SplitInput[]) => {
    _persistSplits(splits);
    toggleEditingSplit();
  };

  const handleUpdateCategory = (id: string) => (c: CategoryFragment) => {
    if (isEditingSplit) {
      updateSplitCategory(id, c);
    } else {
      const newSplits = updateSplitCategory(id, c);
      _persistSplits(newSplits); // Bypass edit toggle!
    }
  };

  const handleUpdateDescriptor = (id: string) => (e: React.ChangeEvent<{ value: string }>) =>
    updateSplitDescriptor(id, e.target.value);

  const handleUpdateAmount = (id: string) => (values?: NumberFormatValues) =>
    updateSplitAmount(id, values?.value);

  const handleStartSplitTransaction = () => {
    startSplit();
    toggleEditingSplit();
  };

  const handleRemoveSplit = (id: string) => () => {
    if (splits.length === 1) {
      deleteSplits();
    } else {
      removeSplit(id);
    }
  };

  useEffectOnFirstRender(() => {
    // If splits shouldn't be open on mount or user can't edit, stop
    if (!splitsOpenByDefault || !canEdit) return;

    // No splits yet, start split
    if (transaction.splits.length === 0) {
      handleStartSplitTransaction();
      return;
    }

    // Already has splits, start edit
    toggleEditingSplit();
  });

  const splitContent = useMemo(() => {
    const activeSplits = isEditingSplit ? splits : transaction.splits;

    return (
      <Editor>
        {activeSplits.map((s, i) => (
          <SplitsRowContainer key={i}>
            <SplitRow>
              <SelectCategoryInput
                value={s.category}
                categories={categories}
                onChange={handleUpdateCategory(s.id)}
                canEdit={canRecategorizeSplit(s)}
                tooltipProps={{
                  align: "center",
                  side: "left",
                }}
                maxWidth={108}
                altStyle={true}
              />
              {isEditingSplit && (
                <IconButton
                  onClick={handleRemoveSplit(s.id)}
                  size="small"
                  css={{
                    flexShrink: 0,
                  }}
                >
                  <RemoveCircle size={14} />
                </IconButton>
              )}
            </SplitRow>
            {isEditingSplit ? (
              <SplitRow css={{ marginTop: "$1h" }}>
                <Input
                  autoFocus={i === 0 && splitsOpenByDefault}
                  value={s.descriptor}
                  placeholder={`Description`}
                  aria-label={`Description for split ${i}`}
                  onChange={handleUpdateDescriptor(s.id)}
                  size="small"
                />
                {!transaction.linkedBills.length && (
                  <AccrualDateInput
                    transaction={transaction}
                    accrualDate={s.accrualDate && parseDate(s.accrualDate)}
                    onChange={(accrualDate) => {
                      updateSplitAccrualDate(s.id, accrualDate);
                    }}
                    onClear={() => {
                      updateSplitAccrualDate(s.id, null);
                    }}
                  />
                )}
                <CurrencyInput
                  truncateWhenInactive={false}
                  value={s.amount}
                  aria-label={`Value ${i}`}
                  onValueChange={handleUpdateAmount(s.id)}
                  thousandSeparator
                  prefix="$"
                  size="small"
                  width={128}
                  css={{
                    flexShrink: 0,
                    width: 128,
                  }}
                />
              </SplitRow>
            ) : (
              <SplitRow css={{ marginTop: "$1h", paddingLeft: "$1" }}>
                <Text variant="bodyS" color="gray300">
                  {s.descriptor}
                  {s.accrualDate && (
                    <>
                      <span style={{ marginLeft: S.$1, marginRight: S.$1 }}>{"·"}</span>
                      {dateFormatter.format(parseDate(s.accrualDate))}
                    </>
                  )}
                  {s.amount && (
                    <>
                      <span style={{ marginLeft: S.$1, marginRight: S.$1 }}>{"·"}</span>
                      {formatMoney({ currency: "USD", amount: s.amount?.toString() })}
                    </>
                  )}
                </Text>
              </SplitRow>
            )}
            {!s.id.includes("split") ? (
              <Box css={{ marginTop: S["1h"] }}>
                <ClassificationsSection
                  hideAddButton={transaction.integrationType === IntegrationType.Stripe && !IS_DEV}
                  entity={{
                    id: s.id,
                    name: s.descriptor,
                    type: ClassAwareType.TransactionDetail,
                    classSegments: s.classSegments,
                  }}
                />
              </Box>
            ) : null}
          </SplitsRowContainer>
        ))}
        {isEditingSplit && (
          <>
            <ListActions>
              {!splitRemainder.eq(0) && (
                <Remainder>
                  Remaining:{" "}
                  <Amount>
                    {formatMoney({ currency: "USD", amount: splitRemainder.toString() })}
                  </Amount>
                </Remainder>
              )}
            </ListActions>

            <Actions>
              <Button prefix={<Add />} variant="minimal" onClick={addSplit}>
                Add a split
              </Button>
              <Button
                variant="minimal"
                onClick={toggleEditingSplit}
                size="compact"
                css={{ marginLeft: "auto" }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={!canSave}
                size="compact"
                onClick={() => {
                  persistSplits(splits);
                }}
              >
                Save
              </Button>
            </Actions>
          </>
        )}
      </Editor>
    );
  }, [splits, transaction.splits, isEditingSplit, canEdit, canRecategorizeSplit]);

  if (isEditingSplit) {
    return (
      <>
        {splitContent}
        <CategorizedBy transaction={transaction} css={{ marginTop: "10px" }} />
      </>
    );
  }

  return (
    <Container>
      {transaction.splits.length === 0 ? (
        <Box css={{ display: "flex", alignItems: "center", gap: S["1"], width: "100%" }}>
          <UpdateCategoryMenu
            id={transaction.id}
            categories={categories}
            metrics={{
              location: UpdateCategoryMetricsLocations.TransactionsDrawer,
              component: UpdateCategoryMetricsView.CategoryModal,
            }}
            altStyle={true}
          />
        </Box>
      ) : (
        <>{splitContent}</>
      )}
      <CategorizedBy transaction={transaction} />
    </Container>
  );
};

const AccrualDateInput = ({
  transaction,
  accrualDate,
  onChange,
  onClear,
  ...props
}: {
  transaction: FullTransactionFragment;
  accrualDate?: CalendarDate | null;
  onChange: (date: CalendarDate | null) => void;
  onClear: React.MouseEventHandler<HTMLButtonElement>;
  css?: React.CSSProperties;
}) => {
  const { lockedPeriodDate } = useActiveCompany<true>();

  return (
    <Tooltip open={accrualDate ? false : undefined} content="Accrual date" arrow={false}>
      <Box {...props}>
        <div style={{ position: "relative" }}>
          <DateInput
            value={accrualDate}
            onChange={(value) => onChange(value && toCalendarDate(value))}
            hideIcon={!!accrualDate}
            inputReadOnly
            minDate={lockedPeriodDate && parseDate(lockedPeriodDate.toString()).add({ days: 1 })}
            maxDate={parseDate(transaction.date)}
            css={{
              width: accrualDate ? undefined : 41,
              height: 35,
              paddingRight: "$2h",
              overflow: "hidden",
            }}
          />
          {accrualDate && (
            <IconButton
              onClick={onClear}
              size="small"
              css={{
                position: "absolute",
                top: 10,
                right: 6,
              }}
            >
              <Clear size={12} color="currentColor" />
            </IconButton>
          )}
        </div>
      </Box>
    </Tooltip>
  );
};

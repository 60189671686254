import { IntegrationType } from "graphql/types";
import React from "react";
import { IntegrationsListItemBaseProps } from "../List";
import Logo from "./Logo";
import { IntegrationDisplayComponent } from "../shared/types";

type SetupExpensifyListItemProps = {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}

export const TITLE = "Expensify";
export const SUBTITLE = "Link employee spend data to your financial statements and tax planning.";

const SetupExpensifyListItem = ({ companyId, DisplayComponent }: SetupExpensifyListItemProps) => {
  return (
    <DisplayComponent
      integrationType={IntegrationType.Expensify}
      title={TITLE}
      subtitle={SUBTITLE}
      logo={<Logo />}
    />
  );
};

export default SetupExpensifyListItem;

import React, { useMemo } from "react";
import { Dialog, Text, useToasts, styled } from "@puzzle/ui";
import CategorySearch from "./../../transactions/CategorySearch";
import { useChartOfAccounts } from "components/dashboard/Accounting/shared/useChartOfAccounts";
import { keyBy } from "lodash";
import {
  useRecategorizeMultipleTransactionsMutation,
  BasicTransactionFragment,
  useSetSplitsMutation,
} from "./graphql.generated";
import { DetailConfirmedState, CategoryFragment } from "graphql/types";
import { pluralize, zIndex } from "@puzzle/utils";
import Loader from "components/common/Loader";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import Big from "big.js";

type BulkRecategorizeProps = {
  transactions: BasicTransactionFragment[];
  categories: CategoryFragment[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  companyId: string;
  toggleRowSelection?: (rows: BasicTransactionFragment[]) => void;
}

const WarningText = styled(Text, {
  fontWeight: "$heavy",
  color: "$gray50",
  padding: "$0h $3",
  fontSize: "$bodyS",
});

const BulkRecategorizeModalPaginated = ({
  transactions,
  open,
  onOpenChange,
  categories,
  companyId,
  toggleRowSelection,
}: BulkRecategorizeProps) => {
  const { categories: coaData } = useChartOfAccounts();
  const { toast } = useToasts();
  const [recategorizeMultipleTransactionsMutation, { loading }] =
    useRecategorizeMultipleTransactionsMutation();
  const [persistSplitsMutation] = useSetSplitsMutation();
  const categoryTypes = useMemo(() => {
    const types = coaData.map((account) => {
      return { id: account.displayId, type: account.type };
    });
    return keyBy(types, "id");
  }, [coaData]);

  const eligible = useMemo(
    () => transactions.filter((t) => t.detail.confirmedState !== DetailConfirmedState.Finalized),
    [transactions]
  );

  const finalizedTotal = useMemo(
    () => transactions.length - eligible.length,
    [transactions, eligible]
  );

  const handleSplits = (transaction: BasicTransactionFragment, category: CategoryFragment) => {
    return persistSplitsMutation({
      variables: {
        input: {
          transactionId: transaction.id,
          splits: transaction.splits.map((c) => ({
            descriptor: c.descriptor,
            ledgerCoaKey: category.permaKey,
            amount: Big(c.amount || 0).toString(),
          })),
        },
      },
    });
  };

  const handleSubmit = (category: CategoryFragment) => {
    eligible.map((t) => {
      if (t.splits.length > 0) {
        handleSplits(t, category);
      }
    });

    const updates = eligible
      .filter((t) => t.splits.length === 0)
      .map((t) => {
        return { id: t.detail.transactionId, ledgerCoaKey: category.coaKey! };
      });

    return recategorizeMultipleTransactionsMutation({
      variables: {
        input: {
          companyId,
          updates,
        },
      },
      onCompleted(d) {
        onOpenChange(false);
        const numUpdated = d.recategorizeMultipleTransactions.updated.length;

        // Deselect all rows
        if (toggleRowSelection) {
          toggleRowSelection(transactions);
        }

        toast({
          message:
            numUpdated !== transactions.length
              ? `${numUpdated} out of ${transactions.length} transactions categorized. Already finalized or linked transactions are not updated.`
              : `${pluralize(transactions.length, "transaction")} categorized`,
        });

        Analytics.transactionsBulkCategorized({ category: category.coaKey! });
      },
    });
  };

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      size="medium"
      style={{
        zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("modal") : "auto",
      }}
    >
      <Dialog.Title>Categorize transactions</Dialog.Title>
      <Text css={{ color: "$gray400", padding: "$2 $3 $0 $3", textVariant: "$bodyS" }}>
        Choose a category to assign to selected transactions.{" "}
      </Text>
      {finalizedTotal > 0 && (
        <WarningText>
          Of the selected transactions,{" "}
          {pluralize(finalizedTotal, " already finalized transaction")} will not be updated.{" "}
        </WarningText>
      )}

      {loading ? (
        <Loader css={{ padding: "$4" }} />
      ) : (
        <CategorySearch
          open={open}
          categories={categories}
          categoryTypes={categoryTypes}
          onChange={(val) => handleSubmit(val)}
        />
      )}
    </Dialog>
  );
};

export default BulkRecategorizeModalPaginated;

import React from "react";
import { AlertDialog } from "@pz/ui/AlertDialog";
import { usePlansPricingModal } from "../PlansPricingModal/usePlansPricingModal";
import { useCheckoutSummary } from "../PlansPricingModal/useCheckoutSummary";
import { color } from "ve";
import { useAddOnConfirmationDialog } from "./useAddOnConfirmationDialog";
import { useAddOnConfirmationDialogStyle } from "./addOnConfirmationDialog.css";
import { joinWithCommasAnd } from "../PlansPricingModal/utils";

export const AddOnConfirmationDialog = () => {
  const { preSelectedAddOns } = usePlansPricingModal();
  const { addAddOnsToStripeSubscription } = useCheckoutSummary();
  const { isOpen, hideAddOnConfirmationDialog } = useAddOnConfirmationDialog();
  return (
    <AlertDialog
      className={useAddOnConfirmationDialogStyle}
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) hideAddOnConfirmationDialog();
      }}
      size="small"
    >
      <AlertDialog.Title showClose>
        Subscribe to {joinWithCommasAnd(preSelectedAddOns?.map((addOn) => addOn.displayName) ?? [])}
      </AlertDialog.Title>
      <AlertDialog.Body css={{ color: color.gray200 }}>
        Would you like to add{" "}
        {joinWithCommasAnd(preSelectedAddOns?.map((addOn) => addOn.displayName) ?? [])} to your
        existing subscription?
      </AlertDialog.Body>
      <AlertDialog.Footer>
        <AlertDialog.CancelButton>No thanks</AlertDialog.CancelButton>
        <AlertDialog.ConfirmButton
          onClick={() => addAddOnsToStripeSubscription()}
          variant="primary"
        >
          Yes
        </AlertDialog.ConfirmButton>
      </AlertDialog.Footer>
    </AlertDialog>
  );
};

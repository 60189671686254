import { styled } from "@puzzle/ui";

export const Cards = styled("div", {
  display: "grid",
  gridAutoFlow: "row",
  columnGap: "$2",
  rowGap: "$1h",

  "@media print": {
    display: "none",
  },

  variants: {
    graph: {
      true: {
        gridAutoRows: "230px",
      },
      false: {
        gridAutoRows: "100px",
      },
    },

    columnCount: {
      three: {
        gridTemplateColumns: "none",
        gridTemplateRows: `repeat(3, minmax(0, 1fr))`,
        "@l": {
          gridTemplateRows: `none`,
          gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
        },
      },
      two: {
        gridTemplateColumns: "none",
        gridTemplateRows: `repeat(2, minmax(0, 1fr))`,
        "@l": {
          gridTemplateRows: `none`,
          gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
        },
      },
    },
  },
});

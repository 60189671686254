import React, { useCallback, useMemo } from "react";
import { useLocalStorage, useEffectOnce } from "react-use";
import { merge } from "lodash";
import * as yup from "yup";
import { isMatch } from "date-fns";
import { useRouter } from "next/router";

import { GroupBy, RangePreset, ReportGroupBy, TabItem } from "@puzzle/ui";
import { CalendarDateString, parseDate, isSameDateValue } from "@puzzle/utils";

import useReportTimePeriods, { useIsInProgress } from "components/reports/useReportTimePeriods";
import { getViewOptions } from "components/companies/common";
import { DetailLevel } from "components/dashboard/Report/types";
import {
  DynamicReportType,
  LedgerReportFilterInput,
  LedgerView,
  ReportTimePeriod,
  ReportingClassType,
} from "graphql/types";
import { reportError } from "lib/errors";
import { allowableCustomDateRange, getAllReportPresets } from "./reportPresets";
import { isGroupedByPeriod } from "components/dashboard/Report/reportClassificationUtils";
import { useReportContext } from "./ReportContext";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

export type StickyState = {
  view: LedgerView;
  preset: RangePreset;
  groupBy: GroupBy;
  groupByPl: ReportGroupBy;
  groupByBs: GroupBy;
  detailLevel: DetailLevel;
  activeTab: DynamicReportType;
  start: CalendarDateString;
  end: CalendarDateString;
  filter: LedgerReportFilterInput;
};

const DEFAULT_PRESETS = getAllReportPresets();
const DEFAULT_PRESET = DEFAULT_PRESETS.find((x) => x.key === "last3Months") || DEFAULT_PRESETS[0];

const EXPECTED_DATE_STRING_FORMAT = "yyyy-MM-dd";
export const TABS: TabItem[] = [
  {
    title: "Cash Activity",
    value: DynamicReportType.CashActivityReport,
  },
  {
    title: "Profit & Loss",
    value: DynamicReportType.ProfitAndLoss,
  },
  {
    title: "Balance Sheet",
    value: DynamicReportType.BalanceSheet,
  },
];

const dateStringValidator = (x?: string) => {
  try {
    if (x) parseDate(x);
  } catch (e) {
    return false;
  }

  return Boolean(x && isMatch(x, EXPECTED_DATE_STRING_FORMAT));
};

const yupOneOf = (args: string[]) => yup.string().required().oneOf(args);
const yupDateString = yup
  .string()
  .required()
  .test("YYYY-MM-DD", (x) => dateStringValidator(x))
  .required();

const localStorageSchema = yup
  .object({
    start: yupDateString,
    end: yupDateString,
    detailLevel: yupOneOf(Object.values(DetailLevel)),
    activeTab: yupOneOf(TABS.map(({ value }) => value)),
    view: yupOneOf(getViewOptions().map(({ value }) => value)).notRequired(),
    groupBy: yupOneOf(Object.values(GroupBy)),
    groupByPl: yup.string(),
    groupByBs: yup.string(),
    filter: yup
      .object()
      .shape({
        segments: yup.object().when("activeTab", {
          is: (activeTab: string) => activeTab === DynamicReportType.ProfitAndLoss,
          then: yup.object().shape({
            reportingClassType: yupOneOf(Object.values(ReportingClassType)).required(),
            reportingClassId: yup.string().required(),
            segmentIds: yup.array().of(yup.string().required()).required(),
          }),
          otherwise: yup.object().notRequired(),
        }),
      })
      .noUnknown(true)
      .strict(true),
    preset: yup
      .object()
      .required()
      .shape({
        key: yupOneOf(DEFAULT_PRESETS.map(({ key }) => key)),
        label: yupOneOf(DEFAULT_PRESETS.map(({ label }) => label)),
        view: yupOneOf(DEFAULT_PRESETS.map(({ view }) => view as string)),
        groupBy: yupOneOf(Object.values(GroupBy)),
      })
      .required()
      .noUnknown(true)
      .strict(true),
  })
  .required()
  .noUnknown(true)
  .strict(true);

const isValidSchema = (state: StickyState) => {
  try {
    return localStorageSchema.validateSync(state);
  } catch (error) {
    reportError("Sticky dashboard report: invalid schema", { error });
    return false;
  }
};

export const useShowSpotlightByDefault = () => {
  const { query } = useRouter();
  return query["showSpotlight"] === "true";
};

const useStickyReportContextValue = (): {
  stickyOptions: StickyState;
  setStickyOptions: (nextState: Partial<StickyState>) => void;
  dateTimeGroupBy: GroupBy;
  timePeriods: ReportTimePeriod[];
  isInProgress: boolean;
  activeGroupBy: ReportGroupBy;
  isGroupByLoading: boolean;
  presets: RangePreset[];
} => {
  const { company } = useActiveCompany();
  const showSpotlightByDefault = useShowSpotlightByDefault();
  const { normalizedReportingClasses } = useReportContext();
  const presets = DEFAULT_PRESETS;
  const preset = DEFAULT_PRESET;
  const [initialStart, initialEnd] = preset.range!();

  // This is the value set as default on the local storage.
  // We need to keep the view undefined to avoid setting it to local storage initially.
  // We want to set the view to the local storage only if the user changes the filter manually.
  // Otherwise, we need to load the default value from the company setting (and if we set it initially,
  // it will never load from the company setting).
  // The priority for view value is:
  // 1 Local storage
  // 2 Company setting
  const initialStickyOptions = useMemo(() => {
    return {
      preset,
      view: undefined,
      groupBy: preset.groupBy ?? GroupBy.Total,
      groupByPl: preset.groupBy ?? GroupBy.Total,
      groupByBs:
        preset.groupBy && preset.groupBy !== GroupBy.Total ? preset.groupBy : GroupBy.Month,
      detailLevel: showSpotlightByDefault ? DetailLevel.Detailed : DetailLevel.Compact,
      activeTab: TABS[0].value as DynamicReportType,
      start: initialStart.toString(),
      end: initialEnd.toString(),
      filter: {},
    };
  }, [preset, initialStart, initialEnd, showSpotlightByDefault]);

  const [_stickyOptions, _setStickyOptions] = useLocalStorage(
    "pz:dashboard-report-context-options",
    initialStickyOptions,
    {
      raw: false,
      serializer: JSON.stringify,
      /**
       * Validate each time we deserialize and pull json out of local storage,
       * if it's invalid return the initial options. `deserializer` will automatically
       * catch if it's not valid JSON.
       */
      deserializer: (value) => {
        const parsed = JSON.parse(value) as StickyState;
        if (isValidSchema(parsed)) return parsed;

        return initialStickyOptions;
      },
    }
  );
  const enableClassesAndDepts =
    (isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsM1) ?? false) &&
    _stickyOptions?.activeTab === DynamicReportType.ProfitAndLoss;

  /**
   * If the preset dates are stale
   * (the end date of the preset doesn't match up with the local storage end date),
   * refresh start and end with the current preset
   */
  useEffectOnce(() => {
    if (!_stickyOptions) return;

    if (_stickyOptions.preset.key !== "custom") {
      const currentPreset = presets.find(({ key }) => key === _stickyOptions.preset.key);
      if (!currentPreset?.range) return;

      if (!isSameDateValue(currentPreset.range()[1], parseDate(_stickyOptions.end), "month")) {
        setStickyOptions({
          preset: currentPreset,
          start: start.toString(),
          end: end.toString(),
        });
      }
    }
    if (_stickyOptions.preset.key === "custom") {
      const dateRange = allowableCustomDateRange(_stickyOptions.start, _stickyOptions.end);
      if (!dateRange.isAllowed) {
        setStickyOptions({
          start: initialStart.toString(),
          end: initialEnd.toString(),
        });
      }
    }
  });

  // if filter is missing, set a default empty filter
  useEffectOnce(() => {
    if (!_stickyOptions) return;

    setStickyOptions({
      filter: {},
    });
  });

  useEffectOnce(() => {
    if (!_stickyOptions || !showSpotlightByDefault) return;

    setStickyOptions({
      detailLevel: DetailLevel.Detailed,
      activeTab: TABS[0].value as DynamicReportType,
    });
  });

  const setStickyOptions = useCallback(
    (nextState: Partial<StickyState>) => {
      let current = _stickyOptions;
      if (!_stickyOptions) {
        current = merge(initialStickyOptions, _stickyOptions);
      }

      // don't merge the filter state just update it
      if (current && nextState.filter) {
        current.filter = nextState.filter;
        delete nextState.filter;
      }

      _setStickyOptions(merge(current, nextState));
    },
    [initialStickyOptions, _setStickyOptions, _stickyOptions]
  );

  const start = _stickyOptions?.start ? parseDate(_stickyOptions?.start) : initialStart;
  const end = _stickyOptions?.end ? parseDate(_stickyOptions?.end) : initialEnd;

  const normalizedReportingClassesKeys = Object.keys(normalizedReportingClasses);
  const activeGroupBy = useMemo(() => {
    if (!_stickyOptions) {
      return GroupBy.Month;
    }
    if (
      _stickyOptions.groupBy === GroupBy.Total &&
      _stickyOptions.activeTab === DynamicReportType.BalanceSheet
    ) {
      return _stickyOptions.groupByBs || GroupBy.Month;
    }

    if (!enableClassesAndDepts) return _stickyOptions.groupBy;

    // If we're grouped by reporting class
    // we know the existing reporting classes
    // and groupByPl is not a known reporting class use groupBy instead
    if (
      !isGroupedByPeriod(_stickyOptions.groupByPl) &&
      normalizedReportingClassesKeys.length > 0 &&
      !normalizedReportingClassesKeys.includes(_stickyOptions.groupByPl)
    ) {
      setStickyOptions({ groupByPl: _stickyOptions.groupBy });
      return _stickyOptions.groupBy;
    }

    return _stickyOptions.groupByPl;
  }, [_stickyOptions, enableClassesAndDepts, normalizedReportingClassesKeys, setStickyOptions]);

  let dateTimeGroupBy = preset.groupBy || GroupBy.Month;
  if (isGroupedByPeriod(activeGroupBy)) {
    dateTimeGroupBy = activeGroupBy as GroupBy;
  }

  const timePeriods = useReportTimePeriods({
    start,
    end,
    groupBy: dateTimeGroupBy,
  });

  const isInProgress = useIsInProgress(timePeriods);

  const isGroupByLoading =
    !isGroupedByPeriod(activeGroupBy) && normalizedReportingClassesKeys.length <= 0;

  const stickyOptionsValue = _stickyOptions ?? initialStickyOptions;

  const view = isPosthogFeatureFlagEnabled(FeatureFlag.CashAcrrualReportDefaultFilter)
    ? company?.features.viewPreference
    : LedgerView.Cash;

  return {
    stickyOptions: {
      ...stickyOptionsValue,
      // Respecting the priority for view value
      // 1 Local storage
      // 2 Company setting
      view: stickyOptionsValue.view ?? view ?? LedgerView.Cash,
    },
    setStickyOptions,
    activeGroupBy,
    isGroupByLoading,
    dateTimeGroupBy,
    timePeriods,
    isInProgress,
    presets,
  };
};

const StickyReportContext = React.createContext<ReturnType<
  typeof useStickyReportContextValue
> | null>(null);
export default StickyReportContext;

export const useStickyReportContext = () => {
  const context = React.useContext(StickyReportContext);
  if (context === null) {
    throw new Error(
      "useStickyReportContext must be used as a child within StickyReportContextProvider"
    );
  }
  return context;
};

export const StickyReportContextProvider = ({ ...props }) => {
  return <StickyReportContext.Provider value={useStickyReportContextValue()} {...props} />;
};

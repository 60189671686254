import React, { useCallback, useState } from "react";
import { formatMoney } from "@puzzle/utils";
import useMonetization from "../useMonetization";
import { SubscriptionBillingCycle } from "../types";
import { Box, Button, S, Separator, Stack, Text } from "ve";
import { YearlyDiscountBadge } from "../PricingModal/Badges";
import { Check } from "@puzzle/icons";
import { colors } from "ve/theme/colors";
import {
  containerStyle,
  planNameStyle,
  specialHeaderBoxRecipe,
  tierContentStackRecipe,
} from "./pricingPackageV2Modal.css";
import { getSubscriptionCycleLabel } from "./utils";
import Link from "components/common/Link";

export type PackagingPlanV2 = {
  name: string;
  description: string;
  header?: string;
  annualPrice?: string;
  stripeAnnualId?: string;
  monthlyPrice?: string;
  stripeMonthlyId?: string;
  expenses: string;
  seats: string;
  isFree?: boolean;
  isMostPopular?: boolean;
  isSignatureOffer?: boolean;
  isCustom?: boolean;
  features: string[];
  featuresHeader: string | React.ReactNode;
  customPlanDemoCallUrl?: string;
};

type PlanBoxProps = {
  plan: PackagingPlanV2;
  onSubscribe: () => Promise<void>;
}

type UpgradeButtonProps = {
  isFree?: boolean;
  isCustom?: boolean;
  customPlanDemoCallUrl?: string;
  planName: string;
  onClick: () => void;
  loading: boolean;
}

const UpgradeButton = ({
  isFree,
  isCustom,
  customPlanDemoCallUrl,
  planName,
  onClick,
  loading,
}: UpgradeButtonProps) => {
  if (isCustom && customPlanDemoCallUrl) {
    return (
      <Link
        external
        href={customPlanDemoCallUrl}
        target="_blank"
        css={{
          textDecoration: "none !important",
        }}
      >
        <Button
          data-testid={`upgrade-button-tier-${planName.toLowerCase()}`}
          variant="gold"
          fullWidth
          onClick={onClick}
        >
          Book a demo
        </Button>
      </Link>
    );
  }
  return (
    <Button
      data-testid={`upgrade-button-tier-${planName.toLowerCase()}`}
      fullWidth
      variant={isFree ? "gold-outline" : "gold"}
      loading={loading}
      onClick={onClick}
    >
      {isFree ? "Get started" : "Upgrade"}
    </Button>
  );
};

const FeatureItem = ({ label }: { label: string }) => {
  return (
    <Text color="gray200" variant="headingS">
      <Stack direction="horizontal" gap={S.$1} css={{ alignItems: "center" }}>
        <Box css={{ width: 12 }}>
          <Check color={colors.greenalpha} size={12} />
        </Box>{" "}
        {label}
      </Stack>
    </Text>
  );
};

export const PlanBox = ({ plan, onSubscribe }: PlanBoxProps) => {
  const {
    name,
    monthlyPrice,
    annualPrice,
    expenses,
    seats,
    isFree,
    featuresHeader,
    features,
    description,
    isSignatureOffer,
    isMostPopular,
    header,
    isCustom,
    customPlanDemoCallUrl,
  } = plan;

  const [isSubscribing, setIsSubscribing] = useState(false);
  const { selectedCycle } = useMonetization();
  const totalPrice = formatMoney(
    {
      currency: "USD",
      amount:
        (selectedCycle === SubscriptionBillingCycle.Monthly ? monthlyPrice : annualPrice) ?? "0",
    },
    { truncateValue: true }
  );

  const isSpecial = isSignatureOffer || isMostPopular;

  const subscribeTo = useCallback(async () => {
    setIsSubscribing(true);
    await onSubscribe();
    setIsSubscribing(false);
  }, [onSubscribe]);

  const shouldShowYearlyDiscount = selectedCycle === SubscriptionBillingCycle.Yearly && !isFree;
  return (
    <Box className={containerStyle}>
      <Box
        className={specialHeaderBoxRecipe({
          background: isSpecial ? (isSignatureOffer ? "green" : "purple") : "transparent",
        })}
      >
        {header}{" "}
      </Box>
      <Stack
        gap="0"
        className={tierContentStackRecipe({
          borderRadius: isSpecial ? "special" : "default",
          border: isSignatureOffer ? "green" : "default",
        })}
      >
        <Stack
          gap={S["1h"]}
          css={{
            padding: S["2h"],
            background: "rgba(0, 0, 0, 0.20)",
          }}
        >
          <Text data-testid={`title-tier-${name.toLowerCase()}`} className={planNameStyle}>
            {name}
          </Text>
          <Box css={{ height: S["8"] }}>
            <Text variant="bodyM" color="gray400">
              {description}
            </Text>
          </Box>
        </Stack>

        <Stack gap={S.$2} css={{ padding: S.$2h }}>
          {isCustom && (
            <Text variant="heading1" color="gray200" weight="bold">
              Custom
            </Text>
          )}
          {!isCustom && (
            <Stack
              gap={S.$0h}
              direction="horizontal"
              css={{
                alignItems: "center",
              }}
            >
              <Text variant="heading1" color="gray200" weight="bold">
                {totalPrice}
              </Text>
              <Text variant="headingS" color="gray400">
                {getSubscriptionCycleLabel(selectedCycle)}
              </Text>
              {shouldShowYearlyDiscount && <YearlyDiscountBadge />}
            </Stack>
          )}
          <UpgradeButton
            isFree={plan.isFree}
            isCustom={plan.isCustom}
            customPlanDemoCallUrl={customPlanDemoCallUrl}
            onClick={subscribeTo}
            loading={isSubscribing}
            planName={name}
          />
          <FeatureItem label={expenses} />
          <FeatureItem label={seats} />
          <Separator />
          {featuresHeader}
          {features.map((feature) => (
            <FeatureItem key={feature} label={feature} />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};
